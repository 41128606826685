<template>
<div :class="`input-toggle flex align-center ${currentValue != reverse ? 'selected' : ''} ${isFocus ? 'focused' : ''}`">
        
    <div class="flex column flex-fill">
        <div class="bold" v-if="label"><i v-if="icon" :class="`icon icon-${icon} mr10`" style="color: var(--gray)"></i>{{label}}</div>
        <div class="secondary-text" v-if="description">{{description}}</div>
    </div>
    
    <slot name="actions"></slot>
    <div :class="`toggle ${color}`">
        <div class="thumb"></div>
        <button @click.stop="currentValue = !currentValue" @focus="isFocus=true" @blur="isFocus=false"></button>
    </div>
    
</div>
</template>

<script>
export default {
    props: {
        value: {
            type: [Number, Boolean],
            default: 0
        },
        label: {
            type: String,
            default: null
        },
        description: {
            type: String,
            default: null
        },
        color: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            default: null
        },
        reverse: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            currentValue: null,
            isFocus: false,

        }
    },
    mounted() {
        this.currentValue = !!this.value;
    },
    methods: {
        
    },
    watch: {
        value: function(n, o) {
            this.currentValue = !!n;
        },
        currentValue: function(n, o) {
            if(o === null) return;

            this.$emit('input', !!n);
            if(n !== o)
            {
                this.$emit('OnValueChanged', n);
            }
        }
    }
}
</script>

<style lang="scss">
.input-toggle {

    position: relative;

    .toggle {
        flex-shrink: 0;
        width: 60px; 
        height: 30px;
        margin-left: 24px;
        background-color: var(--gray-2);
        border-radius: 24px;
        transition: all 0.2s linear;
        position: relative;
        outline: 2px solid transparent;

        .thumb {
            width: 22px;
            height: 22px;
            border-radius: 50%;
            background: var(--gray-3);
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            left: 6px;
            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);
            pointer-events: none;
            transition: all 0.2s linear;
        }

        button {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            right: 0;
            margin: 0px;
            opacity: 0;
            cursor: pointer;
        }

    }

    &.selected {
        .toggle {
            background-color: var(--purple);
            &.red {
                background-color: var(--red);
            }
            &.blue {
                background-color: var(--blue-2);
            }
            &.green {
                background-color: var(--green);
            }
            &.yellow {
                background-color: var(--yellow);
            }
        }
        .thumb {
            left: calc(100% - 28px);
        }
    }

    &.focused {
        .toggle {
            outline: 2px solid rgba(var(--purple-rgb), 0.5);
        }
    }

}

.dark {
    .input-toggle {
        .toggle {
            background-color: var(--dark-outline);
            .thumb {
                background: var(--gray-3);
            }
        }

        &.selected {
            .toggle {
                background-color: var(--cyan);
                &.red {
                    background-color: var(--red);
                }
                &.blue {
                    background-color: var(--blue-2);
                }
                &.green {
                    background-color: var(--green);
                }
                &.yellow {
                    background-color: var(--yellow);
                }
            }
        }

        &.focused {
            .toggle {
                outline: 2px solid var(--purple);
            }
        }

    }
}
</style>
