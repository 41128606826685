export default {
    state: {
        user: null,
        local: null,
        turn: null,
        redirect: null,
        offile: false,
        notifications: [],
        unreadNotifications: 0,
        dark: false,
        ordersTab: null,
        announcement: null
    },
    mutations: {
        login(state, payload) {
            
            payload.app.$emit('Loading');
            try {
                var decoder = require('jwt-decode');
                var data = decoder(payload.token);
                
                window.Util.SetServer(window.Util.server, payload.token);

                window.axios.get('/logged').then(res => {
                    state.notifications = res.data.notifications;
                    state.user = res.data.account;
                    state.local = res.data.local;
                    state.turn = res.data.turn;
                    state.announcement = res.data.announcement;
                    console.log('user set successfully');
                    console.log(res.data);
                    
                    console.log(payload.app.$socket.client);
                    //payload.app.$socket.client.connect();
                    payload.app.$emit('Response', {error: false});

                    var local = res.data.local;
                    var route = {
                        admin: '/bulletin',
                        sub_admin: '/bulletin',
                        local_admin: '/dashboard',
                        cashier: '/orders',
                        delivery: '/delivery/pending',
                        kitchen: '/orders',
                        waiter: '/orders'
                    };  

                    if(local && local.initial_setup)
                    {
                        payload.app.$router.push('/welcome');
                    }
                    else if(state.redirect)
                    {
                        console.log(state.redirect);
                        payload.app.$router.push(state.redirect);
                    }
                    else
                    {
                        payload.app.$router.push(route[res.data.account.role]);
                    }

                    payload.app.$children[0].loggingIn = false;

                }).catch(err => {
                    console.log('login error');
                    payload.app.$children[0].loggingIn = false;
                    NativeStorage.remove('token');
                    payload.app.Info('Datos incorrectos');
                });

            } catch (error) {
                console.log('error login token');
                NativeStorage.remove('token');
                payload.app.Info('Ha ocurrido un error');
            }
            
        },
        logout(state, payload) {
            
            state.user = null;
            state.local = null;
            state.turn = null;
            state.redirect = null;
            state.offile = false;
            var axios = require('axios');
            window.axios = axios.create({
                baseURL: process.env.VUE_APP_BACKEND_URL+'admin/'
            });
            /*
            window.axios.interceptors.request.use((response) => {
                payload.app.$emit('RequestGTAG', response);
                return response;
            }, (error) => {
                payload.app.$emit('RequestErrorGTAG', error);
                return Promise.reject(error);
            });
            */
            if(process.env.CORDOVA_PLATFORM == 'android' || process.env.CORDOVA_PLATFORM == 'ios')
            {
                window.plugins.OneSigna.setSubscription(false);
            }

        },
        setLocal(state, payload) {
            state.local = payload;
        },
        setTurn(state, payload) {
            state.turn = payload;
        },
        setRedirect(state, payload) 
        {
            state.redirect = payload;
        },
        notificationsCount(state, payload) {
            state.unreadNotifications = payload;
        },
        setDarkMode(state, payload) {
            state.dark = payload;
        },
        setOrdersTab(state, payload) {
            state.ordersTab = payload;
        }
    },
    getters: {
        
    },
    actions: {
        
    }
}