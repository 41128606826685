<template>
<vertical-content class="pa-body" @OnBottom="LoadMoreSurveys()" ref="verticalContent">

    <div v-if="rating">

        <div class="secondary-text ta-center" v-if="!surveys.length">No hay evaluaciones</div>
        <div v-else>
            <div class="flex mb20">
                <div class="f18 flex-fill">General</div>
                
            </div>


            <div class="hdvm mb20">
                <div>
                    <div class="section flex align-center h100">
                        <avatar icon="star" class="mr10" color="#f3bb51"></avatar>
                        <div class="bold flex-fill">Promedio</div>
                        <div class="f18">{{rating}} <i class="icon icon-star ml5 cyellow"></i></div>
                    </div>
                </div>

                <div>
                    <div class="section h100">
                        <div class="secondary-text ta-center" v-if="!this.tags">No hay tags evaluados</div>
                        <div v-for="(val, tag) in tags" :key="tag" class="flex mb5">
                            <badge :count="val" color="blue" class="mr10" :round="false"></badge>
                            <span>{{tag}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex mb20 align-center">
                <div class="f18 flex-fill">Evaluaciones de Productos</div>
                
                <input-field
                    class=""
                    placeholder="Buscar por nombre..."
                    v-model="search"
                    pre="search"
                    cleareable
                    inputClass="secondary"
                    :icon="mobile ? 'search' : ''">
                </input-field>
            </div>
            <div class="secondary-text ta-center" v-if="!this.infoItems">No hay ítems evaluados</div>
            <div class="section mb20" v-else>
                <div v-for="(item, id) in filteredItems" :key="id" class="entry flex align-center">
                    <avatar class="mr10"
                    :image="`products/${item.image}`">
                    </avatar>
                    <div class="bold flex-fill">{{item.nombre}}</div>
                    <div class="f18 flex align-center">{{((item.stars).toFixed(1)).replace('.',',')}} <i class="icon icon-star ml5 cyellow"></i></div>
                </div>
            </div>

            <div class="flex mb20">
                <div class="f18 flex-fill">Evaluaciones</div>
            </div>
            <div v-if="this.surveys[0] == 0" class="mb20">
                <div class="secondary-text ta-center">No hay evaluaciones realizadas</div>
            </div>
            <div v-else>
                <div v-for="survey in surveys" :key="survey.id" class="section mb20 selectable" @click="$refs.iapDetails.Open(survey.id_transaction)">
                    <!-- starts comment created_at tags-->
                    <div class="secondary-text mb5">Encuesta respondida el {{Util.Date(survey.created_at)}}</div>
                    <div>
                        <i class="icon icon-star cyellow mr5 f18" v-for="i in survey.stars" :key="i"></i>
                    </div>
                    <div class="flex mt10">
                        <badge v-for="(tag, index) in survey.tags" :key="index" color="blue" class="mr5" :round="false">{{tag}}</badge>
                    </div>
                    <div v-if="survey.comment" class="well mt10">{{survey.comment}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="secondary-text ta-center mt10" v-else>No hay datos</div>

    <iap-details ref="iapDetails"></iap-details>

</vertical-content>
</template>

<script>
export default {
    data() {
        return {
            surveys: [0],
            rating: null,
            tags: [],
            infoItems: [],
            search: null,
        }
    },
    methods: {
        Search(event)
        {
            if(this.surveys && !event) return;

            this.getAverage(event);

            this.$refs.verticalContent.Reset();
        },
        getAverage(range){
            this.Loading();
            axios.post('/customers/average/ratings/'+this.Local.id, {
                range
            })
            .then( ({data}) => {
                console.log(data);
                if(data.status) {
                    this.rating = ((data.ratings).toFixed(1)).replace('.',',');
                    this.tags = data.tags;
                    this.surveys = data.surveys;
                    this.infoItems = data.infoItems;
                }else{
                    this.rating = null;
                    this.tags = null;
                    this.infoItems = null;
                    this.surveys = [0];
                }
                this.Response(data);
            })
            .catch( (err) => {
                //console.log(err)
                this.Response(err);
            })
        },
        LoadMoreSurveys()
        {
            const surveys = this.surveys;
            
            const idLastSurvey = surveys.length > 0? surveys[surveys.length-1].id : null;
            const date = surveys.length > 0? surveys[surveys.length-1].created_at : null;

            axios.post('/customers/average/more/'+this.Local.id, {
                date,
                idLastSurvey,
            })
            .then( ({data}) => {
                if(data.status){
                    this.surveys = surveys.concat(data.surveys);
                }
            }).catch( (err) => {
                //console.log();
            })
        }
    },
    computed: {
        filteredItems: function () {
            if(!this.search) return this.infoItems;

            let items = [];

            for(let key in this.infoItems){
                if(this.Util.InString(this.infoItems[key].nombre, this.search)) items.push(this.infoItems[key]);
            }

            return items;
        }
    }
}
</script>

<style>

</style>