<template>
    <div>
        <modal v-model="parameters" dir="right" :modalClass="['fill', 'right h100 md']" title="Reparto por PedidosYa">
            <i class="options icon-calendar" slot="options"
            @click="modalSchedules=true"
            v-tooltip="'Ver horarios'">
            </i>
            <div class="pa20" v-if="parameters">

                <div class="bold mb10">Hora/Fecha de envío</div>
                <!--<div class="secondary-text mb10">Selecciona el tipo de envío.</div>-->
                <!--
                <label class="mb5">
                    <input type="radio" name="ecommerce" :value="true" v-model="parameters.inmediato"> Inmediato (Retiro lo antes posible) <div class="radio"></div>
                </label>
                -->

                <input-select class="mt10"
                v-model="parameters.offset"
                :items="[
                    {value: 'EXPRESS', text: 'Lo antes posible'},
                    {value: 'SCHEDULE', text: 'Programado (un día y hora específico)'},
                    {value: '30', text: '30 minutos'},
                    {value: '45', text: '45 minutos'},
                    {value: '60', text: '1 hora'},
                    {value: '90', text: '1 hora y 30 minutos'},
                    {value: '120', text: '2 horas'},
                ]">
                </input-select>

                <!--
                <label class="mb15">
                    <input type="radio" name="ecommerce" :value="false" v-model="parameters.inmediato"> Personalizado (Retiro en el día y el horario que elijas) <div class="radio"></div>
                </label>
                -->

                <input-date class="mt5 mb15"
                v-if="parameters.offset == 'SCHEDULE'"
                v-model="parameters.date"
                format="timestamp"
                @OnValueChanged="ValidateDate($event)">
                </input-date>

                <div class="mb20">
                    <input-field class="mt15 input-top"
                    v-model="parameters.name"
                    pre="user"
                    label="Datos del Cliente"
                    placeholder="Nombre">
                    </input-field>

                    <input-field class="input-middle"
                    v-model="parameters.phone"
                    pre="phone"
                    placeholder="Teléfono">
                    </input-field>
                    
                    <input-field class="input-bottom"
                    v-model="parameters.email"
                    pre="mail"
                    placeholder="Email (Opcional)">
                    </input-field>
                </div>

                <div class="mb20">
                    <input-field class="input-top"
                    v-model="parameters.address_additional"
                    pre="comment"
                    label="Dirección"
                    placeholder="Detalle de dirección. Ej: N° Dpto (Opcional)">
                    </input-field>

                    <input-field class="input-bottom"
                    v-model="parameters.instructions"
                    pre="comment"
                    placeholder="Instrucciones de entrega (Opcional)">
                    </input-field>
                </div>

                <map-component style="height: 400px; border-radius: 10px"
                ref="map"
                v-model="parameters"
                useMarker="location3"
                :canEditPolygon="null"
                :canEditMarker="true"
                :pois="[[this.localLocation.latitude, this.localLocation.longitude]]"
                :canSearch="true"
                :geocode="true"
                @OnMarkerChanged="UpdateLocation($event)">
                </map-component>

            </div>
                
            <button v-if="parameters" slot="actions" :disabled="!parameters.address" class="primary purple" @click="GetEstimate()">Estimar Reparto</button>
        </modal>

        <modal v-model="modalSchedules" dir="center" modalClass="sm" title="Horarios Disponibles" hideActions>
            <div class="pa20" v-if="schedules">
                <div class="flex fill align-center"
                v-for="(schedule, index) in schedules"
                :key="index">
                    <div class="flex-fill flex column">
                        {{days[schedule.day]}}
                    </div>
                    <div>
                        {{Util.Date(schedule.from, 'HH:mm')}} - {{Util.Date(schedule.to, 'HH:mm')}}
                    </div>
                </div>
            </div>
        </modal>

        <modal v-model="confirm" :dir="['right', 'center']" :modalClass="['fill' ,'sm']" title="Estimación de Envío">
            <div class="pa20" v-if="confirm">

                <!--El envío consta de la siguiente información: <br><br>-->
                <div class="hdvm">
                
                    <div>
                        <div class="bold">Hora</div>
                        <div>{{Util.Date(confirm.date, 'HH:mm DD/MM/YYYY')}}</div>
                    </div>

                    <div>
                        <div class="bold">Costo de Envío</div>
                        <div class="">{{Util.Price(confirm.price.total)}}</div>
                    </div>

                </div>

                <div v-if="confirm.discount" class="mt20">
                    <div class="bold">Descuento</div>
                    <div class="">-{{Util.Price(confirm.discount)}}</div>
                </div>

                <!--
                <input-field class="mb15 mt15"
                v-model="confirm.emailClient"
                label="Email Cliente"
                description="PedidosYa notificará al cliente cuando el pedido vaya en camino"
                placeholder="(Opcional)">
                </input-field>
                -->

                <div v-if="!pos">
                    <input-field v-if="confirm.address" :class="!confirm.address_additional && !confirm.instructions? 'mt15' : 'mt15 input-top'"
                    v-model="confirm.address"
                    pre="google-maps"
                    label="Dirección"
                    :readonly="true">
                    </input-field>

                    <input-field v-if="confirm.address_additional" :class="!confirm.instructions? 'input-bottom' : 'input-middle'"
                    v-model="confirm.address_additional"
                    pre="comment"
                    :readonly="true">
                    </input-field>

                    <input-field v-if="confirm.instructions" class="input-bottom"
                    v-model="confirm.instructions"
                    pre="comment"
                    :readonly="true">
                    </input-field>


                    <input-field class="mt15 input-top"
                    v-model="confirm.name"
                    pre="user"
                    label="Datos del Cliente"
                    placeholder="Nombre"
                    :readonly="true">
                    </input-field>

                    <input-field :class="confirm.email? 'input-middle' : 'input-bottom'"
                    v-model="confirm.phone"
                    pre="phone"
                    placeholder="Teléfono"
                    :readonly="true">
                    </input-field>

                    <input-field v-if="confirm.email" class="input-bottom"
                    v-model="confirm.email"
                    pre="mail"
                    placeholder="Email (Opcional)"
                    :readonly="true">
                    </input-field>
                </div>
            </div>

            <button slot="actions" class="secondary" @click="confirm=null">Cancelar</button>
            <button slot="actions" v-if="!pos && confirm" class="primary purple" @click="createOrder()">Enviar {{Util.Price(confirm.price.total - confirm.discount)}}</button>
            <button slot="actions" v-if="pos && confirm" class="primary purple" @click="completeData()">Aceptar {{Util.Price(confirm.price.total - confirm.discount)}}</button>
        </modal>
    </div>
</template>

<script>
export default {
    props: {
        order: {
            type: Object,
            default: null,
        },
        pos: {
            type: Boolean,
            default: false,
        },
    },
    data(){
        return {
            parameters: null,
            schedules: null,
            confirm: null,
            days: {
				1: 'Lunes',
				2: 'Martes',
				3: 'Miércoles',
				4: 'Jueves',
				5: 'Viernes',
				6: 'Sábado',
				7: 'Domingo'
			},
            coordinates: null,
            errorDate: false,
            transaction: null,
            zones: null,
            schedules: null,
            localLocation: null,
            modalSchedules: false
        }
    },
    methods: {
        Open(transaction){
            this.transaction = transaction;

            var callback = () => {
                
                this.parameters = {
                    inmediato : true,
                    latitude: this.localLocation.latitude, 
                    longitude: this.localLocation.longitude,
                    name: transaction.customer.name,
                    phone: transaction.customer.phone,
                    email: transaction.customer.email? transaction.customer.email : '',
                    offset: localStorage.PYA_COURIER_TIME_OFFSET ? localStorage.PYA_COURIER_TIME_OFFSET : '45'
                }

                if(this.transaction && this.transaction.id) this.parameters.idTransaction = this.transaction.id;
                if(this.transaction && this.transaction.payment) this.parameters.payment = this.transaction.payment;
                if(this.transaction && this.transaction.total_money) this.parameters.total_money = this.transaction.total_money
                if(this.transaction && this.transaction.total_discount) this.parameters.total_discount = this.transaction.total_discount
                if(this.transaction && this.transaction.orders) this.parameters.orders = this.transaction.orders

                if(this.transaction && this.transaction.customer && this.transaction.customer.address)
                {
                    setTimeout(() => {
                        this.$refs.map.SearchAddress(this.transaction.customer.address);
                    }, 500);
                }
                this.$nextTick( () => {
                    this.$refs.map.SetPolygon([], this.zones);
                });
            };

            if(!this.zones || !this.schedules)
            {
                this.GetZones(callback);
            }
            else
            {
                callback();
            }

           

            

        },
        async GetZones(callback){
            this.Loading();
			axios.get(`/pya/zones/schedules/${this.Local.id}`).then( ({data}) => {

				let polygons = [];
				data.zones.forEach(zone => {
					let polygonGroup = [];
					zone.polygon.coordinates[0].forEach(coordinate => {
						polygonGroup.push({
							'lat' : coordinate[1], 
							'lng' : coordinate[0]
						});
					});
					polygons.push(polygonGroup);
				});
                this.zones = polygons;
                this.schedules = data.schedules;
                this.localLocation = {latitude: data.latitude, longitude: data.longitude};

				this.Response(data);
                if(callback) callback();
            })
            .catch(err => {
                this.Response(err);
            });
        },
        UpdateLocation(latlng){
            this.$set(this.parameters, 'latitude', latlng.lat);
            this.$set(this.parameters, 'longitude', latlng.lng);
            this.$set(this.parameters, 'address', latlng.address);
            //if(latlng.address) this.parameters.address = latlng.address;
        },
        GetEstimate(){
            if(!this.parameters.name) return this.Info('El campo Nombre es obligatorio');
            if(!this.parameters.phone) return this.Info('El campo Teléfono es obligatorio');

            let now = Math.floor( new Date() / 1000);
            let time = Math.floor( new Date() / 1000);
            let timeOffset = parseInt(this.parameters.offset);
            if(!isNaN(timeOffset)) time += (timeOffset * 60);

			this.parameters.date = this.parameters.offset == 'SCHEDULE' ? this.parameters.date : time;

			if(this.parameters.date < now) this.parameters.date = now;

            this.ValidateDate(this.parameters.date);
            
            if(this.errorDate) return this.Info('Fecha u hora inválida');

            this.Loading();
            var data = {
                date: this.parameters.date,
                latitude: this.parameters.latitude,
                longitude: this.parameters.longitude,
                address: this.parameters.address,
                name: this.parameters.name,
                phone: this.parameters.phone,
                email: this.parameters.email,
                address_additional: this.parameters.address_additional,
                instructions: this.parameters.instructions,
            };    
            if(this.parameters.payment) data.payment = this.parameters.payment;
            if(this.parameters.total_money) data.total_money = this.parameters.total_money;
            if(this.parameters.total_discount) data.total_discount = this.parameters.total_discount;
            if(this.parameters.orders) data.orders = this.parameters.orders;
            if(this.parameters.idTransaction) data.idTransaction = this.parameters.idTransaction;

            axios.post(`/pya/order/estimate/${this.Local.id}`, data).then( ({data}) => {

                var confirm = {
                    //date: data.deliveryTime,
                    deliveryOfferId: data.deliveryOffers[0].deliveryOfferId,
                    estimateId: data.estimateId,
                    id_reference: data.referenceId,
                    date: new Date(data.deliveryTime).getTime() / 1000,
                    scheduled: this.parameters.offset == 'SCHEDULE',
                    time_offset: !isNaN(parseInt(this.parameters.offset)) ? parseInt(this.parameters.offset) : null,
                    price: data.price,
                    latitude: this.parameters.latitude,
                    longitude: this.parameters.longitude,
                    address: this.parameters.address,
                    address_additional: data.waypoints[1] && data.waypoints[1].addressAdditional? data.waypoints[1].addressAdditional : null,
                    instructions: data.waypoints[1] && data.waypoints[1].instructions? data.waypoints[1].instructions : null,
                    name: data.waypoints[1] && data.waypoints[1].name? data.waypoints[1].name : null,
                    phone: data.waypoints[1] && data.waypoints[1].phone? data.waypoints[1].phone : null,
                    email: data.notificationMail? data.notificationMail : null,
                    discount: data.discount ? data.discount : 0,
                };

                if(this.transaction && this.transaction.id)
                {
                    confirm.id_reference = this.transaction.id_ticket ? this.transaction.id_ticket : this.transaction.id;
                    if(this.transaction.id_ticket)
                        confirm.id_transaction = this.transaction.id;
                    else
                        confirm.id_influye_transaction = this.transaction.id;
                }

                this.confirm = confirm;

                this.Response(data);
            })
            .catch(err => {
                this.Response(err);
            });
		},
        createOrder(){

            if(!this.confirm.address || !this.confirm.name || !this.confirm.phone) return this.Info('Los campos nombre, teléfono y dirección son obligatorios');

			//this.confirm.date = new Date(this.confirm.date).getTime() / 1000;
            /*
            this.confirm.id_reference = this.transaction.id_ticket ? this.transaction.id_ticket : this.transaction.id;
			if(this.transaction.id_ticket)
                this.confirm.id_transaction = this.transaction.id;
            else
                this.confirm.id_influye_transaction = this.transaction.id;
            */

            if(this.transaction.payment.length !== 1) return this.Info('No es posible crear una orden de envío con multiples métodos de pago');
            if(this.transaction.payment[0].method == 'CASH')
            {
                var total = this.transaction.total_money + this.confirm.price.total;
                if(total > 30000) return this.Info('El total máximo con efectivo para hacer orden de envío es de 30.000 pesos');
                this.confirm.cash = total;
            }

            localStorage.PYA_COURIER_TIME_OFFSET = this.parameters.offset;

            this.Loading();
			axios.post(`/pya/order/${this.Local.id}`, this.confirm)
            .then( ({data}) => {
				this.Response(data);
                this.parameters = null;
				this.confirm = null;
				if(!this.pos) this.Info(`Se ha creado una orden de envio para el pedido #${this.transaction.id_ticket}`);
            })
            .catch(err => {
                this.Response(err);
            });
        },
        completeData(){
            //this.confirm.date = new Date(this.confirm.date).getTime() / 1000;
            //this.parameters.confirm =  this.confirm;
            localStorage.PYA_COURIER_TIME_OFFSET = this.parameters.offset;
            this.$emit("CompleteData", this.confirm);
            this.confirm = null;
            this.parameters = null;
        },
        ValidateDate(value){
            
            let hourActual = Math.floor( new Date() / 1000);
            let limitDate = hourActual + ( ((60 * 60) * 24 ) * 5 );
            let date = this.parameters.date + (60 * 10);
            let schedules = this.schedules;
            let day = Util.Date(value, 'dddd').charAt(0).toUpperCase() + Util.Date(value, 'dddd').slice(1);
            let index = null;

            this.errorDate = false;

            if(value <= hourActual && date <= hourActual){
                this.Info('La fecha debe ser igual o superior a '+ Util.Date(hourActual)); 
                this.$set(this.parameters, 'date', hourActual);
                this.errorDate = true;
            }

            if(value >= limitDate){
                console.log(Util.Date(value, 'HH:mm DD/mm/yyyy'), Util.Date(value, 'HH:mm DD/mm/yyyy'));
                this.Info('La fecha debe ser igual o inferior a '+ Util.Date(limitDate));
                this.$set(this.parameters, 'date', limitDate);
                this.errorDate = true;
            }

            for (let i in this.days) {
                if (this.days.hasOwnProperty(i)) {
                    if(this.days[i] == day) index = i;
                }
            }

            let filterSchedules = schedules.filter(schedule => schedule.day == index);
            
            let availableSchedule = false;
            filterSchedules.forEach( schedule => {
                console.log(schedule);
                var minsEstimate = Util.TimeToMinutes(Util.Date(value, 'HH:mm'));
                var minsFrom = Util.TimeToMinutes(Util.Date(schedule.from, 'HH:mm'));
                var minsTo = Util.TimeToMinutes(Util.Date(schedule.to, 'HH:mm'));

                if(minsEstimate >= minsFrom && minsEstimate <= minsTo){
                    availableSchedule = true;
                }
            });
            if(!availableSchedule) this.errorDate = true;

            if(!availableSchedule) return this.Info('La hora seleccionada no está dentro del rango')
        }
    }
}
</script>