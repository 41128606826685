<template>
<div>
<modal v-model="modal" :title="title" dir="right" :modalClass="['fill', 'md right h100']" hideActions>
<vertical-content v-if="transactions && transactions.length > 0" @OnBottom="LoadTransactionsChunk()">
    <div class="section">
        <div class="entry selectable"
        v-for="(transaction, index) in transactions"
        :key="index"
        @click="$refs.details.Open(transaction)">
            <div class="flex fill align-center">
                <div class="flex column">
                    <div class="bold">#{{Util.Zeropad(transaction.id, 10)}}</div>
                    <div class="secondary-text"><span class="bold">Fecha: </span>{{Util.Date(transaction.created_at, 'DD/MM/YYYY HH:mm')}}</div>
                    <div class="secondary-text"><span class="bold">Método de Pago: </span><span>{{Util.PayMethod(transaction.payment_method)}} </span></div>
                    <div class="secondary-text">Para {{Util.SaleType[transaction.type]}} <span v-if="transaction.customer_name">a {{transaction.customer_name}} <i v-if="transaction.id_user" style="color: rgb(0, 151, 255)" class="icon icon-corbatin"></i></span></div>
                </div>
                <div class="flex-fill"></div>
                <div class="bold mr10">{{Util.Price(transaction.total.total)}}</div>
            </div>
        </div>
    </div>
    <div class="secondary-text ta-center mt10" v-if="!transactions.length">No hay transacciones.</div>
</vertical-content>

</modal>
<iap-details ref="details" @OnTransactionDeleted="TransactionDeleted($event)"></iap-details>
</div>
</template>

<script>
export default {
    props: {
        chuckSize: {
            type: Number,
            default: 20,
        }
    },
    data() {
        return {
            list: [], //ids arrays
            transactions: [], //transactions data
            title: 'Transacciones',
            modal: false
        }
    },
    methods: {
        Open(transactionList, newTitle) {
            this.title = newTitle ? newTitle : this.title;

            this.transactions = [];
            this.list = [];
            
            if(transactionList.length > 0)
            {
                if(typeof transactionList[0] == 'number')
                {
                    //Lista de IDs
                    this.list = JSON.parse(JSON.stringify(transactionList));
                    this.LoadTransactionsChunk();
                }
                else
                {
                    //Lista de Transacciones (data)
                    this.transactions = transactionList;
                    this.modal = true;
                }
            }
            
        },
        Close() {
            this.modal = false;
            this.list = [];
            this.transactions = [];
            this.$refs.details.Close();
        },
        LoadTransactionsChunk()
        {
            if(this.list.length == 0) return;
            this.Loading();
            axios.post(`/transactions/online/${this.Local.id}`, 
                {
                    ids: this.list.splice(0, this.chuckSize)
                })
            .then(({data}) => {
                this.transactions =  this.transactions.concat(data);
                this.Response(this.transactions);
                this.modal = true;
            }).catch(err => {
                this.Response(err);
            });
        },
        GetPayments(t)
        {
            if(t.pay_method == 'MIXED')
            {
                var ret = '';
                var methods = t.ticket.payment.methods;
                for(var i = 0; i < methods.length; i++)
                    ret += ', ' + (methods[i].method == 'CASH' ? 'Efectivo' :methods[i].method);
                return ret.substr(2);
            }
            else
            {
                return t.pay_method == 'CASH' ? 'Efectivo' : t.pay_method;
            }
        },
        TransactionDeleted(id) {
            for(var i = 0; i < this.transactions.length; i++)
            {
                if(this.transactions[i].id == id)
                {
                    this.transactions.splice(i, 1);
                    return;
                }
            }
        }
    }
}
</script>