<template>
<div class="flex column unshrink">
    <!--<div class="menu-header">Turno {{Turn ? 'Activo' : ''}}</div>-->
    <!--
    <div class="menu-item" v-if="!Turn" @click="['waiter', 'delivery'].indexOf(Auth.role) == -1 ? modalStart={} : Confirm({
                text: 'Empezar Turno',
                title: '¿Empezar turno con esta cuenta?',
                class: 'selected-color',
                function: () => {StartTurn()}
    })"><i class="icon icon-chronometer"></i> Empezar Turno</div>
    <div class="flex-column" v-if="Turn">
        <div class="turn-container relative selectable" ref="turnContainer" @click="Options([
            {text: 'Terminar Turno', class: 'error-color', function: () => {OpenEndTurn()}},
            {text: 'Ver Turno', function: () => {OpenTurnDetails()}, if: !!canViewTurn},
            {text: 'Ingresar Comentario', function: () => {modalComment={comment: ''}}},
            {text: 'Ingresar Efectivo', function: () => {OpenTurnCash()}, if: Auth.role !== 'waiter'},
        ], $refs.turnContainer)">
            <div class="flex align-center">
                <i class="icon icon-chronometer mr10" style="font-size: 20px"></i>
                <div class="flex column">
                    <div>
                        <span class="bold">Inicio:</span> {{Util.Date(Turn.started_at, 'HH:mm')}}
                        <i class="icon icon-dots-vertical absolute" style="right: 10px"></i>
                    </div>
                    <div>{{this.activeTime}}</div>
                </div>
            </div>
        </div>
        -->
        <!--
        <div class="menu-item" @click="OpenTurnCash()" v-if="Auth.role !== 'waiter'"><i class="icon icon-money"></i> Ingresar Efectivo</div>
        <div class="menu-item" @click="OpenTurnDetails()" v-if="canViewTurn"><i class="icon icon-check-list"></i> Ver Turno Actual</div>
        <div class="menu-item" @click="modalComment={comment: ''}"><i class="icon icon-comment"></i> Comentario</div>
        -->
        <!--
        <div @click="$emit('ClickMenu', {path: 'turn'})" v-if="Auth.role !== 'waiter'">
            <div :class="`menu-item ${$route.name == 'turn' ? 'menu-selected' : ''}`"><i class="icon icon-history"></i> Actividad del Turno</div>
        </div>
        -->
        <!--
        <div class="menu-item" @click="OpenEndTurn"><i class="icon icon-chronometer"></i> Terminar Turno</div>
        -->
    <!--</div>-->

    <modal v-model="modalStart" dir="center" modalClass="sm" title="Empezar Turno">
        <div class="section" v-if="modalStart">
            <input-field
            v-model="modalStart.initial_cash"
            label="Efectivo Inicial"
            placeholder="(Opcional)"
            type="number">
            </input-field>
        </div>
        
        <button class="primary purple" slot="actions" @click="Confirm({
            text: 'Empezar Turno',
            title: '¿Empezar turno con esta cuenta?',
            class: 'selected-color',
            function: () => {StartTurn(false)}
        })">Empezar Turno</button>
        
    </modal>

    <modal v-model="modalEnd" :dir="['right', 'center']" :modalClass="['fill', 'sm']" title="Terminar Turno">
        <div class="section" v-if="modalEnd && Auth.restrictions.turns_ghost_close">
            <input-field v-for="(value, key) in modalEnd.ghost_close" :key="key"
            v-model="modalEnd.ghost_close[key]"
            :label="`Total ${Util.PayMethod(key)}`"
            type="int" class="mb15">
            </input-field>
        </div>

        <div slot="actions" class="flex flex-fill mr20" v-if="modalEnd && Auth.restrictions.turn_password">
            <input-field
            v-model="modalEnd.password"
            placeholder="Contraseña"
            type="password">
            </input-field>
        </div>
        
        <button slot="actions" class="primary purple" @click="EndTurn()">Cerrar Turno</button>
        
    </modal>

    <modal v-model="modalComment" dir="center" modalClass="sm" title="Comentario de Turno">
        <div class="section" v-if="modalComment">
            <input-field
            label="Comentario"
            v-model="modalComment.comment"
            :lines="5"
            placeholder="Comentario o nota adicional del turno">
            </input-field>
        </div>
        
        <button slot="actions" v-if="modalComment" :disabled="!modalComment.comment" class="primary purple" @click="SendComment()">Ingresar comentario</button>
        
    </modal>

    <modal v-model="modalMoney" :dir="['up', 'center']" :modalClass="['fill', 'sm']" title="Ingresar Efectivo">
        
        <div class="section" v-if="modalMoney">

        <div class="flex align-center well w100 mb15" v-if="modalMoney">
            <div>
                <div class="bold">Efectivo actual</div>
                <div>{{Util.Price(modalMoney.initial_cash)}}</div>
            </div>
            <i class="icon icon-arrow-right mr10 ml10 flex-fill ta-center"></i>
            <div class="">
                <div class="bold">Nuevo efectivo</div>
                <div>{{Util.Price(modalMoney.initial_cash + parseInt(modalMoney.cash ? modalMoney.cash : 0))}}</div>
            </div>
        </div>

            <input-field class="mb15"
            label="Efectivo"
            v-model="modalMoney.cash"
            type="int"
            placeholder="Efectivo que será ingresado a la caja">
            </input-field>

            <input-field
            label="Comentario"
            v-model="modalMoney.comment"
            :lines="3"
            placeholder="Comentario o nota adicional">
            </input-field>

        </div>

        <button slot="actions" v-if="modalMoney" :disabled="!modalMoney.cash" class="primary purple" @click="SendTurnCash()">Ingresar Efectivo</button>
        
    </modal>

    <turn-details ref="turnDetails"
    :showSummary="Auth.role.indexOf('admin') > -1 || Auth.restrictions.turn_details == 'ALL'"
    :showComments="Auth.role.indexOf('admin') > -1 || Auth.restrictions.turn_details == 'ALL'"
    :showMethods="Auth.role.indexOf('admin') > -1 || Auth.restrictions.turn_details == 'ALL'"
    :showTransactions="Auth.role.indexOf('admin') > -1 || Auth.restrictions.turn_details == 'ALL'"
    :showEdit="Auth.role.indexOf('admin') > -1 || Auth.restrictions.turn_details == 'ALL'"
    :showDelivery="Auth.role.indexOf('admin') > -1 || Auth.restrictions.turn_details == 'ALL'"
    :showTips="Auth.role.indexOf('admin') > -1 || ['ALL', 'TIPS'].indexOf(Auth.restrictions.turn_details) > -1"
    :showPendingPayments="Auth.role.indexOf('admin') > -1 || Auth.restrictions.turn_details == 'ALL'"
    :showInvoices="Auth.role.indexOf('admin') > -1 || Auth.restrictions.turn_details == 'ALL'"
    :showExpenses="Auth.role.indexOf('admin') > -1 || Auth.restrictions.turn_details == 'ALL'"
    :showItems="Auth.role.indexOf('admin') > -1 || Auth.restrictions.turn_details == 'ALL'"
    ></turn-details>

</div>
</template>
<script>
export default {
    data() {
        return {
            timer: null,
            activeTime: 0,
            modalStart: null,
            modalEnd: null,
            modalComment: null,
            modalMoney: null
        }
    },
    mounted() {
        this.timer = setInterval(this.UpdateActiveTime, 60000);
        this.UpdateActiveTime();
    },
    methods: {
        StartTurn(promt) {

            if(promt)
            {
                if(['waiter', 'delivery'].indexOf(this.Auth.role) == -1)
                {
                    this.modalStart={};
                }
                else 
                {
                    this.Confirm({
                        text: 'Empezar Turno',
                        title: '¿Empezar turno con esta cuenta?',
                        class: 'selected-color',
                        function: () => {this.StartTurn(false)}
                    });
                }
                return;
            }

            this.Loading();
            axios.post(`/turn/start${this.localTurn}`, this.modalStart).then(res => {
                this.Response(res);
                this.modalStart = null;
                this.$store.commit('setTurn', res.data);
                this.UpdateActiveTime();
            }).catch(err => {
                this.Response(err);
            });
        },
        OpenEndTurn()
        {
            this.Loading();
            axios.get(`/transactions/${this.Local.id}/pending/count`).then(res => {
                this.Response(res);

                if(this.Auth.restrictions.turn_complete_pending_transactions &&  res.data.count > 0)
                {
                    return this.Info(`Aún quedan ${res.data.count} pedidos sin completar. Se deben completar los pedidos pendientes para poder termianr el turno`);
                }

                this.Confirm({
                    text: 'Terminar Turno',
                    title: res.data.count > 0 ? `Aún quedan ${res.data.count} pedidos sin completar ¿Terminar el turno actual de todas formas?` : '¿Terminar turno actual?',
                    class: 'error-color',
                    function: () => {this.EndTurn(true)}
                })    
            }).catch(err => {
                this.Response(err);
            });
            
        },
        async EndTurn(openModal) {

            if(openModal)
            {
                var modalEndData = {};
                if(this.Auth.restrictions.turn_password) 
                {
                    modalEndData.password = '';
                    modalEndData.required = true;
                }
                if(this.Auth.restrictions.turns_ghost_close)
                {
                    modalEndData.required = true;
                    this.Loading();
                    var settings = (await axios.get(`/methods/${this.Local.id}`)).data;
                    var methods = {};
                    methods.CASH = 0;
                    methods.Transferencia = 0;
                    settings.payment_methods.forEach(method => {
                        methods[method.title] = 0;
                    });
                    methods.ONLINE = 0;
                    modalEndData.ghost_close = methods;
                    console.log(settings);
                    console.log(modalEndData);
                    this.Response({error: false});
                }
                if(modalEndData.required)
                {
                    this.modalEnd = modalEndData;
                    return;
                }
            }

            this.Loading();
            axios.post(`/turn/end${this.localTurn}`, this.modalEnd).then(res => {
                this.Info('El turno ha terminado exitósamente');
                this.$refs.turnDetails.Open(res.data);
                this.$store.commit('setTurn', null);
                if(this.timer) clearInterval(this.timer);
                this.modalEnd = null;
            }).catch(err => {
                this.Response(err);
            });
        },
        UpdateActiveTime() {
            if(this.Turn)
            {
                var moment = require('moment');
                var now = moment().unix();
                var min = Math.floor((now - this.Turn.started_at)/60);
                var hour = Math.floor(min/60);
                min = min%60;
                this.activeTime = `${hour > 0 ? Util.SecondsToTime(hour*3600) : ''} ${min > 0 ? Util.SecondsToTime(min*60) : ''}`.trim();
                if(this.activeTime.trim() === '') this.activeTime = 'menos de 1 minuto';
                this.$emit('OnTimerChanged', this.activeTime);
            }
        },
        //registra un comentario o nota del turno
        SendComment() 
        {
            this.Loading();
            axios.put(`/turn/comment${this.localTurn}`, this.modalComment).then(res => {
                this.Info('Comentario registrado');
                this.modalComment = null;
            }).catch(err => {
                this.Response(err);
            });
        },
        OpenTurnCash() 
        {
            this.Loading();
            axios.get(`/turn/cash${this.localTurn}`).then(res => {
                this.Response(res);
                this.modalMoney = {
                    initial_cash: res.data,
                    cash: '',
                    comment: ''
                };
            }).catch(err => {
                this.Response(err);
            });
        },
        SendTurnCash() 
        {
            var cash = parseInt(this.modalMoney.cash);
            if(isNaN(cash) || cash + this.modalMoney.initial_cash < 0) return this.Info('Cantidad inválida');
            
            this.Loading();
            axios.put(`/turn/cash${this.localTurn}`, this.modalMoney).then(res => {
                this.Info('Efectivo ingresado');
                this.modalMoney = null;
            }).catch(err => {
                this.Response(err);
            });
        },
        OpenTurnDetails()
        {
            this.Loading();
            axios.get(`/turn/details${this.localTurn}`).then(res => {
                this.$refs.turnDetails.Open(res.data);
                this.Response(res);
            }).catch(err => {
                this.Response(err);
            });
        }
    },
    computed: {
        localTurn() {
            return this.Local.franchise ? `?local=${this.Local.id}` : '';
        },
        canViewTurn() {
            if(!this.Turn) return false;
            if(this.Auth.role.indexOf('admin') > -1) return true;
            return this.Auth.restrictions.turn_details !== 'NONE';
        },
        turnOptions() {
            var ret = [];
            if(!this.Local || !this.Local.modules.module_turns) return ret;
            if(!this.Turn)
            {
                ret.push({text: 'Empezar Turno', function: () => {this.StartTurn(true)}, icon: 'chronometer'});
            }
            else
            {
                
                if (!this.Local.only_one_turn_active || !this.Local.same_account_open_close_turn || (this.Local.same_account_open_close_turn && this.Turn.id_account == this.Auth.id)) ret.push({text: 'Terminar Turno', class: 'error-color', function: () => {this.OpenEndTurn()}, icon: 'chronometer'});
                if(!!this.canViewTurn) ret.push({text: 'Ver Turno', function: () => {this.OpenTurnDetails()}, icon: 'sheet'});
                ret.push({text: 'Ingresar Comentario', function: () => {this.modalComment={comment: ''}}, icon: 'comment'});
                if(this.Auth.role !== 'waiter' && this.Auth.role !== 'delivery') ret.push({text: 'Ingresar Efectivo', function: () => {this.OpenTurnCash()}, icon: 'bill'});
            }
            return ret;
        }
    },
    sockets : {
        ONLY_TURN(data){
            if(data.id_account != this.Auth.id) {
                this.Info("Se ha activado caja!");
                this.$store.commit('setTurn', {'started_at' : Number(data.started_at), 'id_account' : data.id_account});
                this.UpdateActiveTime();
            }
        },
        END_TURN(data){
            if(data.id_account != this.Auth.id) {
                this.Info("Se ha terminado el turno");
                this.$store.commit('setTurn', null);
            }
        }
    },
    beforeDestroy() {
        if(this.timer) clearInterval(this.timer);
    }
}
</script>
<style lang="scss">
.turn-container {
    background-color: var(--purple);
    color: var(--white);
    padding: 10px;
    margin: 10px 10px 0px 10px;
    border-radius: 10px;
    font-size: 12px;

    &:hover {
        background-color: var(--purple);

        .icon-chronometer {
            transform: scale(1.2) rotate(-10deg);
        }

    }

}
</style>