<template>
<tabs v-model="tab" :tabs="tabs" :keyByTab="true" @OnTabIconos="LoadKeys" @OnTabSnippets="LoadSnippets">

    <div class="opt r">
        <i v-show="tab == 'Categorias'" class="options icon-dots-vertical" @click="Options([
            {text: 'Crear Categoría', class: 'selected-color', function: () => {modalCategory={}}}
        ])"></i>

        <i v-show="tab == 'Snippets'" class="options icon-dots-vertical" @click="Options([
            {text: 'Crear Snippet', class: 'selected-color', function: () => {modalSnippet={}}}
        ])"></i>

        <input-field
        v-show="tab == 'Iconos'"
        pre="search"
        cleareable
        :timeout="200"
        v-model="iconSearch"
        :icon="mobile ? 'search' : ''"
        inputClass="secondary"
        placeholder="Buscar..."
        @OnChange="tab=='Iconos'?LoadKeys(true):null">
        </input-field>

        <button class="primary purple ml10" 
        v-if="Object.values(iconSelection).length" 
        v-show="tab == 'Iconos'"
        @click="modalIcon={keys: Object.keys(iconSelection), icon: null}">Elegir ícono ({{Object.values(iconSelection).length}})</button>
        
        <i class="options icon-dots-vertical ml5" v-show="tab == 'Iconos'" @click="Options([
            {text: 'Deseleccionar Todos', class: 'error-color', function: () => {iconSelection={}}},
            {text: 'Deseleccionar Búsqueda', class: 'error-color', function: () => {SelectFiltered(false)}},
            {text: 'Seleccionar Búsqueda', function: () => {SelectFiltered(true)}}
        ])"></i>

        <button v-show="tab == 'Anuncio'" class="primary purple" @click="SaveAnnouncement">Guardar</button>

    </div>

    <div v-if="settings" slot="Anuncio" class="pa-body">

        <input-field v-model="settings.announcement.title" :lines="2"
        class="mb15"
        label="Encabezado"
        placeholder="(Requerido)"
        cleareable>
        </input-field>

        <input-field v-model="settings.announcement.description" :lines="10"
        label="Contenido"
        placeholder="(Opcional)"
        cleareable>
        </input-field>

    </div>
    
    <div v-if="settings" slot="Categorias" class="pa-body">


        <div class="f18">Categorias de Items</div>
        <div class="secondary-text mb20">Categorias seleccionables para los items de menu.</div>

        <div class="section mb10"
        v-for="category in settings.categories"
        :key="category.id">
            <div class="fill flex align-center">
                <div class="bold">{{category.title}}</div>
            </div>
        </div>

    </div>

    <vertical-content slot="Iconos" class="pa-body" @OnBottom="LoadKeys" :list="iconsKeys">

        <div v-if="iconsKeys">

            <div :class="`section flex align-center mb10 selectable`" v-for="(key, index) in iconsKeys" :key="index"
            @click="iconSelection[key] ? $delete(iconSelection, key) : $set(iconSelection, key, true)">
                <div :class="`check ${iconSelection[key]?'selected':''} mr10`"></div>
                <div class="bold flex-fill">{{key}}</div>
                <i v-show="savedIcons[key]" class="icon icon-circle-check cpurple mr15"></i>
                <img @click.stop="modalIcon={keys: [key], icon: null}" :src="img(`icons/keys/${Util.Alphanumeric(key, '_')}.png`)" width="20" style="transform: scale(1.5)">
            </div>

        </div>

    </vertical-content>

    <div slot="Snippets" class="pa-body">

        <draggable
        :list="snippets"
        :animation="200"
        @change="OnSnippetsOrderChanged">
            <div :class="`section flex align-center mb10 selectable`" v-for="snippet in snippets" :key="snippet.key"
            @click="modalSnippet = Util.Copy(snippet)" 
            :style="{padding: '0px 10px', opacity: snippet.title.indexOf('[HIDDEN]') > -1 ? '0.5' : '1', marginLeft: snippet.key.indexOf('HEADER:') > -1 ? '0px' : '20px'}">
                <div class="flex-fill">
                    <div class="bold">{{snippet.title}}</div>
                    <!--<div class="secondary-text">{{snippet.description}}</div>-->
                </div>
                <!--<img :src="img(`static/${snippet.image}`)" width="40">-->
                <i @click.stop="Confirm({
                    text: 'Eliminar',
                    title: '¿Eliminar Snippet?',
                    class: 'error-color',
                    function: () => {DeleteSnippet(snippet.key)}
                })" class="options icon-trash"></i>
            </div>
        </draggable>

    </div>

    <div v-if="settings" slot="Dev" class="pa-body">
        <div class="log-container" v-html="settings.syslog"></div>

        <div class="log-container">
            <div class="flex align-center">
                <h4 class="flex-fill">SQL</h4>
                <button class="primary purple" @click="SendSQL">Ejecutar</button>
            </div>
            <textarea v-model="sqlCommand" rows="3" style="width: 100%" placeholder=""></textarea>
            <textarea v-model="sqlResponse" rows="3" style="width: 100%; margin-top: -10px" readonly></textarea>
        </div>

    </div>

    
    <modal v-model="modalCategory" dir="center" modalClass="sm" title="Nueva Categoría">
        <div class="pa20" v-if="modalCategory">
            <input-field
            v-model="modalCategory.title"
            label="Título">
            </input-field>
        </div>
        
        <button slot="actions" class="primary purple" @click="Save()">Guardar</button>
        
    </modal>


    <modal v-model="modalIcon" dir="center" modalClass="sm" title="Seleccionar ícono">

        <div class="pa20" v-if="modalIcon">
            <input-icon
            v-model="modalIcon.icon"
            label="Ícono"
            :description="`Seleccionar un ícono para asociar con ${modalIcon.keys.length == 1 ? 'la palabra '+modalIcon.keys[0] : modalIcon.keys.length+' palabras'}`">
            </input-icon>
        </div>

        <button v-if="modalIcon" slot="actions" class="purple primary" :disabled="!modalIcon.icon" @click="SaveIcons">Guardar</button>

    </modal>


    <modal v-model="modalSnippet" dir="center" modalClass="sm" title="Nuevo Snippet">
        <div class="pa20" v-if="modalSnippet">

            <div class="flex">

                <div>
                    <input-field class="mb10"
                    v-model="modalSnippet.key"
                    label="Llave">
                    </input-field>

                    <input-field class="mb10"
                    v-model="modalSnippet.title"
                    label="Título">
                    </input-field>

                    <input-field
                    v-model="modalSnippet.description"
                    label="Descripción">
                    </input-field>
                </div>

                <input-image class="ml10"
                v-model="modalSnippet.image"
                path="static/"
                label="Imagen"></input-image>

            </div>

            <input-field lines class="mt10"
            v-model="modalSnippet.code"
            label="Código CSS">
            </input-field>
        </div>
        
        <button slot="actions" class="primary purple" @click="SaveSnippet()">Guardar</button>
        
    </modal>

</tabs>
</template>
<script>
export default {
    data() {
        return {
            settings: null,
            modalCategory: null,
            tutorials: [],
            localCategories: [],
            modalTutorial: null,
            modalPlay: null,
            modalLocalCategory: null,
            iconsKeys: null,
            maxVisibleIcons: 20,
            tab: '',
            iconSearch: '',
            iconSelection: {},
            modalIcon: null,
            savedIcons: {},
            snippets: [],
            modalSnippet: null,
            tabs: ['Anuncio', 'Categorias', 'Iconos', 'Snuppets', 'Dev'],
            sqlCommand: '',
            sqlResponse: '',
        }
    },
    mounted() {
        console.log(this.Auth);
        this.tabs = [];
        if(this.Auth.influye_admin) this.tabs.push('Anuncio');
        if(this.Auth.influye_admin) this.tabs.push('Categorias');
        if(this.Auth.influye_admin) this.tabs.push('Iconos');
        if(this.Auth.influye_admin) this.tabs.push('Snippets');
        if(this.Auth.influye_dev) this.tabs.push('Dev');

        this.Loading();
        axios.get(`/influye/settings`).then(res => {
            this.Response(res);
            this.settings = res.data;
            this.tutorials = res.data.tutorials;
            this.localCategories = res.data.localCategories;
        }).catch(err => {
            this.Response(err);
        });
    },
    methods: {
        Save() {
            this.Loading();
            axios.put(`/influye/category`, this.modalCategory).then(res => {
                this.Response(res);
                this.settings.categories.push(res.data);
                this.modalCategory = null;
            }).catch(err => {
                this.Response(err);
            });
        },
        LoadKeys(reset)
        {
            if(reset) this.iconsKeys = [];
            this.Loading();
            axios.get(`/influye/icons?skip=${this.iconsKeys ? this.iconsKeys.length : '0'}${this.iconSearch ? '&search='+this.iconSearch : ''}`).then(res => {
                this.Response(res);
                if(!this.iconsKeys)
                    this.iconsKeys = res.data;
                else
                    this.iconsKeys = this.iconsKeys.concat(res.data);
            }).catch(err => {
                this.Response(err);
            });   
        },
        SelectFiltered(val)
        {
            this.filteredIcons.forEach(icon => {
                if(val)
                    this.$set(this.iconSelection, icon, true);
                else
                    this.$delete(this.iconSelection, icon);
            });
        },
        SaveIcons()
        {
            for(var i = 0; i < this.modalIcon.keys.length; i++)
            {
                this.$set(this.savedIcons, this.modalIcon.keys[i], true);
                this.modalIcon.keys[i] = this.Util.Alphanumeric(this.modalIcon.keys[i], '_');
            }

            console.log(this.modalIcon);
            this.Loading();
            axios.patch(`/influye/icons`, this.modalIcon).then(res => {
                this.Response(res);
                this.modalIcon = null;
                this.iconSelection = {};
            }).catch(err => {
                this.Response(err);
            });
        },
        LoadSnippets() {
            this.Loading();
            axios.get(`/influye/snippets`).then(res => {
                this.snippets = res.data;
                this.Response(res);
            }).catch(err => {
                this.Response(err);
            });
        },
        SaveSnippet() {
            this.Loading();
            axios.post(`/influye/snippet`, {snippet: this.modalSnippet}).then(res => {
                this.Response(res);
                this.snippets = res.data;
                this.modalSnippet = null;
            }).catch(err => {
                this.Response(err);
            });
        },
        DeleteSnippet(key) {
            this.Loading();
            axios.delete(`/influye/snippet/${key}`).then(res => {
                this.Response(res);
                this.snippets = res.data;
            }).catch(err => {
                this.Response(err);
            });
        },
        OnSnippetsOrderChanged(data) {
            axios.post(`/influye/snippets/reorder`, {from: data.moved.oldIndex, to: data.moved.newIndex}).then(res => {
                this.snippets = res.data;
            }).catch(err => {
                this.Response(err);
            });
        },
        SaveAnnouncement() {
            this.Loading();
            axios.patch(`/influye/announcement`, this.settings.announcement).then(res => {
                this.Info('Anuncio publicado');
            }).catch(err => {
                this.Response(err);
            });
        },
        SendSQL() {
            this.Loading();
            axios.post(`/influye/sql`, {command: this.sqlCommand}).then(res => {
                this.Response(res);
                this.sqlResponse = JSON.stringify(res.data);
            }).catch(err => {
                this.Response(err);
            });
        }
    },
    computed: {
        filteredIcons() {
            if(!this.iconsKeys) return [];
            var ret = [];
            this.iconsKeys.forEach(key => {
                if(!this.iconSearch || this.Util.InString(key, this.iconSearch))
                {
                    ret.push(key);
                }
            });
            return ret;
        },
        visibleIcons() {
            return this.filteredIcons.slice(0, this.maxVisibleIcons);
        }
    },
    watch: {
        iconSearch: function(n, o) {
            this.maxVisibleIcons = 20;
        }
    }
}
</script>
<style lang="scss">
.log-container {
    textarea {
        margin-top: 10px;
        margin-bottom: 20px;
        font-family: monospace;
        background: transparent;
        color: inherit;
        border: 2px solid var(--gray-2);
        padding: 10px;
        border-radius: 10px;
        box-sizing: border-box;
    }
}
</style>