import multiguard from 'vue-router-multiguard'
import store from './store'

import LoginView from './components/views/LoginView.vue'
import LocalsView from './components/views/LocalsView.vue'
import LocalProfileView from './components/views/LocalProfileView.vue'
import LocalSettings from './components/views/LocalSettings.vue'
import LocalStaffView from './components/views/LocalStaffView.vue'
import TransactionsView from './components/views/TransactionsView.vue'
import PendingPaymentsView from './components/views/PendingPaymentsView.vue'
import CustomersView from './components/views/CustomersView.vue'
import MenuView from './components/views/MenuView.vue'
import InventoryView from './components/views/InventoryView.vue'
import ExpensesView from './components/views/ExpensesView.vue'
import FacturaView from './components/views/FacturaElectronicaView.vue'
import SalesView from './components/views/SalesView.vue'
//import PendingView from './components/views/PendingView.vue'
import LocalDashboard from './components/views/LocalDashboardView.vue'
import LocalTurnsView from './components/views/LocalTurnsView.vue'
import DeliveryView from './components/views/DeliveryView.vue'
import SublocalsView from './components/views/SublocalsView.vue'
import NotificationsView from './components/views/NotificationsView.vue'
import CouponsView from './components/views/CouponsV2View.vue'
import InfluyeSettings from './components/views/InfluyeSettingsView.vue'
import InfluyeAccounts from './components/views/InfluyeAccountsView.vue'
//import TablesView from './components/views/TablesView.vue'
import BulletinView from './components/views/BulletinView.vue'
import SalesmanProfileView from './components/views/SalesmanProfileView.vue'
import OrdersView from './components/views/OrdersView.vue'
import CustomerFacingScreen from './components/views/CustomerFacingScreen.vue'
import InitialSetupView from './components/views/InitialSetupView.vue'
import ExpiredPlanView from './components/views/ExpiredPlanView.vue'
import LoginWaiterView from './components/views/LoginWaiterView.vue'

function restrictionsAdmin(user){
    if(user.role == "local_admin" && user.restrictions_admin){
        const local = store.state.local;
        let routesEnabled = null;
        if(local.type == 'FRANCHISE'){
            routesEnabled = {
                'dashboard' : user.restrictions_admin.dashboard_enabled,
                'transactions' : user.restrictions_admin.transactions_enabled,
                'inventory' : user.restrictions_admin.inventory_enabled,
                'customers' : user.restrictions_admin.customers_enabled,
                'sublocals' : user.restrictions_admin.locals_enabled,
                'menu' : user.restrictions_admin.menu_enabled,
                'settings' : user.restrictions_admin.settings_enabled,
                'coupons' : user.restrictions_admin.coupons_enabled,
            }
        }else{
            routesEnabled = {
                'dashboard' : user.restrictions_admin.dashboard_enabled,
                'transactions' : user.restrictions_admin.transactions_enabled,
                'inventory' : user.restrictions_admin.inventory_enabled,
                'expenses' : user.restrictions_admin.expenses_enabled,
                'facturas': user.restrictions_admin.facturas_enabled,
                'payments' : user.restrictions_admin.payments_enabled,
                'turns' : user.restrictions_admin.turns_enabled,
                'customers' : user.restrictions_admin.customers_enabled,
                'menu' : user.restrictions_admin.menu_enabled,
                'coupons' : user.restrictions_admin.coupons_enabled,
                'settings' : user.restrictions_admin.settings_enabled,
                'orders' : user.restrictions_admin.orders_enabled,
            }
        }

        const allValuesAreZero = Object.values(routesEnabled).every(value => value == 0);

        if(allValuesAreZero) return 'NOTHING_ENABLED';
        return routesEnabled;
    }
    
    return;
}

function defaultRoute(user) {
    console.log('deafultRoute');
    var user = store.state.user;
    var local = store.state.local;
    switch(user.role)
    {
        case 'admin':
            if(!local)
                return {name: 'bulletin'};
            else 
                return {name: 'dashboard'};
        case 'sub_admin':
            if(!local)
                return {name: 'bulletin'};
            else
                return {name: 'dashboard'};
        case 'local_admin':
            let routeName = local.type == 'FRANCHISE'? 'sublocals' : 'dashboard';
            const restrictionAdmin = restrictionsAdmin(user);

            if(restrictionAdmin && restrictionAdmin == 'NOTHING_ENABLED') return {name: 'notifications'}

            while (!restrictionAdmin[routeName]) {
                const allRoutes = Object.keys(restrictionAdmin);
                const currentIndex = allRoutes.indexOf(routeName);
                const nextIndex = currentIndex + 1 < allRoutes.length ? currentIndex + 1 : 0;

                routeName = allRoutes[nextIndex];
            }

            return {name: routeName};
        case 'cashier':
            if(user.restrictions.create_transactions || user.restrictions.pending_transactions)
                return {name: 'orders'};
            else if(user.restrictions.create_expenses && local.modules.module_expenses)
                return {name: 'expenses'};
            else if(user.restrictions.create_inventory && local.modules.module_inventory)
                return {name: 'inventory'};
            else if(user.restrictions.pay_pending_payments)
                return {name: 'payments'};
            else
                return {name: 'notifications'};
        case 'delivery':
            return {name: 'delivery'};
        case 'kitchen':
            return {name: 'orders'};
        case 'waiter':
            return {name: 'orders'};
    }
}

const CheckRestriction = (to, from, next) =>{
    const user = store.state.user;
    const restrictionAdmin = restrictionsAdmin(user);

    if(restrictionAdmin && restrictionAdmin == 'NOTHING_ENABLED') next(defaultRoute(user));

    if(restrictionAdmin && !restrictionAdmin[to.name]){
        next(defaultRoute(user));
    }else{
        next();
    }
}

const CheckLogged = (to, from, next) => {
    console.log(`CheckLogged to(${to.name}) from(${from.name})`);
    
    if(from.name == 'customer-screen')
    {
        next({name: 'customer-screen'});
        return;
    }
    
    if(store.state.user)
    {  
        if(to.name == 'index')
        {
            to.name == defaultRoute().name ? next() : next(defaultRoute());
        }
        else
        {
            next();
        }
    }
    else
    {
        if(to.name == 'index')
        {
            next();
        }
        else
        {
            store.state.redirect = to.path;
            next({name: 'index'});
        }
    }
};

const CheckAdmin = (to, from, next) => {
    if(store.state.user.role == 'admin' || store.state.user.role == 'sub_admin')
        next();
    else
        to.name == defaultRoute().name ? next() : next(defaultRoute());
};

const CheckLocalAdmin = (to, from, next) => {
    if(store.state.user.role.indexOf('admin') > -1)
        next();
    else
        to.name == defaultRoute().name ? next() : next(defaultRoute());
};

const CheckLocal = (to, from, next) => {
    console.log(`CheckLocal to(${to.name}) from(${from.name})`);
    if(store.state.local)
    {
        next();
    }
    else
        to.name == defaultRoute().name ? next() : next(defaultRoute());
};

const CheckExpiredPlan = (to, from, next) => {
    
    console.log(store.state.local);
    if(store.state.user && ['admin', 'sub_admin'].indexOf(store.state.user.role) == -1 && store.state.local && store.state.local.subscription)
    {
        var plansWithExpiration = ['DEMO', 'POSPYME', 'POSPRO', 'ECOMMERCE', 'ECOMMERCEPLUS', 'POSPYME+ECOMMERCE', 'POSPYME+ECOMMERCEPLUS', 'POSPRO+ECOMMERCE', 'POSPRO+ECOMMERCEPLUS'];
        if(plansWithExpiration.indexOf(store.state.local.subscription.plan_name) > -1 && store.state.local.subscription.status == 'UNPAID')
            next('/expired');
        else
            next();
    
    }
    else
        next();
};


const CheckFranchise = (to, from, next) => {
    if(store.state.local.type == 'FRANCHISE')
        next();
    else
        to.name == defaultRoute().name ? next() : next(defaultRoute());
};

const CheckNotFranchise = (to, from, next) => {
    if(store.state.local.type == 'LOCAL')
        next();
    else
        to.name == defaultRoute().name ? next() : next(defaultRoute());
};

//Account restrictions
const CheckRestrictionsSale = (to, from, next) => {
    if(store.state.user.role.indexOf('admin') > -1 || (store.state.user.role == 'cashier' && !!store.state.user.restrictions.create_transactions))
        next();
    else
        next(defaultRoute(store.state.user));
};

const CheckRestrictionsEditAvailability = (to, from, next) => {
    if(store.state.user.role.indexOf('admin') > -1 || (store.state.user.role == 'cashier' && !!store.state.user.restrictions.edit_items_availability))
        next();
    else
        next(defaultRoute(store.state.user));
};

const CheckRestrictionsCanCreateFacturas = (to, from, next) => {
    if(store.state.user.role.indexOf('admin') > -1 || (store.state.user.role == 'cashier' && !!store.state.user.restrictions.can_create_facturas))
        next();
    else
        next(defaultRoute(store.state.user));
};

const CheckLocalToLoginWaiter = (to, from, next) => {
    let local = JSON.parse(localStorage.getItem('owner'));
    if(local && local.name && local.module_table){
        next();
    }else{
        next({path: '/'})
    }
}


export const routes = [
    //Admin Routes
    {
        path: '/locals',
        name: 'locals',
        component: LocalsView,
        beforeEnter: multiguard([CheckLogged, CheckAdmin])
    },
    {
        path: '/admin/settings',
        name: 'globalSettings',
        component: InfluyeSettings,
        beforeEnter: multiguard([CheckLogged, CheckAdmin])
    },
    {
        path: '/admin/accounts',
        name: 'accounts',
        component: InfluyeAccounts,
        beforeEnter: multiguard([CheckLogged, CheckAdmin])
    },


    //Local Admin Routes
    {
        path: '/dashboard',
        name: 'dashboard',
        component: LocalDashboard,
        beforeEnter: multiguard([CheckLogged, CheckLocal, CheckLocalAdmin, CheckExpiredPlan, CheckRestriction])
    },
    {
        path: '/profile',
        name: 'profile',
        component: LocalProfileView,
        beforeEnter: multiguard([CheckLogged, CheckLocal, CheckLocalAdmin, CheckExpiredPlan, CheckRestriction])
    },
    {
        path: '/settings',
        name: 'settings',
        component: LocalSettings,
        beforeEnter: multiguard([CheckLogged, CheckLocal, CheckLocalAdmin, CheckExpiredPlan, CheckRestriction])
    },
    {
        path: '/staff',
        name: 'staff',
        component: LocalStaffView,
        beforeEnter: multiguard([CheckLogged, CheckLocal, CheckLocalAdmin, CheckExpiredPlan, CheckRestriction])
    },
    {
        path: '/transactions',
        name: 'transactions',
        component: TransactionsView,
        beforeEnter: multiguard([CheckLogged, CheckLocal, CheckExpiredPlan, CheckRestriction])
    },
    {
        path: '/payments',
        name: 'payments',
        component: PendingPaymentsView,
        beforeEnter: multiguard([CheckLogged, CheckLocal, CheckNotFranchise, CheckExpiredPlan, CheckRestriction])//, CheckModulePendingPayments])
    },
    {
        path: '/customers',
        name: 'customers',
        component: CustomersView,
        beforeEnter: multiguard([CheckLogged, CheckLocal, CheckLocalAdmin, CheckExpiredPlan, CheckRestriction])
    },
    {
        path: '/menu',
        name: 'menu',
        component: MenuView,
        beforeEnter: multiguard([CheckLogged, CheckLocal, CheckRestrictionsEditAvailability, CheckExpiredPlan, CheckRestriction])
    },
    {
        path: '/inventory',
        name: 'inventory',
        component: InventoryView,
        beforeEnter: multiguard([CheckLogged, CheckLocal, CheckExpiredPlan, CheckRestriction])//, CheckModuleInventory])
    },
    {
        path: '/expenses',
        name: 'expenses',
        component: ExpensesView,
        beforeEnter: multiguard([CheckLogged, CheckLocal, CheckNotFranchise, CheckExpiredPlan, CheckRestriction])//, CheckModuleExpenses])
    },
    {
        path: '/facturas-electronicas',
        name: 'facturas',
        component: FacturaView,
        beforeEnter: multiguard([CheckLogged, CheckLocal, CheckNotFranchise,  CheckRestrictionsCanCreateFacturas, CheckRestriction])
    },
    {
        path: '/sales',
        name: 'sales',
        component: SalesView,
        beforeEnter: multiguard([CheckLogged, CheckLocal, CheckNotFranchise, CheckRestrictionsSale, CheckExpiredPlan, CheckRestriction])
    },
    /*
    {
        path: '/pending',
        name: 'pending',
        component: PendingView,
        beforeEnter: multiguard([CheckLogged, CheckLocal, CheckNotFranchise])
    },
    */
    {
        path: '/turns',
        name: 'turns',
        component: LocalTurnsView,
        beforeEnter: multiguard([CheckLogged, CheckLocal, CheckNotFranchise, CheckLocalAdmin, CheckExpiredPlan])
    },
    {
        path: '/delivery/pending',
        name: 'deliveryPending',
        component: DeliveryView,
        beforeEnter: multiguard([CheckLogged, CheckLocal, CheckExpiredPlan])
    },
    {
        path: '/sublocals',
        name: 'sublocals',
        component: SublocalsView,
        beforeEnter: multiguard([CheckLogged, CheckLocal, CheckFranchise, CheckLocalAdmin, CheckExpiredPlan, CheckRestriction])
    },
    {
        path: '/notifications/:id?',
        name: 'notifications',
        component: NotificationsView,
        beforeEnter: multiguard([CheckLogged, CheckExpiredPlan])
    },
    {
        path: '/coupons',
        name: 'coupons',
        component: CouponsView,
        beforeEnter: multiguard([CheckLogged, CheckLocal, CheckExpiredPlan, CheckRestriction])
    },
    {
        path: '/bulletin',
        name: 'bulletin',
        component: BulletinView,
        beforeEnter: multiguard([CheckLogged, CheckAdmin])
    },
    {
        path: '/profile/:id?',
        name: 'salesman_profile',
        component: SalesmanProfileView,
        beforeEnter: multiguard([CheckLogged, CheckAdmin])
    },
    {
        path: '/orders',
        name: 'orders',
        component: OrdersView,
        beforeEnter: multiguard([CheckLogged, CheckLocal, CheckNotFranchise, CheckExpiredPlan, CheckRestriction])
    },

    {
        path: '/customerscreen',
        name: 'customer-screen',
        component: CustomerFacingScreen,
    },

    {
        path: '/welcome',
        name: 'welcome',
        component: InitialSetupView,
        beforeEnter: multiguard([CheckLogged, CheckLocal, CheckLocalAdmin, CheckExpiredPlan])
    },

    {
        path: '/expired',
        name: 'expired',
        component: ExpiredPlanView,
    },

    {
        path: '/',
        name: 'index',
        component: LoginView,
        beforeEnter: multiguard([CheckLogged])
    },

    {
        path: '/loginwaiter',
        name: 'loginwaiter',
        component: LoginWaiterView,
        beforeEnter: multiguard([CheckLocalToLoginWaiter])
    },
];