<template>
<div class="calendar flex column">
    <div class="flex mb10">
        <button class="transparent icon icon-arrow-left" @click="AddMonth(-1)"></button>
        <div class="flex justify-center flex-fill" style="min-width: 200px">
            <button class="transparent bold mr10" @click="ShowMonthsSelection">{{Util.MonthsArrEs[currentMonth]}}</button>
            <button class="transparent bold ml10" @click="ShowYearSelection">{{currentYear}}</button>
        </div>
        <button class="transparent icon icon-arrow-right" @click="AddMonth(1)"></button>
    </div>
    <div class="flex column relative">
        <div class="flex justify-center">
            <div class="cell day-of-week">Lu</div>
            <div class="cell day-of-week">Ma</div>
            <div class="cell day-of-week">Mi</div>
            <div class="cell day-of-week">Ju</div>
            <div class="cell day-of-week">Vi</div>
            <div class="cell day-of-week">Sá</div>
            <div class="cell day-of-week">Do</div>
        </div>
        <div class="flex justify-center" v-for="(row, indexRow) in daysGrid" :key="`row-${indexRow}`">
            <div v-for="(cell, indexCell) in row" :key="`cell-${indexCell}-${indexRow}`" class="cell">
                <button :class="`day ${cell.date == currentValue ? 'selected' : ''} ${counters && counters[cell.date] ? 'highlight' : ''}`" 
                v-if="cell.month == currentMonth" @click="SelectDate(cell.date);$emit('OnClickDate')">{{cell.day}}</button>
            </div>
        </div>
        <slot></slot>
        <transition enter-active-class="animated fadeIn superfast" leave-active-class="animated fadeOut superfast">
        <div class="absolute overlay trbl0 flex column justify-center" v-if="showMonths">
            <div class="flex column flex-fill justify-center">
                <div class="flex mb20 justify-between">
                    <button :class="`${currentMonth == 0 ? 'selected' : ''} month-of-year`" @click="SelectMonth(1)">Enero</button>
                    <button :class="`${currentMonth == 1 ? 'selected' : ''} month-of-year`" @click="SelectMonth(2)">Febrero</button>
                    <button :class="`${currentMonth == 2 ? 'selected' : ''} month-of-year`" @click="SelectMonth(3)">Marzo</button>
                </div>
                <div class="flex mb20 justify-between">
                    <button :class="`${currentMonth == 3 ? 'selected' : ''} month-of-year`" @click="SelectMonth(4)">Abril</button>
                    <button :class="`${currentMonth == 4 ? 'selected' : ''} month-of-year`" @click="SelectMonth(5)">Mayo</button>
                    <button :class="`${currentMonth == 5 ? 'selected' : ''} month-of-year`" @click="SelectMonth(6)">Junio</button>
                </div>
                <div class="flex mb20 justify-between">
                    <button :class="`${currentMonth == 6 ? 'selected' : ''} month-of-year`" @click="SelectMonth(7)">Julio</button>
                    <button :class="`${currentMonth == 7 ? 'selected' : ''} month-of-year`" @click="SelectMonth(8)">Agosto</button>
                    <button :class="`${currentMonth == 8 ? 'selected' : ''} month-of-year`" @click="SelectMonth(9)">Septiembre</button>
                </div>
                <div class="flex justify-between">
                    <button :class="`${currentMonth == 9 ? 'selected' : ''} month-of-year`" @click="SelectMonth(10)">Octubre</button>
                    <button :class="`${currentMonth == 10 ? 'selected' : ''} month-of-year`" @click="SelectMonth(11)">Noviembre</button>
                    <button :class="`${currentMonth == 11 ? 'selected' : ''} month-of-year`" @click="SelectMonth(12)">Diciembre</button>
                </div>
            </div>
            <button class="secondary" @click="showMonths=false">Ver días</button>
        </div>
        </transition>

        <transition enter-active-class="animated fadeIn superfast" leave-active-class="animated fadeOut superfast">
        <div class="absolute bgbg1 trbl0 flex column justify-center" v-if="showYears">
            <div class="flex column flex-fill ofy pa10">
                <button @click="SelectYear(year)" :class="`month-of-year mb10 ${currentYear == year ? 'selected' : ''}`" style="width: 100%" v-for="year in years" :key="year">{{year}}</button>
            </div>
            <button class="secondary mt10" @click="showYears=false">Ver días</button>
        </div>
        </transition>

    </div>
</div>
</template>

<script>
var moment = require('moment');
export default {
    props: {
        value: {
            type: String,
            default: null
        },
        counters: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            currentValue: null,
            currentDay: null,
            currentMonth: null,
            currentYear: null,
            showMonths: false,
            showYears: false,
            years: []
        }
    },
    mounted() {
        this.SelectDate(this.value);
        var year = moment().year();
        for(var i = 0; i < 100; i++)
        {
            this.years.push(year-i);
        }
    },
    methods: {
        SelectDate(val)
        {
            var date = moment(val, 'DD/MM/YYYY');
            if(!date.isValid()) date = moment();
            this.currentValue =  date.format('DD/MM/YYYY');
            this.currentDay = date.date();
            this.currentMonth = date.month();
            this.currentYear = date.year();
            this.$emit('input', this.currentValue);
            this.$emit('OnDateSelected', this.currentValue);
        },
        AddMonth(val)
        {
            var date = moment().month(this.currentMonth).year(this.currentYear);
            date.add('month', val);
            this.currentMonth = date.month();
            this.currentYear = date.year();
        },
        SelectMonth(val)
        {
            this.SelectDate(`${this.currentDay}/${val}/${this.currentYear}`);
            this.showMonths = false;
        },
        SelectYear(val)
        {
            this.SelectDate(`${this.currentDay}/${this.currentMonth+1}/${val}`);
            this.showYears = false;
        },
        ShowMonthsSelection() 
        {
            if(this.showYears)
            {
                this.showYears = false;
                this.showMonths = true;
            }
            else
                this.showMonths = !this.showMonths;
        },
        ShowYearSelection() 
        {
            if(this.showMonths)
            {
                this.showMonths = false;
                this.showYears = true;
            }
            else
                this.showYears = !this.showYears;
        }
    },
    computed: {
        daysGrid() {
            if(this.currentMonth == null || this.currentYear == null) return [];
            var start = moment().month(this.currentMonth).year(this.currentYear).startOf('month');
            var rows = [];
            var currentRow = [];

            var dayOfWeek = start.format('d');
            dayOfWeek = dayOfWeek == 0 ? 6 : dayOfWeek-1;
            for(var i = 0; i < dayOfWeek; i++)
            {
                start = start.subtract(1, 'day');
            }

            for(var i = 0; i < 42; i++)
            {
                currentRow.push({
                    date: start.format('DD/MM/YYYY'),
                    day: start.date(),
                    month: start.month(),
                    year: start.year()
                });
                start = start.add(1, 'day');
                if(currentRow.length == 7)
                {
                    rows.push(currentRow);
                    currentRow = [];
                }
            }

            return rows;
        }
    },
    watch: {
        value: function(n, o)
        {
            this.SelectDate(n);
        }
    }
}
</script>

<style lang="scss">
.calendar {

    padding: 24px;

    .cell {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin: 4px;

        &.day-of-week {
            color: var(--gray);
            font-weight: 500;
        }

        .day {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            cursor: pointer;
            transition: all 0.1s linear;
            padding: 0px;
            background-color: transparent;
            font-weight: normal;
            color: var(--black);
            &:hover {
                background-color: var(--gray-3);
                transform: scale(1.2);
            }

            &.highlight {
                background-color: var(--gray-pink);
                font-weight: 600;
                color: var(--pink);
            }

            &.selected {
                background-color: var(--gray-cyan);
                font-weight: 600;
                color: var(--blue);
            }
        }

    }

    .month-of-year {
        background-color: transparent;
        font-weight: normal;
        width: 30%;

        &:hover {
            background-color: var(--gray-3);
        }

        &.selected {
            background-color: var(--gray-cyan);
            font-weight: 600;
            color: var(--blue);
        }

    }

    .overlay {
        background-color: white;
    }

}

.dark {
    .calendar {

        .cell {

            &:hover {
                background-color: transparent;
            }
        
            &.day-of-week {
                color: var(--dark-fc-1);
            }

            .day {
                color: var(--dark-fc-1);
                &:hover {
                    background-color: var(--dark-bg-3);
                }

                &.highlight {
                    background-color: var(--dark-bg-3);
                    color: var(--pink);
                }

                &.selected {
                    background-color: var(--dark-outline);
                    color: var(--cyan);
                }
            }

        }

        .month-of-year {
            color: var(--dark-fc-1);

            &:hover {
                background-color: var(--dark-bg-3);
            }

            &.selected {
                background-color: var(--dark-outline);
                color: var(--cyan);
            }

        }

        .overlay {
            background-color: var(--dark-bg-1);
        }

    }
}
</style>