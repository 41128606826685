<template>
<div>
<modal v-model="modal" dir="right" :modalClass="['fill borderless', 'md right h100']" :title="`Factura N°${invoice ? invoice.number : ''}`" hideActions>
	
	<i class="options icon-dots-vertical" slot="options" v-if="isAdmin || canPayInvoice" @click="Options([
		{text: 'Eliminar Factura', class: 'error-color', function: DeleteInvoice, if: isAdmin},
		{text: 'Editar Factura', class: 'selected-color', function: EditInvoice, if: isAdmin},
		{text: 'Liquidar Pago Pendiente', class: 'selected-color', function: () => {OpenPayInvoice()}, if: canPayInvoice},
		{text: 'Imprimir Voucher', function: () => {PrintInvoiceVoucher()}},
	])"></i>

	<div class="pa20 copyable" v-if="invoice">

		<div>
			<div class="f18 mb20">Información</div>

			<div class="mb15">
				<div class="bold">Proveedor</div>
				<div>{{invoice.custom_data.provider.name}} - {{invoice.custom_data.provider.rut}}</div>
			</div>

			<div class="mb15">
				<div class="bold">Número</div>
				<div>Factura N°{{invoice.number}}</div>
			</div>

			<div class="mb15">
				<div class="bold">Fecha</div>
				<div>{{Util.Date(invoice.date, 'LL')}}</div>
			</div>

			<div class="mb15" v-if="invoice.custom_data.comment">
				<div class="bold">Comentario</div>
				<div>{{invoice.custom_data.comment}}</div>
			</div>

			<input-files label="Documentos" readonly v-if="invoice.custom_data.files && invoice.custom_data.files.length" v-model="invoice.custom_data.files"></input-files>

			

			<!-- files -->
		</div>

		<!-- Log -->
		<div>

			<div class="separator mb20 mt20"></div>

			<div class="f18 mb20">Historial</div>

			<div class="mb15">
				<div class="bold">{{Util.Date(invoice.created_at, 'LLLL')}}</div>
				<div>Ingresada por {{invoice.custom_data.created_by}}</div>
			</div>

			<div class="cgreen" v-if="invoice.paid_at">
				<div class="bold">{{Util.Date(invoice.paid_at, 'LLLL')}}</div>
				<div>Pagada por {{invoice.custom_data.paid_by}}</div>
			</div>
		</div>

		<!-- items -->
		<div>

			<div class="separator mb20 mt20"></div>

			<div class="f18 mb20">Items</div>

			<!--
			<table class="summary">
				<tr>
					<th>Item</th>
					<th>Cantidad</th>
					<th>Precio</th>
					<th>Subtotal</th>
					<th>IVA</th>
					<th>Impuesto</th>
					<th>Flete</th>
					<th>Descuento</th>
					<th>Total</th>
				</tr>
				<tr v-for="(item, index) in invoice.data" :key="index">
					<td>{{item.item}}</td>
					<td>{{Util.Number(item.quantity)}} <span class="secondary-text" v-if="item.unit">{{item.unit}}</span></td>
					<td>{{Util.Price(item.price)}}</td>
					<td>{{Util.Price(item.subtotal)}}</td>
					<td>{{Util.Price(item.iva)}}</td>
					<td>{{Util.Price(item.tax)}}</td>
					<td>{{Util.Price(item.deliveryFee)}}</td>
					<td>{{Util.Price(item.discount)}}</td>
					<td class="bold">{{Util.Price(item.total)}}</td>
				</tr>
			</table>
			-->
			<div class="well mb10 flex column" v-for="(item, index) in invoice.data" :key="index">
				<div class="bold">{{item.item}}</div>
				<div class="flex">
					<div class="w20">
						<div class="secondary-text">Cantidad</div>
						<div>{{Util.Number(item.quantity)}} <span class="secondary-text" v-if="item.unit">{{item.unit}}</span></div>
					</div>
					<div class="w20">
						<div class="secondary-text">Precio</div>
						<div>{{Util.Price(item.price)}}</div>
						<div class="secondary-text">Subtotal</div>
						<div>{{Util.Price(item.subtotal)}}</div>
					</div>
					<div class="w20">
						<div class="secondary-text">IVA</div>
						<div>{{Util.Price(item.iva)}}</div>
						<div class="secondary-text">Impuesto+</div>
						<div>{{Util.Price(item.tax)}}</div>
					</div>
					<div class="w20">
						<div class="secondary-text">Flete</div>
						<div>{{Util.Price(item.deliveryFee)}}</div>
						<div class="secondary-text">Descuento</div>
						<div>{{Util.Price(item.discount)}}</div>
					</div>
					<div class="w20 ta-right">
						<div class="secondary-text">Total</div>
						<div class="bold">{{Util.Price(item.total)}}</div>
					</div>
				</div>
			</div>

		</div>

		<!-- metodos de pago -->
		<div>

			<div class="separator mb20 mt20"></div>

			<div class="f18 mb20">Total</div>

			<div class="mb15">
				<div class="bold">Neto</div>
				<div>{{Util.Price(invoice.custom_data.total.subtotal)}}</div>
			</div>

			<div class="mb15">
				<div class="bold">IVA</div>
				<div>{{Util.Price(invoice.custom_data.total.iva)}}</div>
			</div>

			<div class="mb15">
				<div class="bold">Impuesto adicional</div>
				<div>{{Util.Price(invoice.custom_data.total.taxes)}}</div>
			</div>

			<div class="mb15">
				<div class="bold">Flete</div>
				<div>{{Util.Price(invoice.custom_data.total.deliveryFee)}}</div>
			</div>

			<div class="mb15">
				<div class="bold">Descuentos</div>
				<div>{{Util.Price(invoice.custom_data.total.discount)}}</div>
			</div>

			<div class="">
				<div class="bold">Total</div>
				<div>{{Util.Price(invoice.total)}}</div>
			</div>

			<div class="mt15">
				<div class="bold">Método de Pago</div>
				<div>{{Util.PayMethod(invoice.pay_method)}}</div>
			</div>

			<div class="well cred mt15" v-if="invoice.status == 'PENDING'">
				<div>Pago Pendiente</div>
			</div>

		</div>

	</div>

</modal>

<modal v-model="modalPayInvoice" title="Liquidar Factura Pendiente" dir="center" modalClass="sm">
	<div class="pa20" v-if="modalPayInvoice">

		<div class="mb15">
			<div class="bold">Factura</div>
			<div>N°{{modalPayInvoice.number}}</div>
		</div>

		<div class="mb15">
			<div class="bold">Total</div>
			<div>{{Util.Price(modalPayInvoice.total)}}</div>
		</div>
	
		<input-select v-if="payMethods" 
		v-model="modalPayInvoice.pay_method" 
		:items="payMethods" 
		label="Método de Pago"
		canType="search"></input-select>
	
	</div>
	
	<!--	<button @click="modalPayInvoice=null">Cancelar</button>-->
	<button slot="actions" class="primary purple" @click="PayInvoice(null, null)">Liquidar Pago</button>
	
</modal>

</div>
</template>

<script>
export default {
	data() {
		return {
			modal: null,
			invoice: null,
			payMethods: null,
			modalPayInvoice: null,
		}
	},
	methods: {
		Open(invoice, from_pending_payment)
		{
			if(from_pending_payment)
			{
				if(typeof invoice == 'number')
				{
					this.Loading();
					axios.get(`/pending/payment/invoice/${invoice}`).then(res => {
						this.invoice = res.data;
						this.modal = true;
						this.Response(res);
					}).catch(err => {
						this.Response(err);
					});
				}
				else
				{
					this.Loading();
					axios.get(`/invoice/${invoice.custom_data.id_invoice}`).then(res => {
						this.invoice = res.data;
						this.modal = true;
						this.Response(res);
					}).catch(err => {
						this.Response(err);
					});
				}
			}
			else
			{
				if(typeof invoice == 'number')
				{
					this.Loading();
					axios.get(`/invoice/${invoice}`).then(res => {
						this.invoice = res.data;
						this.modal = true;
						this.Response(res);
					}).catch(err => {
						this.Response(err);
					});
				}
				else
				{
					this.invoice = invoice;
					this.modal = true;
				}
			}
		},
		CancelInvoice(invoice)
		{
			if(!invoice) invoice = this.invoice;
		},
		OpenPayInvoice(invoice)
		{
			if(!invoice) invoice = this.invoice;
			if(!this.payMethods)
			{
				this.Loading();
				axios.get(`/methods/${this.Local.id}`).then(res => {
					this.Response(res);
					this.payMethods = this.Util.SettingsToPaymentMethods(res.data);
					this.modalPayInvoice = invoice;
				}).catch(err => {
					this.Response(err);
				});
			}
			else
				this.modalPayInvoice = invoice;
		},
		PayInvoice(invoice, method)
		{
			if(!invoice) invoice = this.modalPayInvoice;
			if(!method) method = this.modalPayInvoice.pay_method;
			this.Loading();
			axios.post(`/invoice/${invoice.id}/pay`, {method: method}).then(res => {
				this.Info(`Factura pagada exitosamente`);
				this.invoice = res.data;
				this.$emit('OnInvoicePaid', res.data);
				this.modalPayInvoice = null;
			}).catch(err => {
				this.Response(err);
			});
		},
		DeleteInvoice(invoice, rollback)
		{
			if(!invoice) invoice = this.invoice;
			if(rollback === undefined)
			{
				return this.Options([
					{text: 'Eliminar y reducir inventario', class: 'error-color', function: () => {this.DeleteInvoice(invoice, true)}, confirm: `¿Eliminar Factura N°${invoice.number} reduciendo el inventario?`},
					{text: 'Eliminar sin afectar inventario', function: () => {this.DeleteInvoice(invoice, false)}, confirm: `¿Eliminar Factura N°${invoice.number} sin afectar el inventario?`},
				]);
			}
			this.Loading();
			axios.post(`/invoice/${invoice.id}/delete`, {rollback: rollback}).then(res => {
				this.Info(`Factura eliminada exitosamente`);
				this.$emit('OnInvoiceDeleted', res.data);
				this.invoice = null;
				this.modal = null;
			}).catch(err => {
				this.Response(err);
			});
		},
		EditInvoice(invoice){
			this.modal = null;
			if(!invoice) invoice = this.invoice;
            this.$emit("OnEditInvoice", invoice);
		},
		PrintInvoiceVoucher(invoice)
		{
			var data = invoice ? invoice : this.invoice;
            if(!data) return;

            var moment = require('moment');
            var now = moment();
            var arr = [
                {text: this.Local.name},
                {text: `Fecha impresión: ${now.format('HH:mm DD/MM/YYYY')}`},
                {text: `Fecha factura: ${this.Util.Date(data.date,'HH:mm DD/MM/YYYY')}`},
                {text: `Ingresada por: ${data.custom_data.created_by}`},
            ];

			if(data.custom_data.edit_by)
			{
				arr.push({text: `Editada por: ${data.custom_data.edit_by}`});	
			}

            arr.push({text: `Factura Número: ${data.number}`});
			arr.push({text: `Proveedor: ${data.custom_data.provider.name} - ${data.custom_data.provider.rut}`});
            arr.push({line: true});

			data.data.forEach(entry => {
				arr.push({text: [`${entry.item}`, (entry.quantity > 0 ? '+' : '')+this.Util.Number(entry.quantity)]});
            	//arr.push({text: [``, `${this.Util.Number(data.quantity_from)} -> ${this.Util.Number(data.quantity_to)}`]});
			});
            
			arr.push({line: true});
			arr.push({text: `Neto: ${this.Util.Price(data.custom_data.total.subtotal)}`});
			arr.push({text: `IVA: ${this.Util.Price(data.custom_data.total.iva)}`});
			if(data.custom_data.total.taxes) arr.push({text: `Impuesto adicional: ${this.Util.Price(data.custom_data.total.taxes)}`});
			if(data.custom_data.total.deliveryFee) arr.push({text: `Flete: ${this.Util.Price(data.custom_data.total.deliveryFee)}`});
			if(data.custom_data.total.discount) arr.push({text: `Descuento: ${this.Util.Price(data.custom_data.total.discount)}`});
			arr.push({text: `Total: ${this.Util.Price(data.total)}`});
            
            this.Util.NotifyService('print', {data: arr, id: Math.random().toString()});
		}
	},
	computed: {
		canPayInvoice() {
			return !!(this.invoice && this.invoice.status == 'PENDING' && (this.isAdmin || this.Auth.restrictions.pay_pending_inventory_invoices));
		}
	}
}
</script>
<style>
</style>