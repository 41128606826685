import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import App from './App.vue'
import {routes} from './routes'
import storeData from './store'
import longClickDirective from './directives/longclick'
import draggable from 'vuedraggable'
import VueSocketIO from 'vue-socket.io-extended'
import io from 'socket.io-client'
import scrollTo from 'animated-scroll-to/lib/animated-scroll-to.js'
import InputFacade from 'vue-input-facade'
import VueCurrencyInput from 'vue-currency-input'
import VTooltip from 'v-tooltip'

//Browser App
import browser from './browser'

import './registerServiceWorker'

document.addEventListener(process.env.CORDOVA_PLATFORM ? 'deviceready' : 'DOMContentLoaded', () => {

Vue.config.productionTip = false
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(InputFacade);
Vue.use(VTooltip)
Vue.use(VueCurrencyInput, {
	locale: 'es-ES',
	precision: {min: 0, max: 3},
	'distraction-free': {
		hideNegligibleDecimalDigits: false,
		hideCurrencySymbol: true,
		hideGroupingSymbol: false
	}
});
window.Util = require('./util.js').default;

window.scrollTo = scrollTo;
window.Util.scrollTo = scrollTo;

const store =  new Vuex.Store(storeData);

const router = new VueRouter({
	//base: '/myproject/public_html/',
	mode: process.env.CORDOVA_PLATFORM === 'android' || process.env.CORDOVA_PLATFORM === 'ios' ? 'hash' : 'history',
	routes: routes,
});

/*
router.afterEach((to, from) => {
	if(router.app.Auth && (['admin', 'sub_admin'].indexOf(router.app.Auth.role) > -1) && router.app.Local)
	{
		router.app.$socket.client.emit('CONNECT_TO_LOCAL', router.app.Local.id);
	}
});
*/

const socket = io(process.env.VUE_APP_REALTIME_SERVER, {
	autoConnect: false,
	//reconnectionAttempts: 5
});
window.Util.socket = socket;
Vue.use(VueSocketIO, socket);

const longClickInstance = longClickDirective({delay: 400, interval: 0});
Vue.directive('longclick', longClickInstance);

Vue.component('button-file', require('./components/common/ButtonFile.vue').default);
Vue.component('input-field', require('./components/common/InputField.vue').default);
Vue.component('input-image', require('./components/common/InputImage.vue').default);
Vue.component('input-date', require('./components/common/InputDate.vue').default);
Vue.component('input-select', require('./components/common/InputSelect.vue').default);
Vue.component('input-range', require('./components/common/InputRange.vue').default);
Vue.component('input-color', require('./components/common/InputColor.vue').default);
Vue.component('input-files', require('./components/common/InputFiles.vue').default);
Vue.component('input-text', require('./components/common/InputText.vue').default);
Vue.component('input-toggle', require('./components/common/InputToggle.vue').default);
Vue.component('input-icon', require('./components/common/InputIcon.vue').default);
Vue.component('badge', require('./components/common/Badge.vue').default);
Vue.component('popover-menu', require('./components/common/PopoverMenu.vue').default);

Vue.component('tabs', require('./components/common/Tabs.vue').default);
Vue.component('modal', require('./components/common/Modal.vue').default);
Vue.component('side-menu', require('./components/common/SideMenu.vue').default);
Vue.component('loading', require('./components/common/Loading.vue').default);
Vue.component('chart', require('./components/common/Chart.vue').default);
Vue.component('map-component', require('./components/common/MapComponent.vue').default);
Vue.component('vertical-content', require('./components/common/VerticalContent.vue').default);
Vue.component('size-manager', require('./components/common/SizeManager.vue').default);
Vue.component('modal-estimate-pya', require('./components/common/ModalEstimatePya.vue').default);
Vue.component('modal-factura-electronica', require('./components/common/ModalFacturaElectronica.vue').default);
Vue.component('factura-electronica', require('./components/views/FacturaElectronicaView.vue').default);

Vue.component('fullscreen-header-default', require('./components/common/FullscreenHeaderDefault.vue').default);
Vue.component('transactions-list', require('./components/common/TransactionsList.vue').default);
Vue.component('transaction-details', require('./components/common/TransactionDetails.vue').default);
Vue.component('customer-details', require('./components/common/CustomerDetails.vue').default);
Vue.component('account-form', require('./components/common/AccountForm.vue').default);
Vue.component('order-form', require('./components/common/OrderForm.vue').default);
Vue.component('account-turn-menu', require('./components/common/AccountTurnMenu.vue').default);
Vue.component('turn-details', require('./components/common/TurnDetails.vue').default);
Vue.component('expense-details', require('./components/common/ExpenseDetails.vue').default);
Vue.component('inventory-details', require('./components/common/InventoryTransactionDetails.vue').default);
Vue.component('pending-payment-details', require('./components/common/PendingPaymentTransactionDetails.vue').default);
Vue.component('items-selection', require('./components/common/ItemsSelection.vue').default);
Vue.component('menu-item-form', require('./components/common/MenuItemForm.vue').default);
Vue.component('iap-settings', require('./components/views/InAppOrdersView.vue').default);
Vue.component('local-profile-settings', require('./components/views/LocalProfileView.vue').default);
Vue.component('transaction-edit', require('./components/common/TransactionItemsEdit.vue').default);
Vue.component('sales-form', require('./components/common/SalesForm.vue').default);
Vue.component('local-sii-settings', require('./components/views/LocalSiiSettings.vue').default);
Vue.component('iap-details', require('./components/common/IAPDetails.vue').default);
Vue.component('iap-transactions-list', require('./components/common/IAPList.vue').default);
Vue.component('iap-controller', require('./components/common/IAPController.vue').default);
Vue.component('virtual-menu-settings', require('./components/views/VirtualMenuSettings.vue').default);
Vue.component('local-staff-view', require('./components/views/LocalStaffView.vue').default);
Vue.component('invoice-details', require('./components/common/InvoiceDetails.vue').default);
Vue.component('iap-quick-settings', require('./components/common/IAPQuickSettings.vue').default);
Vue.component('calendar', require('./components/common/Calendar.vue').default);
Vue.component('avatar', require('./components/common/Avatar.vue').default);
//Vue.component('customization-form', require('./components/common/CustomizationForm.vue').default);
Vue.component('draggable', draggable);

//Axios setup
window.Util.SetServer();

Vue.mixin({
	data() {
		return {
			Util: window.Util,
		}
	},
	mounted() {
		this.Util.app = this;
	},
	methods: {
		Options(options, event, backdrop) {
			this.$root.$emit('ShowOptions', options, event, backdrop);
		},
		Confirm(params) {
			this.$root.$emit('Confirm', params);
		},
		Toast(params) {
			this.$root.$emit('Toast', params);
		},
		img(file, url, fallback) {
			if(file === null || file == 'DELETE') return fallback ? this.img(fallback, url) : null;
			if(file.indexOf('data:') > -1 ) return file.substr(file.indexOf('data:'));
			var u = `${this.Util.backend}storage/${file}`;
			return url ? `url(${u})` : u;
		},
		Loading(param) {
			this.$root.$emit('Loading', param);
		},
		Response(param) {
			this.$root.$emit('Response', param);
		},
		Info(param) {
			this.$root.$emit('Response', {info: param.replace(/\n/g, '<br>')});
		}
	},
	computed: {
		platform() {
			return this.$root.currentPlatform;
		},
		mobile() {
			return this.platform == 'mobile';
		},
		desktop() {
			return this.platform == 'desktop';
		},
		keyboard() {
			return this.$root.currentKeyboard;
		},
		Auth() {
			return this.$store.state.user;
		},
		Local() {
			return this.$store.state.local;
		},
		Turn() {
			return this.$store.state.turn;
		},
		dark() {
			return this.$store.state.dark;
		},
		isAdmin() {
			return this.$store.state.user ? this.$store.state.user.role.indexOf('admin') > -1 : false;
		}
	}
})

new Vue({
	router,
	store,
	render: h => h(App),
	data() {
		return {
			currentPlatform: 'none',
			currentKeyboard: false,
		}
	},
	mounted() {
		NativeStorage.getItem('token', (val) => {
			console.log('Token found: ' + val);
			this.$store.commit('login', {token: val, app: this});
		}, () => {
			console.log('No Login Token');
			this.$children[0].loggingIn = false;
		});
		
	}
}).$mount('#app');

});

