/* eslint-disable no-console */

import { register } from 'register-service-worker'

console.log('REGISTER SERVICE WORKER');
if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(function (registrations) {
          for (let registration of registrations) {
            registration.update()
            // Set an interval to check for updates periodically (e.g., every 30 minutes)
            setInterval(() => {
              registration.update();
              console.log('interval update check');
            }, 30 * 60 * 1000); // 30 minutes
          }
        })
      }
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated () {
      console.log('New content is available; please refresh.');
      window.updatefound = true;
      var event = new Event('UpdateFound', {bubbles: true});
      document.dispatchEvent(event);
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
