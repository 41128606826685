<template>
<div>

    <div v-if="label" class="bold mb10">{{label}}</div>
    <div class="secondary-text mb10" v-if="description">{{description}}</div>

    <div @click.stop="isOpen = !isOpen" ref="el">
        
            <slot></slot>
            <div v-show="!isHidden">
                <button v-if="icon" :class="`transparent icon icon-${icon}`"></button>
                <div v-else ref="input" :class="`input-field input-select relative after selectable ${inputClass}`">
                    
                    <button class="absolute fill trbl0 bgt"></button>
                    <div class="input" v-if="currentValue">{{currentValue.label}}</div>
                    <div class="input cgray2" v-else>{{placeholder}}</div>
                    <div class="after">
                        <i :class="`icon icon-arrow-down`"></i>
                    </div>
                </div>
            </div>

    </div>


    <modal v-model="isOpen" hideActions :domElement="$refs.el" :hideBackdrop="desktop" :dir="['up', 'center']" :modalClass="['w100 up']" ref="modal"
    :clickOutside="!submodalOpen">
        <div class="input-popover" style="padding: 0; max-height: 100%">
               
                <div v-if="isOpen" class="flex">

                    <div class="flex column pa10 options-panel unshrink">
                        <button v-for="option in selectOptions" :key="option.value" 
                        @click="currentOption = option.value"
                        :class="`ta-left ${currentOption == option.value ? 'selected' : ''}`">{{option.text}}</button>
                    </div>

                    <div class="flex column ofy pa10 flex-fill" style="max-height: 40vh">
                        <button v-for="(subOption, index) in subSelectOptions[currentOption]" :key="index" 
                        @click="SelectOption(subOption)"
                        :class="`ta-left ${currentValue.label == subOption.text ? 'selected' : ''}`"
                        style="white-space: pre">{{subOption.text}}</button>

                        <div v-if="currentOption == 'RANGE'" class="pa10">
                            <input-date inputClass="sm"
                            ref="rangeStart"
                            v-model="start"
                            class="mb15"
                            label="Inicio"
                            format="timestamp"
                            @OnModalDateChanged="$event => submodalOpen = $event"
                            @OnModalTimeChanged="$event => submodalOpen = $event">
                            </input-date>

                            <input-date inputClass="sm"
                            ref="rangeFinish"
                            v-model="finish"
                            class="mb15"
                            label="Fin"
                            format="timestamp"
                            @OnModalDateChanged="$event => submodalOpen = $event"
                            @OnModalTimeChanged="$event => submodalOpen = $event">
                            </input-date>

                            <button class="primary purple w100" @click="SearchRange">Aceptar</button>
                        </div>

                    </div>
                    
                </div>
                
        </div>
    </modal>
    
</div>
</template>

<script>
var moment = require('moment');
moment.locale('es');
export default {
    props: {
        value: {
            type: [Object, String],
            default: () => {}
        },
        label: {
            type: String,
            default: null
        },
        description: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: null
        },
        inputClass: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            default: null
        },
        isHidden: {
            type: Boolean,
            default: false
        },
        triggerFirstValueChanged: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            currentValue: null,
            range: null,
            currentOption: 'DAY',
            selectOptions: [],
            subSelectOptions: {
                DAY: [],
                WEEK: [],
                MONTH: [],
                YEAR: []
            },
            start: null,
            finish: null,
            isOpen: false,
            submodalOpen: false,
        }
    },
    mounted() {
        
        this.selectOptions = [
            {text: 'Seleccionar Día', value: 'DAY'},
            {text: 'Seleccionar Semana', value: 'WEEK'},
            {text: 'Seleccionar Mes', value: 'MONTH'},
            {text: this.mobile ? 'Seleccionar Año' : 'Seleccionar Año o Periodo', value: 'YEAR'},
            {text: 'Seleccionar Rango', value: 'RANGE'},
        ];

        var current = moment();

        this.start = moment().startOf('day').unix();
        this.finish = moment().endOf('day').unix();

        current = moment();
        for(var i = 0; i <= 14; i++)
        {
            var label = current.format('ddd D MMMM'); 
            if(this.desktop)
            {
                if(i==0) label += ' (Hoy)';
                if(i==1) label += ' (Ayer)';
            }
            this.subSelectOptions.DAY.push({
                text: label,
                value: {
                    option: 'DAY',
                    label: label,
                    rangeStart: current.startOf('day').unix(),
                    rangeEnd: current.endOf('day').unix()
                }
            });
            current.subtract(1, 'day');
        }
        current = moment();
        this.subSelectOptions.DAY.push({
            text: 'Últimas 24 horas',
            value: {
                option: 'DAY',
                label: 'Últimas 24 horas',
                rangeStart: current.unix() - 3600,
                rangeEnd: current.unix()
            }
        });

        current = moment();
        this.subSelectOptions.WEEK.push({
            text: 'Esta semana',
            value: {
                option: 'DAY',
                label: 'Esta semana',
                rangeStart: current.startOf('week').unix(),
                rangeEnd: current.endOf('week').unix()
            }
        });
        current = current.subtract(1, 'week');
        this.subSelectOptions.WEEK.push({
            text: 'Semana pasada',
            value: {
                option: 'DAY',
                label: 'Semana pasada',
                rangeStart: current.startOf('week').unix(),
                rangeEnd: current.endOf('week').unix()
            }
        });
        current = current.subtract(1, 'week');
        this.subSelectOptions.WEEK.push({
            text: 'Semana ante pasada',
            value: {
                option: 'DAY',
                label: 'Semana ante pasada',
                rangeStart: current.startOf('week').unix(),
                rangeEnd: current.endOf('week').unix()
            }
        });
        current = moment();
        this.subSelectOptions.WEEK.push({
            text: 'Últimos 7 días',
            value: {
                option: 'DAY',
                label: 'Últimos 7 días',
                rangeStart: current.unix() - (7*24*3600),
                rangeEnd: current.unix()
            }
        });

        current = moment();
        for(var i = 0; i < 12; i++)
        {
            var label = current.format('MMMM YYYY');
            this.subSelectOptions.MONTH.push({
                text: label,
                value: {
                    option: 'MONTH',
                    label: label,
                    rangeStart: current.startOf('month').unix(),
                    rangeEnd: current.endOf('month').unix()
                }
            });
            current.subtract(1, 'month');
        }
        current = moment();
        this.subSelectOptions.MONTH.push({
            text: 'Últimos 30 días',
            value: {
                option: 'MONTH',
                label: 'Últimos 30 días',
                rangeStart: current.unix() - (30*24*3600),
                rangeEnd: current.unix()
            }
        });

        current = moment();
        do {
            this.subSelectOptions.YEAR.push({
                text: current.format('YYYY'),
                value: {
                    option: 'YEAR',
                    label: current.format('YYYY'),
                    rangeStart: current.startOf('year').unix(),
                    rangeEnd: current.endOf('year').unix()
                }
            });
            
            this.subSelectOptions.YEAR.push({
                text: '- 2do semestre ' + current.format('YYYY'),
                value: {
                    option: 'YEAR',
                    label: '2do semestre ' + current.format('YYYY'),
                    rangeStart: current.month(6).startOf('month').unix(),
                    rangeEnd: current.clone().month(11).endOf('month').unix()
                }
            });

            this.subSelectOptions.YEAR.push({
                text: '- 1er semestre ' + current.format('YYYY'),
                value: {
                    option: 'YEAR',
                    label: '1er semestre ' + current.format('YYYY'),
                    rangeStart: current.month(0).startOf('month').unix(),
                    rangeEnd: current.clone().month(5).endOf('month').unix()
                }
            });

            this.subSelectOptions.YEAR.push({
                text: '- 3er trimestre ' + current.format('YYYY'),
                value: {
                    option: 'YEAR',
                    label: '3er trimestre ' + current.format('YYYY'),
                    rangeStart: current.month(8).startOf('month').unix(),
                    rangeEnd: current.clone().month(11).endOf('month').unix()
                }
            });

            this.subSelectOptions.YEAR.push({
                text: '- 2do trimestre ' + current.format('YYYY'),
                value: {
                    option: 'YEAR',
                    label: '2do trimestre ' + current.format('YYYY'),
                    rangeStart: current.month(4).startOf('month').unix(),
                    rangeEnd: current.clone().month(7).endOf('month').unix()
                }
            });

            this.subSelectOptions.YEAR.push({
                text: '- 1er trimestre ' + current.format('YYYY'),
                value: {
                    option: 'YEAR',
                    label: '1er trimestre ' + current.format('YYYY'),
                    rangeStart: current.month(0).startOf('month').unix(),
                    rangeEnd: current.clone().month(3).endOf('month').unix()
                }
            });
            /*
            this.subSelectOptions.YEAR.push({
                text: '  4to cuatrimestre ' + current.format('YYYY'),
                value: {
                    option: 'YEAR',
                    label: '4to cuatrimestre ' + current.format('YYYY'),
                    rangeStart: current.month(9).startOf('month').unix(),
                    rangeEnd: current.clone().month(11).endOf('month').unix()
                }
            });

            this.subSelectOptions.YEAR.push({
                text: '  3er cuatrimestre ' + current.format('YYYY'),
                value: {
                    option: 'YEAR',
                    label: '3er cuatrimestre ' + current.format('YYYY'),
                    rangeStart: current.month(6).startOf('month').unix(),
                    rangeEnd: current.clone().month(8).endOf('month').unix()
                }
            });

            this.subSelectOptions.YEAR.push({
                text: '  2do cuatrimestre ' + current.format('YYYY'),
                value: {
                    option: 'YEAR',
                    label: '2do cuatrimestre ' + current.format('YYYY'),
                    rangeStart: current.month(3).startOf('month').unix(),
                    rangeEnd: current.clone().month(5).endOf('month').unix()
                }
            });

            this.subSelectOptions.YEAR.push({
                text: '  1er cuatrimestre ' + current.format('YYYY'),
                value: {
                    option: 'YEAR',
                    label: '1er cuatrimestre ' + current.format('YYYY'),
                    rangeStart: current.month(0).startOf('month').unix(),
                    rangeEnd: current.clone().month(2).endOf('month').unix()
                }
            });
            */

            current.subtract(1, 'year');
        } while(current.year() >= 2019)

        
        //this.currentOption = this.value && this.value.option ? this.value.option : 'DAY';
        //this.range = this.value && this.value.index ? this.subSelectOptions[this.currentOption][this.value.index].value : null;
        //console.log(this.value);
        if(this.value)
        {
            if(this.value == 'NONE')
            {
                this.currentValue = {};
            }
            else
            {
                this.currentValue = this.subSelectOptions[this.value.option][this.value.index].value;
                this.currentOption = this.value.option;
            }
        }
        else
        {
            this.currentValue = this.subSelectOptions.DAY[0].value;
        }

    },
    methods: {
        SelectOption(option)
        {
            this.currentValue = option.value;
            this.isOpen = false;
        },
        SearchRange()
        {
            console.log(this.start);
            console.log(this.finish);
            if(this.start < this.finish)
            { 
                this.currentValue = {
                    option: 'RANGE',
                    //label: `${this.Util.Date(this.start, 'DD/MM/YYYY HH:mm')} - ${this.Util.Date(this.finish, 'DD/MM/YYYY HH:mm')}`,
                    label: `${this.Util.Date(this.start, 'DD/MM/YYYY')} - ${this.Util.Date(this.finish, 'DD/MM/YYYY')}`,
                    rangeStart: this.start,
                    rangeEnd: this.finish + 59
                };
                this.isOpen = false;
            }
        },
        Open()
        {
            this.isOpen = true;
        }
    },
    watch: {
        currentValue: function(n, o) {
            this.$emit('input', n);
            if(o || this.triggerFirstValueChanged)
                this.$emit('OnValueChanged', n);
        }
    }
}
</script>

<style lang="scss">
.input-popover .options-panel 
{
    background-color: var(--gray-3);
}
.dark .input-popover .options-panel 
{
    background-color: var(--dark-bg-1);
}
</style>
