export default ({delay = 400, interval = 50, rightClick = false}) => ({
    bind: function (el, binding, vNode) {
      if (typeof binding.value !== 'function') {
        const compName = vNode.context.name
        let warn = `[longclick:] provided expression '${binding.expression}' is not a function, but has to be`
        if (compName) { warn += `Found in component '${compName}' ` }
        console.warn(warn) // eslint-disable-line
      }
  
      let pressTimer = null
      let pressInterval = null
  
      const start = (e) => {
          console.log(e);
        if(e.button == 2)
        {
            if(rightClick) 
            {

            }
            else
            {
                return;
            }
        }
  
        if (pressTimer === null) {
          pressTimer = setTimeout(() => {
            if (interval && interval > 0) {
              pressInterval = setInterval(() => {
                handler()
              }, interval)
            }
            handler()
          }, delay)
        }
      }
  
      // Cancel Timeout
      const cancel = () => {
        if (pressTimer !== null) {
          clearTimeout(pressTimer)
          pressTimer = null
        }
        if (pressInterval) {
          clearInterval(pressInterval)
          pressInterval = null
        }
      }
      // Run Function
      const handler = (e) => {
        binding.value(e)
      }
  
      ;['mousedown', 'touchstart'].forEach(e => el.addEventListener(e, start))
      ;['click', 'mouseout', 'touchend', 'touchcancel', 'touchmove'].forEach(e => el.addEventListener(e, cancel))
    }
  })