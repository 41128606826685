<template>
<!--<tabs v-model="tab" :tabs="['Ventas', 'Publicaciones', 'Actualizaciones']" :keyByTab="true"-->
<!--@OnTabPublicaciones="!operationsPosts.length ? LoadBulletin('OPERATION') : null"-->
<tabs v-if="tabs" v-model="tab" :tabs="tabs" :keyByTab="true"
:tabCount="tabCount" tabCountNotification
@OnTabActualizaciones="!developmentPosts.length ? LoadBulletin('DEVELOPMENT') : null"
@OnTabInsights="insightData ? null : LoadInsight()">

    
    <div class="opt r" v-show="tab == 'Insights'">

        
            <input-select class="mr5" :items="months" v-model="currentMonth" @OnValueChanged="LoadInsight" :triggerFirstValueChanged="false"></input-select>
            <input-select :items="years" v-model="currentYear" @OnValueChanged="LoadInsight" :triggerFirstValueChanged="false"></input-select>
        
        <!--
        <i class="options icon-dots-vertical" v-if="tab == 'Publicaciones' && Auth.role == 'admin'" @click="Options([
            {text: 'Nuevo Post', class: 'selected-color', function: () => {modalPost={type:'OPERATION'}}},
        ])"></i>
        <i class="options icon-dots-vertical" v-if="tab == 'Actualizaciones' && Auth.role == 'admin'" @click="Options([
            {text: 'Nuevo Post', class: 'selected-color', function: () => {modalPost={type:'DEVELOPMENT'}}},
        ])"></i>
        -->
    </div>

    <vertical-content slot="Ventas" class="pa-body" v-if="salesData" @OnBottom="LoadBulletin('sales', salesData.last_sales[salesData.last_sales.length-1].created_at)">
        <!--
        <div class="hdvm mb15" style="align-items: stetch">
            <div class="">
                <div class="section h100">
                    <div class="f18 mb20">Top Vendedor</div>
                    <div class="flex" style="align-items: flex-start">
                        <div class="mr20" v-if="salesData.top_sales.length">
                            <div class="top-profile">
                                <div class="contain-img" :style="{'border-radius': '50%', 'background-image': img(`accounts/${salesData.top_sales[0].image}`, true)}"></div>
                            </div>
                        </div>
                        <table class="table-top">
                            <tr v-for="(sale, index) in salesData.top_sales" :key="index" :class="index == 0 ? 'top-entry' : ''">
                                <td>{{index+1}}</td>
                                <td>{{sale.account}}</td>
                                <td>{{Util.Number(sale.uf)}} UF</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            -->
            <!--
            <div>
                <div class="section">
                    <div class="f18 mb20">Top Rendimiento</div>
                    <div class="flex" style="align-items: flex-start">
                        <div class="mr10" v-if="salesData.top_performance.length">
                            <div class="top-profile">
                                <div class="contain-img" :style="{'border-radius': '50%', 'background-image': img(`accounts/${salesData.top_performance[0].image}`, true)}"></div>
                            </div>
                        </div>
                        <table class="table-top">
                            <tr v-for="(sale, index) in salesData.top_performance" :key="index" :class="index == 0 ? 'top-entry' : ''">
                                <td>{{index+1}}</td>
                                <td>{{sale.account}}</td>
                                <td>{{Util.Number(sale.performance)}} UF</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            -->
            <!--
        </div>
        -->
        <!--
        <div class="hdvm mb15" style="align-items: stretch">
            <div>
                <div class="section h100">
                    <div class="f18 mb20">Top Clientes</div>
                    <table class="table-top">
                        <tr v-for="(sale, index) in salesData.top_clients" :key="index" :class="index == 0 ? 'top-entry' : ''">
                            <td>{{index+1}}</td>
                            <td>{{sale.account}}</td>
                            <td>{{sale.clients}}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div>
                <div class="section h100">
                    <div class="f18 mb20">Top Suscripciones Anuales</div>
                    <table class="table-top">
                        <tr v-for="(sale, index) in salesData.top_annual" :key="index" :class="index == 0 ? 'top-entry' : ''">
                            <td>{{index+1}}</td>
                            <td>{{sale.account}}</td>
                            <td>{{sale.annual}}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div>
                <div class="section h100">
                    <div class="f18 mb20">Top Suscripciones Semestrales</div>
                    <table class="table-top">
                        <tr v-for="(sale, index) in salesData.top_biannual" :key="index" :class="index == 0 ? 'top-entry' : ''">
                            <td>{{index+1}}</td>
                            <td>{{sale.account}}</td>
                            <td>{{sale.biannual}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        -->
        <!--
        <div class="hdvm">
            <div>
                <div class="section">
                    <div class="bold">Top Cartera Activa <span class="secondary-text">(todo el tiempo)</span></div>
                    <div class="flex">
                        <div class="mr10" v-if="salesData.top_level.length">
                            <div class="top-profile">
                                <div class="contain-img" :style="{'border-radius': '50%', 'background-image': img(`accounts/${salesData.top_level[0].image}`, true)}"></div>
                            </div>
                        </div>
                        <table class="summary">
                            <tr>
                                <th>#</th>
                                <th>Vendedor</th>
                                <th>Nivel Cartera</th>
                            </tr>
                            <tr v-for="(sale, index) in salesData.top_level" :key="index" :class="index == 0 ? 'top-entry' : ''">
                                <td>{{index+1}}</td>
                                <td>{{sale.account}}</td>
                                <td>{{sale.level}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div>
                <div class="section">
                    <div class="bold">Top Suscripciones <span class="secondary-text">(este mes)</span></div>
                    <div class="flex">
                        <div class="mr10" v-if="salesData.top_months.length">
                            <div class="top-profile">
                                <div class="contain-img" :style="{'border-radius': '50%', 'background-image': img(`accounts/${salesData.top_months[0].image}`, true)}"></div>
                            </div>
                        </div>
                        <table class="summary">
                            <tr>
                                <th>#</th>
                                <th>Vendedor</th>
                                <th>Meses</th>
                            </tr>
                            <tr v-for="(sale, index) in salesData.top_months" :key="index" :class="index == 0 ? 'top-entry' : ''">
                                <td>{{index+1}}</td>
                                <td>{{sale.account}}</td>
                                <td>{{sale.months}} meses</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
        -->

        <!--
        <div class="section" v-if="salesData.last_direct_sale" style="color: var(--white); background-color: #4bc445 !important">
            <div class="flex align-center">
                <i class="icon icon-olympic-torch" style="display: inline-block; transform: scale(2); margin-right: 20px"></i>
                <div>¡{{salesData.last_direct_sale.custom_data.account.name}} lleva la batuta con la última venta directa!</div>
            </div>
        </div>
        -->
        
        <div class="section relative sale-entry" v-for="sale in salesData.last_sales" :key="sale.id" style="margin-bottom: 20px">
            <div class="flex" style="border-radius: inherit">
                <div class="flex column" style="padding: 15px; padding-right: 0px">
                    <div style="width: 60px; height: 60px;">
                        <div class="contain-img" :style="{'border-radius': '50%', 'background-image': img(`accounts/${sale.custom_data.account.image}`, true)}"></div>
                    </div>
                    <!--<div class="secondary-text ta-center">#{{sale.sale_number_personal}}</div> -->
                </div>
                <div class="flex column flex-fill" style="padding: 20px;">
                    <div class="secondary-text" v-if="sale.source == 'DIRECT' || sale.source == 'ASSISTED'">{{sale.custom_data.account.name}} concretó una venta el {{Util.Date(sale.created_at)}}</div>
                    <div class="secondary-text" v-if="sale.source == 'SELLUP'">{{sale.custom_data.account.name}} concretó un sell-up el {{Util.Date(sale.created_at)}}</div>
                    <div class="secondary-text" v-if="sale.source == 'SUBSCRIPTION'">{{sale.custom_data.account.name}} concretó una subscripción el {{Util.Date(sale.created_at)}}</div>
                    <div class="bold" :style="{'font-size': '20px', color: SaleColor(sale)}">{{sale.custom_data.local.name}}</div>
                    <div class="flex" style="flex-wrap: wrap">
                        <div class="sale-tag" v-if="sale.source == 'SELLUP'">Sell-up</div>
                        <div class="sale-tag" v-if="sale.source == 'SUBSCRIPTION'">Subscripción</div>
                        <div class="sale-tag" v-if="(sale.source == 'DIRECT' || sale.source == 'ASSISTED') && sale.sale_number_month == 1">Primera venta del mes</div>
                        <div class="sale-tag" v-if="(sale.source == 'DIRECT' || sale.source == 'ASSISTED') && sale.sale_number_month > 1">#{{sale.sale_number_month}} del mes</div>
                        <div class="sale-tag" v-if="(sale.source == 'DIRECT' || sale.source == 'ASSISTED')">#{{sale.sale_number_personal}} de {{sale.custom_data.account.name}}</div>
                        <div class="sale-tag" v-if="sale.months == 3">Plan trimestral</div>
                        <div class="sale-tag" v-if="sale.months == 6">Plan semestral</div>
                        <div class="sale-tag" v-if="sale.months == 12">Plan anual</div>
                        <div class="sale-tag" v-if="sale.source == 'DIRECT'">Venta directa</div>
                        <div class="sale-tag" v-if="salesData.last_direct_sale && salesData.last_direct_sale.id == sale.id">¡Batuta!</div>
                    </div>
                </div>
                <div :class="`flex column plan justify-center align-center unshrink`" :style="{'background-color': SaleColor(sale)}">
                    <div>{{Util.Number(sale.plan)}}</div>
                    <div style="font-size: 10px; margin-top: -4px">UF</div>
                    <div v-if="sale.source !== 'SELLUP' && sale.months == 1" class="mt5" style="font-size: 10px">MENSUAL</div>
                    <div v-if="sale.source !== 'SELLUP' && sale.months == 3" class="mt5" style="font-size: 10px">TRIMESTRAL</div>
                    <div v-if="sale.source !== 'SELLUP' && sale.months == 6" class="mt5" style="font-size: 10px">SEMESTRAL</div>
                    <div v-if="sale.source !== 'SELLUP' && sale.months == 12" class="mt5" style="font-size: 10px">ANUAL</div>
                    <div v-if="sale.source == 'SELLUP'" class="mt5" style="font-size: 10px">SELLUP</div>
                </div>
            </div>
            <button @click="LikePost(sale, 'SALE')" class="confetti-btn" style="right: 80px" :disabled="sale.custom_data.likes && sale.custom_data.likes.indexOf(Auth.id) > -1" title="¡Celebra con el equipo!"><span v-if="sale.custom_data.likes && sale.custom_data.likes.length">{{sale.custom_data.likes.length}}</span><i class="icon icon-confetti"></i></button>
        </div>

    </vertical-content>

    <!--
    <vertical-content slot="Publicaciones" @OnBottom="LoadBulletin('OPERATION', operationsPosts[operationsPosts.length-1].created_at)">
        
        <div class="section relative" v-for="post in operationsPosts" :key="post.id" style="margin-bottom: 20px">
            <div class="flex mb5 align-center">
                <div style="width: 30px; height: 30px;" class="mr10">
                    <div class="contain-img" :style="{'border-radius': '50%', 'background-image': img(`accounts/${post.data.account.image}`, true)}"></div>
                </div>
                <div class="secondary-text flex-fill">Publicado por {{post.data.account.name}} el {{Util.Date(post.created_at)}}</div>
                <i v-if="Auth.role == 'admin'" class="options icon-dots-vertical" @click="Options([
                    {text: 'Eliminar Post', class: 'error-color', function: () => {DeletePost(post)}, confirm: '¿Eliminar publicación?'}
                ])"></i>
            </div>
            <div class="flex column">
                <div v-if="post.data.title" class="bold" style="font-size: 16px">{{post.data.title}}</div>
                <div v-if="post.data.description" v-html="post.data.description.replace(/\n/g, '<br>')" class="mt10 mb10" style="line-height: 1.1"></div>
                <img v-if="post.data.image" :src="img(`posts/${post.data.image}`)" style="width: 100%" class="mt5">
                <div class="flex" v-if="post.data.tags">
                    <div class="sale-tag" v-for="(tag, index) in post.data.tags" :key="post.id+'-tag-'+index">{{tag}}</div>
                </div>
            </div>
            <button @click="LikePost(post, 'POST')" class="confetti-btn" :disabled="post.data.likes && post.data.likes.indexOf(Auth.id) > -1" title="¡Celebra con el equipo!"><span v-if="post.data.likes && post.data.likes.length">{{post.data.likes.length}}</span><i class="icon icon-confetti"></i></button>
        </div>
        
       
    </vertical-content>
    -->

    <div slot="Insights" class="pa-body">
        <div v-if="insightData">

            <div class="hdvm mb10">

                <div>
                <div class="section">
                    <div class="flex align-center">
                        <avatar icon="receipt" :color="Util.baseColors.blue" class="mr10"></avatar>
                        <div class="flex-fill">
                            <div class="bold">Pedidos</div>
                            <div class="secondary-text">Transacciones</div>
                        </div>
                        <div class="bold f18">{{Util.Number(insightData.transactions_count)}}</div>
                    </div>
                </div>
                </div>

                <div>
                <div class="section">
                    <div class="flex align-center">
                        <avatar icon="bag" :color="Util.baseColors.pink" class="mr10"></avatar>
                        <div class="flex-fill">
                            <div class="bold">Pedidos Online</div>
                            <div class="secondary-text">Store</div>
                        </div>
                        <div class="bold f18">{{Util.Number(insightData.store_count)}}</div>
                    </div>
                </div>
                </div>

            </div>

            <div class="hdvm mb10">

                <div>
                <div class="section">
                    <div class="flex align-center">
                        <avatar icon="pedidosya" :color="Util.baseColors.red" class="mr10"></avatar>
                        <div class="flex-fill">
                            <div class="bold">PedidosYa</div>
                            <div class="secondary-text">Envios</div>
                        </div>
                        <div class="bold f18">{{Util.Number(insightData.order_pya_count)}}</div>
                    </div>
                </div>
                </div>

                <div>
                <div class="section">
                    <div class="flex align-center">
                        <avatar icon="bill" :color="Util.baseColors.red" class="mr10"></avatar>
                        <div class="flex-fill">
                            <div class="bold">Webpay</div>
                            <div class="secondary-text">Pedidos pagados</div>
                        </div>
                        <div class="bold f18">{{Util.Number(insightData.webpay_count)}}</div>
                    </div>
                </div>
                </div>

            </div>

            <div class="hdvm mb10">
                <div>
                <div class="section">
                    <div class="flex align-center">
                        <avatar icon="invoice" :color="Util.baseColors.yellow" class="mr10"></avatar>
                        <div class="flex-fill">
                            <div class="bold">Boletas</div>
                            <div class="secondary-text">Recibidas</div>
                        </div>
                        <div class="bold f18">{{Util.Number(insightData.boletas_count)}}</div>
                    </div>
                </div>
                </div>

                <div>
                <div class="section">
                    <div class="flex align-center">
                        <avatar icon="invoice" :color="Util.baseColors.yellow" class="mr10"></avatar>
                        <div class="flex-fill">
                            <div class="bold">Facturas</div>
                            <div class="secondary-text">Recibidas</div>
                        </div>
                        <div class="bold f18">{{Util.Number(insightData.facturas_count)}}</div>
                    </div>
                </div>
                </div>
            </div>
            
            <div class="hdvm mb10">
                <div>
                <div class="section">
                    <div class="flex align-center">
                        <avatar icon="mail" :color="Util.baseColors.green" class="mr10"></avatar>
                        <div class="flex-fill">
                            <div class="bold">Emails</div>
                            <!--<div class="secondary-text">Cuentas</div>-->
                        </div>
                        <div class="bold f18">{{Util.Number(insightData.email_count)}}</div>
                    </div>
                </div>
                </div>

                <div>
                <div class="section">
                    <div class="flex align-center">
                        <avatar icon="calendar" :color="Util.baseColors.green" class="mr10"></avatar>
                        <div class="flex-fill">
                            <div class="bold">Pago Suscripciones</div>
                            <!--<div class="secondary-text">Cuentas</div>-->
                        </div>
                        <div class="bold f18">{{Util.Number(insightData.subscriptions_count)}}</div>
                    </div>
                </div>
                </div>
            </div>

            <div class="section">
                <div class="flex align-center">
                    <avatar icon="key" :color="Util.baseColors.green" class="mr10"></avatar>
                    <div class="flex-fill">
                        <div class="bold">Logins</div>
                        <div class="secondary-text">Locales únicos: {{insightData.logins.length}}</div>
                    </div>
                    <div class="bold f18">{{Util.Number(insightData.login_count)}}</div>
                </div>
            </div>

            <div class="mt20 mb20 f18">Logs ({{insightData.logs.total}})</div>

            <div class="section mt10">
                <chart 
                :data="[insightData.logs.time]"
                dataset="count"
                type="bar"
                :label="(data) => {return data.data+' hrs'}"
                :singleDataset="false"
                :showLegend="false"
                :xLabelFormat="(data) => {return data}"
                :yLabelFormat="null"
                :tooltip="(value, label, index) => {return `${parseInt(100 * value / insightData.logs.total)}% (${value})`}"
                :customColor="[Util.baseColors.purple, Util.baseColors.pink]">
                </chart>
            </div>

            <div class="section mt10">
                <chart 
                :data="[insightData.logs.days]"
                dataset="count"
                type="bar"
                :label="(data) => {return Util.DaysArrEs[data.data]}"
                :singleDataset="false"
                :showLegend="false"
                :xLabelFormat="(data) => {return data}"
                :yLabelFormat="null"
                :tooltip="(value, label, index) => {return `${parseInt(100 * value / insightData.logs.total)}% (${value})`}"
                :customColor="[Util.baseColors.purple, Util.baseColors.pink]">
                </chart>
            </div>

            <div class="section mt10">
                <chart 
                :data="[insightData.logs.month]"
                dataset="count"
                type="bar"
                :label="(data) => {return data.data}"
                :singleDataset="false"
                :showLegend="false"
                :xLabelFormat="(data) => {return data}"
                :yLabelFormat="null"
                :tooltip="(value, label, index) => {return `${parseInt(100 * value / insightData.logs.total)}% (${value})`}"
                :customColor="[Util.baseColors.purple, Util.baseColors.pink]">
                </chart>
            </div>

            <div class="section mt10">
                <div class="scroll-container">
                    <div class="entry"
                    v-for="(entry, index) in insightData.logs.locals"
                    :key="index">
                        <div class="flex align-center fill">
                            <div class="percentage-icon cw">{{parseInt(100 *entry.count / insightData.logs.total)}}%</div>
                            <div class="bold">{{entry.data}}</div>
                            <div class="flex-fill"></div>
                            <div>{{Util.Number(entry.count)}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section mt10">
                <div class="scroll-container">
                    <div class="entry"
                    v-for="(entry, index) in insightData.logs.event"
                    :key="index">
                        <div class="flex align-center fill">
                            <div class="percentage-icon cw">{{parseInt(100 *entry.count / insightData.logs.total)}}%</div>
                            <div class="bold">{{entry.data}}</div>
                            <div class="flex-fill"></div>
                            <div>{{Util.Number(entry.count)}}</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div slot="Publicaciones" class="fill contain">
        <iframe src="https://blog.influye.app" class="fill" style="border: none"></iframe>
    </div>

    <vertical-content slot="Actualizaciones" class="pa-body" @OnBottom="LoadBulletin('DEVELOPMENT', developmentPosts[developmentPosts.length-1].created_at)">
        
        <div class="section relative" v-for="post in developmentPosts" :key="post.id" style="margin-bottom: 20px">
            <div class="flex mb5 align-center">
                <div style="width: 30px; height: 30px;" class="mr10">
                    <div class="contain-img" :style="{'border-radius': '50%', 'background-image': img(`accounts/${post.data.account.image}`, true)}"></div>
                </div>
                <div class="secondary-text flex-fill">Publicado por {{post.data.account.name}} el {{Util.Date(post.created_at)}}</div>
                <i v-if="Auth.role == 'admin'" class="options icon-dots-vertical" @click="Options([
                    {text: 'Eliminar Post', class: 'error-color', function: () => {DeletePost(post)}, confirm: '¿Eliminar publicación?'}
                ])"></i>
            </div>
            <div class="flex column">
                <div v-if="post.data.title" class="bold" style="font-size: 16px">{{post.data.title}}</div>
                <div v-if="post.data.description" v-html="post.data.description.replace(/\n/g, '<br>')" class="mt10 mb10" style="line-height: 1.1"></div>
                <img v-if="post.data.image" :src="img(`posts/${post.data.image}`)" style="width: 100%" class="mt5">
                <div class="flex" v-if="post.data.tags">
                    <div class="sale-tag" v-for="(tag, index) in post.data.tags" :key="post.id+'-tag-'+index">{{tag}}</div>
                </div>
            </div>
            <button @click="LikePost(post, 'POST')" class="confetti-btn" :disabled="post.data.likes && post.data.likes.indexOf(Auth.id) > -1" title="¡Celebra con el equipo!"><span v-if="post.data.likes && post.data.likes.length">{{post.data.likes.length}}</span><i class="icon icon-confetti"></i></button>
        </div>

    </vertical-content>

    <modal v-model="modalPost" title="Nuevo Post" dir="up" desktopWidth="500px">
        <div class="section" v-if="modalPost">
            <input-field v-model="modalPost.title"
            label="Título" placeholder="(Opcional)">
            </input-field>
            
            <input-field v-model="modalPost.description"
            label="Descripción" placeholder="(Opcional)" :lines="3">
            </input-field>

            <input-field v-model="modalPost.tags"
            label="Tags" placeholder="(Opcional. Una por línea)" :lines="3">
            </input-field>

            <input-image
            label="Imagen"
            v-model="modalPost.image">
            </input-image>
        </div>
        <div class="modal-actions">
            <button class="selected-color" @click="PublishPost()">Publicar</button>
        </div>
    </modal>

</tabs>
</template>

<script>
import InputSelect from '../common/InputSelect.vue';
const confetti = require('canvas-confetti').default;
export default {
  components: { InputSelect },
    data() {
        return {
            tabs: null,
            tab: null,
            salesData: null,
            operationsPosts: [],
            developmentPosts: [],
            modalPost: null,
            tabCount: {},
            currentMonth: null,
            currentYear: null,
            months: [
                {text: 'Enero', value: 1},
                {text: 'Febrero', value: 2},
                {text: 'Marzo', value: 3},
                {text: 'Abril', value: 4},
                {text: 'Mayo', value: 5},
                {text: 'Junio', value: 6},
                {text: 'Julio', value: 7},
                {text: 'Agosto', value: 8},
                {text: 'Septiembre', value: 9},
                {text: 'Octubre', value: 10},
                {text: 'Noviembre', value: 11},
                {text: 'Diciembre', value: 12},
            ],
            years: [],
            insightData: null
        }
    },
    mounted() {
        this.LoadBulletin('sales');

        this.tabs = ['Ventas'];
        if(this.Auth.influye_admin) this.tabs.push('Insights')

        let moment = require('moment');
        var date = moment();
        this.currentYear = parseInt(date.format('YYYY'));
        this.currentMonth = parseInt(date.subtract(1, 'month').format('M'));

        for(var i = 2021; i <= this.currentYear; i++)
        {
            this.years.push({text: i, value: i});
        }

    },
    methods: {
        LoadBulletin(type, date) 
        {
            var typeToTab = {
                sales: 'Ventas',
                OPERATION: 'Publicaciones',
                DEVELOPMENT: 'Actualizaciones'
            };
            this.$delete(this.tabCount, typeToTab[type]);
            this.Loading();
            axios.get(`/influye/bulletin/${type}${date ? '?date='+date : ''}`).then(res => {
                this.Response(res);
                console.log(res.data);
                if(type == 'sales')
                {
                    if(date)
                        this.salesData.last_sales = this.salesData.last_sales.concat(res.data);
                    else
                        this.salesData = res.data;
                }
                else if(type == 'OPERATION')
                {
                    this.operationsPosts = this.operationsPosts.concat(res.data);
                }
                else if(type == 'DEVELOPMENT')
                {
                    this.developmentPosts = this.developmentPosts.concat(res.data);
                }
                
            }).catch(err => {   
                this.Response(err);
            });
        },
        SaleColor(sale)
        {
            if(sale.source == 'SELLUP') return 'var(--yellow)';
            if(sale.source == 'SUBSCRIPTION') return 'var(--blue-2)';
            if(sale.source == 'DIRECT') return 'var(--green)';
            if(sale.source == 'ASSISTED') return 'var(--green)';
            //if(sale.sale_number_month == 1) return '#f89e25'; //'#f53921';
            if(sale.months == 6) return '#6491d7';
            if(sale.months == 12) return '#7c68c8';
            return '#3cbbd2';
        },
        LikePost(post, type)
        {
            var count = 200;
            var defaults = {
            origin: { y: 0.7 }
            };

            function fire(particleRatio, opts) {
            confetti(Object.assign({}, defaults, opts, {
                particleCount: Math.floor(count * particleRatio)
            }));
            }

            fire(0.25, {
            spread: 26,
            startVelocity: 55,
            });
            fire(0.2, {
            spread: 60,
            });
            fire(0.35, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8
            });
            fire(0.1, {
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2
            });
            fire(0.1, {
            spread: 120,
            startVelocity: 45,
            });

            if(type == 'POST')
            {
                if(!post.data.likes) this.$set(post.data, 'likes', [this.Auth.id]);
                else post.data.likes.push(this.Auth.id);
            }
            else if(type == 'SALE')
            {
                if(!post.custom_data.likes) this.$set(post.custom_data, 'likes', [this.Auth.id]);
                else post.custom_data.likes.push(this.Auth.id);
            }

            axios.post(`/influye/bulletin/post/${post.id}/like`, {type: type}).then(res => {
                console.log(res);
            }).catch(err => {
                console.log(err);
            });
        },
        LoadInsight()
        {
            this.Loading();
            axios.get(`/influye/insights/${this.currentYear}/${this.currentMonth}`).then(res => {
                this.insightData = res.data;
                this.Response(res);
            }).catch(err => {
                this.Response(err);
            });
        },
    },
    computed: {

    }
}
</script>

<style lang="scss">
.sale-entry {
    padding: 0px !important;
}
.sale-entry .plan {
    width: 70px;
    font-size: 20px;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
    color: var(--white);
}
.sale-tag {
    background-color: var(--gray-3);
    font-size: 12px;
    border-radius: 20px;
    padding: 5px 15px;
    margin-right: 5px;
    margin-top: 5px;
}
.top-entry {
    color: var(--yellow);
    font-weight: 500;
}

.confetti-btn {
    position: absolute;
    padding: 5px 15px;
    background-color: var(--purple);
    color: var(--white);
    border: none !important;
    border-radius: 10px;
    bottom: -10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    font-weight: 500;
}
.confetti-btn span {
    margin-right: 10px;
}
.confetti-btn:disabled {
    background-color: var(--white);
    color: var(--black);
    opacity: 1 !important;
    cursor: default;
    
}

.top-profile {
    width: 60px; 
    height: 60px;
    position: relative;
    margin-top: 15px;
}
.top-profile::after {
    content: "N";
    font-family: 'influye-admin-font';
    position: absolute;
    top: -24px;
    right: -5px;
    color: var(--yellow);
    font-size: 30px;
    transform: rotate(27deg);
}

.table-top {
    
    border-collapse: collapse;
    width: 100%;
    
    tr {
        width: 100%;
    }

    td {
        padding: 2px 0px;
        &:nth-child(1) {
            width: 20px;
        }
        &:nth-child(3) {
            text-align: right;
        }
    }   
}


.dark {
    .sale-tag {
        background-color: var(--dark-bg-2);
    }
    .confetti-btn:disabled {
        background-color: var(--dark-bg-3);
        color: var(--dark-fc-2);       
    }
}

</style>
