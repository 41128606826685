if(!process.env.CORDOVA_PLATFORM)
{
    //console.log('apply browser settings browser');

	window.NativeStorage = {
		getItem: function(key, callback, error) {
			if(key in localStorage && localStorage[key] !== undefined && localStorage[key] !== 'undefined')
			{
				var value = JSON.parse(localStorage[key]);
				callback(value);
			}
			else
				if(error) error();
		},
		setItem: function(key, val, callback) {
			localStorage[key] = JSON.stringify(val);
			if(callback) callback(val);
		},
		remove: function(key, callback) {
			localStorage.removeItem(key);
			if(callback) callback();
		},
		keys: function(callback, error)
		{
			callback(Object.keys(localStorage));
		}
	}
}