<template>
<div>
    <button v-if="icon" v-show="!isInputVisible" @click="isInputVisible=true" :class="`icon transparent icon-${icon}`"></button>
    <div v-if="label" class="bold">{{label}}</div>
    <div v-if="description" class="secondary-text">{{description}}</div>
    <div v-show="isInputVisible || !icon" :class="`${label || description ? 'mt10' : ''} input-field ${inputClass} ${pre ? 'pre' : ''} ${after || type == 'password' || cleareable || submit || info || loading ? 'after' : ''} ${mobile && useHeader ? 'use-header' : ''}`" :style="useHeader">

        <div class="input-group flex w100">
            <div class="w100 relative">
                <div class="before" v-if="before">
                    <slot name="inner-before"></slot>
                </div>

                <div class="pre" v-if="pre" style="pointer-events: none">
                    <i :class="`icon icon-${pre}`"></i>
                </div>
                <textarea v-if="lines" v-model="currentValue" @keyup.enter="Enter()" :placeholder="placeholder" ref="inputRef" @focus="InputFocus" @blur="Blur" :rows="lines === true ? 15 : (lines < 0 ? -lines : (currentValue ? lines : 1))"></textarea>
                <currency-input v-else-if="isNumeric" v-model="currentValue" :readonly="readonly" @keyup.enter="Enter()" :placeholder="placeholder" ref="inputRef" @focus="InputFocus" @blur="Blur" locale="de-DE" :precision="isNumeric.precision" :distraction-free="{hideNegligibleDecimalDigits: false,hideCurrencySymbol: true,hideGroupingSymbol: false}" :currency="{suffix: suffix, prefix: prefix}" :allow-negative="isNumeric.negative"></currency-input>
                <input v-else :readonly="readonly" :type="(passwordVisible?'text':type)" @input="(e) => currentValue = e.target.value" v-model="currentValue" @keyup.enter="Enter()" :placeholder="placeholder" ref="inputRef" @focus="InputFocus" @blur="Blur" >
                <div class="after" v-if="after || type == 'password' || cleareable || submit || info || loading">
                    <i v-if="loading" class="icon icon-semi-circle spin"></i>
                    <i @click.stop="currentValue = ''; isInputVisible=false; Focus(icon ? false : true)" v-else-if="cleareable" v-show="currentValue || isInputVisible" class="icon icon-cross selectable" title="Limpiar"></i>
                    <i @click="Enter()" v-else-if="submit" v-show="currentValue" class="icon icon-enter selectable"></i>
                    <i  @click="passwordVisible=!passwordVisible" v-else-if="type == 'password'" :class="`icon icon-${passwordVisible?'invisible':'eye'} selectable`" style="font-size: 17px"></i>
                    <i @click="Info(info)" v-else-if="info" class="icon icon-question selectable" v-tooltip="info"></i>
                    <i v-else :class="`icon icon-${after}`"></i>
                    <slot name="inner-after"></slot>
                </div>
            </div>
           
            <slot name="after"></slot>
        </div>
        
    </div>
</div>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Number],
            default: null
        },
        label: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        placeholder: {
            type: String,
            default: ''
        },
        readonly: {
            type: Boolean,
            default: false
        },
        timeout: {
            type: Number,
            default: 0
        },
        before: {
            type: String,
            default: null
        },
        pre: {
            type: String,
            default: null
        },
        after: {
            type: String,
            default: null
        },
        cleareable: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: null
        },
        iconTooltip: {
            type: String,
            default: null
        },
        inputClass: {
            type: String,
            default: ''
        },
        useHeader: {
            type: [Boolean, Object],
            default: false,
        },
        suffix: {
            type: String,
            default: ''
        },
        prefix: {
            type: String,
            default: ''
        },
        submit: {
            type: Boolean,
            default: false
        },
        info: {
            type: String,
            default: null
        },
        lines: {
            type: [Number, Boolean],
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        autofocus: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            currentValue: null,
            passwordVisible: false,
            timer: null,
            isInputVisible: false,
            isFocused: false,
        }
    },
    mounted () {
        var val = this.value;
        if(this.isNumeric)
        {
            var castVal = parseFloat(val);
            if(!isNaN(castVal)) this.currentValue = castVal;
        }
        else
            this.currentValue = val;

        if(this.autofocus) this.Focus();
        
    },
    methods: {
        Enter()
        {
            var val = this.currentValue;
            if(this.isNumeric)
            {
                var castVal = parseFloat(val);
                if(!isNaN(castVal)) val = castVal;
            }
            this.$emit('OnEnter', val);
        },
        InputFocus() {
            this.isFocused = true;
        },
        Focus(blur)
        {
            var el = this.$el.getElementsByTagName('input');
            if(blur === false)
            {
                el[0].blur();
            }
            else
            {
                el[0].focus();
                el[0].select();
            }
        },
        Blur() 
        {
            setTimeout(() => {
                if(!this.currentValue)
                    this.isInputVisible = false;
            }, 10);
            this.isFocused = false;
            this.$emit('OnBlur');
        }
    },
    computed: {
        isNumeric() {
            if(this.type == 'number') return { precision: {min: 0, max: 7}, negative: true }
            if(this.type == 'number+') return { precision: {min: 0, max: 7}, negative: false }
            if(this.type == 'int') return { precision: 0, negative: true }
            if(this.type == 'int+') return { precision: 0, negative: false }
            if(this.type == 'float') return { precision: {min: 0, max: 3}, negative: true }
            if(this.type == 'float+') return { precision: {min: 0, max: 3}, negative: false }
            return false;
        }
    },
    watch: {
        value: function(n, o) {
            this.currentValue = this.isNumeric ? parseFloat(n) : n;
        },
        currentValue: function(n, o) {
            //if(o === null) return;

            var val = n;
            
            /*
            if(this.isNumeric && n === null)
            {
                this.currentValue = 0;
                this.$emit('input', 0);
                this.$emit('OnChange', 0);
                return;
            }
            */
            
            
            if(this.timeout)
            {
                if(this.timer) clearTimeout(this.timer);
                if(val)
                {
                    this.timer = setTimeout(() => {
                        this.$emit('input', val);
                        this.$emit('OnChange', val);
                        if(this.isFocused) this.$emit('OnChangeFocused', val);
                        this.timer = null;
                    }, this.timeout);
                }
                else
                {
                    this.$emit('input', val);
                    this.$emit('OnChange', val);
                    if(this.isFocused) this.$emit('OnChangeFocused', val); 
                    this.timer = null;
                }

                return;
            }

            this.$emit('input', val);
            this.$emit('OnChange', val);
            if(this.isFocused) this.$emit('OnChangeFocused', val);

        },
        isInputVisible: function(n, o) {
            if(n) 
            {
                this.$nextTick(() => {
                    this.$refs.inputRef.focus();
                });
            }
        }
    },
}
</script>

<style lang="scss">
.input-field {
    
    position: relative;
    border-radius: 10px;
    

    &.pre {
        input, .input {
            padding-left: 30px;
        }
    }

    &.after {
        input, .input {
            padding-right: 30px;
        }
    }

    input, .input, textarea {
        border: none;
        width: 100%;
        height: 40px;
        padding: 10px 15px;
        border: 1px solid var(--gray-2);
        border-radius: inherit;
        transition: all 0.2 linear;
        outline: 3px solid rgba(var(--purple-rgb), 0);
        box-sizing: border-box;

        &:focus {
            border-color: var(--purple);
            outline: 3px solid var(--gray-purple);
        }

    }

    textarea {
        height: auto;
    }

    .input {
        height: 40px;
    }

    .pre {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 10px;
        color: var(--gray);
    }

    .before {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 10px;
        color: var(--gray);
    }

    .after {
        position: absolute;
        top: 0px;
        right: 0px;
        height: 100%;
        display: flex;
        align-items: center;
        padding-right: 10px;
        color: var(--gray);
    }

    &.secondary {

        input, .input, textarea {
            background-color: var(--gray-3);
            border: none;
            /*
            &::placeholder {
                color: var(--gray-1);
            }
            */
        }

    }

    .input {
        min-width: 219px;
    }

}

.auto-width {
    .input {
        min-width: 0px;
    }
}

.sm {
    .input {
        min-width: unset !important;
    }
}

.use-header {
    position: fixed;
    left: 8px;
    right: 8px;
    top: 8px;
    z-index: 1000;
    transform: translate3d(0,0,0);

    input {
        background-color: var(--white) !important;
        outline: none !important;
    }

}


input::placeholder, textarea::placeholder {
    color: var(--gray-2);
}


.dark {

    .cpurple {
        color: var(--dark-purple);
    }

    .input-field {

        input, .input, textarea {
            color: var(--dark-fc-1);
        }
        
        input, .input, textarea {
            background-color: var(--dark-bg-0);
            border: 1px solid var(--dark-outline);
            outline: 3px solid transparent;
            
            &:focus {
                border-color: var(--purple);
                outline: 2px solid var(--purple);
            }

        }

        .pre {
            color: var(--dark-fc-3);
        }

        .after {
            color: var(--dark-fc-3);
        }

        &.secondary {
            input, .input, textarea {
                border: none;
                background-color: var(--dark-bg-0);
            }

        }

    }

    .use-header {
        input {
            background-color: var(--dark-bg-1) !important;
        }

    }


    input::placeholder, textarea::placeholder, .input.placeholder {
        color: var(--dark-fc-3);
    }
}
</style>
