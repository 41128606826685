<template>
<div>
    <div class="flex justify-center">
        <div :style="{width: '100%', height: '200px', 'padding-top': '10px'}">
            <canvas width="100"
            v-if="data"
            ref="chart">
            </canvas>
        </div>
    </div>

    <div v-if="showLegend === 'auto' && data && data.length" class="mt20">
        <div class="entry flex align-center" v-for="(legend, index) in data[0]" :key="index">
            <avatar class="mr20"
            :color="GetColor(index, legend.data)"
            :defaultImageIcon="Util.Keywords[legend.data] ? Util.Keywords[legend.data].icon : defaultLegendIcon"
            :imageIcon="legend.data">
            </avatar>
            <div>{{Util.Keywords[legend.data] ? Util.Keywords[legend.data].label : legend.data}}</div>
        </div>
    </div>    

</div>
</template>

<script>
import {Chart} from 'chart.js'
/** Customize the Rectangle.prototype draw method */
Chart.elements.Rectangle.prototype.draw = function() {
  const ctx = this._chart.ctx;
  const vm = this._view;
  let left, right, top, bottom, signX, signY, borderSkipped;
  let borderWidth = vm.borderWidth;

  // If radius is less than 0 or is large enough to cause drawing errors a max
  //      radius is imposed. If cornerRadius is not defined set it to 0.
  let cornerRadius = this._chart.config.options.cornerRadius;
  let fullCornerRadius = this._chart.config.options.fullCornerRadius;
  let stackedRounded = this._chart.config.options.stackedRounded;
  const typeOfChart = this._chart.config.type;

  if (cornerRadius < 0) {
    cornerRadius = 0;
  }
  if (typeof cornerRadius === 'undefined') {
    cornerRadius = 0;
  }
  if (typeof fullCornerRadius === 'undefined') {
    fullCornerRadius = false;
  }
  if (typeof stackedRounded === 'undefined') {
    stackedRounded = false;
  }

  if (!vm.horizontal) {
    // bar
    left = vm.x - vm.width / 2;
    right = vm.x + vm.width / 2;
    top = vm.y;
    bottom = vm.base;
    signX = 1;
    signY = bottom > top ? 1 : -1;
    borderSkipped = vm.borderSkipped || 'bottom';
  } else {
    // horizontal bar
    left = vm.base;
    right = vm.x;
    top = vm.y - vm.height / 2;
    bottom = vm.y + vm.height / 2;
    signX = right > left ? 1 : -1;
    signY = 1;
    borderSkipped = vm.borderSkipped || 'left';
  }

  // Canvas doesn't allow us to stroke inside the width so we can
  // adjust the sizes to fit if we're setting a stroke on the line
  if (borderWidth) {
    // borderWidth shold be less than bar width and bar height.
    const barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
    borderWidth = borderWidth > barSize ? barSize : borderWidth;
    const halfStroke = borderWidth / 2;
    // Adjust borderWidth when bar top position is near vm.base(zero).
    const borderLeft = left + (borderSkipped !== 'left' ? halfStroke * signX : 0);
    const borderRight = right + (borderSkipped !== 'right' ? -halfStroke * signX : 0);
    const borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0);
    const borderBottom = bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0);
    // not become a vertical line?
    if (borderLeft !== borderRight) {
      top = borderTop;
      bottom = borderBottom;
    }
    // not become a horizontal line?
    if (borderTop !== borderBottom) {
      left = borderLeft;
      right = borderRight;
    }
  }

  ctx.beginPath();
  ctx.fillStyle = vm.backgroundColor;
  ctx.strokeStyle = vm.borderColor;
  ctx.lineWidth = borderWidth;

  // Corner points, from bottom-left to bottom-right clockwise
  // | 1 2 |
  // | 0 3 |
  const corners = [
    [left, bottom],
    [left, top],
    [right, top],
    [right, bottom]
  ];

  // Find first (starting) corner with fallback to 'bottom'
  const borders = ['bottom', 'left', 'top', 'right'];
  let startCorner = borders.indexOf(borderSkipped, 0);
  if (startCorner === -1) {
    startCorner = 0;
  }

  function cornerAt(index) {
    return corners[(startCorner + index) % 4];
  }

  // Draw rectangle from 'startCorner'
  let corner = cornerAt(0);
  ctx.moveTo(corner[0], corner[1]);

  let nextCornerId, width, height, x, y;
  for (let i = 1; i < 4; i++) {
    corner = cornerAt(i);
    nextCornerId = i + 1;
    if (nextCornerId === 4) {
      nextCornerId = 0
    }

    width = corners[2][0] - corners[1][0];
    height = corners[0][1] - corners[1][1];
    x = corners[1][0];
    y = corners[1][1];

    let radius = cornerRadius;
    // Fix radius being too large
    if (radius > Math.abs(height) / 2) {
      radius = Math.floor(Math.abs(height) / 2);
    }
    if (radius > Math.abs(width) / 2) {
      radius = Math.floor(Math.abs(width) / 2);
    }

    let x_tl, x_tr, y_tl, y_tr, x_bl, x_br, y_bl, y_br;
    if (height < 0) {
      // Negative values in a standard bar chart
      x_tl = x;
      x_tr = x + width;
      y_tl = y + height;
      y_tr = y + height;

      x_bl = x;
      x_br = x + width;
      y_bl = y;
      y_br = y;

      // Draw
      ctx.moveTo(x_bl + radius, y_bl);

      ctx.lineTo(x_br - radius, y_br);

      // bottom right
      ctx.quadraticCurveTo(x_br, y_br, x_br, y_br - radius);


      ctx.lineTo(x_tr, y_tr + radius);

      // top right
      fullCornerRadius ? ctx.quadraticCurveTo(x_tr, y_tr, x_tr - radius, y_tr) : ctx.lineTo(x_tr, y_tr, x_tr - radius, y_tr);


      ctx.lineTo(x_tl + radius, y_tl);

      // top left
      fullCornerRadius ? ctx.quadraticCurveTo(x_tl, y_tl, x_tl, y_tl + radius) : ctx.lineTo(x_tl, y_tl, x_tl, y_tl + radius);


      ctx.lineTo(x_bl, y_bl - radius);

      //  bottom left
      ctx.quadraticCurveTo(x_bl, y_bl, x_bl + radius, y_bl);

    } else if (width < 0) {
      // Negative values in a horizontal bar chart
      x_tl = x + width;
      x_tr = x;
      y_tl = y;
      y_tr = y;

      x_bl = x + width;
      x_br = x;
      y_bl = y + height;
      y_br = y + height;

      // Draw
      ctx.moveTo(x_bl + radius, y_bl);

      ctx.lineTo(x_br - radius, y_br);

      //  Bottom right corner
      fullCornerRadius ? ctx.quadraticCurveTo(x_br, y_br, x_br, y_br - radius) : ctx.lineTo(x_br, y_br, x_br, y_br - radius);

      ctx.lineTo(x_tr, y_tr + radius);

      // top right Corner
      fullCornerRadius ? ctx.quadraticCurveTo(x_tr, y_tr, x_tr - radius, y_tr) : ctx.lineTo(x_tr, y_tr, x_tr - radius, y_tr);

      ctx.lineTo(x_tl + radius, y_tl);

      // top left corner
      ctx.quadraticCurveTo(x_tl, y_tl, x_tl, y_tl + radius);

      ctx.lineTo(x_bl, y_bl - radius);

      //  bttom left corner
      ctx.quadraticCurveTo(x_bl, y_bl, x_bl + radius, y_bl);

    } else {

      let lastVisible = 0;
      for (let findLast = 0, findLastTo = this._chart.data.datasets.length; findLast < findLastTo; findLast++) {
        const datasetMeta = this._chart.getDatasetMeta(findLast);
        const pointData = datasetMeta.controller._data[this._index];
        const yVal = typeof pointData === 'object' ? pointData.y : pointData;
        if (!datasetMeta.hidden && yVal > 0) {
          lastVisible = findLast;
        }
      }

      const rounded = stackedRounded || this._datasetIndex === lastVisible;

      if (rounded) {
        // Positive Value
        ctx.moveTo(x + radius, y);

        ctx.lineTo(x + width - radius, y);

        // top right
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius);


        ctx.lineTo(x + width, y + height - radius);

        // bottom right
        if (fullCornerRadius || typeOfChart === 'horizontalBar')
          ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        else
          ctx.lineTo(x + width, y + height, x + width - radius, y + height);


        ctx.lineTo(x + radius, y + height);

        // bottom left
        if (fullCornerRadius)
          ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
        else
          ctx.lineTo(x, y + height, x, y + height - radius);


        ctx.lineTo(x, y + radius);

        // top left
        if (fullCornerRadius || typeOfChart === 'bar')
          ctx.quadraticCurveTo(x, y, x + radius, y);
        else
          ctx.lineTo(x, y, x + radius, y);
      } else {
        ctx.moveTo(x, y);
        ctx.lineTo(x + width, y);
        ctx.lineTo(x + width, y + height);
        ctx.lineTo(x, y + height);
        ctx.lineTo(x, y);
      }
    }

  }

  ctx.fill();
  if (borderWidth) {
    ctx.stroke();
  }
};

export default {
    props: {
        type: {
            type: String,
            default: 'line'
        },
        data: {
            type: Array,
            default: () => null
        },
        width: {
            type: String,
            default: '250px'
        },
        height: {
            type: String,
            default: '200px'
        },
        title: {
            type: String,
            default: ''
        },
        label: {
            type: [String, Function, Array],
            default: 'data'
        },
        dataset: {
            type: [String, Function, Array],
            default: 'count'
        },
        showLegend: {
            type: [Boolean, String],
            default: false
        },
        defaultLegendIcon: {
            type: String,
            default: ''
        },
        yLabelFormat: {
            type: Function,
            default: (value) => {return value}
        },
        xLabelFormat: {
            type: Function,
            default: (value) => {return value}
        },
        singleDataset: {
            type: Boolean,
            default: true
        },
        maxDatasets: {
            type: Number,
            default: null
        },
        tooltip: {
            type: Function,
            default: null
        },
        legends: {
            type: Array,
            default: null
        },
        customColor: {
            type: [String, Array],
            default: null
        },
        keyColors: {
            type: Object,
            default: null
        },
        debug: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
           chart: null,
           colors: {
               8: ['#5908fe', '#c900d1', '#fb00a2', '#ff0079', '#ff3358', '#ff7043', '#ff993f', '#f3bb51'],
               7: ['#5908fe', '#d400c9', '#ff0093', '#ff0067', '#ff5f49', '#ff933e', '#f3bb51'],
               6: ['#5908fe', '#e100be', '#ff0080', '#ff4353', '#ff8a3e', '#f3bb51'],
               5: ['#5908fe', '#f100ad', '#ff0067', '#ff7b40', '#f3bb51'],
               4: ['#5908fe', '#ff0093', '#ff5f49', '#f3bb51'],
               3: ['#5908fe', '#ff0067', '#f3bb51'],
               2: ['#5908fe', '#f3bb51'],
               1: '#5908fe'
           }
           /*
           colors: {
               8: ['#fff35e', '#ffc752', '#fe9252', '#ee5351', '#e52c66', '#582e60', '#0570a7', '#32bcd2'],
               7: ['#fff35e', '#ffc850', '#ee5351', '#e32d64', '#592f61', '#048aaa', '#70abbb'],
               6: ['#fff35e', '#ffc752', '#f5f1d4', '#4b4b64', '#0570a7', '#35bad2'],
               5: ['#fff35e', '#e52c66', '#582d62', '#048aa8', '#35bad2'],
               4: ['#fff35e', '#e52c66', '#582e60', '#34bbd2'],
               3: ['#fff35e', '#4b4b66', '#34bbd2'],
               2: ['#fff35e', '#4b4b66'],
               1: '#fff35e'
           }
           */
        }
    },
    mounted() {
        this.BuildChart();
    },
    methods: {
        BuildChart() {

            if(this.chart) 
            {
                this.chart.destroy();
                this.chart = null;
            }

            if(this.data == null) return;

            this.$nextTick(() => {

                if(typeof this.$refs.chart === 'undefined') return;

                var datasets = [];
                for(var i = 0; i < this.data.length; i++)
                {
                    datasets.push(this.Dataset(this.data[i], i));
                }  

                var scales = {
                    yAxes: [
                        {
                            display: this.yLabelFormat != null,
                            ticks: {
                                min: 0, 
                                maxTicksLimit: 5,
                                callback: (value, index, values) => {
                                    if(!value) return null;
                                    return (this.yLabelFormat ? this.yLabelFormat(value, index, values) : value) + '   ';
                                },
                                fontColor: this.dark ? '#7c7e84' : '#9D9FB7',
                                fontSize: 12,
                                padding: 0,
                                fontFamily: 'Poppins'
                            },
                            gridLines: {
                                drawBorder: false,
                                display: true,
                                color: this.dark ? '#3b3d52' : '#D4D6EA',
                                zeroLineWidth: 0,
                                borderDash: [5, 10]
                            }
                        }
                    ],
                    xAxes: [
                        {
                            display: this.xLabelFormat != null,
                            bounds: 'ticks',
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: this.mobile ? 5 : (datasets[0].labels.length > 7 ? 7 : 100),
                                callback: (value, index, values) => {
                                    return this.xLabelFormat ? this.xLabelFormat(value, index, values) : value;
                                },
                                minRotation: 0,
                                maxRotation: 0,
                                fontColor: this.dark ? '#E7ECF5' : '#232323',
                                fontSize: 10,
                                fontFamily: 'Poppins'
                            },
                            gridLines: {
                                drawBorder: false,
                                display: false
                            }
                        }
                    ]
                }

                var tooltips = {
                    enabled: this.tooltip !== null,
                    titleFontFamily: 'Poppins',
                    bodyFontFamily: 'Poppins',
                    footerFontFamily: 'Poppins',
                    titleFontSize: 16,
                    bodyFontSize: 14,
                    titleMarginBottom: 8,
                    xPadding: 15,
                    yPadding: 15,
                    caretSize: 0,
                    cornerRadius: 10,
                    displayColors: false,
                    caretPadding: 20,
                    callbacks: {
                        label: (tooltip, data) => {
                            var index = tooltip.index;
                            var currentData = data.datasets[tooltip.datasetIndex].data[index];
                            var currentLabel = data.labels[index];
                            var legend = this.legends && tooltip.datasetIndex < this.legends.length ? this.legends[tooltip.datasetIndex] : '';
                            return this.tooltip ? this.tooltip(currentData, currentLabel, index, tooltip.datasetIndex, legend) : currentLabel
                        }
                    }
                }

                var ctx = this.$refs.chart.getContext('2d');
                this.chart = new Chart(ctx, {
                    type: this.type,
                    data: {
                        labels: datasets[0].labels,
                        datasets: datasets
                    },
                    options: {
                        cornerRadius: 10,
                        stackedRounded: true,
                        maintainAspectRatio: false,
                        legend: { 
                            
                            display: this.showLegend === true,
                            position: 'bottom',
                            align: 'start',
                            fullWidth: true,
                            labels: {
                                
                                boxWidth: 15,
                                padding: 5,
                                fontColor: this.dark ? '#E7ECF5' : '#232323',
                                fontSize: 14,
                                defaultFontFamily: 'Poppins'
                                
                            }
                        },
                        animation: {
                            duration: 1000,
                        },
                        title: {
                            display: this.title ? true : false,
                            text: this.title
                        },
                        scales: scales,
                        tooltips: tooltips,
                    },
                })

            });

        },
        Dataset(n, number)
        {

            var labels = [];
            var data = [];
            for(var i = 0; i < n.length; i++)
            {
                if(typeof this.label == 'function')
                    labels.push(this.label(n[i]));
                else if(typeof this.label == 'object')
                    labels.push(this.label[i]);
                else
                    labels.push(n[i][this.label]);
                
                if(Array.isArray(this.dataset))
                    data.push(n[i][this.dataset[number]]);
                else if(typeof this.dataset == 'function')
                    data.push(this.dataset(n[i], i));
                else if(this.dataset === null)
                    data.push(n[i]);
                else
                    data.push(n[i][this.dataset]);
                 
            }

            if(this.maxDatasets && data.length > this.maxDatasets)
            {
                var trimmed = data.splice(this.maxDatasets);
                var trimmedTotal = 0;
                for(var i = 0; i < trimmed.length; i++)
                    trimmedTotal+= trimmed[i];
                data.push(trimmedTotal);

                labels.splice(this.maxDatasets);
                if(data.length > this.maxDatasets)
                    labels.push('Otros');

            }
            
            var border = null;
            var background = null;
            if(this.customColor)
            {
                if(this.debug)
                {
                    console.log('CHART DEBUG');
                    console.log(n);
                    console.log(number);
                    console.log(this.type);
                    console.log(this.data);
                    console.log(this.customColor);
                }
                
                
                
                if(this.data.length == 1 && Array.isArray(this.customColor) && this.customColor.length > 1)
                {
                    var tmp = [];
                    for(var i = 0; i < n.length; i++)
                    {
                        var color = i >= this.customColor.length ? this.customColor[this.customColor.length-1] : this.customColor[i];
                        if(this.keyColors && this.keyColors[n[i].data])
                            tmp.push(this.keyColors[n[i].data]);
                        else
                            tmp.push(color);
                    }

                    border = tmp;
                    background = tmp;
                }
                else
                {
                    //89, 8, 254;
                    var index = number;
                    if(index >= this.customColor.length) index = this.customColor.length-1;
                    var customColor = this.customColor[index];
                    border = typeof customColor == 'string' ? customColor : customColor.border;
                    var bg = this.$refs.chart.getContext('2d').createLinearGradient(0, 0, 0, 190);
                    bg.addColorStop(0, typeof customColor == 'string' ? customColor : (customColor.top ? customColor.top : customColor.bg));
                    bg.addColorStop(0.8, typeof customColor == 'string' ? customColor : (customColor.bottom ? customColor.bottom : customColor.bg));
                    background = bg;
                }
                

                if(this.type == 'bar' && this.data.length == 1 && Array.isArray(this.customColor) && this.customColor.length == 2)
                {
                    const interpolate = require('color-interpolate');
 
                    let colormap = interpolate([this.customColor[0], this.customColor[1]]);
                    let black = colormap(0); // 'rgb(0, 0, 0)'
                    let white = colormap(1); // 'rgb(255, 255, 255)'
                    let gray = colormap(.5); // 'rgb(128, 128, 128)'

                    var maxVal = 0;
                    for(var i = 0; i < n.length; i++)
                        if(n[i][this.dataset] > maxVal) maxVal = n[i][this.dataset];
                    
                    var tmp = [];
                    for(var i = 0; i < n.length; i++)
                    {
                        const color = colormap(n[i][this.dataset] / maxVal);
                        tmp.push(color);
                    }

                    border = tmp;
                    background = tmp;
                    
                }

            }
            else
            {
                var colors = this.colors[this.singleDataset ? 1 : Math.min(this.data.length == 1 ? this.data[0].length : this.data.length, this.maxDatasets ? this.maxDatasets+1 : 99999999)];
                if(!this.singleDataset && this.data.length > 1)
                {
                    colors = this.colors[this.data.length][number];
                }
                var bgColors = colors;
                if(this.type == 'line')
                {
                    if(typeof bgColors == 'string')
                    {
                        var rgb = this.Util.Hex2Rgb(bgColors);
                        bgColors = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.3)`;
                    }
                    else
                    {
                        var newBg = [];
                        for(var i = 0; i < bgColors.length; i++)
                        {
                            var rgb = this.Util.Hex2Rgb(bgColors[i]);
                            newBg.push(`rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.3)`);
                        }
                        bgColors = newBg;
                    }
                }
                border = colors;
                background = bgColors;
            }

            return {
                label: this.legends ? this.legends[number] : this.title,
                data: data,
                borderColor: this.type == 'line' ? border : (this.dark ? '#26272e' : 'white'),
                backgroundColor: background,
                labels: labels,
                pointHoverBackgroundColor: 'white',
                pointHoverBorderWidth: 2.5,
                pointRadius: 0,
                hitRadius: 10,
                hoverRadius: 10,
                lineTension: 0.4,
            }
        },
        GetColor(index, data)
        {
            if(data && this.keyColors && this.keyColors[data]) return this.keyColors[data];
            var colors = this.colors[this.data[0].length];
            if(!colors) return null;
            if(typeof colors == 'string') return colors;
            return colors[index];
        }
    },
    watch: {
        data: function(n, o){
            if(this.debug)
            {
                console.log('CHART DATA CHANGED');
                console.log(n);
                console.log(o);
            }
            //if(JSON.stringify(n) == JSON.stringify(o)) return;
            this.BuildChart();
        }
    },
    beforeDestroy() {
        if(this.chart) this.chart.destroy();
    }
}
</script>

<style>

</style>
