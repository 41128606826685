<template>
<div class="input-icon flex ">
    <div class="flex">
        <popover-menu ref="popover" @OnOpen="FocusInput">
            <div slot="target" class="btn-icon relative">
                <button class="absolute trbl0 bgt fill" style="border-radius: inherit"></button>
                <img v-show="currentValue" :src="img(`/icons/${currentValue}.png`)">
                <div v-if="!currentValue" class="secondary-text ta-center lh1">Seleccionar ícono</div>
                <!--<icon :value="currentValue" :id="id" :icon="icon"></icon>-->
            </div>
            
            <div class="">

                <input-field placeholder="Buscar..." inputClass="secondary" class="mb10"
                v-model="search"
                pre="search"
                cleareable
                :timeout="500"
                :loading="loading"
                ref="inputSearch"
                @OnChange="Search($event)"></input-field>
                
                <div class="relative">
                    <div class="flex icons-container">
                        <button v-for="icon in icons" :key="icon" @click="SelectIcon(icon)">
                            <img :src="img(`/icons/${icon}.png`)">
                        </button>
                        <!--
                        <div v-show="loading" class="bgw fill absolute trbl0 flex justify-center align-center">
                            <i class="icon icon-semi-circle spin"></i>
                        </div>
                        -->
                        <div v-show="!loading && !icons.length" class="pa20 flex column w100">
                            <div class="secondary-text ta-center">No se encontraron íconos</div>
                        </div>
                    </div>
                </div>

            </div>
        </popover-menu>
    </div>
    <div class="flex column ml10" v-if="label || description">
        <div v-if="label" class="bold">{{label}}</div>
        <div v-if="description" class="secondary-text mt8">{{description}}</div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        description: {
            type: String,
            default: null
        },
        id: {
            type: String,
            default: null
        },
        icon: {
            type: [String, Number],
            default: 11,
        }
    },
    data() {
        return {
            currentValue: null,
            loading: false,
            icons: [],
            search: ''
        }
    },
    mounted() {
        this.currentValue = this.value;
        //this.Search();
    },
    methods: {
        Search(val) {
            this.loading = true;
            axios.post(`/influye/icons`, {search: val}).then(res => {
                this.icons = res.data;
                this.loading = false;
            }).catch(err => {
                console.log(err);
                this.loading = false;
            }); 
        },
        SelectIcon(icon)
        {
            this.currentValue = icon;
            this.$emit('input', icon);
            this.$refs.popover.Close();
        },
        FocusInput()
        {
            if(this.desktop) 
            {
                setTimeout(() => {
                    this.$refs.inputSearch.Focus();
                }, 50);
            }
        }
    }
}
</script>

<style lang="scss">

.icons-container {
    width: 308px;
    //height: 200px;
    flex-wrap: wrap;
    overflow-y: auto;
    align-content: baseline;

    button {
        background-color: transparent !important;
        display: flex !important;
        justify-content: center !important;
        align-content: center !important;
        width: 40px !important;
        height: 40px !important;
        padding: 0px !important;
        margin: 4px !important;
        max-height: 40px !important;

        &:hover {
            img {
                transform: scale(1.2);
            }
        }

        img {
            width: 85%;
            height: 85%;
            transition: all 0.1s linear;
        }

    }

}

.btn-icon {
    width: 80px;
    height: 80px;
    background-color: var(--gray-3);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: var(--gray-2-5);
    }

    img {
        width: 80%;
        height: 80%;
    }

}

//Mobile
@media (max-width: 820px) {
    .icons-container {
        width: 100%;
    }
}

.dark {
    .btn-icon {
        background-color: var(--dark-bg-0);
        color: var(--dark-fc-2);
    }
}


</style>