<template>
<div class="pa-body">

    <popover-menu class="opt r" icon="dots-vertical" maxWidth="340px">
        <div>
            <input-toggle v-model="deliveryAttentionSoundEnabled"
            @OnValueChanged="Util.StoreLocal('deliveryAttentionSoundEnabled', $event, true)"
            label="Sonido de Alerta"
            description="Sonido que reproduce cuando se ingresa un pedido">
            </input-toggle>
        </div>
    </popover-menu>

    <div class="secondary-text ta-center mt10" v-if="!visibleTransactions.length">No hay pedidos</div>
    <div class="" v-else>
        <div class="section mb10 selectable"
        v-for="transaction in visibleTransactions"
        :key="transaction.id"
        @click="position=null;modalDetails=transaction;SearchAddress()">
            <div class="fill flex align-center">
                <div class="flex-fill flex column">
                    <div class="bold">#{{Util.Zeropad(transaction.id_ticket, 10)}}</div>
                    <div class="secondary-text"><span class="bold">Fecha: </span>{{Util.Date(transaction.delivery_at, 'HH:mm')}}</div>
                    <div class="secondary-text" v-if="transaction.custom_data.delivery_time"><span class="bold">Entrega: </span>{{transaction.custom_data.delivery_time}} hrs</div>
                    <div class="secondary-text" v-if="transaction.customer && transaction.customer.name"><span class="bold">Nombre: </span>{{transaction.customer.name}}</div>
                    <div class="secondary-text" v-if="transaction.customer && transaction.customer.address"><span class="bold">Dirección: </span>{{transaction.customer.address}}</div>
                </div>
                <!--<i class="icon icon-arrow-right-o c4"></i>-->
            </div>
        </div>
    </div>
  
    <!--
    <div style="width: 50vw" v-if="false && desktop">
        <map-component></map-component>
    </div>
    -->

    <modal v-model="modalDetails" :dir="['right', 'right']" :modalClass="['fill', 'md right h100']" :title="modalDetails?`#${Util.Zeropad(modalDetails.id_ticket, 10)}`:''">
        <i class="options icon-dots-vertical" slot="options" @click="Options([
            {text: 'Ver Detalles', function: () => {$refs.transactionDetails.Open(modalDetails)}},
            {text: 'Marcar como Entregada', function: () => {MarkAsDelivered()}, if: !modalDetails.custom_data.delivered_at}
            //{text: 'Editar Método de Pago', function: () => {$refs.transactionDetails.OpenEditPayment(modalDetails, OnPaymentEdit)}, if: !!(Auth.role.indexOf('admin') > -1 || Auth.restrictions.edit_transactions_payment)}
        ])"></i>
        <div v-if="modalDetails" class="pa20">
            <div class="">
                <!--<div class="f18 mb20">General</div>-->
                <div v-if="modalDetails.custom_data.delivery_time" class="mb15">
                    <div class="bold">Entrega programada</div>
                    <div>{{modalDetails.custom_data.delivery_time}} hrs</div>
                </div>
                <div v-if="modalDetails.customer && modalDetails.customer.name" class="mb15">
                    <div class="bold">Nombre</div>
                    <div>{{modalDetails.customer.name}}</div>
                </div>
                <div v-if="modalDetails.customer.address" class="flex mb15">
                    <div class="flex-fill">
                        <div class="bold">Dirección</div>
                        <div>{{modalDetails.customer.address}}</div>
                    </div>
                    <div class="options icon-dots-vertical" @click="Options([
                        {text: 'Buscar en Waze', function: () => {Util.OpenWaze(modalDetails.customer.address)}, if: !!mobile},
                        {text: 'Buscar en Google Maps', function: () => {Util.OpenGoogleMaps(modalDetails.customer.address)}},
                        {text: 'Abrir Ubicación en Google Maps', function: () => {Util.OpenGoogleMaps(modalDetails.custom_data)}, if: !!modalDetails.custom_data.longitude},
                    ])"></div>
                </div>
                <div v-if="modalDetails.customer.phone" class="flex mb15">
                    <div class="flex-fill">
                        <div class="bold">Contacto</div>
                        <div>{{modalDetails.customer.phone}}</div>
                    </div>
                    <div class="options icon-dots-vertical" @click="Options([
                        {text: 'Llamar', function: () => {Util.Open(`tel:${modalDetails.customer.phone}`)}},
                        {text: 'Enviar Datos de Transferencia (Whatsapp)', function: () => {SendDepositData(modalDetails.customer.phone)}, if: canSendDepositData},
                        {text: 'Abrir en Whastapp', function: () => {Util.OpenWhatsapp(modalDetails.customer.phone, `Hola, soy tu repartidor de ${Local.name}`)}},
                    ])"></div>
                </div>

                
                
                <div class="f18 flex">
                    <div class="bold flex-fill">Total</div>
                    <div class="bold">{{Util.Price(modalDetails.total_money)}}</div>
                </div>
                <div class="flex" v-for="(tip, indexTip) in modalDetails.custom_data.tips" :key="'tip'+indexTip">
                    <div class="flex-fill">Propina ({{Util.PayMethod(tip.method)}})</div>
                    <div>{{Util.Price(tip.total)}}</div>
                </div>
                <div class="f18 flex mt15" v-if="modalDetails.custom_data.tips">
                    <div class="bold flex-fill">Total con propinas</div>
                    <div class="bold">{{Util.Price(modalDetails.total_money + Util.Sum(modalDetails.custom_data.tips, 'total'))}}</div>
                </div>

                <div class=""
                v-for="(method, index) in modalDetails.payment" :key="index">
                    <div class="flex fill">
                        <div class="flex column flex-fill">
                            <div class="flex-fill mr10">{{Util.PayMethod(method.method)}} ({{Util.Price(method.subtotal)}})</div>
                            <div v-if="Util.MethodChange(modalDetails, method, index)" class="secondary-text"><span class="bold">Se paga con: </span>{{Util.Price(method.cash)}}</div>		
                        </div>
                        <div class="flex column" style="text-align: right">
                            <div class="bold">{{Util.Price(Util.MethodTip(modalDetails, index) + method.subtotal)}}</div>
                            <div v-if="Util.MethodChange(modalDetails, method, index)" class="secondary-text"><span class="bold">Vuelto: </span> {{Util.Price(Util.MethodChange(modalDetails, method, index))}}</div>
                        </div>
                    </div>
                </div>   

                
            </div>
            <div class="mt20" v-if="modalDetails.location!==null">
                <!--<div class="f18 mb20">Ubicación</div>-->
                <div class="separator"></div>
                <div v-if="modalDetails.location==='loading'" class="secondary-text ta-center mt10">Buscando ubicación</div>
                <div v-if="modalDetails.location===false" class="secondary-text ta-center mt10">No se encontró la ubicación</div>
                <div style="width: 100%; height: 300px" v-if="modalDetails.location && modalDetails.location!=='loading'">
                    <div class="secondary-text ta-center mt10">{{modalDetails.location.long_address}}</div>
                    <map-component
                    v-model="modalDetails.location" :useMarker="true"
                    :path="modalDetails.path">
                    </map-component>
                </div>
            </div>
        </div>
        
        <button slot="actions" class="primary error-color" v-if="Auth.role.indexOf('admin') > -1 || Auth.restrictions.cancel_transactions" @click="$refs.transactionDetails.OpenCancel(modalDetails)">Cancelar Pedido</button>
        <button slot="actions" class="primary purple" v-if="canComplete" @click="$refs.transactionDetails.OpenComplete(modalDetails, true)">Entregar Pedido</button>
        <button slot="actions" class="primary purple" v-if="!canComplete && modalDetails && !modalDetails.custom_data.delivered_at" @click="MarkAsDelivered()">Marcar como Entregado</button>
        
    </modal>
    <transaction-details ref="transactionDetails"
    @OnTransactionCompleted="OnTransactionCompleted"></transaction-details>

</div>
</template>

<script>
export default {
    data() {
        return {
            transactions: [],
            modalDetails: null,
            position: null,
            modalCancel: null,
            deliveryAttentionSoundEnabled: false,
            audioAttention: null,
            initialLoad: false,
        }
    },
    mounted() {
        if(localStorage.deliveryAttentionSoundEnabled) this.deliveryAttentionSoundEnabled = true;
        this.audioAttention = new Audio(require('../../assets/attention.mp4'));
        this.Update();
    },
    methods: {
        Update(hideLoading) {
            if(!hideLoading) this.Loading();
            axios.get('/delivery/transactions').then(res => {
                this.transactions = res.data;
                if(!hideLoading) this.Response(res);
            }).catch(err => {
                if(!hideLoading) this.Response(err);
            });
        },
        SearchAddress() {
            this.$set(this.modalDetails, 'location', 'loading');
            if(this.modalDetails.customer.address) {
                //Geocode
                axios.get(`/delivery/transaction/${this.modalDetails.id}/location`).then(res => {
                    console.log(res.data);
                    this.$set(this.modalDetails, 'location', res.data);
                    if(res.data.path)
                    {
                        res.data.polyline = res.data.directions.geometry;
                        this.$set(this.modalDetails, 'path', res.data);
                    }
                }).catch(err => {
                    this.$set(this.modalDetails, 'location', false);
                    console.log(err);
                });
            }
            else
            {
                this.$set(this.modalDetails, 'location', null);
            }
        },
        OnTransactionCompleted(transaction)
        {
            this.Info('Se ha completado la transacción');
            this.Update(true);
            this.modalDetails = null;
        },
        CancelTransaction()
        {
            var data = {
                reason: this.modalCancel.reason,
                reduce_stock: this.modalCancel.reduce_inventory
            };

            if(this.modalCancel.ticket.payment[0].method == 'ONLINE')
            {
                data.refund = this.modalCancel.refund;
            }

            this.Loading();
            axios.post(`/transaction/${this.modalCancel.id}/cancel`, data).then(res => {
                this.Info(`Se ha cancelado la transacción`);
                this.modalCancel = null;
                this.modalDetails = null;
                this.Update(true);
            }).catch(err => {
                this.Response(err);
            });
        },

        SendDepositData(phone)
        {
            this.Loading();
			axios.get(`/local/${this.Local.id}/bank`).then(res => {
				this.Response(res);
				this.Util.OpenWhatsapp(phone, `${res.data.name}\n${res.data.rut}\n${res.data.bank}\n${res.data.account}\n${res.data.account_number}\n${res.data.email}`);
				console.log(res.data);
			}).catch(err => {
				this.Response(err);
			})
        },

        OnPaymentEdit(data)
        {
            this.Info('Se ha editado la transacción');
            this.modalDetails = data;
        },

        MarkAsDelivered()
        {
            this.Confirm({
                text: 'Entregado',
                title: '¿Marcar el pedido como entregado?',
                function: () => {
                    this.Loading();
                    axios.post(`/transaction/${this.modalDetails.id}/delivered`).then(res => {
                        this.Info(`Pedido marcado como entregado`);
                        this.modalDetails = null;
                    }).catch(err => {
                        this.Response(err);
                    });
                }
            });
        }
    },
    computed: {
        canComplete() 
        {
            if(!this.modalDetails) return false;
            if(this.Auth.role.indexOf('admin') > -1) return true;
            if(!this.Auth.restrictions.complete_transactions) return false;
            return this.modalDetails.paid_at || this.Auth.restrictions.complete_transactions_unpaid;
        },
        canSendDepositData()
        {
            if(!this.modalDetails) return false;
			for(var i = 0; i < this.modalDetails.payment.length; i++)
				if(this.modalDetails.payment[i].method.toUpperCase().indexOf('TRANSFERENCIA') > -1) return true;
			return false;
        },
        visibleTransactions()
        {
            var ret = [];
            this.transactions.forEach(transaction => {
                if(!transaction.custom_data.delivered_at || this.canComplete) ret.push(transaction);
            });
            return ret;
        }
    },
    watch: {
        visibleTransactions: function(n, o)
        {
            if(!this.initialLoad) 
            {
                this.initialLoad = true;
                return;
            }

            if(n && o && n.length > o.length)
            {
                if(this.deliveryAttentionSoundEnabled) this.audioAttention.play();
            }
        }
    },
    sockets: {
        TRANSACTION_CANCELLED(data) {
            this.Update(true);
        },
        TRANSACTION_DELIVERY(data) {
            this.Update(true);
        },
        TRANSACTION_COMPLETED(data) {
            this.Update(true);
        },
        TRANSACTION_EDIT(data) {
            this.Update(true);
        },
    }
}
</script>

<style>

</style>
