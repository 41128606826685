<template>
<div class="input-image">

    <div class="flex column">
        
        <div class="bold mb10" v-if="label">{{label}}</div>
        <div class="image relative" ref="croppie" @click="Options([
            {text: 'Borrar imagen', function: DeleteImage, if: !!(currentValue && currentValue !== 'DELETE'), class: 'error-color'},
            {text: 'Pegar del portapapeles', function: PasteImage},
            {text: 'Sacar foto con cámara', function: StartCam},
            {text: 'Seleccionar archivo', function: () => {$refs.inputFile.Open()}},
        ])"
        :style="{width: (width + 'px'), height: (height + 'px'), 'background-image': currentImage, 'background-size': 'contain'}">
            <button class="absolute fill trbl0 bgt"></button>
        </div>
        
        <button-file v-show="false" ref="inputFile" @FileSelected="OnFileSelected($event)"></button-file>
        
    </div>
    
    <!--
    <modal v-model="modal" dir="center" title="Seleccionar imagen" @OnClose="CancelImage()">
            <div v-if="crop && edit" ref="croppie" :style="{width: '100%', height: edit?'248px':'0px'}"></div>
            <div v-if="!crop && edit" class="image-inner" :style="{width: '100%', height: '100%', 'background-image': `url(${edit})`, 'background-size': fillMode}"></div>
            <div v-if="!edit" class="image-inner" :style="{width: '100%', height: '100%', 'background-image': currentImage, 'background-size': fillMode}"></div>
    </modal>
    -->

    <div v-if="cameraEnabled" ref="containerCamera" class="absolute full trbl0" style="z-index: 9999; background-color: var(--black)">
        <video ref="video" class="cam"></video>
        <div class="cam-btn" style="right: 10px; top: 10px" @click="StopCam"><i class="icon icon-cross"></i></div>
        <div class="cam-btn" style="right: calc(50% - 35px); bottom: 10px; width: 70px; height: 70px" @click="TakePicture"><i class="icon icon-camera"></i></div>
        <canvas ref="canvas" hidden></canvas>
    </div>

</div>
</template>

<script>
import Webcam from 'webcam-easy';
var Croppie = require('croppie');
export default {
    props: {
        value: {
            type: String,
            default: null
        },
        height: {
            type: [String, Number],
            default: 100
        },
        width: {
            type: [String, Number],
            default: 100
        },
        isEdit: {
            type: Boolean,
            default: true
        },
        crop: {
            type: [Boolean, Object],
            default: false
        },
        path: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            currentValue: null,
            croppie: null,
            cameraEnabled: false,
            webcam: null,
        }
    },
    mounted() {
        this.currentValue = this.value;
    },
    methods: {
        OnFileSelected(image) {
            if(this.crop)
            {
                var cropSettings = {
                    showZoomer: false, 
                    viewport: {
                        type: 'square',
                    }
                };
                var outputSettings = {
                    type: 'base64',
                    size: {
                        width: 500,
                        height: 500
                    }
                }

                if(this.croppie) this.croppie.destroy();
                
                this.croppie = new Croppie(this.$refs.croppie, cropSettings);
                this.$refs.croppie.addEventListener('update', (ev) => {
                    this.croppie.result(outputSettings).then((res) => {
                        this.currentValue = res;
                    });
                });
                this.croppie.bind({url: image});

            }
            else
            {
                this.currentValue = image;
            }
        },
        CancelImage() {
            this.edit = null;
            if(this.croppie) this.croppie.destroy();
            this.croppie = null;
        },
        DeleteImage() {
            this.currentValue = 'DELETE';
            this.CancelImage();
            this.modal = false;
        },
        SelectImage() {
            this.$emit('input', this.edit);
            this.$emit('ImageSelected', this.edit);
            this.modal = false;
        },
        async PasteImage() {
            if(!navigator.clipboard) return console.log('Clipboard API unavailable');
            const clipboardItems = await navigator.clipboard.read();
            for (const clipboardItem of clipboardItems) {
                for (const type of clipboardItem.types) {
                    if (type === 'image/png') {
                        var blob = await clipboardItem.getType(type);
                        var reader = new FileReader();
                        reader.onload = (e) => {
                            this.currentValue = e.target.result;
                        }
                        reader.readAsDataURL(blob);
                    }
                }
            }
        },
        StartCam() 
        {
            this.cameraEnabled = true;
            
            setTimeout(() => {
                document.body.appendChild(this.$refs.containerCamera);    
                this.webcam = new Webcam(this.$refs.video, 'environment', this.$refs.canvas);
                this.webcam.start().then(result =>{
                    console.log("webcam started");
                    this.cameraEnabled = true;
                }).catch(err => {
                    console.log(err);
                    this.cameraEnabled = false;
                    this.webcam = null;
                });
            }, 1);
        },
        StopCam()
        {
            if(!this.webcam) return;  
            this.webcam.stop();
            this.webcam = null;
            this.cameraEnabled = false;
        },
        TakePicture() 
        {
            if(!this.webcam) return;
            this.currentValue = this.webcam.snap();
            this.StopCam();
        },

    },
    computed: {
        currentImage() {
            if(!this.currentValue || this.currentValue === 'DELETE') return null;
            if(this.currentValue.substr(0, 4) == 'data') return `url("${this.currentValue}")`;
            return this.img(this.path+this.currentValue, true);
        }
    },
    watch: {
        value: function(n, o) {
            this.currentValue = n;
        },
        currentValue: function(n, o) {
            this.$emit('input', n);
            this.$emit('ImageSelected', n);
        },
        modal: function(n, o) {
            if(!n)
            {
                this.edit = null;
                if(this.croppie) {
                    this.croppie.destroy();
                    this.croppie = null;
                }
            } 
        }
    },
    beforeDestroy() {
        if(this.croppie) this.croppie.destroy();
    }
}
</script>

<style lang="scss">
.input-image {
    
    .image {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-color: var(--gray-3);
        border-radius: 8px;

        &:hover {
            background-color: var(--gray-2-5);
        }

    }

}
.no-image {
    background-color: whitesmoke;
}
.cam {
    position: absolute;
    top: 50%; 
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%) !important;
    transform: translateX(-50%) translateY(-50%) !important;
    min-width: 100%; 
    min-height: 100%; 
    width: auto; 
    height: auto; 
    overflow: hidden;
    max-width: 100%;
}
.cam-btn {
    background-color: var(--white);
    position: absolute;
    border-radius: 50%;
    font-size: 30px;
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}
.carousel-controls {
    bottom: 0px; 
    background-color: white;
    opacity: 0.5;
    border-radius: 0px 0px 10px 10px;
}

.dark {
    .input-image {
    
        .image {
            background-color: var(--dark-bg-2);
            &:hover {
                background-color: var(--dark-bg-0);
            }
        }

    }
    .cam-btn {
        background-color: var(--dark-bg-1);
        color: var(--dark-fc-1);
    }
    .carousel-controls {
        color: var(--dark-fc);
        background-color: var(--dark-bg-1);
        opacity: 0.5;
    }
}

</style>
