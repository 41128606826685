<template>
<div class="fill flex column">
    
    <div class="flex justify-center flex-fill pa20" style="max-height: calc(100% - 80px)">

        <transition :enter-active-class="`animated ${forward ? 'fadeInRight' : 'fadeInLeft'} superfast`" :leave-active-class="`animated ${forward ? 'fadeOutLeft' : 'fadeOutRight'} superfast`" mode="out-in">
        
        <div key="step1" v-if="step == 0" class="section h100 flex column justify-center pa20 w100" style="max-width: 500px">   
            <img :src="require(`../../assets/${dark ? 'logo-login' : 'logo-color'}.png`)" class="mb20" style="transform: scale(0.7)">
            <div class="bold ta-center mb20">¡Bienvenid@ a Influye!</div>
            <div class="ta-center">Empecemos a configurar tu negocio</div>
        </div>

        <div key="step2" v-if="step == 1" class="section h100 flex column justify-center pa20 w100 step-container">
            
            <div class="bold ta-center mb20">¿Dónde se encuentra tu negocio?</div>

            <map-component style="border-radius: 10px" class="mb20"
            v-model="location" canEditMarker
            useMarker canSearch geocode :initialZoom="4"
            @OnMarkerChanged="LocationChanged($event)">
            </map-component>

            <input-field
            v-model="address"
            label="Dirección"
            description="Esta dirección se mostrará en los vouchers y página de comercio">
            </input-field>

        </div>


        <div key="step3" v-if="step == 2" class="section h100 flex column pa20 w100 step-container">
            
            <div class="bold ta-center mb20">¿Cuales métodos de pago se aceptan?</div>

            <avatar class="mb10"
            defaultImageIcon="706"
            title="Efectivo">
                <input-toggle v-model="methods.cash" slot="right"></input-toggle>
            </avatar>

            <avatar class="mb10" v-for="method in options.methods" :key="method"
            :imageIcon="method"
            defaultImageIcon="59"
            :title="method">
                <input-toggle v-model="methods[method]" slot="right"></input-toggle>
            </avatar>

            <div class="flex-fill"></div>

            <div class="secondary-text ta-center">Puedes configurar los datos de Transferencia Bancaria y Pagos Online más tarde</div>

        </div>

        <div key="step4" v-if="step == 3" class="section h100 flex column pa20 w100 step-container">
            
            <div class="bold ta-center mb20">¿Qué se vende en tu negocio?</div>

            <avatar class="mb10" v-for="section in options.menu" :key="section"
            :imageIcon="section"
            defaultImageIcon="3910"
            :title="section">
                <input-toggle v-model="menu[section]" slot="right"></input-toggle>
            </avatar>

            <div class="flex-fill"></div>

            <div class="secondary-text ta-center"></div>

        </div>

        <div key="step5" v-if="step == 4" class="section h100 flex column pa20 w100 step-container">
            
            <div class="bold ta-center mb20">¿Cómo opera tu negocio?</div>

            <avatar class="mb10"
            defaultImageIcon="4773"
            title="Tengo una persona en caja"
            :description="accounts.cashier ? 'Se creará la cuenta <b>Caja</b> con contraseña <b>123456</b>' : ''">
                <input-toggle v-model="accounts.cashier" slot="right"></input-toggle>
            </avatar>

            <avatar class="mb10"
            defaultImageIcon="666"
            title="Tengo un repartidor"
            :description="accounts.delivery ? 'Se creará la cuenta <b>Repartidor</b> con contraseña <b>123456</b>' : ''">
                <input-toggle v-model="accounts.delivery" slot="right"></input-toggle>
            </avatar>

            <avatar class="mb10"
            defaultImageIcon="3152"
            title="Tengo mesas con garzones"
            :description="accounts.waiter ? 'Se creará la cuenta <b>Mesero</b> con contraseña <b>123456</b>' : ''">
                <input-toggle v-model="accounts.waiter" slot="right"></input-toggle>
            </avatar>

            <div class="flex-fill"></div>

            <div class="secondary-text ta-center">Puedes cambiar los nombres y contraseñas de las cuentas más tarde</div>

        </div>

        </transition>

    </div>

    <div class="pa20 flex">
        <button v-if="step == 0" class="secondary" @click="SkipConfiguration()">Saltar</button>
        <button v-else class="secondary" @click="forward=false;step--">Atrás</button>
        <div class="flex-fill"></div>
        <button v-if="step == 4" class="primary purple" @click="CompleteConfiguration()">¡Listo!</button>
        <button v-else class="primary purple" @click="forward=true;step++">Continuar</button>
    </div>

</div>
</template>

<script>
export default {
    data() {
        return {
            step: 0,
            forward: true,
            location: {latitude: -36.827080852203075, longitude: -73.05028438568117},
            address: '',
            methods: {
                cash: false,
                transbank: false,
                amipass: false,
                sodexo: false
            },
            menu: {
                sushi: false,
                bar: false,
                burger: false,
                pizza: false,
            },
            accounts: {
                cashier: false,
                delivery: false,
                waiter: false
            },
            options: null,
        }
    },
    mounted() {
        this.Loading();
        axios.get(`/local/${this.Local.id}/welcome`).then(res => {
            this.Response(res);
            this.options = res.data;
            console.log(res.data);
        }).catch(err => {
            this.Response(err);
        })
    },
    methods: {
        LocationChanged(data) {
            this.location = {latitude: data.lat, longitude: data.lng};
            if(data.address) this.address = data.address;
        },
        SkipConfiguration() {
            this.Confirm({
                text: 'Saltar Configuración',
                title: '¿Seguro que desea saltar la configuración inicial?',
                class: 'error-color',
                function: () => {
                    this.Loading();
                    axios.post(`/local/${this.Local.id}/welcome`, {skip: true}).then(res => {
                        location.href = '/';
                    }).catch(err => {
                        this.Response(err);
                    })
                }
            });
        },
        CompleteConfiguration() {
            this.Loading();
            var data = {
                location: this.location,
                address: this.address,
                methods: this.methods,
                menu: this.menu,
                accounts: this.accounts
            };
            axios.post(`/local/${this.Local.id}/welcome`, data).then(res => {
                location.href = '/';
            }).catch(err => {
                this.Response(err);
            })
        }
    }
}
</script>

<style>
.step-container {
    max-width: 500px;
    overflow: auto;
}
</style>