<template>
<div></div>
</template>

<script>
export default {
    data() {
        return {
            currentSKU: '',
			timerSKU: null,
        }
    },
    mounted() {
		document.addEventListener('keypress', this.CheckSKU);
	},
    methods: {
        CheckSKU(e)
		{
			if(!this.timerSKU)
			{
				console.log('create timer');
				this.timerSKU = setTimeout(() => {
					this.currentSKU = '';
					this.timerSKU = null;
					console.log('timer expired');
				}, 200);	
			}

			if(e.keyCode !== 13)
			{
				this.currentSKU += e.key;
			}
			else 
			{
				if(this.currentSKU.length >= 6)
				{
					e.preventDefault(); 
					e.stopPropagation();
					console.log(this.currentSKU);
					this.$emit('OnSKU', this.currentSKU);
				}
				this.currentSKU = '';
				if(this.timerSKU) 
				{
					clearTimeout(this.timerSKU);
					this.timerSKU = null;
				}
			}
		},
    },
	beforeDestroy() {
		document.removeEventListener('keypress', this.CheckSKU);
	}
}
</script>

<style>

</style>