<template>
<div class="flex-fill fill flex column ofy">
<tabs v-model="tab" :tabs="tabs" :keyByTab="true" :alwaysVisible="true" @OnTabChanged="TabChanged($event)"
:tabCount="tabCount" tabCountOnSelectedOnly>

    <div slot="Mesas" class="pa-body">
        <div class="secondary-text mt10 ta-center" v-if="tables === null || !Util.Arr(tables).length">No hay mesas</div>
        <div class="mb20" v-for="section in tablesSorted" :key="section.section">
            <div class="flex align-center mb20">
                <div class="f18 flex-fill flex align-center">{{section.section}} <span class="counter">{{section.tables.length}}</span></div>
                <i class="options icon-dots-vertical" @click="Options([
                    {text: 'Nueva Mesa', function: () => {ClickNewTable(section.section)}}
                ])"></i>
            </div>
            <div class="section mb10"
            v-for="(table, key) in section.tables" :key="key">
                <div class="fill flex align-center">

                    <avatar class="mr10"
                    defaultImageIcon="3152"
                    :imageIcon="section.section"
                    :color="TableColor(table)">
                    </avatar>
                    
                    <div class="flex column flex-fill">
                        <div class="bold">{{table.name}}</div>
                        <div class="secondary-text" v-if="table.reserved">Mesa reservada para el {{Util.Date(table.reservation_data.date, 'LLLL')}}</div>
                        <div class="secondary-text" v-if="table.id_transaction">Mesa activa</div>
                        <div class="secondary-text" v-if="!table.available">Mesa deshabilitada</div>
                        <div class="secondary-text" v-if="table.parent_table">Mesa unida a {{table.parent_table}}</div>
                    </div>
                    <i class="options icon-dots-vertical" @click="Options([
                        {text: 'Eliminar', class: 'error-color', confirm: `¿Eliminar ${table.name}?`, function: () => {DeleteTable(table)}, if: !table.id_transaction && !table.parent_table},
                        {text: 'Editar Mesa', class: '', function: () => {ClickEditTable(table)}, if: !table.id_transaction && !table.parent_table},
                        {text: 'Habilitar Mesa', confirm: `¿Habilitar ${table.name}?`, function: () => {TableAvailability(table, true)}, if: !table.available && !table.parent_table},
                        {text: 'Deshabilitar Mesa', confirm: `¿Deshabilitar ${table.name}?`, function: () => {TableAvailability(table, false)}, if: !!table.available && !table.id_transaction && !table.parent_table},
                        {text: 'Liberar Mesa', confirm: `¿Liberar ${table.name}? Si hay un pedido activo seguirá pendiente`, function: () => {FreeTable(table)}},
                    ])"></i>
                </div>
            </div>
        </div>
        <!--
        <div class="section" v-else>
            <div class="input-style"
            v-for="(table, key) in tables" :key="key">
                <div class="fill flex align-center">
                    <i class="icon icon-cross mr10 c3" title="Mesa deshabilitada" v-if="!table.available"></i>
                    <i class="icon icon-receipt mr10 c3" title="Mesa activa" v-if="table.id_transaction"></i>
                    <i class="icon icon-datetime mr10 c3" title="Mesa reservada" v-if="table.reserved"></i>
                    <div class="flex-fill bold">{{table.name}} <span class="secondary-text" v-if="table.section">({{table.section}})</span></div>
                    <i class="options icon-dots-vertical" @click="Options([
                        {text: 'Eliminar', class: 'error-color', confirm: `¿Eliminar ${table.name}?`, function: () => {DeleteTable(table)}, if: !table.id_transaction},
                        {text: 'Habilitar Mesa', confirm: `¿Habilitar ${table.name}?`, function: () => {TableAvailability(table, true)}, if: !table.available},
                        {text: 'Deshabilitar Mesa', confirm: `¿Deshabilitar ${table.name}?`, function: () => {TableAvailability(table, false)}, if: table.available},
                    ])"></i>
                </div>
            </div>
        </div>
        -->
    </div>

    <local-staff-view ref="staffView" slot="Cuentas" @OnAccountsChanged="accountsCount = $event.length"></local-staff-view>
    <iap-settings v-if="tabs.includes('Pedidos Online')" slot="Pedidos Online" ref="iapSettings"></iap-settings>
    <local-profile-settings slot="General" ref="profile" class="pa-body" @OnSettingsSaved="OnSettingsSaved()"></local-profile-settings>
    <local-sii-settings v-if="tabs.includes('SII')" ref="sii" slot="SII"></local-sii-settings>
    <virtual-menu-settings v-if="tabs.includes('Menú Virtual')" ref="virtualMenu" slot="Menú Virtual"></virtual-menu-settings>

    <div class="opt r">
        <button class="primary purple" @click="$refs.iapSettings.Save()" v-show="tab=='Pedidos Online'">Guardar</button>
        <button class="primary purple" @click="$refs.modules.Save()" v-show="tab=='Módulos'">Guardar</button>
        <button class="primary purple" @click="$refs.profile.Save()" v-show="tab=='General'">Guardar</button>
        <button class="primary purple" @click="$refs.virtualMenu.Save()" v-show="tab=='Menú Virtual'">Guardar</button>
        <button class="primary purple" @click="SaveCategories()" v-show="tab=='Categorías'">Guardar</button>
        <i class="options icon-dots-vertical" v-show="tab=='Mesas'" @click="Options([
            {text: 'Nueva Mesa', function: () => {ClickNewTable()}}
        ])"></i>
        
    </div>

    <modal v-model="modalTable" :dir="['right', 'center']" :modalClass="['fill', 'sm']" :title="`${modalTable && modalTable.edit ? 'Editar':'Nueva'} Mesa`">
        <div class="section" v-if="modalTable">
            <input-field class="mb15"
            v-model="modalTable.name"
            label="Nombre"
            placeholder="(Ej: Mesa 1)">
            </input-field>
            <input-select
            v-model="modalTable.section"
            label="Sección"
            :items="tableSections"
            :canType="true">
            </input-select>
        </div>
        
        <button slot="actions" class="primary purple" v-if="modalTable" @click="modalTable.edit ? EditTable() : SaveTable()">Guardar</button>
        
    </modal>

</tabs>

</div>
</template>

<script>

export default {
    data() {
        return {
            tab: null,
            tables: null,
            modalTable: null,
            editTable : false,
            accountsCount: 0,
        }
    },
    mounted() {

    },
    methods: {

        TabChanged(tab)
        {
            if(tab == 'Cuentas')
            {
                this.$refs.staffView.Init();
            }
            if(tab == 'Pedidos Online')
            {
                this.$refs.iapSettings.Init();
            }
            if(tab == 'Menú Virtual')
            {
                this.$refs.virtualMenu.Init();
            }
            if(tab == 'SII')
            {
                this.$refs.sii.Init();
            }
            if(tab == 'Módulos')
            {
                this.$refs.modules.Init();   
            }
            if(tab == 'Mesas' &&  this.tables === null)
            {
                this.Loading();
                axios.get(`/tables/${this.Local.id}`).then(res => {
                    this.Response(res);
                    this.tables = res.data;
                }).catch(err => {
                    this.Response(err);
                });
            }
        },
        ClickNewTable(section){
            this.modalTable = {name: '', available: true, id_transaction: null, edit : false, section: (section && section !== 'Sin definir' ? section : null)}
        },
        ClickEditTable(table){
            this.modalTable = {id: table.id, name: table.name, section: table.section, available: true, id_transaction: null, edit : true }
        },
        SaveTable()
        {
            if(!this.modalTable.name) return this.Info('Nombre inválido'); 
            this.Loading();
            axios.put(`/tables/${this.Local.id}`, this.modalTable).then(res => {
                this.Response(res);
                this.tables = res.data;
                this.modalTable = null,
                this.editTable = false;
            }).catch(err => {
                this.Response(err);
            });
        },
        EditTable()
        {
            if(!this.modalTable.name) return this.Info('Nombre inválido'); 
            this.Loading();
            axios.put(`/tables/${this.Local.id}/edit`, this.modalTable).then(res => {
                this.Response(res);
                this.tables = res.data;
                this.modalTable = null,
                this.editTable = false;
            }).catch(err => {
                this.Response(err);
            });
        },
        DeleteTable(table) 
        {
            this.Loading();
            axios.patch(`/tables/${this.Local.id}/delete`, {table: table.name}).then(res => {
                this.Response(res);
                this.tables = res.data;
            }).catch(err => {
                this.Response(err);
            });
        },
        TableAvailability(table, is_available) 
        {
            this.Loading();
            axios.patch(`/tables/${this.Local.id}/availability`, {table: table.name, is_available: is_available}).then(res => {
                this.Response(res);
                this.tables = res.data;
            }).catch(err => {
                this.Response(err);
            });
        },
        FreeTable(table) 
        {
            this.Loading();
            axios.patch(`/tables/${this.Local.id}/free`, {table: table.name}).then(res => {
                this.Info('Mesa liberada');
                this.tables = res.data;
            }).catch(err => {
                this.Response(err);
            });
        },
        TableColor(table)
        {
            if(table.reserved) return this.Util.baseColors.yellow;
            if(table.id_transaction) return this.Util.baseColors.green;
            if(!table.available) return this.Util.baseColors.red;
            if(table.parent_table) return this.Util.baseColors.green;
            return null;
        },
        OnSettingsSaved(){
            if(this.tabs.includes('Pedidos Online')) 
            {
                this.$refs.iapSettings.settings = null;
                this.$refs.iapSettings.location = null;
            }
        }
    },
    computed: 
    {
        tabs() {

            var ret = ['General'];
            if(this.Auth.role == 'local_admin' && this.Auth.restrictions_admin){
                if(this.Auth.restrictions_admin.settings_accounts_enabled) ret.push('Cuentas');
                if(this.Auth.restrictions_admin.settings_tables_enabled && this.Local.type == 'LOCAL' && this.Local.modules.module_tables) ret.push('Mesas');
                if(this.Auth.restrictions_admin.settings_online_orders_enabled && (this.Local.modules.module_store || this.Local.modules.module_store_plus)) ret.push('Pedidos Online');
                if(this.Auth.restrictions_admin.settings_menu_virtual_enabled && this.Local.modules.module_virtual_menu) ret.push('Menú Virtual');
                if(this.Auth.restrictions_admin.settings_sii_enabled && (this.Local.modules.module_sii_boleta || this.Local.modules.module_sii_factura)) ret.push('SII');
            }else{
                ret.push('Cuentas');
                if(this.Local.type == 'LOCAL' && this.Local.modules.module_tables) ret.push('Mesas');
                if(this.Local.modules.module_store || this.Local.modules.module_store_plus) ret.push('Pedidos Online');
                if(this.Local.modules.module_virtual_menu) ret.push('Menú Virtual');
                if(this.Local.modules.module_sii_boleta || this.Local.modules.module_sii_factura) ret.push('SII');
            }
            
            //if(this.Auth.role == 'admin' || this.Auth.role == 'sub_admin') ret.push('Categorías');
            //if(this.Auth.role == 'admin' || this.Auth.role == 'sub_admin') ret.push('Módulos');
            return ret;
        },
        tablesSorted() {
            var sections = {};
            var undefinedSection = {
                section: 'Sin definir',
                tables: []
            };

            for(var key in this.tables)
            {
                var table = this.tables[key];
                if(table.section)
                {
                    if(!sections[table.section]) sections[table.section] = {section: table.section, tables: []};
                    sections[table.section].tables.push(table);
                }
                else
                {
                    undefinedSection.tables.push(table);
                }
            }

            var ret = this.Util.NaturalSort(Object.values(sections), 'section');
            if(undefinedSection.tables.length) ret.push(undefinedSection);

            ret.forEach(section => {
                section.tables = this.Util.NaturalSort(section.tables, 'name');
            });

            return ret;
        },
        tableSections() {
            var ret = [{text: 'Sin definir', value: null}];
            if(!this.tables) return ret;

            var categories = {};

            for(var key in this.tables)
            {
                var section = this.tables[key].section;
                if(section && !categories[section])
                {
                    ret.push({text: section, value: section});
                    categories[section] = true;
                }
            }
            return ret;
        },
        tabCount() {
            return {
                Cuentas: this.accountsCount,
                Mesas: this.tables ? Object.values(this.tables).length : 0
            }
        }
    }
}
</script>