<template>
<div class="flex align-center">

    <div :class="`unshrink avatar relative ${round ? 'round' : ''}`" :style="style">
        <div v-if="label" class="label">{{Util.Initials(label)}}</div>
        <i v-if="icon" :class="`icon icon-${icon}`" style="transform: scale(1.4)"></i>
        <i v-if="secondaryIcon" :class="`secondary-icon icon absolute icon-${secondaryIcon}`" :style="{'background-color': style.color}"></i>
        <img v-if="defaultImageIcon && !iconImageLoaded" :src="img(`/icons/${defaultImageIcon}.png`)" style="width: 60%">
        <img v-if="imageIcon" v-show="iconImageLoaded" :src="img(`/icons/keys/${Util.Alphanumeric(imageIcon, '_')}.png`)" style="width: 60%" @load="ImageLoaded">
        <slot></slot>
    </div>

    <div class="flex column ml10 flex-fill" v-if="title || description">
        <div v-if="title" class="bold">{{title}}</div>
        <div v-if="description" class="secondary-text" v-html="description"></div>
    </div>

    <slot name="right"></slot>

</div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: null
        },
        description: {
            type: String,
            default: null
        },
        size: {
            type: [Number, Array],
            default: 40
        },
        round: {
            type: Boolean,
            default: true
        },
        image: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        color: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            default: null
        },
        imageIcon: {
            type: String,
            default: null
        },
        defaultImageIcon: {
            type: [String, Number],
            default: null
        },
        border: {
            type: Boolean,
            default: false
        },
        secondaryIcon: {
            type: String,
            default: null
        },
        opaque: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            iconImageLoaded: false
        }
    },
    methods: {
        ImageLoaded(event)
        {
            this.iconImageLoaded = true;
        },
    },
    computed: {
        style() {
            var currentSize = `${Array.isArray(this.size) ? (this.mobile ? this.size[0] : this.size[1]) : this.size}px`;
            var color = '';
            var bgColor = '';

            if(this.color)
            {
                if(this.color == 'AUTO')
                {
                    if(this.label)
                    {
                        color = this.Util.AvatarColors[this.label.length % this.Util.AvatarColors.length];
                    }
                }
                else
                {
                    color = this.color;
                }
                var rgb = this.Util.Hex2Rgb(color);
                if(rgb) bgColor = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.15)`;
            }

            return {
                width: currentSize, 
                height: currentSize, 
                'background-image': this.image ? this.img(this.image, true) : '',
                'background-color': this.opaque ? (color ? color : '') : (bgColor ? bgColor : ''),
                color: color ? color : '',
                outline: this.border ? `2px solid ${color}` : ''
            }
        }
    }
}
</script>

<style lang="scss">
.avatar {
    background-color: var(--gray-3);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .label {
        font-weight: 500;
    }

    .secondary-icon {
        bottom: 0px; 
        right: 0px; 
        font-size: 8px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--white);
    }

    &>i::before {
        vertical-align: -10px;
    }

}

.dark {
    .avatar {
        background-color: var(--dark-bg-2);
    }
}
</style>