<template>
<div class="input-text">
    <div class="bold" v-if="label"><i v-if="icon" :class="`icon icon-${icon} mr5`" style="color: var(--gray)"></i>{{label}}</div>
    <div class="secondary-text" v-if="description">{{description}}</div>

    <div v-if="keywords && showKeywords" class="mb10 mt5">
        <button @click="currentValue+=key" class="keyword mr5" v-for="(keyword, key) in keywords" :key="key">{{keyword}}</button>
    </div>

    <div :class="`relative ${label || description ? 'mt10' : ''}`">
        <textarea v-model="currentValue" 
        :rows="rows" 
        :placeholder="placeholder" 
        @scroll="OnScroll" 
        ref="textarea"
        @focus="isFocus = true"
        @blur="isFocus = false"></textarea>
        <div class="over">
            <div v-html="highlight" ref="highlight"></div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            default: null
        },
        description: {
            type: String,
            default: null
        },
        rows: {
            type: Number,
            default: 3
        },
        placeholder: {
            type: String,
            default: ''
        },
        keywords: {
            type: Object,
            default: null
        },
        showKeywords: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            currentValue: '',
            isFocus: false,
        }
    },
    mounted() {
        this.currentValue = this.value;
    },
    methods: {
        OnScroll() {
            var top = this.$refs.textarea.scrollTop;
            this.$refs.highlight.style.marginTop = `-${top}px`;
        }
    },
    computed: {
        highlight() {
            if(!this.currentValue) return '';
            var ret = this.currentValue.replace(/\n/g, '<br>');
            if(this.keywords)
            {
                for(var key in this.keywords)
                {
                    var re = new RegExp(key, 'g');
                    //var replacement = this.isFocus ? `<span class="highlight">${key}</span>` : `<span class="bold">${this.keywords[key].replace(/ /g, '_').toUpperCase()}</span>`
                    var replacement = this.isFocus ? `<span class="highlight">${key}</span>` : `<span class="bold">${this.keywords[key]}</span>`
                    ret = ret.replace(re, replacement);
                }
            }
            return ret;
        }
    },
    watch: {
        currentValue: function(n, o) {
            this.$emit('input', n);
        },
        value: function(n, o)
        {
            this.currentValue = n;
        }
    }
}
</script>

<style lang="scss">
.input-text {

    textarea {
        padding: 8px 16px;
        border: 1px solid var(--gray-2);
        border-radius: 8px;
        width: 100%;
        font-size: 14px;
        resize: none;
        margin: 0;
        box-sizing: border-box;
        display: block;
        color: transparent;
        caret-color: black;

        &:focus {
            outline: 2px solid rgba(var(--purple-rgb), 0.2);
            border-color: var(--purple);
        }
    }

    .over {
        pointer-events: none;
        position: absolute;
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
        color: var(--black);
        padding: 8px 16px;
        overflow: hidden;
        white-space: break-spaces;

        .highlight {
            background-color: rgba(var(--yellow-rgb), 0.4);
            pointer-events: none;
            border-radius: 4px;
        }

    }

    .keyword {
        font-weight: normal;
        font-size: 12px;
        padding: 4px 4px;
        height: auto;
        background-color: var(--gray-cyan);
        display: inline-block;
    }

}

.dark {
    .input-text {

        textarea {
            background-color: var(--dark-bg-0);
            border: 1px solid var(--dark-outline);
            caret-color: white;

            &:focus {
                outline: 2px solid var(--purple);
                border-color: var(--purple);
            }
        }

        .over {
            color: var(--dark-fc);
            
            .highlight {
                background-color: rgba(var(--yellow-rgb), 0.4);
            }

        }

        .keyword {
            background-color: var(--dark-bg-3);
            color: var(--dark-fc-1);
        }

    }
}
</style>