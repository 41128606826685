<template>
<div class="pa-body">

<div class="opt r">
    <i class="options icon-dots-vertical" @click="Options([
        {text: 'Nueva Cuenta', function: () => {modalAccount={role: 'admin'}}},
    ])"></i>
</div>

<div v-if="accounts.length">
    <div :class="`section mb10 ${account.is_salesman ? 'selectable' : ''}`"
    @click="account.is_salesman ? $router.push(`/profile/${account.id}`) : null"
    v-for="(account, index) in accounts"
    :key="index">
        <div class="flex fill align-center">
            
            <avatar class="mr10"
            :label="account.display_name"
            :color="Util.AccountType(account).color">
            </avatar>

            <div class="flex align-center flex-fill">
                <div class="flex column">
                    <div><span class="c2 bold">{{account.display_name}}</span> <span class="secondary-text ml5">{{Util.AccountType(account).label}}</span></div>
                    <div class="c3" style="font-size: 12px">Creada el {{Util.Date(account.created_at)}}</div>
                </div>
            </div>
            <i class="options icon-dots-vertical" @click.stop="Options([
                {text: 'Eliminar Cuenta', class: 'error-color', function: () => {DeleteAccount(account)}, confirm: '¿Eliminar cuenta definitivamente?'},
                {text: `Editar`, function: () => {$refs.accountForm.Open(account.id)}},
            ])"></i>
        </div>
    </div>
</div>
<div v-else class="ta-center mt10 secondary-text">No hay cuentas</div>
<account-form ref="accountForm"></account-form>

<modal v-model="modalAccount" :dir="['right', 'center']" :modalClass="['fill', 'sm']" :title="desktop?'Nueva Cuenta':''">
    <div class="pa20 flex column" v-if="modalAccount">

        <input-select class="mb15"
        v-model="modalAccount.type"
        :items="[{value: 'admin', text: 'Administrador Global'}, {value: 'sub_admin', text: 'Sub Administrador'}, {value: 'salesman', text: 'Consultor'}]"
        label="Tipo Cuenta">
        </input-select>

        <input-field class="mb15"
        v-model="modalAccount.display_name"
        label="Nombre">
        </input-field>

        <input-field class="mb15"
        v-model="modalAccount.user"
        label="Usuario"
        placeholder="(Nombre de la cuenta que se usará para iniciar sesión)">
        </input-field>

        <input-field class="mb15"
        v-model="modalAccount.password"
        label="Contraseña"
        type="password">
        </input-field>

        <input-field
        v-model="modalAccount.password_confirmation"
        label="Repetir Contraseña"
        type="password">
        </input-field>

    </div>
    
    <button slot="actions" class="primary purple" @click="SaveAccount()">Guardar</button>
    
</modal>

</div>
</template>
<script>
export default {
    data() {
        return {
            accounts: [],
            modalAccount: null
        }
    },
    mounted() {
        this.Loading();
        axios.get(`/influye/accounts`).then(res => {
            this.Response(res);
            this.accounts =  res.data;
        }).catch(err => {
            this.Response(err);
        });
    },
    methods: {
        SaveAccount() {
            this.$root.$emit('Loading');
            axios.put(`/influye/account`, this.modalAccount).then(res => {
                this.$root.$emit('Response', res);
                this.accounts.push(res.data);
                this.modalAccount = false;
            }).catch(err => {
                this.$root.$emit('Response', err);
            });
        },
        DeleteAccount(account) {
            this.$root.$emit('Loading');
            axios.delete(`/account/${account.id}`).then(res => {
                this.$root.$emit('Response', res);
                for(var i = 0; i < this.accounts.length; i++)
                    if(this.accounts[i].id == account.id) return this.accounts.splice(i, 1);
            }).catch(err => {
                this.$root.$emit('Response', err);
            });
        },
    }
}
</script>