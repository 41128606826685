<template>
    <div>
        <modal v-model="modalFactura" dir="right" :modalClass="['fill', 'lg right h100']" :title="`Emitir ${typeDTE == 33? 'Factura Electrónica' : 'Nota de Crédito'}`">
            <div class="section" v-if="modalFactura">
                <input-date
                class="mb15"
                v-model="modalFactura.emisionDate"
                label="Fecha Emisión"
                type="DATE"
                format="timestamp">
                </input-date>

                <input-select class="mb15"
                label="Actividad Económica"
                :canType="true"
                v-model="modalFactura.actecos"
                :items="actecos">
                </input-select>

                <div v-if="typeDTE == 61">
                    <div class="separator mb20 mt20"></div>
                    <div class="bold">Referencia</div>

                    <input-select class="mb15 mt15"
                    label="Tipo de documento"
                    v-model="modalFactura.typeDocument"
                    canType="search"
                    :items="[
                        { value: 33, text: 'Factura Electrónica' }, 
                        { value: 39, text: 'Boleta Electrónica' },
                    ]">
                    </input-select>

                    <input-field class="mb15"
                    v-model="modalFactura.folio_reference"
                    label="Folio Ref."
                    type="int+">
                    </input-field>

                    <input-date
                    class="mb15"
                    v-model="modalFactura.emisionDate_reference"
                    label="Fecha Ref."
                    type="DATE"
                    format="timestamp">
                    </input-date>
                </div>

                <div class="separator mb20 mt20"></div>

                <label v-if="modalFactura.typeDocument == 39">
                    <input type="checkbox" v-model="modalFactura.without_receiver">Sin receptor <div class="checkmark"></div>
                </label>
                <div v-if="!modalFactura.without_receiver" class="flex align-center mb10">
                    <div class="bold flex-fill flex" style="font-size: 15px">
                        Receptor
                    </div>
                        
                    <i class="options icon-trash" v-if="modalFactura.receiver && this.Auth.role != 'cashier'" v-tooltip="'Eliminar receptor'" @click="Confirm({
                        text: 'Eliminar receptor',
                        title: '¿Desea eliminar este receptor?',
                        class: 'selected-color',
                        function: () => { deleteReceiver(modalFactura.receiver, true) }
                    })"></i>

                    <i class="options icon-edit" v-if="modalFactura.receiver" v-tooltip="'Editar receptor'" @click="modalFacturaReceptor = Util.Copy(modalFactura.receiver)"></i>

                    <i class="options icon-plus" v-tooltip="'Añadir receptor'" @click="OpenModalReceiver()"></i>
                </div>

                <div v-if="modalFactura.receiver && !modalFactura.without_receiver">
                    <input-select class="mb15"
                    v-if="receptorsFactura && !modalFactura.hide_receiver"
                    v-model="modalFactura.receiver"
                    canType="search"
                    :items="receptorsFactura"
                    @OnValueChanged="loadReceiverAddress($event)">
                    </input-select>
                    
                    <input-select class="mb15"
                    v-show="receptorsFactura && modalFactura.receiver && modalFactura.receiver.multi_address.length > 1"
                    v-model="modalFactura.receiver_address"
                    :items="receiverAddresses">
                    </input-select>

                    <div class="mb15" v-if="modalFactura.receiver_address">
                        <!-- <div class="bold">Email</div> -->
                        <div>{{ modalFactura.receiver_address.email }}</div>
                    </div>
                </div>

                <div v-if="typeDTE == 33"> <!-- DESCUENTO -->
                    <div class="separator mb20 mt20"></div>

                    <div class="bold mb10">¿Posee algún descuento?</div>

                    <input-field class="mb15"
                    v-model="modalFactura.discount"
                    label="Descuento"
                    placeholder="$0"
                    prefix="$"
                    type="int+">
                    </input-field>
                </div>

                <div v-if="typeDTE == 33">
                    <div class="separator mb20 mt20"></div>

                    <div class="bold mb10">Método de Pago</div>

                    <input-select class="mb15" 
                    v-model="modalFactura.payment_method"
                    :items="paymentMethods">
                    </input-select>
                </div>

                <div class="separator mb20 mt20"></div>

                <!-- Items -->
                <div>
                    <div class="flex align-center mb20">
                        <div class="f18 flex-fill flex">Items <span class="counter">{{modalFactura.detail.length}}</span></div>
                        <i class="options icon-plus" @click="modalItem = {item: '', quantity: 0, price: 0, total_price: 0, discount: 0, tax: 0, affectIva: true}"></i>
                    </div>
                    <div class="secondary-text mt10 ta-center" v-if="!modalFactura.detail.length">No hay items</div>
                    <div class="flex well selectable mb10" v-for="(entry, index) in modalFactura.detail" :key="index" @click="modalItem=Util.Copy(entry, {index: index, oldItem: entry.item})">
                        <div class="flex-fill">
                            <div class="bold">{{entry.item}}</div>
                            <div class="bold secondary-text mt10 mb10">{{entry.description}}</div>
                            <div class="flex">
                                <div class="w20">
                                    <div class="secondary-text">Cantidad</div>
                                    <div>{{Util.Number(entry.quantity)}}</div>
                                </div>
                                <div class="w20">
                                    <div class="secondary-text">Costo Unitario</div>
                                    <div>{{Util.Price(entry.price)}}</div>

                                    <div class="secondary-text">Subtotal</div>
                                    <div v-if="!entry.total_price">-</div>
                                    <div v-else>{{Util.Price(entry.total_price)}}</div>
                                </div>
                                <div class="w20">
                                    <div class="secondary-text">Impuesto Adicional</div>
                                    <div v-if="!entry.taxAmount">-</div>
                                    <div v-else>{{Util.Price(entry.taxAmount)}}</div>

                                    <div class="secondary-text">IVA</div>
                                    <div v-if="!entry.ivaAmount">-</div>
                                    <div v-else>{{Util.Price(entry.ivaAmount)}}</div>
                                </div>
                                <div class="w20">
                                    <div class="secondary-text">Descuento</div>
                                    <div v-if="!entry.discountAmount">-</div>
                                    <div v-else>{{Util.Price(entry.discountAmount)}}</div>
                                    
                                    <div class="secondary-text">Monto Neto</div>
                                    <div v-if="!entry.netoAmount">-</div>
                                    <div v-else>{{Util.Price(entry.netoAmount)}}</div>
                                </div>
                                <div class="w20">
                                    <div class="secondary-text">Total</div>
                                    <div v-if="!entry.total">-</div>
                                    <div v-else>{{Util.Price(entry.total)}}</div>
                                </div>
                            </div>
                        </div>
                        <i class="options icon-trash" @click.stop="modalFactura.detail.splice(index, 1)"></i>
                    </div>
                </div>
            </div>

            <div class="fill flex align-center" slot="actions">
                <div> {{ Util.Price(totalDte) }} </div>
            </div>

            <button v-if="typeDTE == 33" slot="actions" class="primary purple" @click="Confirm({
                title: `Se procederá a enviar la factura electrónica al SII`,
                text: 'Confirmar',
                class: 'selected-color',
                function: () => {generateFacturaElectronica()}
            })">Emitir</button>

            <button v-if="typeDTE == 61" slot="actions" class="primary purple" @click="Confirm({
                title: `Se procederá a enviar la Nota de Crédito al SII`,
                text: 'Confirmar',
                class: 'selected-color',
                function: () => {generateNotaCredito()}
            })">Emitir</button>
        </modal>

        <modal v-model="modalItem" dir="right" :modalClass="['fill', 'sm right h100']" :title="modalItem && modalItem.index >= 0 ? 'Editar Item' : 'Agregar Item'">
            <div class="pa20" v-if="modalItem">
                <input-select class="mb15"
                v-model="modalItem.item" 
                :items="inventorySelectItems"
                label="Item"
                canType
                @OnValueChanged="SelectItem($event, modalItem)">
                </input-select>

                <input-field class="mb15"
                v-model="modalItem.description"
                label="Descripción"
                :lines="3"
                placeholder="(Opcional)">
                </input-field>

                <input-field class="mb15"
                v-model="modalItem.quantity"
                type="float+" 
                label="Cantidad"
                :suffix="inventory[modalItem.item] && inventory[modalItem.item].unit ? ' '+inventory[modalItem.item].unit : ''"
                @OnChangeFocused="QuantityChanged($event, modalItem)">
                </input-field>

                <input-field class="mb15"
                v-model="modalItem.price" 
                type="float+" 
                label="Costo Unitario"
                prefix="$"
                @OnChangeFocused="UnitCostChanged($event, modalItem)">
                </input-field>

                <input-field class="mb15"
                v-model="modalItem.total_price" 
                type="float+" 
                label="Subtotal Producto"
                prefix="$"
                @OnChangeFocused="TotalPriceChanged($event, modalItem)">
                    <i class="icon icon-cross selectable" v-tooltip="'Quitar'" 
                    v-show="modalItem.total_price" slot="inner-after"
                    @click="$set(modalItem, 'total_price', null);$set(modalItem, 'price', null)"></i>
                </input-field>

                <input-select class="mb15"
                v-model="modalItem.tax" 
                :items="taxAdditional"
                label="Impuesto Adicional">
                </input-select>

                <input-field class="mb15"
                v-model="modalItem.discount" 
                label="Descuento" 
                type="float" 
                placeholder="(Opcional)"
                :prefix="modalItem.discount < 0 ? '$' : ''"
                :suffix="modalItem.discount > 0 ? '%' : ''"
                info="Un número negativo representa un valor fijo en pesos y un número positivo un porcentaje sobre el costo total del item">
                </input-field>

                <label class="mb10">
                    <input type="checkbox" v-model="modalItem.affectIva"> Afecto de IVA <div class="checkmark"></div>
                </label>

                <div class="mb15" v-if="modalItem.netoAmount">
                    <div class="bold">Monto Neto</div>
                    <div class="bold">{{Util.Price( modalItem.netoAmount )}}</div>
                </div>

                <div class="mb15" v-if="modalItem.ivaAmount">
                    <div class="bold">IVA 19%</div>
                    <div class="bold">{{Util.Price( modalItem.affectIva?  modalItem.ivaAmount  : 0 )}}</div>
                </div>

                <div class="mb15" v-if="itemResumen.total">
                    <div class="bold">Total</div>
                    <div class="bold">{{Util.Price( itemResumen.total )}}</div>
                </div>
            </div>

            <button slot="actions" class="primary purple" @click="AddInventoryItemToInvoice">{{modalItem && modalItem.index >= 0 ? 'Editar' : 'Agregar'}}</button>
        </modal>

        <modal v-model="modalFacturaReceptor" dir="right" :modalClass="['fill', 'md right h100']" :title="modalFacturaReceptor && modalFacturaReceptor.id? 'Editar receptor' : 'Crear receptor'">
            <div class="section" v-if="modalFacturaReceptor">
                <input-field
                inputClass="primary mb15"
                v-model="modalFacturaReceptor.rut"
                placeholder="RUT"
                label="RUT">
                </input-field>

                <input-field
                inputClass="primary mb15"
                v-model="modalFacturaReceptor.name"
                placeholder="Razón Social"
                label="Razón social">
                </input-field>

                <input-field
                inputClass="primary mb15"
                v-model="modalFacturaReceptor.alias"
                placeholder="Alias"
                label="Alias">
                </input-field>

                <input-field
                inputClass="primary mb15"
                v-model="modalFacturaReceptor.line_business"
                placeholder="Giro"
                label="Giro del negocio">
                </input-field>

                <div class="flex align-center mb5">
                    <div class="flex-fill">
                        <div class="bold">Direcciones</div>
                    </div>
                    <div class="f18">
                        <i class="options icon-plus" v-tooltip="'Nueva dirección'" @click="addAddressReceiver()"></i>
                    </div>
                </div>

                <div v-for="(address, index) in modalFacturaReceptor.multi_address" :key="index">
                    <div class="align-center mb10 well">
                        <div class="flex align-center mb5">
                            <div class="flex-fill">
                                <div class="bold">Dirección</div>
                            </div>
                            <div class="f18">
                                <i class="options icon-trash" v-tooltip="'Eliminar dirección'" @click.stop="Confirm({
                                    text: 'Eliminar',
                                    title: '¿Eliminar dirección?',
                                    class: 'error-color',
                                    function: () => {modalFacturaReceptor.multi_address.splice(index, 1)}
                                })"></i>
                            </div>
                        </div>

                        <input-field
                        inputClass="primary mb15"
                        v-model="address.email"
                        placeholder="Email"
                        label="Email">
                        </input-field>

                        <input-field
                        inputClass="primary mb15"
                        v-model="address.address"
                        placeholder="Dirección"
                        label="Dirección">
                        </input-field>

                        <input-field
                        inputClass="primary mb15"
                        v-model="address.commune"
                        placeholder="Comuna"
                        label="Comuna">
                        </input-field>

                        <input-field
                        inputClass="primary mb15"
                        v-model="address.city"
                        placeholder="Ciudad"
                        label="Ciudad">
                        </input-field>
                    </div>
                </div>
            </div>

            <button v-if="modalFacturaReceptor && modalFacturaReceptor.id" slot="actions" class="primary purple" @click="editReceiver()">Editar</button>
            <button v-else slot="actions" class="primary purple" @click="createReceiver()">Crear</button>
        </modal>

        <modal v-model="modalSendDte" dir="center" modalClass="sm" :title="`Enviar ${modalSendDte ? Util.DTE[modalSendDte.type] : 'Factura'}`">
            <i slot="options" v-if="modalSendDte && modalSendDte.type == 33" class="options icon-dots-vertical" @click="Options([{text: 'Imprimir', function: () => {PrintFactura(modalSendFactura.printData)}}])"></i>
            <div class="pa20" v-if="modalSendDte">
                <input-field class="mb20"
                v-model="modalSendDte.email"
                label="Email">
                    <button slot="after" class="primary purple" @click="SendFacturaEmail()">Enviar</button>
                </input-field>
            </div>

	        <button slot="actions" class="secondary" @click="DownloadDte(0)">{{ modalSendDte && modalSendDte.type == 61? 'Abrir' : 'Descargar Tributaria'}}</button>
	        <button v-if="modalSendDte && modalSendDte.type == 33" slot="actions" class="secondary" @click="DownloadDte(1)">Descargar Cedible</button>
        </modal>
    </div>
</template>

<script>
export default {
    props: {
        order: {
            type: Object,
            default: null,
        }
    },
    data(){
        return {
            modalFactura: null,
            modalItem: null,
            modalFacturaReceptor: null,
            modalSendDte: null,
            receptorsFactura: null,
            receiverAddresses: [],
            actecos: [],
            inventory: {},
            taxAdditionalSII: {},
            typeDTE: 33,
            paymentMethods: [{text: 'Seleccione método de pago', value: null}],
        }
    },
    methods: {
        Open(type = 33, receiver){
            this.typeDTE = type;
            this.getReceivers(receiver);
        },
        OpenModalReceiver(receiver){
            if(receiver) return this.modalFacturaReceptor = receiver;
            return this.modalFacturaReceptor = { multi_address : [{ address: '', city: '', commune: '' }] };
        },
        getReceivers(receiver){
            this.Loading();

            axios.post(`/sii/receivers/${this.Local.id}`)
            .then( ({data}) => {
                if(!data.status) return this.Info(data.message);
                this.modalFactura = { detail: [], receiver: data.receivers[0], detailsItems: {} , discount: 0 };
                this.inventory = data.items;
                this.taxAdditionalSII = data.tax_additional;
                this.loadReceptorData(data.receivers, receiver);
                this.loadActecosData(data.actecos);
                if(this.order) this.importInventory(this.order.orders);
                if(data.actecos) this.$set(this.modalFactura, 'actecos' , data.actecos[0]);
                this.Response(data);
                setTimeout(() => {
                    if (receiver){
                        this.modalFactura.receiver = receiver;
                        this.loadReceiverAddress(receiver);
                        this.modalFactura.hide_receiver = true;
                    }

                    data.payment_methods.forEach(method => {
                        if (!this.paymentMethods.some(m => m.value == method)) {
                            this.paymentMethods.push({
                                text: method === 'CASH' ? 'Efectivo' : method,
                                value: method
                            });

                            if(this.order) this.$set(this.modalFactura, 'payment_method', this.order.payment[0].method);
                        }
                    });
                }, 0);
            })
            .catch( err => {
                this.Response(err);
            })
        },
        createReceiver(){
            const rutRegex = /^\d{1,2}\.\d{3}\.\d{3}[-][0-9kK]{1}$/;

            if(!this.modalFacturaReceptor.rut) return this.Info('RUT es obligatorio');
            if(!this.modalFacturaReceptor.name) return this.Info('Razón social es obligatorio');
            if(!this.modalFacturaReceptor.line_business) return this.Info('Giro es obligatorio');

            if(!this.modalFacturaReceptor.rut.includes('-')) this.modalFacturaReceptor.rut = Util.Rut(this.modalFacturaReceptor.rut);
            if(!rutRegex.test(this.modalFacturaReceptor.rut)) return this.Info('Rut inválido');

            let error = null;
            this.modalFacturaReceptor.multi_address.forEach( data => {
                if(!data.email || data.email == '') return error = 'El email es obligatorio';
                if(!data.address || data.address == '') return error = 'La dirección es obligatoria';
                if(!data.commune || data.commune == '') return error = 'La comuna es obligatoria';
                if(!data.city || data.city == '') return error = 'La ciudad es obligatoria';
            });
            if(error) return this.Info(error);

            this.Loading();
            axios.post(`/sii/receiver/${this.Local.id}/create`, this.modalFacturaReceptor)
            .then( ({data}) => {
                if(data.status){
                    if(this.modalFactura) this.$set(this.modalFactura, 'receiver', data.receiver);
                    this.loadReceptorData(data.receivers);
                    this.modalFacturaReceptor = null;
                    this.$emit('reloadReceiver');
                }

                return this.Info(data.message);
            })
            .catch( err => {
                this.Response(err);
                if(err.response.data.message) this.Info(err.response.data.message);
            });
        },
        editReceiver(){
            this.Loading();
            if(!this.modalFacturaReceptor.rut) return this.Info('RUT es obligatorio');
            if(!this.modalFacturaReceptor.name) return this.Info('Razón social es obligatorio');
            if(!this.modalFacturaReceptor.line_business) return this.Info('Giro es obligatorio');

            let error = null;
            this.modalFacturaReceptor.multi_address.forEach( data => {
                if(!data.email || data.email == '') return error = 'El email es obligatorio';
                if(!data.address || data.address == '') return error = 'La dirección es obligatoria';
                if(!data.commune || data.commune == '') return error = 'La comuna es obligatoria';
                if(!data.city || data.city == '') return error = 'La ciudad es obligatoria';
            });
            if(error) return this.Info(error);

            axios.post(`/sii/receiver/${this.Local.id}/edit`, this.modalFacturaReceptor)
            .then( ({data}) => {
                if(data.status){
                    if(this.modalFactura) this.$set(this.modalFactura, 'receiver', data.receiver);
                    this.loadReceptorData(data.receivers);
                    this.loadReceiverAddress(data.receiver);
                    this.modalFacturaReceptor = null;
                    this.$emit('reloadReceiver');
                }

                this.Info(data.message);
            })
            .catch( err => {
                this.Response(err);
                if(err.response.data.message) this.Info(err.response.data.message);
            });
        },
        deleteReceiver(receiver, modal){
            this.Loading();
            axios.post(`/sii/receiver/${this.Local.id}/delete`, receiver)
            .then( ({data}) => {

                if(data.status){
                    if(modal) this.getReceivers();
                    this.$emit('reloadReceiver');
                } 

                return this.Info(data.message);
            })
            .catch( err => {
                this.Response(err);
            });
        },
        loadReceptorData(receivers){
            this.receptorsFactura = [];

            receivers.forEach(receiver => {
                this.receptorsFactura.push(
                    {text: `${receiver.rut} ${receiver.name} ${receiver.alias? '('+receiver.alias+')' : ''}`, value: receiver}
                );
            });
        },
        loadReceiverAddress(receiver){
            this.receiverAddresses = [];
            receiver.multi_address.forEach( address => {
                this.receiverAddresses.push(
                    {text: `${address.address}, ${address.commune}, ${address.city}`, value: address}
                );
            });
        },
        loadActecosData(actecos){
            if(!actecos) return;
            this.actecos = [];

            actecos.forEach( acteco => {
                this.actecos.push(
                    {text: acteco, value: acteco.split('-')[0].trim() }
                );
            });
        },
        AddInventoryItemToInvoice(){
            if(!this.modalItem.item) return this.Info('Item inválido');
            if(this.modalItem.quantity <= 0) return this.Info('Cantidad inválida');
            if(this.modalItem.price <= 0) return this.Info('Precio inválido');

            var index = this.modalItem.index;
            delete this.modalItem[index];

            isNaN(index)? this.modalFactura.detail.push(this.modalItem) : this.$set(this.modalFactura.detail, index, this.modalItem);
            this.modalItem = null;
        },
        QuantityChanged(quantity, modal){
            quantity = parseFloat(quantity);
            if(isNaN(quantity) || quantity == 0) return;

            let unitCost = parseFloat(modal['price']);

            if(!isNaN(unitCost)){
                this.$set(modal, 'total_price', unitCost*quantity);
                return;
            }

            let totalCost = parseFloat(modal['total_price']);
            if(!isNaN(totalCost))
            {
                this.$set(modal, 'price', totalCost / quantity);
                return;
            }

            /* if(!this.modalItem) return;
            if(isNaN(this.modalItem.quantity)) return this.$set(this.modalItem, 'quantity', '');
            let price = (!this.modalItem.price || isNaN(this.modalItem.price))? 0 : this.modalItem.price;
            this.$set(this.modalItem, 'total_price', price*val); */
        },
        UnitCostChanged(unitCost, modal){
            var quantity = parseFloat(modal['quantity']);
            if(isNaN(quantity)) return;
            unitCost = parseFloat(unitCost);
            if(isNaN(unitCost)) return this.$set(modal, 'total_price', '');
            this.$set(modal, 'total_price', unitCost * quantity);

            /* if(!this.modalItem || this.modalItem.quantity <= 0) return;
            if(isNaN(this.modalItem.quantity)) return this.$set(this.modalItem, 'total_price', '');
            this.$set(this.modalItem, 'total_price', parseFloat((val*this.modalItem.quantity).toFixed(2)) ); */
        },
        TotalPriceChanged(totalPrice, modal){
            var quantity = parseFloat(modal['quantity']);
            if(isNaN(quantity) || quantity == 0) return;
            totalPrice = parseFloat(totalPrice);
            if(isNaN(totalPrice)) return this.$set(modal, 'price', '');
            this.$set(modal, 'price', totalPrice / quantity);

            /* if(!this.modalItem || this.modalItem.quantity <= 0) return;
            if(isNaN(this.modalItem.quantity)) return this.$set(this.modalItem, 'price', '');
            var cost = val / this.modalItem.quantity ;
            this.$set(this.modalItem, 'price', parseFloat(cost.toFixed(2))); */
        },
        generateFacturaElectronica(factura = null){
            const actecosRegex = /^\d{6}( - .+)?$/;

            let facturaData = factura? factura: this.modalFactura;

            if(!facturaData.actecos) return this.Info('La actividad económica es obligatoria');
            if(!actecosRegex.test(facturaData.actecos)) return this.Info('La actividad económica es inválida');
            if(!facturaData.receiver) return this.Info('El receptor es obligatorio');
            if(!factura && !facturaData.detail.length) return this.Info('La factura debe contener ítems');

            let details = this.calculateInvoiceDetail();
            let acteco = facturaData.actecos.split(' - ')[0];

            if(!factura) this.$set(facturaData, 'detailsItems', details);
            this.$set(facturaData, 'actecos', acteco);
            if(this.order) this.$set(facturaData, 'order_id', this.order.id);

            this.$set(facturaData.receiver, 'address', facturaData.receiver_address.address);
            this.$set(facturaData.receiver, 'city', facturaData.receiver_address.city);
            this.$set(facturaData.receiver, 'commune', facturaData.receiver_address.commune);
            this.$set(facturaData.receiver, 'email', facturaData.receiver_address.email);

            this.Loading();
            axios.post(`/sii/factura/${this.Local.id}/create`, facturaData)
            .then( ({data}) => {
                if(!data.status) return this.Info(data.message);
                this.$emit("generateFactura", data.transaction);
                facturaData = null;
                this.OpenmodalSendDte(data.factura);
                return this.Info(data.message);
            })
            .catch( err => {
                this.Response(err);
            });
        },
        generateNotaCredito(){
            const actecosRegex = /^\d{6}( - .+)?$/;
            if(!this.modalFactura.actecos) return this.Info('La actividad económica es obligatoria');
            if(!actecosRegex.test(this.modalFactura.actecos)) return this.Info('La actividad económica es inválida');
            if(!this.modalFactura.detail.length) return this.Info(`La ${this.type == 33? 'factura' : 'nota de crédito'} debe contener ítems`);
            if(this.modalFactura.typeDocument == 33 && !this.modalFactura.receiver) return this.Info('El receptor es obligatorio');
            if(!this.modalFactura.typeDocument) return this.Info(`Es necesario el tipo de documento de referencia`);
            if(!this.modalFactura.folio_reference ) return this.Info(`Es necesario el folio de referencia`);
            if(!this.modalFactura.emisionDate_reference) return this.Info(`Es necesaria la fecha de referencia`);

            this.Loading();

            let details = this.calculateInvoiceDetail();
            let acteco = this.modalFactura.actecos.split(' - ')[0];

            this.$set(this.modalFactura, 'detailsItems', details);
            this.$set(this.modalFactura, 'actecos', acteco);
            if(this.order) this.$set(this.modalFactura, 'order_id', this.order.id);
            if(this.modalFactura.without_receiver) this.$set(this.modalFactura, 'receiver', null);

            if(this.modalFactura.receiver){
                this.$set(this.modalFactura.receiver, 'address', this.modalFactura.receiver_address.address);
                this.$set(this.modalFactura.receiver, 'city', this.modalFactura.receiver_address.city);
                this.$set(this.modalFactura.receiver, 'commune', this.modalFactura.receiver_address.commune);
                this.$set(this.modalFactura.receiver, 'email', this.modalFactura.receiver_address.email);
            }

            axios.post(`/sii/nota-credito/${this.Local.id}/create`, this.modalFactura)
            .then( ({data}) => {
                if(!data.status) return this.Info(data.message);
                this.$emit("generateFactura", data.transaction);
                this.modalFactura = null;
                this.OpenmodalSendDte(data.nota_credito, data.nota_credito.type);
                return this.Info(data.message);
            })
            .catch( err => {
                this.Response(err);
            });
        },
        calculateInvoiceDetail(){
            if(!this.modalFactura) return;
            if(!this.modalFactura.detail.length) return;

            let items = [];
            let mntNeto = 0;
            let taxes = 0;
            let discount = 0;
            let iva = 0;

            for(let i = 0; i < this.modalFactura.detail.length; i++){
                let item = this.modalFactura.detail[i];
                if(item.quantity <= 0 || item.price <= 0) return;
                let itemQuantity = item.quantity;
                let itemPrice = item.price;
                let itemDescription = item.description;
                let itemTotal = item.quantity * item.price;

                //DESCUENTO
                let itemDiscount = 0;
                let itemDiscountPercentage = isNaN(item.discount) ? 0 : item.discount;
                if(itemDiscountPercentage >= 0){
                    itemDiscount = (itemDiscountPercentage / 100) * itemTotal;
                }else{
                    itemDiscount = -itemDiscountPercentage;
                    itemDiscountPercentage = (itemDiscount * 100) / itemTotal;
                }
                discount += itemDiscount;

                let itemNeto = itemTotal - itemDiscount;
                mntNeto += itemNeto;

                //IMPUESTO ADICIONAL
                let itemTax = 0;
                let itemTaxCode = item.tax? item.tax.toString().split("-")[0] : null;
                let itemTaxPercentage = item.tax? item.tax.toString().split("-")[1] : 0;
                itemTax = Math.round((itemTaxPercentage / 100) * itemNeto);
                taxes += itemTax;

                let ivaItem = item.affectIva ? Math.round(itemNeto * 0.19) : 0;

                let entryTotal =  itemNeto + itemTax + ivaItem;

                iva += ivaItem;
                if(entryTotal < 0) return null;
                items.push({
                    item: item.item,
                    quantity: itemQuantity,
                    price: itemPrice,
                    description: itemDescription,
                    tax: itemTax,
                    taxPercentage: itemTaxPercentage,
                    taxCode: itemTaxCode,
                    discount: itemDiscount,
                    discountPercentage: itemDiscountPercentage,
                    mntNeto: itemNeto,
                    iva: ivaItem,
                    affection: item.affectIva,
                    total: entryTotal,
                    unitCost: this.Util.RoundFloat(entryTotal / itemQuantity),
                    unit: this.modalFactura.detail[item.item] && this.modalFactura.detail[item.item].unit ? this.modalFactura.detail[item.item].unit : null
                });
            }
            
            return {
                items: items,
                total: {
                    mntNeto,
                    iva,
                    taxes,
                    discount,
                    total: mntNeto + taxes + iva
                },
            };
        },
        importInventory(orders){
            orders.forEach( item => {
                if(item.price >= 0){ //ítems normales
                    this.modalItem = {};
                    let unidadXitem = item.total_money / 1.19;
                    this.$set(this.modalItem, 'item', item.title);
                    this.$set(this.modalItem, 'description', item.comment);
                    this.$set(this.modalItem, 'quantity', item.quantity);
                    this.$set(this.modalItem, 'price', unidadXitem/item.quantity);
                    this.$set(this.modalItem, 'total_price', unidadXitem);
                    this.$set(this.modalItem, 'ivaAmount', unidadXitem*0.19);
                    this.$set(this.modalItem, 'netoAmount', unidadXitem);
                    this.$set(this.modalItem, 'affectIva', true);
                    this.$set(this.modalItem, 'total', item.total_money);
                    this.AddInventoryItemToInvoice();
                    this.modalItem = null;
                }else{ //cupones o descuentos
                    if(this.modalFactura) this.modalFactura.discount += -item.price;
                }
                
            });
        },
        OpenmodalSendDte(data, type = 33){
            const dteType = type == 33? 'factura' : 'nota_credito';
            //Data proveniente de transacción 
            let idDte = null;
            let folio = null;
            let total = null;
            let rutReceiver = null;
            let date = null;
            let email = null;
            let printData = null;
            if(data.custom_data){
                let dteData = data.custom_data[dteType];
                idDte = data['id_'+dteType];
                folio = dteData.folio;
                total = parseInt(dteData.total.replace(".", ""));
                rutReceiver = dteData.receptor && dteData.receptor.RUTRecep? dteData.receptor.RUTRecep : (dteData.rutReceptor? dteData.rutReceptor.replace(/\./g,'') : '');
                date = dteData.fecha_corta;
                email = dteData.receptor && dteData.receptor.CorreoRecep? dteData.receptor.CorreoRecep : '';
                printData = dteData;
            //Data proveniente de factura 
            }else{
                idDte = data.id;
                folio = data.folio;
                total = data.total;
                rutReceiver = data.rut_receiver;
                date = data.date;
                email = data.print_data.receptor && data.print_data.receptor.CorreoRecep? data.print_data.receptor.CorreoRecep : '';
                printData = data.print_data;
            }

            this.modalSendDte = {idDte, folio, total, rutReceiver, date, email, type, printData};
        },
        SendFacturaEmail(){
            if(!this.modalSendDte.email || this.modalSendDte.email == "") return this.Info('El campo email es obligatorio.');
            this.Loading();
			axios.post(`/sii/dte/send`, this.modalSendDte).then( ({data}) => {
                this.Info(`${this.Util.DTE[this.modalSendDte.type]} enviada a ${this.modalSendDte.email}`);
                return this.modalSendDte = null;
			}).catch(err => {
				this.Response(err);
			});
        },
        DownloadDte(cedible){
            let dataDte = this.modalSendDte;
            Util.Open(`${this.Util.backend}admin/sii/dte/${dataDte.folio}/${dataDte.type}/${dataDte.total}/${dataDte.rutReceiver}/navigator/pdf/${cedible}`)
        },
        SelectItem(title, modal){
            let item = this.inventory.filter(function(obj) {
                return obj.title === title;
            })[0];
            
            if(!item || modal.oldItem == title) return;
            
            this.$set(this.modalItem, 'price', item.price/1.19);
        },
        addAddressReceiver(){
            if (!this.modalFacturaReceptor.multi_address) this.$set(this.modalFacturaReceptor, 'multi_address', []);

            this.modalFacturaReceptor.multi_address.push({ address: '', city: '', commune: '' });
        },
        PrintFactura(facturaData) {
            this.Util.NotifyService('factura', {data: facturaData, id: Math.random().toString()});
        }
    },
    computed: {
        inventorySelectItems() {
            var ret = [];
            for(var key in this.inventory)
            {
                var item = this.inventory[key];
                ret.push({
                    text: item.title + (item.unit ? ` (${item.unit})` : ''),
                    value: item.title
                });
            }
            return this.Util.NaturalSort(ret, 'text');
        },
        taxAdditional(){
            var ret = [];
            for(var key in this.taxAdditionalSII)
            {
                var item = this.taxAdditionalSII[key];
                ret.push({
                    text: `${item.name} ${item.tax_percentage}%`,
                    value: `${item.code}-${item.tax_percentage}`
                });
            }
            return this.Util.NaturalSort(ret, 'code');
        },
        itemResumen() {
            if(!this.modalItem) return {};
            if(!this.modalItem.total_price || isNaN(this.modalItem.total_price) || this.modalItem.total_price == 0) return {};
            let totalProduct = this.modalItem.total_price;

            let discountAmount = isNaN(this.modalItem.discount)? 0 : this.modalItem.discount;
            discountAmount = discountAmount >= 0? (discountAmount / 100) * totalProduct : -discountAmount;

            let netoAmount = totalProduct - discountAmount;

            let taxAmount = this.modalItem.tax? this.modalItem.tax.toString().split("-")[1] : 0;
            taxAmount = Math.round((taxAmount / 100) * (netoAmount));

            let ivaAmount = this.modalItem.affectIva? Math.round(netoAmount * 0.19) : 0;
            let total = ivaAmount + netoAmount + taxAmount;

            this.$set(this.modalItem, 'netoAmount', netoAmount);
            this.$set(this.modalItem, 'discountAmount', discountAmount);
            this.$set(this.modalItem, 'taxAmount', taxAmount);
            this.$set(this.modalItem, 'ivaAmount', ivaAmount);
            this.$set(this.modalItem, 'total', total);

            return {
                netoAmount,
                ivaAmount,
                total
            };
        },
        totalDte() {
            if(!this.modalFactura || !this.modalFactura.detail) return 0;
            let total = this.modalFactura.detail.reduce((accumulator, currentItem) => accumulator + currentItem.total, 0);
            if(!this.modalFactura.discount) return total;
            let totalWithDiscount = total - this.modalFactura.discount;
            return totalWithDiscount;
        }
    }
}
</script>