<template>
<tabs :tabs="['Perfil', 'Pagos']" :keyByTab="true" @OnTabPagos="UpdateChecks()">

    <!--
    <div class="opt r">
        <input-field v-model="search"
        inputClass="secondary"
        pre="search"
        cleareable
        placeholder="Buscar">
        </input-field>

        <input-select class="ml5" 
        inputClass="secondary"
        v-model="filter"
        :items="[{text: 'Nivel', value: 'LEVEL'},{text: 'Nombre', value: 'NAME'},{text: 'Fecha Creación', value: 'DATE'},{text: 'Plan', value: 'PLAN'}]"></input-select>
    </div>
    -->

    <div slot="Perfil" class="pa-body">
        
        <!--
        <div class="section mb20">
            <div class="flex align-center mb0">
                <div class="f18">Cartera de Clientes <span v-if="account">de {{account.name}}</span></div>
                
            </div>
            <div class="flex" style="flex-wrap: wrap">
                <div class="local-profile"
                @click="GoToLocal(local)"
                v-for="local in visibleLocals" :key="local.id"
                :title="`Plan ${Util.Number(local.plan_price)} UF\n${planStatus[local.plan_status]}`+(local.plan_status=='PAID'?`\nExpira el ${Util.Date(local.plan_expiration)}`:'')">
                    <div style="width: 60px; height: 60px;">
                        <div class="contain-img" :style="{'border-radius': '50%', 'background-image': img(`logos/${local.logotype}`, true)}"></div>
                    </div>
                    <div class="name">{{local.name}}</div>
                    
                </div>
            </div>
        </div>
        -->

        <!--
        <div class="hdvm">
            <div>
                <div class="section cw" style="background-color: rgb(248, 158, 37) !important">
                    <div class="flex align-center">
                        <div class="flex column flex-fill">
                            <div>Rendimiento UF/mes</div>
                            <div style="font-weight: bold; font-size: 24px">{{Util.Number(performance)}}</div>
                        </div>
                        <i style="font-size: 44px" class="icon icon-performance"></i>
                    </div>
                </div>
            </div>
            <div v-if="lastDirectSale">
                <div class="section cw" style="background-color: #6db614 !important">
                    <div class="flex align-center">
                        <div class="flex column flex-fill">
                            <div>Actualmente llevas la</div>
                            <div style="font-weight: bold; font-size: 24px">Batuta</div>
                        </div>
                        <i style="font-size: 44px" class="icon icon-olympic-torch"></i>
                    </div>
                </div>
            </div>
            -->
            <!--
            <div>
                <div class="section cw selectable" style="background-color: rgb(124, 104, 200) !important" @click="credits >= wheelCost ? SpinWheel() : null">
                    <div class="flex align-center">
                        <div class="flex column flex-fill">
                            <div>Creditos Influye</div>
                            <div style="font-weight: bold; font-size: 24px">{{Util.Number(credits)}}</div>
                        </div>
                        <i style="font-size: 44px" class="icon icon-poker"></i>
                    </div>
                </div>
            </div>
            -->
        <!--</div>-->

        <div class="hdvm mb20">
            <div>
                <div class="h100 section flex align-center">
                    <avatar class="mr10"
                    icon="money"
                    :color="Util.baseColors.purple">
                    </avatar>
                    <div class="flex-fill">
                        <div class="bold">Total</div>
                        <div class="secondary-text">{{currentMonth.clients}} clientes</div>
                    </div>
                    <div class="f18 cpurple">{{Util.Number(currentMonth.total_uf)}} <span class="secondary-text">UF</span></div>
                </div>
            </div>
            <div>
                <div class="h100 section flex align-center">
                    <avatar class="mr10"
                    icon="calendar"
                    :color="Util.baseColors.red">
                    </avatar>
                    <div class="flex-fill flex column">
                        <div class="flex align-center">
                            <div class="bold flex-fill">Anuales</div>
                            <div class="f18 cred">{{Util.Number(currentMonth.annual_uf)}} <span class="secondary-text">UF</span></div>
                        </div>
                         <div class="flex align-center">
                            <div class="bold flex-fill">Semestrales</div>
                            <div class="f18 cyellow">{{Util.Number(currentMonth.biannual_uf)}} <span class="secondary-text">UF</span></div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div>
                <div class="h100 section flex align-center" v-tooltip="'Promedio de ventas totales de los últimos 4 meses'">
                    <avatar class="mr10"
                    icon="arrow-ne"
                    :color="Util.baseColors.green">
                    </avatar>
                    <div class="flex-fill">
                        <div class="bold">Rendimiento</div>
                    </div>
                    <div class="f18 cgreen">{{Util.Number(performance)}} <span class="secondary-text">UF</span></div>
                </div>
            </div>
        </div>

        <div class="hdvm mb20" style="align-items: stretch">
            <div class="flex-fill">
                <div class="section h100">
                    <div class="flex align-center mb20">
                        <div class="f18 flex-fill">
                            <div>Ventas</div>
                            <!--<div class="secondary-text">Rendimiento {{Util.Number(performance)}} UF/mes</div>-->
                        </div>
                        <!--<div class="sale-tag" v-if="history.length" title="Este mes">{{Util.Number(history[history.length-1].sum)}} UF</div>-->
                    </div>
                    <chart
                    type="line"
                    :data="[history]"
                    dataset="sum"
                    :label="(data) => {return Util.Date(data.start, 'MMMM')}"
                    :yLabelFormat="(value) => {return `${value} UF`}"
                    :tooltip="(value, label, index) => {return `${value} UF`}">
                    </chart>
                </div>
            </div>
            <div class="flex-fill">
                <div class="section h100">
                    <div class="flex align-center mb20">
                        <div class="f18 flex-fill">Suscripciones</div>
                        <!--<div class="sale-tag" v-if="history.length" title="Este mes">{{Util.Number(history[history.length-1].clients)}} clientes</div>-->
                    </div>
                    <chart
                    type="line"
                    :data="[history, history, history]"
                    :dataset="['clients', 'annual', 'biannual']"
                    :singleDataset="false"
                    :showLegend="true"
                    :legends="['Clientes', 'Anuales', 'Semestrales']"
                    :label="(data) => {return Util.Date(data.start, 'MMMM')}"
                    :yLabelFormat="(value) => {return `${value}`}"
                    :tooltip="(value, label, index, datasetIndex, legend) => {return `${value} ${legend}`}">
                    </chart>
                </div>
            </div>
            <!--
            <div :style="{width: mobile ? '100%' : '32%'}">
                <div class="section">
                    <div class="flex">
                        <div class="bold flex-fill">Rendimiento</div>
                        <div>5 UF</div>
                    </div>
                    <chart
                    type="line"
                    :data="[history]"
                    dataset="performance"
                    :label="(data) => {return data.month}"
                    :yLabelFormat="(value) => {return value}"
                    :tooltip="(value, label, index) => {return `${value}`}">
                    </chart>
                </div>
            </div>
            -->
        </div>
        
        <div class="section" v-if="check">
            <div class="flex align-center mb20">
                <div class="flex-fill"><span class="f18">Ganancia mes {{Util.Date(timestamp, 'MMMM')}}</span> <span class="secondary-text ml5">(en curso)</span></div>
                <div class="sale-tag" title="Valor actual de la UF">Valor UF ${{Util.Number(check.uf_value)}}</div>
            </div>
            <table class="summary mt10">
                <tr>
                    <th></th>
                    <th></th>
                </tr>
                <tr v-for="(entry, index) in check.check" :key="index">
                    <td>{{entry.title}} <span v-if="entry.info" class="secondary-text">({{entry.info}})</span></td>
                    <td style="text-align: right">{{Util.Price(entry.total)}}</td>
                </tr>
                <tr class="f18">
                    <td>Total</td>
                    <td style="text-align: right">{{Util.Price(check.total)}}</td>
                </tr>
            </table>
        </div>

        <!--
        <div class="section">
            <div class="bold">Premios</div>
            <div class="secondary-text mt10 ta-center" v-if="!influyeItems.length">No tienes premios, usa tus creditos Influye para girar la ruleta y probar suerte!</div>
            <div class="flex" style="flex-wrap: wrap">
                <div class="local-profile"
                v-for="item in influyeItems" :key="item.id">
                    <div class="name">{{item.data.description}}</div>
                    <div class="name" style="margin-bottom: 5px; height: auto">{{Util.Date(item.created_at)}}</div>
                </div>
            </div>
        </div>
        -->

    </div>

    <div slot="Pagos" class="pa-body">
        <div class="section mb10" v-for="(check, index) in checks" :key="index">
            <div class="flex align-center">
                <div class="bold flex-fill">Ganancia mes {{Util.Date(check.start, 'MMMM')}}</div>
                <div class="sale-tag" title="Valor actual de la UF">Valor UF ${{Util.Number(check.uf_value)}}</div>
            </div>
            <table class="summary mt10">
                <tr>
                    <th></th>
                    <th></th>
                </tr>
                <tr v-for="(entry, index) in check.check" :key="index">
                    <td>{{entry.title}} <span v-if="entry.info" class="secondary-text">({{entry.info}})</span></td>
                    <td style="text-align: right">{{Util.Price(entry.total)}}</td>
                </tr>
                <tr class="f18">
                    <td>Total</td>
                    <td style="text-align: right">{{Util.Price(check.total)}}</td>
                </tr>
            </table>
        </div>
    </div>

    <modal v-model="modalSlot" size="200px" :closeable="modalSlot && modalSlot.state !== 'LOADING'">
        <div v-if="modalSlot" class="flex column justify-center align-center" style="width: 200px; height: 200px">
            <i v-if="modalSlot.state=='LOADING'" style="font-size: 100px; line-height: 0" class="icon icon-poker spin"></i>
            <div v-if="modalSlot.state=='WIN'" class="flex column justify-center align-center">
                <i style="font-size: 80px;" class="icon icon-confetti animated pulse infinite superfast selected-color bw"></i>
                <div class="bold">¡Has ganado!</div>
                <div class="mt10">{{modalSlot.prize}}</div>
            </div>
            <div v-if="modalSlot.state=='LOOSE'" class="flex column justify-center align-center">
                <i style="font-size: 80px;" class="icon icon-sad error-color bw"></i>
                <div class="bold">Oops! Sigue participando</div>
            </div>
        </div>
    </modal>

</tabs>
</template>

<script>
var moment = require('moment');
const confetti = require('canvas-confetti').default;
export default {
    data() {
        return {
            locals: [],
            history: [],
            performance: 0,
            credits: 0,
            level: 0,
            timestamp: 0,
            account: null,
            check: null,
            search: '',
            filter: 'LEVEL',
            planStatus: {
                PAID: 'Pagado',
                FREE: 'Gratis',
                UNPAID: 'Expirado'
            },
            modalSlot: null,
            influyeItems: [],
            wheelCost: 99999,
            lastDirectSale: false,
            checks: [],
            currentMonth: null
        }
    },
    mounted() {
        var id = this.$route.params.id;
        if(!id)
        {
            if(!this.Auth.is_salesman) return this.Info('No deberias estar aquí');
            id = this.Auth.id;
        }

        this.Loading();
        axios.get(`/influye/salesman/${id}/profile`).then(res => {
            this.Response(res);
            console.log(res.data);
            this.history = res.data.history;
            this.performance = res.data.performance;
            this.locals = res.data.locals;
            this.credits = res.data.credits;
            this.level = res.data.level;
            this.timestamp = res.data.timestamp;
            this.account = res.data.account;
            this.check = res.data.check;
            this.influyeItems = res.data.items;
            this.wheelCost = res.data.wheel_cost;
            this.lastDirectSale = res.data.last_direct_sale;
            this.currentMonth = res.data.current;
        }).catch(err => {  
            this.Response(err);
        });
    },
    methods: {
        GoToLocal(local) {
            this.Loading();
            axios.get(`/relogin/${local.id}`).then(res => {
                this.Response(res);
                this.$store.commit('setLocal', res.data);
                this.Util.SetServer(res.data.server, res.data.token);
                this.$router.push('/dashboard');
            }).catch(err => {
                this.Response(err);
            });
        },
        Confetti()
        {
            var count = 200;
            var defaults = {
            origin: { y: 0.7 }
            };
            function fire(particleRatio, opts) {
            confetti(Object.assign({}, defaults, opts, {
                particleCount: Math.floor(count * particleRatio)
            }));
            }

            fire(0.25, {
            spread: 26,
            startVelocity: 55,
            });
            fire(0.2, {
            spread: 60,
            });
            fire(0.35, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8
            });
            fire(0.1, {
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2
            });
            fire(0.1, {
            spread: 120,
            startVelocity: 45,
            });
        },
        UpdateChecks()
        {
            var id = this.$route.params.id;
            if(!id)
            {
                if(!this.Auth.is_salesman) return this.Info('No deberias estar aquí');
                id = this.Auth.id;
            }

            if(!this.checks.length)
            {
                this.Loading();
                axios.get(`/influye/salesman/${id}/checks`).then(res => {
                    this.Response(res);
                    this.checks = res.data;
                    console.log(res.data);
                }).catch(err => {
                    this.Response(err);
                });
            }
        }
    },
    computed: {
        visibleLocals() {
            var ret = [];
            this.locals.forEach(local => {
                if(!this.search || this.Util.InString(local.name, this.search)) ret.push(local);
            });

            switch(this.filter)
            {
                case 'LEVEL':
                    ret.sort((a, b) => {
                        return b.level - a.level;
                    });
                    break;
                case 'NAME':
                    return this.Util.NaturalSort(ret, 'name');
                case 'DATE': 
                    ret.sort((a, b) => {
                        return moment(b.created_at).unix() - moment(a.created_at).unix();
                    });
                    break;
                case 'PLAN':
                    var PlanValue = function(local) {
                        if(local.plan_status == 'FREE') return parseInt(moment(local.created_at).unix()/1000000);
                        else if(local.plan_status == 'UNPAID') return local.plan_expiration/1000;
                        else return local.plan_expiration * 1000;
                        
                    }
                    ret.sort((a, b) => {
                        return PlanValue(b) - PlanValue(a);
                    });
                break;
            }

            return ret.slice(0, 20);
        }
    }
}
</script>
<style>
.local-profile {
    width: 80px;
    background-color: var(--gray-3);
    border-radius: 20px;
    margin: 15px 10px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    font-size: 12px;
    position: relative;
    cursor: pointer;
    transition: all 0.1s linear;
}
.local-profile:hover {
    outline: 2px solid var(--purple);
}

.local-profile .name {
    font-size: 10px;
    line-height: 1;
    margin: 5px 0px 20px 0px;
    text-align: center;
    height: 30px;
}
.local-profile .level {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    bottom: -10px;
    background-color: #6491d7;
    border-radius: 50%;
    color: var(--white);
    font-size: 16px;
    font-weight: bold;
}
.purple-tag {
    padding: 5px 10px;
    border-radius: 20px;
    background-color: #6491d7;
    color: var(--white);
}
</style>