<template>
    <div class="fill flex column flex-fill">
        <div class="opt r">
            <input-range inputClass="secondary" v-show="tab != 'Receptores'" class="ml5" v-model="search"
                :icon="mobile ? 'calendar' : ''" @OnValueChanged="LoadDtes(true)">
            </input-range>

            <input-field v-if="tab == 'Receptores'" slot="options"
            inputClass="secondary"
            v-model="searchReceiver"
            placeholder="Buscar..."
            pre="search"
            cleareable>
            </input-field>

            <input-select v-if="tab == 'Facturas Electrónicas'"
            class="ml5" inputClass="secondary" icon="sort"
            v-model="sortFacturas"
            slot="options"
            :items="[
                {text: 'Ordenar por número', value: 'folio,desc'},
                {text: 'Ordenar por emisión', value: 'issue_at,desc'},
            ]">
            </input-select>
            
            <i class="options icon-dots-vertical ml5" @click="Options([
                { text: 'Emitir Factura', class: 'selected-color', function: () => { $refs.modalFacturaElectronica.Open(33) } },
                { text: 'Buscar Factura', function: () => {modalFacturaSearch={number: 0}}},
            ])" v-show="tab == 'Facturas Electrónicas'"></i>

            <i class="options icon-dots-vertical ml5" @click="Options([
                { text: 'Emitir Nota de Crédito', class: 'selected-color', function: () => { $refs.modalFacturaElectronica.Open(61) } },
            ])" v-show="tab == 'Notas de Crédito'"></i>

            <i class="options icon-dots-vertical ml5" @click="Options([
                { text: 'Crear receptor', class: 'selected-color', function: () => { $refs.modalFacturaElectronica.OpenModalReceiver() } },
            ])" v-show="tab == 'Receptores'"></i>
        </div>

        <tabs v-model="tab" v-if="tabs" :tabs="tabs" :keyByTab="true" @OnTabReceptores="getReceivers(true)"
        :tabCount="tabCount" tabCountOnSelectedOnly>
            <vertical-content class="pa-body" slot="Facturas Electrónicas" ref="verticalContentFacturas" @OnBottom="LoadDtes()" :list="facturas">
                <div class="secondary-text ta-center mt10" v-if="!facturas || !facturas.length">No hay registros de facturas electrónicas.</div>
                <div v-else>
                    <div class="section mb10 selectable" v-for="(factura, index) in facturas" :key="index"
                        @click="modalFacturaDetail = factura">
                        <div class="flex fill align-center">
                            <avatar class="mr10"
                            defaultImageIcon="111"
                            :color="AvatarColor(Util.FacturaStatus[factura.status].color)">
                            </avatar>
                            <div class="flex column flex-fill">
                                <div class="bold"><span class="bold">Factura N°</span>{{ factura.folio }}</div>
                                <div class="secondary-text" v-if="factura.issue_at">
                                    <span class="bold">Fecha de emisión: </span>{{ Util.Date(factura.issue_at, 'DD/MM/YYYY') }}
                                </div>
                                <div class="secondary-text">
                                    <span :class="`bold ${Util.FacturaStatus[factura.resend_status? factura.resend_status : factura.status].color}`">Estado: </span>
                                    <span :class="`${factura.resend_status? 'line-through' : ''} ${Util.FacturaStatus[factura.status].color}`">{{ Util.FacturaStatus[factura.status].label }}</span>
                                    <span v-if="factura.resend_status" class="ml5"> 
                                        <i class="icon icon-arrow-right mr5"></i>
                                        <span :class="`${Util.FacturaStatus[factura.resend_status].color}`">
                                            {{ Util.FacturaStatus[factura.resend_status].label }}
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div class="bold" v-if="factura.total">
                                {{ Util.Price(factura.total) }}</div>
                            <div class="options icon-dots-vertical ml10" @click.stop="Options([
                                { text: 'Obtener estado avanzado', class: 'csupport', icon: 'lifebuoy', if: Auth.role == 'admin', function: () => { GetAdvancedStatus(factura.id, factura.type) } },
                                { text: 'Obtener registro', class: 'csupport', icon: 'lifebuoy', if: Auth.role == 'admin', function: () => { GetSqlDte(factura.id, factura.type) } },
                                { text: 'Cancelar factura electrónica', class: 'error-color', if: factura.status == 'OK', function: () => { CancelFactura(factura.id) } },
                                { text: 'Reenviar factura electrónica', if: factura.status == 'ERROR' || factura.status == 'FAILED', confirm: '¿Desea reenviar la factura electrónica? Esta acción consumirá folios', function: () => { ResendFactura(factura) } },
                                { text: 'Imprimir', function: () => PrintFactura(factura)},
                                { text: 'Descargar PDF Tributario', function: () => { getDtePDF(33, factura.id, factura.folio) } },
                                { text: 'Descargar PDF Cedible', function: () => { getDtePDF(33, factura.id, factura.folio, true) } },
                                { text: 'Enviar factura electrónica', class: 'selected-color', function: () => { $refs.modalFacturaElectronica.OpenmodalSendDte(factura) } },
                            ])"></div>
                        </div>
                    </div>
                </div>
            </vertical-content>
            <vertical-content class="pa-body" slot="Notas de Crédito" ref="verticalContentNotasCredito" @OnBottom="BottomNotasCredito()" :list="notasCredito">
                <div>
                    <div class="secondary-text ta-center mt10" v-if="!notasCredito || !notasCredito.length">No hay registro de notas de crédito.</div>
                    <div v-else>
                        <div class="section mb10 selectable" v-for="(notaCredito, index) in notasCredito" :key="index" @click="modalFacturaDetail = notaCredito">
                            <div class="flex fill align-center">
                                <avatar class="mr10"
                                defaultImageIcon="130"
                                :color="AvatarColor(Util.FacturaStatus[notaCredito.status].color)">
                                </avatar>
                                <div class="flex column flex-fill">
                                    <div class="bold"><span class="bold">Nota de Crédito N°</span>{{ notaCredito.folio }}</div>
                                    <div class="secondary-text" v-if="notaCredito.send_at">
                                        <span class="bold">Fecha de emisión: </span>{{ Util.Date(notaCredito.send_at, 'DD/MM/YYYY') }}
                                    </div>
                                    <div :class="`secondary-text ${Util.FacturaStatus[notaCredito.status].color}`"><span class="bold">Estado:
                                        </span>{{ Util.FacturaStatus[notaCredito.status].label }}</div>
                                </div>
                                <div class="bold" v-if="notaCredito.total && notaCredito.total">
                                    {{ Util.Price(notaCredito.total) }}</div>
                                <div class="options icon-dots-vertical ml10" @click.stop="Options([
                                    // { text: 'Cancelar nota de crédito electrónica', class: 'error-color', if: notaCredito.status == 'OK', function: () => { cancelNC(notaCredito.id) } },
                                    { text: 'Descargar PDF', function: () => { getDtePDF(61, notaCredito.id, notaCredito.folio) } },
                                    { text: 'Enviar Nota de Crédito Electrónica', class: 'selected-color', function: () => { $refs.modalFacturaElectronica.OpenmodalSendDte(notaCredito, notaCredito.type) } },
                                    { text: 'Obtener estado avanzado', class: 'csupport', icon: 'lifebuoy', if: Auth.role == 'admin' && notaCredito.status != 'OK', function: () => { GetAdvancedStatus(notaCredito.id, notaCredito.type) } },
                                    { text: 'Obtener registro', class: 'csupport', icon: 'lifebuoy', if: Auth.role == 'admin' && notaCredito.status != 'OK', function: () => { GetSqlDte(notaCredito.id, notaCredito.type) } }
                                ])"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </vertical-content>
            <vertical-content class="pa-body" slot="Receptores" ref="verticalContentReceivers" @OnBottom="BottomReceiver()"
                :list="receivers">
                <div>
                    <div class="secondary-text ta-center mt10" v-if="!filteredReceivers.length">No hay datos.</div>
                    <div v-else>
                        <div class="section mb10 selectable" v-for="(receiver, index) in filteredReceivers" :key="index"
                            @click="modalReceiverDetail = {receiver , facturas : [], tabs : ['General', 'Facturas']}">
                            <div class="flex fill align-center">
                                <avatar class="mr10"
                                :label="receiver.name"
                                color="AUTO">
                                </avatar>
                                <div class="flex column flex-fill">
                                    <div class="bold">{{ receiver.name }}</div>
                                    <div class="secondary-text" v-show="receiver.alias">{{ receiver.alias }}</div>
                                </div>
                                <div class="bold">
                                    {{ receiver.rut }}
                                </div>
                                <div class="options icon-dots-vertical ml10" @click.stop="Options([
                                    {
                                        text: 'Borrar', class: 'error-color', function: () => {
                                            Confirm({
                                                title: `¿Seguro de borrar este receptor?`,
                                                text: 'Confirmar',
                                                class: 'selected-color',
                                                function: () => { $refs.modalFacturaElectronica.deleteReceiver(receiver, false) }
                                            })
                                        }
                                    },
                                    { text: 'Editar', function: () => { $refs.modalFacturaElectronica.OpenModalReceiver(Util.Copy(receiver)) } },
                                    { text: 'Emitir factura', function: () => { $refs.modalFacturaElectronica.Open(33, Util.Copy(receiver)) } },
                                ])"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </vertical-content>
        </tabs>

        <modal v-model="modalFacturaDetail" dir="right" :modalClass="['fill', 'lg right h100']" 
        :title="`Detalle de ${modalFacturaDetail && modalFacturaDetail.type != 33? 'Nota de Crédito' : 'Factura'}`"
        hideActions>
            <i class="options icon-dots-vertical ml5" slot="options" @click="Options([
                { text: 'Imprimir', if: modalFacturaDetail.type == 33, function: () => PrintFactura(modalFacturaDetail)},
                { text: `Descargar PDF${modalFacturaDetail.type == 33? ' Tributario' :''}`, function: () => { getDtePDF(modalFacturaDetail.type, modalFacturaDetail.id, modalFacturaDetail.folio) } },
                { text: 'Descargar PDF Cedible', if: modalFacturaDetail.type == 33 , function: () => { getDtePDF(modalFacturaDetail.type, modalFacturaDetail.id, modalFacturaDetail.folio, true) } },
                { text: `Enviar ${modalFacturaDetail.type == 33? 'Factura Electrónica' : 'Nota de Crédito'}`, class: 'selected-color', function: () => { $refs.modalFacturaElectronica.OpenmodalSendDte(modalFacturaDetail, modalFacturaDetail.type) } }
            ])"></i>

            <div v-if="modalFacturaDetail" class="pa20">
                <div>
                    <div class="f18 mb20">Información</div>

                    <div class="mb15">
                        <div class="bold">Número</div>
                        <div>{{modalFacturaDetail.type != 33? 'Nota de Crédito' : 'Factura'}} N°{{ modalFacturaDetail.folio }}</div>
                    </div>

                    <div class="mb15">
                        <div class="bold">Fecha de creación</div>
                        <div>{{ Util.Date(modalFacturaDetail.created_at, 'LL') }}</div>
                    </div>

                    <div class="mb15">
                        <div class="bold">Fecha de emisión</div>
                        <div v-if="modalFacturaDetail.type == 33">{{ Util.Date(modalFacturaDetail.issue_at, 'LL') }}</div>
                        <div v-else>{{ Util.Date(modalFacturaDetail.send_at, 'LL') }}</div>
                    </div>

                    <div :class="`mb15 ${Util.FacturaStatus[modalFacturaDetail.status].color}`">
                        <div class="bold">Estado</div>
                        <div>{{ Util.FacturaStatus[modalFacturaDetail.status].label }}</div>
                    </div>
                </div>

                <div>
                    <div class="separator mb20 mt20"></div>

                    <div class="f18 mb20">Emisor</div>

                    <div class="mb15">
                        <div class="bold">RUT</div>
                        <div>{{ modalFacturaDetail.print_data.emisor.RUTEmisor }}</div>
                    </div>

                    <div class="mb15">
                        <div class="bold">Razón social</div>
                        <div>{{ modalFacturaDetail.print_data.emisor.RznSoc }}</div>
                    </div>
                </div>

                <div v-if="modalFacturaDetail.print_data.receptor">
                    <div class="separator mb20 mt20"></div>

                    <div class="f18 mb20">Receptor</div>

                    <div class="mb15">
                        <div class="bold">RUT</div>
                        <div>{{ modalFacturaDetail.print_data.receptor.RUTRecep }}</div>
                    </div>

                    <div class="mb15">
                        <div class="bold">Razón social</div>
                        <div>{{ modalFacturaDetail.print_data.receptor.RznSocRecep }}</div>
                    </div>
                </div>

                <div>
                    <div class="separator mb20 mt20"></div>
                    <div class="f18 mb20">Items</div>
                    <div class="well mb10 flex column" v-for="(item, index) in modalFacturaDetail.print_data.items"
                        :key="index">
                        <div class="bold">{{ item.item }}</div>
                        <div class="bold secondary-text mt10 mb10" v-if="item.description">{{ item.description }}</div>
                        <div class="flex">
                            <div class="w20">
                                <div class="secondary-text">Cantidad</div>
                                <div>{{ Util.Number(item.quantity) }} <span class="secondary-text" v-if="item.unit">{{
                                    item.unit }}</span></div>
                            </div>
                            <div class="w20">
                                <div class="secondary-text">Precio</div>
                                <div>${{ item.price }}</div>
                                <div class="secondary-text">Subtotal</div>
                                <div>${{ item.subtotal? item.subtotal : item.quantity * item.price}}</div>
                            </div>
                            <div class="w20">
                                <div class="secondary-text">Impuesto adicional</div>
                                <div v-if="!item.tax || item.tax == 0">-</div>
                                <div v-else>${{ item.tax }}</div>

                                <div class="secondary-text">IVA</div>
                                <div v-if="!item.iva">-</div>
                                <div v-else>${{ item.iva }}</div>
                            </div>
                            <div class="w20">
                                <div class="secondary-text">Descuento</div>
                                <div v-if="!item.discount || item.discount == 0">-</div>
                                <div v-else>${{ item.discount }}</div>

                                <div class="secondary-text">Mnt neto</div>
                                <div v-if="!item.neto || item.neto == 0">-</div>
                                <div v-else>${{ item.neto }}</div>
                            </div>
                            <div class="w20 ta-right">
                                <div class="secondary-text">Total</div>
                                <div class="bold">${{ item.total ? item.total : modalFacturaDetail.print_data.total}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="separator mb20 mt20"></div>

                    <div class="f18 mb20">Total</div>

                    <div class="mb15">
                        <div class="bold">Neto</div>
                        <div>${{ modalFacturaDetail.print_data.neto }}</div>
                    </div>

                    <div class="mb15">
                        <div class="bold">IVA 19%</div>
                        <div>${{ modalFacturaDetail.print_data.iva }}</div>
                    </div>

                    <div class="mb15">
                        <div class="bold">Impuesto adicional</div>
                        <div>${{ modalFacturaDetail.print_data.impuestoAdicional? modalFacturaDetail.print_data.impuestoAdicional : 0 }}</div>
                    </div>

                    <div class="mb15">
                        <div class="bold">Descuentos</div>
                        <div>${{ modalFacturaDetail.print_data.descuento? modalFacturaDetail.print_data.descuento : 0 }}</div>
                    </div>

                    <div class="mb20">
                        <div class="bold">Total</div>
                        <div>{{ Util.Price(modalFacturaDetail.total) }}</div>
                    </div>

                    <div v-if="modalFacturaDetail.print_data.payment_method">
                        <div class="f18 mb15">Método de Pago</div>
                        <div>{{Util.PayMethod(modalFacturaDetail.print_data.payment_method)}}</div>
                    </div>
                </div>
            </div>
        </modal>

        <modal v-model="modalReceiverDetail" dir="right" :modalClass="['fill', 'sm right h100']" title="Detalles del receptor" hideActions>
            <input-date v-if="modalReceiverDetail && modalReceiverDetail.tab == 'Facturas'" slot="options" 
            icon="calendar" type="DATE" @OnAcceptDate="LoadFacturasReceiver($event, true)"></input-date>

            <input-select v-if="modalReceiverDetail && modalReceiverDetail.tab == 'Facturas'"
            class="ml5" inputClass="secondary" icon="sort"
            v-model="sortFacturasReceiver"
            slot="options"
            :items="[
                {text: 'Ordenar por número', value: 'folio,desc'},
                {text: 'Ordenar por emisión', value: 'issue_at,desc'},
            ]">
            </input-select>

            <tabs :tabs="modalReceiverDetail.tabs" v-if="modalReceiverDetail" :keyByTab="true" v-model="modalReceiverDetail.tab" @OnTabFacturas="LoadFacturasReceiver()">
                    <div slot="General" class="pa20" v-if="modalReceiverDetail.receiver">
                        <div class="mb15">
                            <div class="bold">Razón social</div>
                            <div>{{ modalReceiverDetail.receiver.name }} {{modalReceiverDetail.receiver.alias? `(${modalReceiverDetail.receiver.alias})` : ''}}</div>
                        </div>

                        <div class="mb15">
                            <div class="bold">RUT</div>
                            <div>{{ modalReceiverDetail.receiver.rut }}</div>
                        </div>

                        <div class="mb15">
                            <div class="bold">Giro del negocio</div>
                            <div>{{ modalReceiverDetail.receiver.line_business }}</div>
                        </div>

                        <div v-for="(data, index) in modalReceiverDetail.receiver.multi_address" :key="index" class="mb15 well">
                            <div class="mb10">
                                <div class="bold">Email</div>
                                <div>{{ data.email }}</div>
                            </div>

                            <div class="mb10">
                                <div class="bold">Dirección</div>
                                <div>{{ data.address }}</div>
                            </div>

                            <div class="mb10">
                                <div class="bold">Comuna</div>
                                <div>{{ data.commune }}</div>
                            </div>

                            <div class="mb10">
                                <div class="bold">Ciudad</div>
                                <div>{{ data.city }}</div>
                            </div>
                        </div>
                    </div>

                    <vertical-content slot="Facturas" ref="verticalReceiverFacturas"  class="pa20" v-if="modalReceiverDetail.facturas" @OnBottom="LoadFacturasReceiver()">
                        <div class="secondary-text ta-center mt10" v-if="!modalReceiverDetail.facturas.length">No hay facturas</div>
                        <div class="entry selectable"
                        v-for="(factura, index) in modalReceiverDetail.facturas"
                        :key="index"
                        @click="modalFacturaDetail = factura">
                            <div class="flex fill align-center">
                                <div class="flex column">
                                    <div class="bold">Factura N°{{factura.folio}}</div>
                                    <div class="secondary-text"><span class="bold">Fecha de emisión: </span>{{Util.Date(factura.issue_at, 'DD/MM/YYYY')}}</div>
                                </div>
                                <div class="flex-fill"></div>
                                <div class="flex column mr10" style="text-align: right">
                                    <div class="bold">{{Util.Price(factura.total)}}</div>
                                </div>
                            </div>
                        </div>
                    </vertical-content>
                </tabs>
        </modal>

        <modal v-model="modalFacturaSearch" dir="center" modalClass="sm" title="Buscar Factura">
            <div class="section" v-if="modalFacturaSearch">
                <input-field
                v-model="modalFacturaSearch.number"
                label="Número de Factura"
                type="int+"
                prefix="# "
                @OnEnter="SearchFactura(0)">
                </input-field>
            </div>
            
            <button slot="actions" class="primary purple" @click="SearchFactura(0)">Buscar</button>
            
        </modal>

        <modal v-model="modalDteStatus" dir="center" modalClass="sm" title="Estado Dte">
            <div class="pa20" v-if="modalDteStatus">
                <p>Recibido: {{ modalDteStatus.RECIBIDO }}</p>
                <p>Estado: {{ modalDteStatus.ESTADO }}</p>
                <p>Glosa: {{ modalDteStatus.GLOSA }}</p>
                <p>Número de atención: {{ modalDteStatus.NUMATENCION }}</p>
            </div>

            <button slot="actions" class="primary purple" @click="modalDteStatus = null">Cerrar</button>
        </modal>

        <modal-factura-electronica ref="modalFacturaElectronica" @generateFactura="LoadDtes(true)"
            @reloadReceiver="getReceivers(true)"></modal-factura-electronica>
    </div>
</template>
<script>
export default {
    data() {
        return {
            facturas: [],
            notasCredito: [],
            modalFacturaDetail: null,
            modalReceiverDetail: null,
            search: null,
            receivers: [],
            tab: null,
            tabs: null,
            searchReceiver: null,
            modalFacturaSearch: null,
            sortFacturas: 'issue_at,desc',
            sortFacturasReceiver: 'issue_at,desc',
            modalDteStatus: null,
        }
    },
    mounted() {
        this.tabs = [];
        if ((this.Local.modules.module_sii_factura && this.isAdmin) || this.Auth.role == 'cashier') this.tabs.push('Facturas Electrónicas');
        if (this.Local.modules.module_sii_factura && this.isAdmin) this.tabs.push('Notas de Crédito');
        if (this.Local.modules.module_sii_factura && this.isAdmin) this.tabs.push('Receptores');
    },
    methods: {
        LoadDtes(clear) {
            if(!this.sortFacturas) return;
            let parts = this.sortFacturas.split(',');

            if (clear) {
                this.facturas = [];
                this.notasCredito = [];
                this.$refs.verticalContentFacturas.Reset();
                if(this.$refs.verticalContentNotasCredito) this.$refs.verticalContentNotasCredito.Reset();
            }

            let range = this.search;

            this.Loading();
            axios.post(`/dte/${this.Local.id}/facturas?skip=${this.facturas.length}&orderBy=${parts[0]}&orderDirection=${parts[1]}`, {
                range,
            })
            .then(({ data }) => {
                this.facturas = this.facturas.concat(data.facturas);
                this.Response(data);
            }).catch(err => {
                this.Response(err);
            });

            axios.post(`/dte/${this.Local.id}/notas_credito`, { range })
            .then(({ data }) => {
                this.notasCredito = data.notas_credito;
                this.Response(data);
            }).catch(err => {
                this.Response(err);
            });
        },
        BottomFacturas() {
            if (!this.facturas.length) return;

            this.Loading();

            axios.post(`/dte/${this.Local.id}/facturas`, {
                last: this.facturas[this.facturas.length - 1].issue_at - 1
            })
            .then(({ data }) => {
                this.facturas = this.facturas.concat(data.facturas);
                this.Response(data);
            }).catch(err => {
                this.Response(err);
            });
        },
        BottomNotasCredito(){
            if (!this.notasCredito.length) return;

            this.Loading();

            axios.post(`/dte/${this.Local.id}/notas_credito`, {
                last: this.notasCredito[this.notasCredito.length - 1].send_at - 1
            })
            .then(({ data }) => {
                this.notasCredito = this.notasCredito.concat(data.notas_credito);
                this.Response(data);
            }).catch(err => {
                this.Response(err);
            });
        },
        BottomReceiver() {
            if (!this.receivers.length) return;

            this.Loading();

            axios.post(`/sii/receivers/${this.Local.id}`, {
                last: this.receivers[this.receivers.length - 1].created_at - 1,
                quantity: 10
            })
                .then(({ data }) => {
                    this.receivers = this.receivers.concat(data.receivers);
                    this.Response(data);
                }).catch(err => {
                    this.Response(err);
                });
        },
        CancelFactura(idFactura) {
            this.Loading();

            axios.post(`/sii/factura/${this.Local.id}/cancel`, {
                idFactura
            })
            .then(({ data }) => {
                this.LoadDtes();
                return this.Info(data.message);
            })
            .catch(err => {
                this.Response(err);
            });
        },
        getDtePDF(typeDte = 33, idDte, folio, cedible = 0) {
            this.Loading();
            axios.post(`/sii/dte/pdf`, {
                idDte,
                typeDte, 
                cedible,
            }, {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf'
                }
            })
            .then((res) => {
                if(typeDte == 61){
                    this.Util.DownloadFile(res, `nota_credito_${folio}.pdf`);
                    return this.Response(res);
                }

                this.Util.DownloadFile(res, `factura_electronica_${folio}_${cedible? 'cedible' : ' tributaria'}.pdf`);
                this.Response(res);
            })
            .catch(err => {
                this.Response(err);
            });
        },
        getReceivers(clear) {
            if (clear) {
                this.receivers = [];
                this.$refs.verticalContentReceivers.Reset();
            }

            this.Loading();
            axios.post(`/sii/receivers/${this.Local.id}`, { quantity: 10 })
                .then(({ data }) => {
                    this.receivers = data.receivers;
                    this.Response(data);
                })
                .catch(err => {
                    this.Response(err);
                })
        },
        PrintFactura(factura) {
            this.Util.NotifyService('factura', {data: factura.print_data, id: Math.random().toString()});
        },
        LoadFacturasReceiver(range, clear){
            if(!this.sortFacturasReceiver) return;
            let parts = this.sortFacturasReceiver.split(',');
            let lengthFacturas = 0;

            let postData = {};

            if(this.modalReceiverDetail){
                postData.receiver = this.modalReceiverDetail.receiver;

                if (clear) {
                    this.modalReceiverDetail.facturas = [];
                    this.$refs.verticalReceiverFacturas.Reset();
                }

                let facturas = this.modalReceiverDetail.facturas;
                lengthFacturas = facturas.length;

                if(range){
                    this.$refs.verticalReceiverFacturas.Reset();
                    let date = range.endOf('day').unix();
                    this.modalReceiverDetail.facturas = [];
                    postData.date = date;
                }
            }

            this.Loading();
            axios.post(`/dte/${this.Local.id}/facturas?skip=${lengthFacturas}&orderBy=${parts[0]}&orderDirection=${parts[1]}`, postData).then(({data}) => {
                if(!this.modalReceiverDetail){
                    this.$set(this.modalReceiverDetail, 'facturas', data.facturas);
                    return this.Response(data);
                }

                if(range){
                    this.modalReceiverDetail.facturas = data.facturas;
                    return this.Response(data);
                }

                this.modalReceiverDetail.facturas = this.modalReceiverDetail.facturas.concat(data.facturas);
                return this.Response(data);
            }).catch(err => {
                this.Response(err);
            });
        },
        SearchFactura(number){
            if(!this.modalFacturaSearch) return;

            if(number) this.modalFacturaSearch.number = parseInt(this.modalFacturaSearch.numer) + number;

            this.Loading();
            axios.post(`/dte/${this.Local.id}/facturas`, {idFactura: this.modalFacturaSearch.number}).then(({data}) => {
                if(data.status) this.modalFacturaDetail = data.factura;
                this.Response(data);
            }).catch(err => {
                this.Response(err);
            });
        },
        GetAdvancedStatus(idDte, type){
            this.Loading();
            axios.get(`/sii/dte/${idDte}/${type}/status`).then(({data}) => {
                this.Response(data);
                this.modalDteStatus = data;
            }).catch(err => {
                this.Response(err);
            });
        },
        AvatarColor(color){
            let background = '#34323e';
            const baseColor = Util.baseColors[color.slice(1)];
            if(baseColor) background = baseColor;
            return background;
        },
        GetSqlDte(idDte, type){
            this.Loading();
            axios.get(`/sii/dte/${idDte}/${type}/sql`, {responseType: 'blob'}).then((res) => {
                this.Util.DownloadFile(res, this.Util.Filename(`Dte_${type}`, 'sql', true));
                this.Response(res);
            }).catch(err => {
                this.Response(err);
            });
        },
        ResendFactura(factura){
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(factura.xml, "application/xml");

            const emisionDate = xmlDoc.querySelector("FchEmis").textContent;

            const emisor = {
                rut: xmlDoc.querySelector("RUTEmisor").textContent,
                razonSocial: xmlDoc.querySelector("RznSoc").textContent,
                giro: xmlDoc.querySelector("GiroEmis").textContent,
                direccion: xmlDoc.querySelector("DirOrigen").textContent,
                comuna: xmlDoc.querySelector("CmnaOrigen").textContent,
                acteco: xmlDoc.querySelector("Acteco").textContent,
            };

            const receptor = {
                rut: xmlDoc.querySelector("RUTRecep").textContent,
                razonSocial: xmlDoc.querySelector("RznSocRecep").textContent,
                giro: xmlDoc.querySelector("GiroRecep").textContent,
                correo: xmlDoc.querySelector("CorreoRecep").textContent,
                direccion: xmlDoc.querySelector("DirRecep").textContent,
                comuna: xmlDoc.querySelector("CmnaRecep").textContent,
            };

            const totals = {
                neto: xmlDoc.querySelector("MntNeto").textContent,
                iva: xmlDoc.querySelector("IVA").textContent,
                total: xmlDoc.querySelector("MntTotal").textContent,
                discount: xmlDoc.querySelector("ValorDR")? xmlDoc.querySelector("ValorDR").textContent : 0,
            };

            const parseMonto = (value) =>
                value ? parseInt(value.toString().replace(/\./g, ""), 10) : 0;
                
            factura.detailsItems = {
                items: factura.print_data.items.map((item) => ({
                    item: item.item || "SIN NOMBRE",
                    quantity: parseMonto(item.quantity) || 0,
                    price: parseMonto(item.price),
                    tax: parseMonto(item.tax),
                    taxPercentage: parseMonto(item.tax_percentage),
                    taxCode: item.taxCode || null,
                    discount: parseMonto(item.discount),
                    discountPercentage: parseMonto(item.discount_percentage),
                    mntNeto: parseMonto(item.neto) || (parseMonto(item.price) * item.quantity),
                    iva: parseMonto(item.iva),
                    affection: item.affection !== undefined ? item.affection : false,
                    total: parseMonto(item.total),
                    unitCost: parseMonto(item.unitCost),
                    unit: item.unit || null,
                })),
                total: {
                    mntNeto: totals.neto,
                    iva: totals.iva,
                    taxes: factura.print_data.items.reduce((acc, item) => acc + parseMonto(item.tax), 0),
                    discount: totals.discount ? Math.round(totals.discount) + Math.round(totals.discount * 0.19) : 0,
                    total: totals.total,
                },
            };

            if(factura.print_data.details_items) factura.detailsItems = factura.print_data.details_items;

            factura.detail = factura.detailsItems.items;
            if(factura.print_data.detail) factura.detail = factura.print_data.detail;

            factura.receiver = {
                "rut": receptor.rut,
                "name": receptor.razonSocial,
                "line_business": receptor.giro,
                "id_local": factura.id_local,
                "address": receptor.direccion.toUpperCase(),
                "city": receptor.comuna.toUpperCase(),
                "commune": receptor.comuna.toUpperCase(),
                "email": receptor.correo.toUpperCase()
            };

            factura.emisionDate = Date.parse(`${emisionDate}T00:00:00`) / 1000;

            factura.discount = parseInt(factura.print_data.descuento.replace(/\./g, ''), 10);
            factura.actecos = emisor.acteco;
            factura.receiver_address = {
                "address": receptor.direccion.toUpperCase(),
                "city": receptor.comuna.toUpperCase(),
                "commune": receptor.comuna.toUpperCase(),
                "email": receptor.correo.toUpperCase()
            };

            factura.payment_method = factura.print_data.payment_method;
            this.$refs.modalFacturaElectronica.generateFacturaElectronica(factura);
        }
    },
    computed: {
        filteredReceivers() {
            if(!this.receivers) return [];
            var ret = [];

            this.receivers.forEach(receiver => {
                if(this.Util.InString(receiver.name, this.searchReceiver) || this.Util.InString(receiver.rut, this.searchReceiver) || this.Util.InString(receiver.alias, this.searchReceiver))
                    ret.push(receiver);
            });

            ret.sort((a, b) => {
                return b.id - a.id;
            })

            return ret;
        },
        tabCount() {
            return {
                Receptores: this.receivers ? this.receivers.length : 0
            };
        }
    },
    watch: {
        sortFacturasReceiver: function(n, o) {
            this.LoadFacturasReceiver(null, true);
        },
        sortFacturas: function(n, o) {
            this.LoadDtes(true);
        },
    }
}
</script>