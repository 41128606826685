<template>

<div class="fill flex column">
    <div class="opt r">
        <input-date :icon="mobile ? 'calendar' : null" 
        slot="options"
        inputClass="secondary" 
        type="DATE"
        format="timestamp"
        v-model="dateFilter"
        modalOnly
        :toEndDay="true"
        @OnAcceptDate="LoadTurns()">
    </input-date>
    </div>

    <div class="secondary-text ta-center mt10" v-if="!turns.length">No hay turnos</div>
    
    <vertical-content ref="verticalTurns" v-else @OnBottom="LoadTurns(true)" class="pa-body">
        <div class="">
            <div :class="`section selectable mb10 ${turn.is_active ? 'cpink' : ''}`"
            @click="$refs.turnDetails.Open(turn.id)"
            v-for="turn in turns"
            :key="turn.id">
                <div class="fill flex align-center">
                    <avatar class="mr10"
                    :color="turn.is_active ? '#E601EB' : ''"
                    :label="turn.name">
                    </avatar>
                    <div class="flex column flex-fill">
                        <div class="flex"><div class="bold">Turno de {{turn.name}}</div> <span v-if="turn.is_active" class="secondary-text ml5">(Activo)</span></div>
                        <div class="secondary-text"><span class="bold">Inicio: </span>{{Util.Date(turn.started_at, 'DD/MM/YYYY HH:mm')}}</div>
                        <div class="secondary-text" v-if="turn.ended_at"><span class="bold">Fin: </span>{{Util.Date(turn.ended_at, 'DD/MM/YYYY HH:mm')}}</div>
                    </div>
                    <i class="options icon-dots-vertical" v-if="turn.is_active" @click.stop="Options([
                        {text: 'Cerrar Turno', function: () => {CloseTurn(turn)}, confirm: '¿Cerrar turno?'}
                    ])"></i>
                    <!--<i v-else class="options icon-arrow-right-o"></i>-->
                </div>
            </div>
        </div>
    </vertical-content>
    
    <turn-details ref="turnDetails"></turn-details>

</div>
</template>

<script>
export default {
    data() {
        return {
            turns: [],
            modalTurn: null,
            dateFilter : null
        }
    },
    mounted() {
        this.LoadTurns();
    },
    methods: {
        LoadTurns(bottom=false) {
            //`/turns/${this.Local.id}${this.turns.length ? '/'+(this.turns[this.turns.length-1].started_at-1):''}`
            this.Loading();
            if(this.$refs.verticalTurns) this.$refs.verticalTurns.Reset()
            if(!bottom) this.turns = []
            this.$nextTick(() => {
                axios.get(`/turns/${this.Local.id}/${!bottom? this.dateFilter : (this.turns[this.turns.length-1].started_at-1)}`).then(res => {
                    this.Response(res);
                    this.turns = this.turns.concat(res.data);
                }).catch(err => {
                    this.Response(err);
                });
            })

        },
        CloseTurn(turn) {
            this.Loading();
            axios.post(`/turn/${turn.id}/end`).then(res => {
                this.Info('Turno cerrado exitosamente');
                for(var i = 0; i < this.turns.length; i++)
                {
                    if(this.turns[i].id == res.data.id)
                    {
                        this.$set(this.turns, i, res.data);
                        break;
                    }
                }
            }).catch(err => {
                this.Response(err);
            });
        }
        
    }
}
</script>

<style lang="scss">
.summary {
    border-collapse: collapse;
    width: 100%;
}
.summary tr {
    width: 100%;
    border-bottom: 1px solid var(--gray-2);
}
.summary th {
    text-align: left;
    text-transform: uppercase;
    color: var(--gray-1);
    font-weight: normal;
    font-size: 12px;
}
.summary td {
    padding: 5px 0px;
}
.summary tr:not(:first-child):hover {
    background-color: var(--gray-3);
}
@media (max-width: 820px) {
    .summary th {
        font-size: 10px;
    }
    .summary {
        font-size: 10px;
    }
}

.dark {
    .summary tr {
        border-bottom: 1px solid var(--dark-outline);
    }
    .summary th {
        color: var(--dark-fc-2);
        
    }
    .summary tr:not(:first-child):hover {
        background-color: var(--dark-bg-3);
    }
}
</style>