<template>
<div>
    <div class="relative">
        
        <div :class="`badge ${color} ${round ? 'round' : 'not-round'}`">
            <div class="pulse" v-if="pulse === true || (pulse === 'auto' && count !== null && count > 0)"></div>
            <span class="label" v-if="count !== null">{{max !== null ? (count > max ? `${Util.Number(max)}+` : Util.Number(count)) : Util.Number(count)}}</span>
            <slot></slot>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        count: {
            type: Number,
            default: null
        },
        max: {
            type: Number,
            default: null
        },
        color: {
            type: String,
            default: 'pink'
        },
        round: {
            type: Boolean,
            default: true
        },
        pulse: {
            type: [Boolean, String],
            default: false
        }
    }
}
</script>

<style lang="scss">
.badge {
    font-size: 10px;
    color: var(--white);
    min-width: 20px;
    height: 20px;
    flex-shrink: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    &.round {
        width: 20px;
    }
    &.not-round {
        padding: 2px 5px;
        border-radius: 10px;
    }

    &.pink {
        background-color: var(--pink);
    }
    &.red {
        background-color: var(--red);
    }
    &.green {
        background-color: var(--green);
    }
    &.yellow {
        background-color: var(--yellow);
    }
    &.purple {
        background-color: var(--purple);
    }
    &.blue {
        background-color: var(--blue-2);
    }
    &.gray {
        background-color: var(--gray-3);
        color: var(--black);
    }
    &.white {
        background-color: var(--white);
        color: var(--black);
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    }

    .label {
        z-index: 9;
    }

    .pulse {
        background-color: inherit;
        width: 100%;
        height: 100%;
        position: absolute;
        border-radius: inherit;
        animation: anim-pulse 1s ease-out 0s infinite;
    }

}

@keyframes anim-pulse {
    0% {
        transform: scale(1);
        opacity: 0.5;
    }
    100% {
        transform: scale(1.8);
        opacity: 0;
    }
}

</style>