<template>
<div class="input-files flex column">
    <div class="bold mb10" v-if="label">{{label}}</div>
    <div class="secondary-text mb10" v-if="description">{{description}}</div>

    <!--
    <button class="transparent icon absolute" style="right: 0; top: 0" @click.stop="Confirm({
                text: 'Eliminar',
                title: '¿Eliminar archivo?',
                class: 'error-color',
                function: () => {files.splice(index, 1)}
            })"><i class="icon icon-close"></i></button>
    -->

    <div class="flex files-container ofx">

        
        <button v-for="file in files" :key="file.filename" class="file" @click="Options([
            {text: 'Ver archivo', function: () => {Util.Open(Util.Storage('files', file.key))}, icon: 'eye'},
            {text: 'Eliminar', icon: 'trash', class: 'error-color', function: () => {files.splice(index, 1)}, confirm: '¿Eliminar archivo?', if: !readonly}
        ])">
            <div class="flex column">
                <i class="preview icon icon-file-clip"></i>
                <div>{{file.filename}}</div>
                <div>{{Util.Date(file.created_at, 'HH:mm DD/MM/YYYY')}}</div>
            </div>
        </button>
        

        <button v-if="!readonly" class="file" style="width: 80px" @click="Options([
            {text: 'Seleccionar archivo', function: () => {$refs.btnFile.Open()}, icon: 'folder'},
            {text: 'Tomar foto', function: () => {$refs.inputImage.StartCam()}, icon: 'camera'},
            {text: 'Pegar imagen', function: () => {$refs.inputImage.PasteImage()}, icon: 'paste'},
        ])">
            <div class="flex column">
                <i class="preview icon icon-plus"></i>
                <div>Agregar</div>
            </div>
        </button>


    </div>

    <button-file v-show="false" ref="btnFile" 
    :type="null" accept="*/*"
    @FileSelected="OnFileSelected($event)"></button-file>
    <input-image ref="inputImage" v-show="false" @ImageSelected="SaveImage($event)"></input-image>
    
</div>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        description: {
            type: String,
            default: null
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            files: null,
        }
    },
    mounted() {
        this.files = this.value;
        if(!this.files) this.files = [];
    },
    methods: {
        OnFileSelected(file) {
            //this.Loading();
            var sizeMB = (file.size / 1024) / 1024;
            console.log(sizeMB);
            if(sizeMB > 5) return this.Info('El archivo debe ser menor a 5MB');

            var formData = new FormData();
            formData.append('file', file);
            this.Loading();
            axios.post(`/filemanager/file/${this.Local.id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                console.log(res.data);
                this.files.push(res.data);
                this.Response(res);
            }).catch(err => {
                this.Response(err);
            });
        },
        SaveImage(img)
        {
            this.Loading();
            axios.post(`/filemanager/image/${this.Local.id}`, {image: img}).then(res => {
                console.log(res.data);
                this.files.push(res.data);
                this.Response(res);
            }).catch(err => {
                this.Response(err);
            });
        },
    },
    computed: {

    },
    watch: {
        files: function(n, o) {
            this.$emit('input', n);
        }
    },
    beforeDestroy() {
  
    }
}
</script>

<style lang="scss">
.input-files {

    .file {
        width: 100px;
        height: 100px;
        margin-right: 10px;
        display: inline-block;
        background-color: var(--gray-3);
        font-size: 10px;
        font-weight: 400;
        color: var(--gray);
        overflow: hidden;
        margin-bottom: 5px;

        .preview {
            font-size: 28px;
            color: var(--gray-2);
            margin-bottom: 8px;
        }

    }

}

.dark {
    .input-files {
        .file {
            background-color: var(--dark-bg-3);
            color: var(--dark-fc-2);
            .preview {
                color: var(--dark-fc-2);
            }
        }
    }
}
</style>
