<template>
<modal v-model="pending_payment" :dir="['right', 'center']" :modalClass="['fill', 'sm']" title="Pago Pendiente" hideActions>
    <div class="section flex column copyable" v-if="pending_payment">
        <div class="mb15">
            <div class="bold">Pago Pendiente</div>
            <div>{{pending_payment.method}}</div>
        </div>

        <div class="flex mb15">
            <div class="flex-fill">
                <div class="bold">Transacciones</div>
                <div class="flex-fill">{{Util.Number(pending_payment.transactions.length)}}</div>
            </div>
            <button v-if="pending_payment.transactions.length" class="secondary" @click="ViewTransactions(transactions)">Ver Transacciones</button>
        </div>

        <div class="mb15">
            <div class="bold">Total</div>
            <div>{{Util.Price(pending_payment.total_money)}}</div>
        </div>

        <div class="mb15">
            <div class="bold">Fecha</div>
            <div>{{Util.Date(pending_payment.date, 'LLL')}}</div>
        </div>

        <div class="mb15">
            <div class="bold">Método de Pago</div>
            <div>{{Util.PayMethod(pending_payment.pay_method)}}</div>
        </div>
        <div class="mb15">
            <div class="bold">Ingresado por</div>
            <div>{{pending_payment.custom_data.created_by}}</div>
        </div>
        <div class="mb15" v-if="pending_payment.comment">
            <div class="bold">Comentario</div>
            <div>{{pending_payment.comment}}</div>
        </div>
    </div>
    <modal v-model="modalViewPendingData" dir="right" :title="'Detalle transacción'" :modalClass="['fill', 'sm right h100']" hideActions>
        <tabs v-if="modalViewPendingData" :tabs="['Transacciones', 'Abonos']" :keyByTab="true" v-model="tabModal">
            <vertical-content ref="Transactions" slot="Transacciones" class="pa20" >
                <div class="" v-if="modalViewPendingData.transactions.length > 0">
                    <div class="entry selectable" 
                    v-for="(transaction, index) in modalViewPendingData.transactions"
                    :key="index"
                    @click="$refs.details.Open(transaction)">
                        <div class="flex fill align-center">
                            <div class="flex-fill flex column">
                                <div class="bold">#{{Util.Zeropad(transaction.id_ticket, 10)}}</div>
                                    <div class="secondary-text"><span class="bold">Fecha: </span>{{Util.Date(transaction.created_at, 'DD/MM/YYYY HH:mm')}}</div>
                                    <div class="secondary-text"><span class="bold">Método de Pago: </span><span v-for="(payment, index2) in transaction.payment" :key="index2">{{Util.PayMethod(payment.method)}} </span></div>
                                    <div class="secondary-text">Para {{Util.SaleType[transaction.type]}} <span v-if="transaction.customer.name">a {{transaction.customer.name}} <i v-if="transaction.id_user" style="color: rgb(0, 151, 255)" class="icon icon-corbatin"></i></span></div>
                            </div>
                            <div class="bold">{{Util.Price(transaction.total_money)}}</div>
                        </div>
                    </div>
                </div>
                <div v-else class="secondary-text mt10 ta-center">No hay transacciones.</div>
            </vertical-content>
            <vertical-content ref="pending" slot="Abonos" class="pa20" >
                <div class="" v-if="modalViewPendingData.pending_transactions.length > 0">
                    <div class="entry " 
                    v-for="(pending, index) in modalViewPendingData.pending_transactions"
                    :key="index">
                        <div class="flex fill align-center">
                            <div class="flex-fill flex column">
                                <div >
                                    <div>Abono</div>
                                    <div class="bold">
                                        {{Util.Price(pending.custom_data.partial_payment)}}
                                    </div> 
                                </div>
                                <div class="secondary-text"><span class="bold">Fecha: </span>{{Util.Date(pending.date, 'DD/MM/YYYY HH:mm')}}</div>
                                <div class="secondary-text" v-if="pending.pay_method"><span class="bold">Método de pago:</span> {{Util.PayMethod(pending.pay_method)}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="secondary-text mt10 ta-center">No hay abonos.</div>
            </vertical-content>
        </tabs>
    </modal>
    <transaction-details ref="details" @OnTransactionDeleted="deleteTransaction()"></transaction-details>
    <!-- <transactions-list ref="transactionsList"></transactions-list> -->
</modal>
</template>
<script>
export default {
    data() {
        return {
            pending_payment: null,
            modalViewPendingData : null,
            tabModal: null
        }
    },
    methods: {
        Open(pending_payment) 
        {
            if(typeof pending_payment == 'number')
            {
                this.Loading();
                axios.get(`/payments/pending/transaction/${pending_payment}`).then(res => {
                    this.Response(res);
                    this.pending_payment = res.data;
                }).catch(err => {
                    console.log(err);
                    this.Response(err);
                });
            }
            else
            {
                this.pending_payment = pending_payment;
            }
        },
        ViewTransactions(transactions)
        {

            this.Loading();
            axios.post(`/transactions/pending/${this.Local.id}`, { ids : transactions}).then(res => {
                this.Response(res);
                this.modalViewPendingData = { transactions : res.data.transactions, pending_transactions : res.data.pendings}
            }).catch(err => {
                this.Response(err);
            });
        },
    },
    computed: {
        transactions() {
            var ret = [];
            if(!this.pending_payment) return ret;
            for(var i = 0; i < this.pending_payment.transactions.length; i++)
                ret.push(this.pending_payment.transactions[i].id);
            return ret;
        }
    }
}
</script>