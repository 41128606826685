<template>
<div>


<div ref="target" @click="modal = !modal">
    <i v-if="icon" :class="`options icon-${icon}`"></i>
    <slot name="target"></slot>
</div>

<modal v-model="modal" :domElement="desktop ? $refs.target : null" :hideActions="true" :hideBackdrop="desktop" :dir="['up', 'center']" :modalClass="['up w100','']">
    <div v-if="modal" :class="body" :style="desktop ? {'max-width': maxWidth} : null">
        <slot></slot>
    </div>
</modal>


</div>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            default: null
        },
        body: {
            type: String,
            default: 'input-popover'
        },
        maxWidth: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            modal: false
        }
    },
    methods: {
        Open() {
            this.modal = true;
        },
        Close() {
            this.modal = false;
        }
    },
    watch: {
        modal: function(n, o) {
            this.$emit(n ? 'OnOpen' : 'OnClose');
        }
    }
}
</script>

<style>

</style>