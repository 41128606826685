<template>
<button class="file-btn">
    <div>
        <slot></slot>
    </div>
    <input @change="FileSelected($event)" type="file" style="width: 100%" :accept="accept" ref="inputFile">
</button>
</template>

<script>
export default {
    props: {
        accept: {
            type: String,
            default: "image/*"
        },
        type: {
            type: String,
            default: 'base64'
        },
        encoding: {
            type: String,
            default: 'UTF-8'
        }
    },
    methods: {
        Open() {
            console.log('open click');
            this.$refs.inputFile.click();
        },
        FileSelected(e){
            var input = e.target;
            if(input.files && input.files[0])
            {
                if(this.type)
                {
                    var reader = new FileReader();
                    reader.onload = (e) => {
                        this.$emit('FileSelected', e.target.result);
                    }
                    if(this.type == 'base64')
                        reader.readAsDataURL(input.files[0]);
                    else if(this.type == 'text')
                        reader.readAsText(input.files[0], this.encoding);
                }
                else
                {
                    this.$emit('FileSelected', input.files[0]);
                }
                this.$emit('File', input.files[0]);
            }
        }
    }
}
</script>

<style>
.file-btn {
    position: relative;
    padding: 0;
    cursor: pointer;
    background-color: transparent !important;
    border: none !important;
}
.file-btn * {
    cursor: pointer;
}
.file-btn input[type="file"] {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
}
.file-btn * {
    cursor: pointer;
}
</style>
