<template>
    <div class="fill swiper-no-swiping relative" ref="map">
        <div class="absolute" style="right: 12px; top: 12px; z-index: 1000" v-if="canSearch">
            <button class="btn-leaflet" @click="showSearch=true;" v-tooltip="'Buscar dirección'"><i class="icon icon-search"></i></button>
        </div>
    
        <div v-show="currentAddress" class="absolute flex justify-center" style="bottom: 12px; left: 12px; right: 12px; z-index: 1000">
            <div @click="Util.Clipboard(currentAddress)" class="well mini selectable">{{currentAddress}} <i class="icon icon-copy selectable"></i></div>
        </div>
    
        <transition enter-active-class="superfast animated fadeIn" leave-active-class="superfast animated fadeOut">
            <div v-if="showSearch" class="absolute trbl0 fill flex column pa20" style="z-index: 10000; background-color: rgba(0,0,0,0.5)">
                <input-field after=" " placeholder="Buscar dirección" v-model="search" :timeout="500" autofocus>
                    <i @click="showSearch=false; search=''; searchResults=[]" class="icon icon-cross selectable"  slot="inner-after"></i>
                </input-field>
                <div class="section mr10 ml10 ofy" style="max-height: 85%; border-top-left-radius: 0px; border-top-right-radius: 0px;">
                    <div v-for="(address, index) in searchResults" 
                    :key="index" class="entry selectable"
                    @click="SelectAddress(address)">{{address.title}}</div>
                    <div v-if="!searchResults.length" class="ta-center secondary-text">No se encontraron resultados</div>
                </div>
            </div>
        </transition>
    
    </div>
</template>
    
<script>
import Leaflet from 'leaflet';
import LeafletHeat from 'leaflet.heat';
export default {
    props: {
        value: {
            type: Object,
            default: () => {}
        },
        view: {
            type: Object,
            default: () => ({lat: 0, lon: 0, zoom: 1})
        },
        useHeatMap: {
            type: Boolean,
            default: false
        },
        heatMapPoints: {
            type: Array,
            default: () => []
        },
        useMarker: {
            type: [Boolean, String],
            default: false
        },
        canEditMarker: {
            type: Boolean,
            default: false
        },
        path: {
            type: Object,
            default: () => null
        },
        canEditPolygon: {
            type: Boolean,
            default: false
        },
        polygon: {
            type: Array,
            default: () => []
        },
        pois: {
            type: Array,
            default: () => []
        },
        canSearch: {
            type: Boolean,
            default: false
        },
        geocode: {
            type: Boolean,
            default: false
        },
        initialZoom: {
            type: Number,
            default: 15
        }
    },
    data() {
        return {
            map: null,
            heatMap: null,
            marker: null,
            polygonMarkers: [],
            polygonData: null,
            extraLayer: null,
            geocoder: null,
            showSearch: false,
            search: '',
            searchResults: [],
            currentAddress: null,
            colors: {                
                15: ['#12BC6F' ,'#E3860F' ,'#092CEE' ,'#AD12BC' ,'#60E50F' ,'#D82F41' ,'#66CA9D', '#B2D71C', '#81a1ec', '#b699ea', '#e48ed8', '#ff87b9', '#ff8993', '#ff966c', '#ffab4a'], 
                14: ['#E3860F' ,'#092CEE' ,'#AD12BC' ,'#60E50F' ,'#D82F41' ,'#66CA9D', '#B2D71C', '#81a1ec', '#b699ea', '#e48ed8', '#ff87b9', '#ff8993', '#ff966c', '#ffab4a'], 
                13: ['#092CEE' ,'#AD12BC' ,'#60E50F' ,'#D82F41' ,'#66CA9D', '#B2D71C', '#81a1ec', '#b699ea', '#e48ed8', '#ff87b9', '#ff8993', '#ff966c', '#ffab4a'], 
                12: ['#AD12BC' ,'#60E50F' ,'#D82F41' ,'#66CA9D', '#B2D71C', '#81a1ec', '#b699ea', '#e48ed8', '#ff87b9', '#ff8993', '#ff966c', '#ffab4a'], 
                11: ['#60E50F' ,'#D82F41' ,'#66CA9D', '#B2D71C', '#81a1ec', '#b699ea', '#e48ed8', '#ff87b9', '#ff8993', '#ff966c', '#ffab4a'],                  
                10: ['#D82F41' ,'#66CA9D', '#B2D71C', '#81a1ec', '#b699ea', '#e48ed8', '#ff87b9', '#ff8993', '#ff966c', '#ffab4a'],                 
                9: ['#66CA9D', '#B2D71C', '#81a1ec', '#b699ea', '#e48ed8', '#ff87b9', '#ff8993', '#ff966c', '#ffab4a'], 
                8: ['#B2D71C', '#81a1ec', '#b699ea', '#e48ed8', '#ff87b9', '#ff8993', '#ff966c', '#ffab4a'],
                7: ['#81a1ec', '#b699ea', '#e48ed8', '#ff87b9', '#ff8993', '#ff966c', '#ffab4a'],
                6: ['#8aa0ed', '#c695e5', '#f68aca', '#ff87a0', '#ff9372', '#ffab4a'],
                5: ['#979eed', '#dc90dc', '#ff86b2', '#ff907b', '#ffab4a'],
                4: ['#a99bec', '#f68aca', '#ff8b89', '#ffab4a'],
                3: ['#c695e5', '#ff87a0', '#ffab4a'],
                2: ['#f68aca', '#ffab4a'],
                1: ['#ffab4a'],
            },
            timer: null,
        }
    },
    mounted() {
        this.map = Leaflet.map(this.$refs.map).setView([this.view.lat, this.view.lon], this.view.zoom);
        Leaflet.tileLayer(process.env.VUE_APP_TILE_PROVIDER, {
            attribution: process.env.VUE_APP_MAP_ATTRIBUTION
        }).addTo(this.map);

        this.map.on('zoomend', () => {
            this.$emit('OnZoomEnd', this.CurrentPosition());
            if(this.timer) clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.$emit('OnNavigationEnd', this.CurrentPosition());
            }, 500);
        });
        this.map.on('dragend', (e) => {
            this.$emit('OnDragEnd', this.CurrentPosition());
            if(this.timer) clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.$emit('OnNavigationEnd', this.CurrentPosition());
            }, 500);
        });

        if(this.useHeatMap)
            this.heatMap = Leaflet.heatLayer(this.heatMapPoints, { 
                
            }).addTo(this.map);

        this.extraLayer = L.layerGroup().addTo(this.map);

        this.markersLayer = L.featureGroup().addTo(this.map);

        this.UpdateMarkers(this.pois, true, true);

        if(this.useMarker && this.value && this.value.latitude && this.value.longitude)
        {
            var icon = L.icon({
                iconUrl: require(`../../assets/${typeof this.useMarker == 'string' ? this.useMarker : 'location2'}.png`),
                iconSize: [50, 132],
                iconAnchor: [23, 64]
            });

            this.marker = Leaflet.marker([this.value.latitude, this.value.longitude], {draggable: this.canEditMarker, icon: icon}).addTo(this.map);
            console.log(this.marker);
            if(this.canEditMarker)
            {
                this.marker.on('drag', (e) => {
                    this.currentAddress = null;
                    this.$emit('OnMarkerChanged', e.latlng);
                });
                this.marker.on('dragend', (e) => {
                    if(!this.geocode) return;
                    var latlng = e.target.getLatLng();
                    axios.post('/geocode', {latitude: latlng.lat, longitude: latlng.lng}).then(res => {
                        console.log(res.data);
                        this.currentAddress = res.data.title;
                    }).catch(err => { 
                        console.log(err);
                    });
                });
            }
            this.map.setView([this.value.latitude, this.value.longitude], this.initialZoom);
        }

        if(this.path)
        {
            var polyline = require('@mapbox/polyline');
            var points = [];//polyline.decode(this.path.polyline);
            for(var i = 0; i < this.path.directions.legs[0].steps.length; i++)
            {
                var step = this.path.directions.legs[0].steps[i];
                points = points.concat(polyline.decode(step.geometry));
            }
            
            L.polyline(points, {color: 'white', weight: 8}).addTo(this.map);
            var pathLine = L.polyline(points, {color: '#00b5ff', weight: 4}).addTo(this.map);
            this.map.fitBounds(pathLine.getBounds());

            var iconShop = L.icon({
                iconUrl: require('../../assets/location2.png'),
                iconSize: [50, 132],
                iconAnchor: [23, 64]
            });

            var iconCustomer = L.icon({
                iconUrl: require('../../assets/location3.png'),
                iconSize: [50, 132],
                iconAnchor: [23, 64]
            });

            L.marker([this.path.start[1], this.path.start[0]], {icon: iconShop}).addTo(this.map);
            L.marker([this.path.end[1], this.path.end[0]], {icon: iconCustomer}).addTo(this.map);

        }

        this.map.on('click', (e) => {
            if(this.canEditPolygon)
                this.AddPolygonPoint(e.latlng);
        });
        this.polygonData = L.polygon([], {color: 'var(--purple)'}).addTo(this.map);

        for(var i = 0; i < this.polygon.length; i++) 
        {
            this.AddPolygonPoint(this.polygon[i], true);
        }


    },
    methods: {
        AddPolygonPoint(latlng, addLast)
        {
            var icon = L.icon({
                iconUrl: require('../../assets/polygon_marker.png'),
                iconSize: [25, 25],
                iconAnchor: [12, 12]
            });
            var m = L.marker(latlng, {icon: icon, draggable: true}).addTo(this.map);
            
            if(this.canEditPolygon)
            {
                m.on('click', (e) => {
                    for(var i = 0; i < this.polygonMarkers.length; i++)
                    {
                        if(e.target == this.polygonMarkers[i])
                        {
                            this.map.removeLayer(this.polygonMarkers[i]);
                            this.polygonMarkers.splice(i, 1);
                            this.UpdatePolygon();
                            break;
                        }
                    }
                });
                m.on('drag', (e) => {
                    this.UpdatePolygon();
                });
            }
            
            if(this.polygonMarkers.length > 1 && !addLast)
            {
                var nearest = {index: 0, distance: 999999};
                for(var i = 1; i < this.polygonMarkers.length; i++)
                {
                    var m1 = m.getLatLng();
                    var m2 = this.polygonMarkers[i-1].getLatLng();
                    var m3 = this.polygonMarkers[i].getLatLng();
                    var mid = {lat: (m3.lat + m2.lat)/2, lng: (m3.lng + m2.lng)/2};
                    var dist1 = Math.sqrt( Math.pow(mid.lat - m1.lat, 2) + Math.pow(mid.lng - m1.lng,2) );
                    var dist2 = Math.sqrt( Math.pow(m2.lat - m1.lat, 2) + Math.pow(m2.lng - m1.lng,2) );
                    var dist = Math.min(dist1, dist2);
                    if(dist < nearest.distance)
                    {
                        nearest.index = (i==1 && dist2 < dist1) ? 0 : i;
                        nearest.distance = dist;
                    }
                }
                this.polygonMarkers.splice(nearest.index, 0, m);
            }
            else
            {
                this.polygonMarkers.push(m);
            }
            this.UpdatePolygon(!this.canEditPolygon);
        },
        SetPolygon(polygon, background)
        {
            this.extraLayer.clearLayers();
            if(background)
            {
                var colors = this.colors[background.length] ? this.colors[background.length] : this.colors[15];
                var index = 0;
                background.forEach(pol => {
                    //var color = index < colors.length ? colors[index] : colors[colors.length - 1];
                    var color = 'var(--green)';
                    this.extraLayer.addLayer(L.polygon(pol, {color: color}));
                    index++;
                });
            }
            

            this.polygonMarkers.forEach(marker => {
                this.map.removeLayer(marker);
            });
            this.polygonMarkers = [];

            for(var i = 0; i < polygon.length; i++) 
            {
                this.AddPolygonPoint(polygon[i], true);
            }
            this.UpdatePolygon(true);
            this.polygonData.bringToFront();
            if(polygon.length)
            {
                var bounds = this.polygonData.getBounds();
                this.map.fitBounds(bounds);
                this.map.panTo(bounds.getCenter());
            }
        },
        UpdatePolygon(noEmit)
        {
            var latlngs = [];
            for(var i = 0; i < this.polygonMarkers.length; i++)
            {
                latlngs.push(this.polygonMarkers[i].getLatLng());
            }
            this.polygonData.setLatLngs(latlngs);
            
            if(noEmit) return;

            this.$emit('OnPolygonChanged', latlngs);
        },
        EnableInteraction(val)
        {
            this.map._handlers.forEach(function(handler) {
                if(val) handler.enable(); 
                else handler.disable();
            });
        },
        SetLocation(lat, lng)
        {
            if(this.marker) 
            {
                var newLatLng = new L.LatLng(lat, lng);
                this.marker.setLatLng(newLatLng);
                this.$emit('OnMarkerChanged', newLatLng);    
            }
            this.map.setView([lat, lng], 15);
        },
        SelectAddress(address)
        {
            this.SetLocation(address.lat, address.lon); 
            this.showSearch = false;
            this.currentAddress = address.title;
            this.search = '';
            this.searchResults = [];
        },
        SearchAddress(address)
        {
            this.showSearch = true;
            this.search = address;
            this.searchResults = [];
        },
        CurrentPosition() 
        {
            // Get the center and bounds of the current map view
            var center = this.map.getCenter(); // Get the map's center as a LatLng object
            var bounds = this.map.getBounds(); // Get the map bounds

            // Get the northeast and southwest corners of the bounds
            var northEast = bounds.getNorthEast();
            var southWest = bounds.getSouthWest();

            // Create a LatLng point directly east of the center (same latitude, but use the easternmost longitude)
            var eastPoint = Leaflet.latLng(center.lat, northEast.lng);

            // Calculate the distance between the center and the east point (this will give half the width in meters)
            var radiusMeters = center.distanceTo(eastPoint);

            return {
                center: center,
                radius: radiusMeters
            };

        },
        UpdateMarkers(points, clearLayer, zoomMap, test)
        {
            if(clearLayer) this.markersLayer.clearLayers();
            var markers = [];
            for(var i = 0; i < points.length; i++)
            {
                let pointData = Array.isArray(points[i]) ? {latitude: points[i][0], longitude: points[i][1]} : points[i];

                var icon = null;
                
                if(pointData.icon)
                {
                    icon = L.divIcon({
                        html: points[i].icon,
                    });   
                }

                if(!icon)
                {
                    icon = L.icon({
                        iconUrl: require('../../assets/location2.png'),
                        iconSize: [50, 132],
                        iconAnchor: [23, 64]
                    });
                }

                var marker = Leaflet.marker([pointData.latitude, pointData.longitude], {icon: icon}).addTo(this.markersLayer);
                
                if(pointData.tooltip)
                {
                    marker.bindTooltip(pointData.tooltip, {
                        direction: 'top', // Position it above the marker
                        className: 'custom-tooltip'
                    });
                }

                if(pointData.click)
                {
                    marker.on('click', pointData.click);
                }

                markers.push(marker);
            }
            if(markers.length > 0)
            {
                if(zoomMap) this.map.fitBounds(this.markersLayer.getBounds(), {maxZoom: 15});
            }
        }
    },
    beforeDestroy() {
        if(this.map)
        {
            this.map.remove();
            this.map = null;
        }
    },
    watch: {
        heatMapPoints: function(n, o) {
            if(this.heatMap)
            {
                this.heatMap.setLatLngs(n);
            }
        },
        showSearch: function(n, o) {
            this.EnableInteraction(!n);
        },
        search: function(n, o)
        {
            if(n)
            {
                axios.post('/geocode/reverse', {address: n}).then(res => {
                    this.searchResults = res.data;
                }).catch(err => {
                    console.log(err);
                });
            }
            else
            {
                this.searchResults = [];
            }
        },
        currentAddress: function(n, o) {
            if(this.marker)
            {
                var latlng = this.marker.getLatLng();
                latlng.address = n;
                console.log(latlng);
                this.$emit('OnMarkerChanged', latlng);
            }
            
        }
    },
    showSearch: function(n, o) {
        this.EnableInteraction(!n);
    },
    search: function(n, o)
    {
        if(n)
        {
            axios.post('/geocode/reverse', {address: n}).then(res => {
                this.searchResults = res.data;
            }).catch(err => {
                console.log(err);
            });
        }
        else
        {
            this.searchResults = [];
        }
    },
    currentAddress: function(n, o) {
        if(this.marker)
        {
            var latlng = this.marker.getLatLng();
            latlng.address = n;
            console.log(latlng);
            this.$emit('OnMarkerChanged', latlng);
        }
        
    }
}
</script>
    
<style lang="scss">
.btn-leaflet {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white; 
    border-radius: 4px;
    outline: 2px solid rgba(0,0,0,0.25);
    padding: 0px !important;
    width: 40px;
    height: 40px;
    &:hover {
        background-color: #EEE;
        outline: 2px solid rgba(0,0,0,0.25);
    }
    &:focus {
        outline: 2px solid rgba(0,0,0,0.25) !important;
    }
}
</style>
    
