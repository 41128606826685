<template>
<modal v-model="modalCustomization" dir="right" :modalClass="['fill', 'md right h100']" :title="titles[type]">
    
    <button v-if="modalCustomization && modalCustomization.tab == 'Avanzado'" class="secondary" slot="actions" @click="snippetGroup == 'ALL' ? snippetGroup = '' : snippetGroup = 'ALL'">Expandir Todos</button>
    <button class="primary purple" slot="actions" @click="Save()">Guardar</button>

    <div class="preview-window animated fadeIn delay-05s flex column justify-center align-center" v-if="desktop && modalCustomization && type == 'MESSAGES'" :style="{background: modalCustomization.modal_bg, color: modalCustomization.modal_fc, height: '300px'}">
        
        <img :src="messageImage" width="150">
        <div class="ta-center" style="font-size: 9px; margin-top: 10px; padding: 0px 40px" v-html="message"></div>

    </div>
    <div class="preview-window animated fadeIn delay-05s flex column justify-center align-center" v-if="desktop && modalCustomization && type == 'NOTIFICATIONS'" 
    v-show="desktop && modalCustomization && modalCustomization['send_email_'+notificationItem]"
    :style="{background: modalCustomization.modal_bg, color: modalCustomization.modal_fc, height: '240px'}">
        
        <img :src="img('logos/'+Local.logotype)" height="15">
        <div style="font-size: 10px; margin-top: 5px">{{Local.name}}</div>
        <div class="secondary-text" style="font-size: 6px">Dirección</div>
        <div class="secondary-text" style="font-size: 6px">912341234</div>

        <div style="padding: 15px 20px; font-size: 8px" class="thin w100" v-html="notificationMessage"></div>

        <div :style="{'background': modalCustomization.email_button_color, color: 'white', padding: '4px 6px', 'border-radius': '3px'}">VER PEDIDO</div>
        

    </div>

    <!-- preview window-->
    <!--
    <div :class="`preview-window animated fadeIn delay-05s`" v-if="desktop && modalCustomization && modalCustomization.tab == 'Avanzado' && type == 'STYLE'">
        <img v-if="modalCustomization.snippetPreview" :src="img('static/'+modalCustomization.snippetPreview)" class="fill">
    </div>
    -->

    <div :class="`preview-window live-preview zoom-${zoomLevel}`" v-if="desktop && modalCustomization && (type == 'STYLE' || type == 'STYLE_FRANCHISE')">
        
        <div class="relative fill">
            <iframe id="livePreview" ref="livePreview" :src="url"></iframe>
        </div>
        <div class="absolute flex" style="top: -50px; right: 0; gap: 5px">
            <button @click="zoomLevel = 'mobile'" :class="zoomLevel == 'mobile' ? 'primary' : 'secondary'">Mobile</button>
            <button @click="zoomLevel = 'tablet'" :class="zoomLevel == 'tablet' ? 'primary' : 'secondary'">Tablet</button>
            <button @click="zoomLevel = ''" :class="zoomLevel == '' ? 'primary' : 'secondary'">Escritorio</button>
            <button @click="zoomLevel = 'xl'" :class="zoomLevel == 'xl' ? 'primary' : 'secondary'">Escritorio 2</button>
        </div>
    </div>

        
    <tabs v-model="modalCustomization.tab" v-if="modalCustomization && type == 'STYLE'" :tabs="['General', 'Avanzado']" :tab="modalCustomization.tab" :keyByTab="true"
    @OnTabAvanzado="LoadSnippets">
        
        <div slot="General" class="pa20">
            <input-field v-model="modalCustomization.font"
            label="Fuente" class="mb15"
            description="Ingresa el nombre de una fuente de Google Fonts">
                <div class="append" slot="after"><i class="options icon-external" @click="Util.Open('https://fonts.google.com/')" v-tooltip="'Abrir Google Fonts'"></i></div>
            </input-field>
            
            <input-select class="mb15" :items="[
                {text: 'Cuadrado', value: 'FLAT'},
                {text: 'Cuadrado con sombra', value: 'FLAT_SHADOW'},
                {text: 'Redondeado', value: 'ROUND'},
                {text: 'Redondeado con sombra', value: 'ROUND_SHADOW'},
                {text: 'Circular', value: 'CIRCLE'},
                {text: 'Circular con sombra', value: 'CIRCLE_SHADOW'},
            ]"
            v-model="modalCustomization.logo_style"
            label="Estilo Logo"></input-select>

            <input-select :items="[
                {text: 'Grande', value: 'DEFAULT'},
                {text: 'Pequeño', value: 'SMALL'},
            ]"
            v-model="modalCustomization.item_image_size"
            label="Tamaño imágenes de productos"
            description="Define como se mostrará la imagen del producto cuando se ven los detalles del producto"></input-select>

            <div class="separator mb20 mt20"></div>
            
            <div class="f18 mb20">Botones</div>
            <input-select :items="[
                {text: 'Cuadrado', value: 'FLAT'},
                {text: 'Cuadrado con sombra', value: 'FLAT_SHADOW'},
                {text: 'Cuadrado con borde delgado', value: 'FLAT_THINBRD'},
                {text: 'Cuadrado con borde', value: 'FLAT_BORDER'},
                {text: 'Redondeado', value: 'ROUND'},
                {text: 'Redondeado con sombra', value: 'ROUND_SHADOW'},
                {text: 'Redondeado con borde delgado', value: 'ROUND_THINBRD'},
                {text: 'Redondeado con borde', value: 'ROUND_BORDER'},
                {text: 'Circular', value: 'CIRCLE'},
                {text: 'Circular con sombra', value: 'CIRCLE_SHADOW'},
                {text: 'Circular con borde delgado', value: 'CIRCLE_THINBRD'},
                {text: 'Circular con borde', value: 'CIRCLE_BORDER'},
            ]"
            v-model="modalCustomization.button_style"
            label="Estilo"></input-select>
            
            <div class="separator mb20 mt20"></div>
            
            <div class="f18 mb20">Ventanas</div>
            <input-select :items="[
                {text: 'Redondeada', value: 'ROUND'},
                {text: 'Cuadrada', value: 'FLAT'},
            ]"
            v-model="modalCustomization.modal_style"
            label="Estilo" class="mb15"></input-select>
            <div class="hs">
                <input-color label="Color Fondo" v-model="modalCustomization.modal_bg" class="mb15 sm"></input-color>
                <input-color label="Color Texto" v-model="modalCustomization.modal_fc" class="mb15 sm"></input-color>
            </div>
            <div class="hs">
                <input-color label="Color Primario" v-model="modalCustomization.modal_c1" class="mb15 sm"></input-color>
                <input-color label="Color Secundario" v-model="modalCustomization.modal_c2" class="mb15 sm"></input-color>
            </div>
            <div class="hs">
                <input-color label="Color Seleccionado" v-model="modalCustomization.modal_cy" class="mb15 sm"></input-color>
                <input-color label="Color Requerido" v-model="modalCustomization.modal_cn" class="sm"></input-color>
            </div>

            <div class="separator mb20 mt20"></div>

            <div class="f18 mb20">Sección Izquierda</div>
            <div class="hs">
                <input-color label="Color Fondo" v-model="modalCustomization.left_bg" class="mb15 sm"></input-color>
                <input-color label="Color Texto" v-model="modalCustomization.left_fc" class="sm"></input-color>
            </div>
            
            <div class="separator mb20 mt20"></div>

            <div class="f18 mb20">Sección Central </div>
            <div class="hs">
                <input-color label="Color Fondo" v-model="modalCustomization.center_bg" class="mb15 sm"></input-color>
                <input-color label="Color Texto" v-model="modalCustomization.center_fc" class="mb15 sm"></input-color>
            </div>
            <div class="hs">
                <input-color label="Color Primario" v-model="modalCustomization.center_c1" class="mb15 sm"></input-color>
                <input-color label="Color Secundario" v-model="modalCustomization.center_c2" class="sm"></input-color>
            </div>
            
            <div class="separator mb20 mt20"></div>

            <div class="f18 mb20">Sección Derecha </div>
            <div class="hs">
                <input-color label="Color Fondo" v-model="modalCustomization.right_bg" class="mb15 sm"></input-color>
                <input-color label="Color Texto" v-model="modalCustomization.right_fc" class="mb15 sm"></input-color>
            </div>
            <div class="hs">
                <input-color label="Color Primario" v-model="modalCustomization.right_c1" class="mb15 sm"></input-color>
                <input-color label="Color Secundario" v-model="modalCustomization.right_c2" class="sm"></input-color>
            </div>
        </div>

        <div slot="Avanzado" class="pa20">
            
            <input-select class="mb15"
            v-model="modalCustomization.advanced"
            :items="[{text: 'Estilos', value: 'SNIPPETS'}, {text: 'Código CSS', value: 'CSS'}]">
            </input-select>

            <div v-if="modalCustomization.advanced == 'SNIPPETS'">
                <div v-for="snippet in snippets" :key="snippet.key">
                    <div v-if="snippet.key.indexOf('HEADER:') > -1" class="flex column mb20 mt20 selectable"
                    @click="snippetGroup == snippet.key ? snippetGroup = '' : snippetGroup = snippet.key">
                        <div class="f18"><span v-show="SnippetCount(snippet.key)" class="cgreen">({{SnippetCount(snippet.key)}})</span> {{snippet.title}}</div>
                        <div class="secondary-text">{{snippet.description}}</div>
                    </div>
                    <div v-else-if="snippet.title.indexOf('[HIDDEN]') == -1" :class="`well selectable flex mb5 ${enabledSnippets[snippet.key] !== 'DISABLED' ? 'selected' : ''}`"
                    @mouseenter="$set(modalCustomization, 'snippetPreview', snippet.image)"
                    v-show="snippetGroup == 'ALL' || snippet.group == snippetGroup">
                        <div class="flex column flex-fill" @click="enabledSnippets[snippet.key] !== 'DISABLED' ? EnableSnippet(snippet.key, 'DISABLED') : EnableSnippet(snippet.key, 'ENABLED')">
                            <div class="bold">{{snippet.title}}</div>
                            <!--<div class="secondary-text">{{snippet.description}}</div>-->
                        </div>
                        <input-select v-model="enabledSnippets[snippet.key]" class="auto-width"
                        :triggerFirstValueChanged="false"
                        :items="[{text: '❌', value: 'DISABLED'}, {text: '✅', value: 'ENABLED'}, {text: '📱', value: 'MOBILE'}, {text: '💻', value: 'DESKTOP'}]"
                        @OnValueChanged="EnableSnippet(snippet.key, $event)">
                        </input-select>
                    </div>
                </div>
            </div>

            <input-field lines v-if="modalCustomization.advanced == 'CSS'" v-model="modalCustomization.css"></input-field>

        </div>

    </tabs>


    <!-- Modal personalization franchise -->
    <div v-if="modalCustomization && type == 'STYLE_FRANCHISE'" class="pa20">
        
        <div class="f18 mb20">General</div>
        
        <input-field v-model="modalCustomization.font"
        label="Fuente" class="mb15"
        description="Ingresa el nombre de una fuente de Google Fonts">
            <div class="append" slot="after"><i class="options icon-external" @click="Util.Open('https://fonts.google.com/')" v-tooltip="'Abrir Google Fonts'"></i></div>
        </input-field>
        
        <input-select :items="[
            {text: 'Cuadrado', value: 'FLAT'},
            {text: 'Cuadrado con sombra', value: 'FLAT_SHADOW'},
            {text: 'Redondeado', value: 'ROUND'},
            {text: 'Redondeado con sombra', value: 'ROUND_SHADOW'},
            {text: 'Circular', value: 'CIRCLE'},
            {text: 'Circular con sombra', value: 'CIRCLE_SHADOW'},
        ]"
        v-model="modalCustomization.logo_style"
        label="Estilo Logo"></input-select>
        

        <div class="separator mb20 mt20"></div>
        
        <div class="f18 mb20">Botones</div>
        <input-select :items="[
            {text: 'Cuadrado', value: 'FLAT'},
            {text: 'Cuadrado con sombra', value: 'FLAT_SHADOW'},
            {text: 'Cuadrado con borde', value: 'FLAT_BORDER'},
            {text: 'Redondeado', value: 'ROUND'},
            {text: 'Redondeado con sombra', value: 'ROUND_SHADOW'},
            {text: 'Redondeado con borde', value: 'ROUND_BORDER'},
            {text: 'Circular', value: 'CIRCLE'},
            {text: 'Circular con sombra', value: 'CIRCLE_SHADOW'},
            {text: 'Circular con borde', value: 'CIRCLE_BORDER'},
        ]"
        v-model="modalCustomization.button_style"
        label="Estilo"></input-select>
        
        <div class="separator mb20 mt20"></div>
        
        <div class="f18 mb20">Ventanas</div>
        <input-select :items="[
            {text: 'Redondeada', value: 'ROUND'},
            {text: 'Cuadrada', value: 'FLAT'},
        ]"
        v-model="modalCustomization.modal_style"
        label="Estilo" class="mb15"></input-select>
        <div class="hs">
            <input-color label="Color Fondo" v-model="modalCustomization.modal_bg" class="mb15 sm"></input-color>
            <input-color label="Color Texto" v-model="modalCustomization.modal_fc" class="mb15 sm"></input-color>
        </div>
        <div class="hs">
            <input-color label="Color Primario" v-model="modalCustomization.modal_c1" class="mb15 sm"></input-color>
            <input-color label="Color Secundario" v-model="modalCustomization.modal_c2" class="mb15 sm"></input-color>
        </div>
        <div class="hs">
            <input-color label="Color Seleccionado" v-model="modalCustomization.modal_cy" class="mb15 sm"></input-color>
            <input-color label="Color Requerido" v-model="modalCustomization.modal_cn" class="sm"></input-color>
        </div>

        <div class="separator mb20 mt20"></div>

        <div class="f18 mb20">Sección Central </div>
        <div class="hs">
            <!-- <input-color label="Color Primario" v-model="modalCustomization.center_c1" class="mb15 sm"></input-color> -->
            <input-color label="Color de tarjeta" v-model="modalCustomization.center_c2" class="sm"></input-color>
        </div>

        <div class="separator mb20 mt20"></div>

        <div class="f18 mb20">Barra de navegación </div>
        <div class="hs">
            <input-color label="Color Fondo" v-model="modalCustomization.navbar_bg" class="mb15 sm"></input-color>
            <input-color label="Color Secundario" v-model="modalCustomization.navbar_c2" class="sm"></input-color>
        </div>

        <div class="separator mb20 mt20"></div>

        <div class="f18 mb20">Pie de página </div>
        <div class="hs">
            <input-color label="Color Fondo" v-model="modalCustomization.footer_bg" class="mb15 sm"></input-color>
            <input-color label="Color Texto" v-model="modalCustomization.footer_fc" class="mb15 sm"></input-color>
        </div>
        <div class="hs">
            <!-- <input-color label="Color Primario" v-model="modalCustomization.center_c1" class="mb15 sm"></input-color> -->
            <input-color label="Color Secundario" v-model="modalCustomization.footer_c2" class="sm"></input-color>
        </div>

    </div>

    <div v-if="modalCustomization && type == 'MESSAGES'">
        <div class="section">
            <div class="mb15">Puedes personalizar los mensajes e imágenes que se muestran en cada paso del pedido.</div>

            <input-select class="mb15"
            label="Mensaje"
            :items="messageItems" 
            v-model="messageItem">
            </input-select>

            <input-text v-if="messageItem == 'DISCOUNT'"
            v-model="modalCustomization.pos_discount" 
            :keywords="keywords">
            </input-text>
            
            <div class="flex" v-if="messageItem == 'PENDING'">
                <input-image v-model="modalCustomization.image_order_pending" :crop="true" :cropSize="[500, 410]" :cropViewport="[250, 205]" path="banners/"></input-image>
                <input-text class="ml20 fill" v-model="modalCustomization.status_order_pending" :keywords="keywords" ></input-text>
            </div>

            <div class="flex" v-if="messageItem == 'CONFIRM'">
                <input-image v-model="modalCustomization.image_order_confirmation" :crop="true" :cropSize="[500, 410]" :cropViewport="[250, 205]" path="banners/"></input-image>
                <input-text v-model="modalCustomization.status_order_confirmation" :keywords="keywords" class="ml20 fill"></input-text>
            </div>

            <div class="flex" v-if="messageItem == 'ONLINE_PAYMENT'">
                <input-image v-model="modalCustomization.image_order_payment" :crop="true" :cropSize="[500, 410]" :cropViewport="[250, 205]" path="banners/"></input-image>
                <input-text v-model="modalCustomization.status_order_payment" class="ml20 fill" :keywords="keywords"></input-text>
            </div>

            <input-text v-model="modalCustomization.status_order_bank"  class="fill" :keywords="keywords" v-if="messageItem == 'BANK_PAYMENT'"></input-text>
            
            <div class="flex" v-if="messageItem == 'SCHEDULED'">
                <input-image v-model="modalCustomization.image_order_scheduled" :crop="true" :cropSize="[500, 410]" :cropViewport="[250, 205]" path="banners/"></input-image>
                <input-text v-model="modalCustomization.status_order_scheduled"  class="ml20 fill" :keywords="keywords"></input-text>
            </div>
        
            <div class="flex" v-if="messageItem == 'SHIPMENT'">
                <input-image v-model="modalCustomization.image_order_shipment_ready" :crop="true" :cropSize="[500, 410]" :cropViewport="[250, 205]" path="banners/"></input-image>
                <input-text v-model="modalCustomization.status_order_shipment"  class="ml20 fill" :keywords="keywords"></input-text>
            </div>

            <div class="flex" v-if="messageItem == 'SHIPMENT_TAKEAWAY'">
                <input-image v-model="modalCustomization.image_order_shipment_takeaway" :crop="true" :cropSize="[500, 410]" :cropViewport="[250, 205]" path="banners/"></input-image>
                <input-text v-model="modalCustomization.status_order_shipment_takeaway"  class="ml20 fill" :keywords="keywords"></input-text>
            </div>

            <div class="flex" v-if="messageItem == 'PREPARING'">
                <input-image v-model="modalCustomization.image_order_preparing" :crop="true" :cropSize="[500, 410]" :cropViewport="[250, 205]" path="banners/"></input-image>
                <input-text v-model="modalCustomization.status_order_preparing"  class="ml20 fill" :keywords="keywords"></input-text>
            </div>

            <div class="flex"  v-if="messageItem == 'DELIVERY'">
                <input-image v-model="modalCustomization.image_order_delivery" :crop="true" :cropSize="[500, 410]" :cropViewport="[250, 205]" path="banners/"></input-image>
                <input-text v-model="modalCustomization.status_order_delivery"  class="ml20 fill" :keywords="keywords"></input-text>
            </div>

            <div class="flex" v-if="messageItem == 'SHIPMENT_DELIVERY'">
                <input-image v-model="modalCustomization.image_order_shipment" :crop="true" :cropSize="[500, 410]" :cropViewport="[250, 205]" path="banners/"></input-image>
                <input-text v-model="modalCustomization.status_order_delivery"  class="ml20 fill" :keywords="keywords"></input-text>
            </div>
        
            <div class="flex"  v-if="messageItem == 'TAKEAWAY'">
                <input-image v-model="modalCustomization.image_order_takeaway" :crop="true" :cropSize="[500, 410]" :cropViewport="[250, 205]" path="banners/"></input-image>
                <input-text v-model="modalCustomization.status_order_takeaway"  class="ml20 fill" :keywords="keywords"></input-text>
            </div>
        
            <div class="flex" v-if="messageItem == 'COMPLETED'">
                <input-image v-model="modalCustomization.image_order_completed" :crop="true" :cropSize="[500, 410]" :cropViewport="[250, 205]" path="banners/"></input-image>
                <input-text v-model="modalCustomization.status_order_completed"  class="ml20 fill" :keywords="keywords"></input-text>
            </div>

            <div class="flex" v-if="messageItem == 'CANCELLED'">
                <input-image v-model="modalCustomization.image_order_cancelled" :crop="true" :cropSize="[500, 410]" :cropViewport="[250, 205]" path="banners/"></input-image>
                <input-text v-model="modalCustomization.status_order_cancelled"  class="ml20 fill" :keywords="keywords"></input-text>
            </div>

            <div class="well mt20 secondary-text">
                <div class="mb15">Las siguientes palabras claves se remplazarán por un valor (en mayúsculas e incluyendo las llaves)</div>
                <div><span class="bold">{TOTAL} </span><span>El monto total del pedido</span></div>
                <div><span class="bold">{WAIT} </span><span>Tiempo aproximado que demorara en realizarse el pedido</span></div>
                <div><span class="bold">{TIME} </span><span>Tiempo aproximado en que el pedido estará listo</span></div>
                <div><span class="bold">{DATE} </span><span>Fecha y hora en que está programado el pedido</span></div>
                <div><span class="bold">{ETA} </span><span>Tiempo estimado cuando un encargo será despachado (encargos)</span></div>
                <div><span class="bold">{NAME} </span><span>Nombre del cliente</span></div>
                <div><span class="bold">{ADDRESS} </span><span>Dirección de envío del pedido</span></div>
                <div><span class="bold">{LOCAL} </span><span>Nombre del local</span></div>
                <div><span class="bold">{LOCAL_ADDRESS} </span><span>Dirección del local</span></div>
                <div><span class="bold">{REASON} </span><span>Motivo por cual el pedido fue cancelado</span></div>
                <div><span class="bold">{POS_DISCOUNT} </span><span>Total del descuento ingresado en caja</span></div>
                <div><span class="bold">{METHOD} </span><span>Método de pago</span></div>
            </div>

        </div>
    </div>

    <div v-if="modalCustomization && type == 'NOTIFICATIONS'" class="pa20">

        <div class="mb15">Puedes personalizar las notificaciones que se envian por email al cliente cuando el pedido cambia de estado.</div>

        <input-select class="mb15"
        label="Notificación"
        :items="notificationsItems"
        v-model="notificationItem">
        </input-select>

        <div class="" v-for="(event, key) in {
            'El pedido fue recibido': 'recieved', 
            'Confirmación del pedido': 'confirmation', 
            'Pago online pendiente': 'online_payment',   
            'Deposito bancario pendiente': 'bank_deposit', 
            'Pedido programado agendado': 'scheduled',
            'Pedido en preparación': 'preparing',
            'Encargo procesado': 'shipment_ready',
            'Encargo con retiro': 'shipment_takeaway',
            'Pedido delivery en camino': 'delivery',   
            'Pedido listo para retirar': 'takeaway',
            'Pedido modificado': 'edit',  
            'Pedido completado': 'completed',
            'Pedido cancelado': 'cancelled',
        }" :key="key"
        v-show="event == notificationItem">
            
            <input-toggle class="mb15"
            v-model="modalCustomization['send_email_'+event]"
            label="Enviar email"
            description="Se enviará el correo de notificación al cliente">
            </input-toggle>

            <div v-if="Local.modules.module_store_plus"  v-show="modalCustomization['send_email_'+event]">
                <input-text v-model="modalCustomization['message_title_'+event]" label="Asunto" class="mb15" :rows="1" :keywords="keywordsNotifications"></input-text>
                
                <input-text class="input-top"
                v-model="modalCustomization['message_'+event]" 
                :lines="3" 
                label="Mensaje" 
                :keywords="keywordsNotifications">
                </input-text>
                
                <div class="well mini border bottom secondary-text" style="text-align: right">{{modalCustomization['message_'+event] ? modalCustomization['message_'+event].length : 0}}/180 Caracteres</div>
            </div>
        </div>

        <div v-if="Local.modules.module_store_plus"  v-show="modalCustomization['send_email_'+notificationItem]">
            <div class="well mt15 secondary-text">
                <div class="mb15">Las siguientes palabras claves se remplazarán por un valor al ser enviada una notificación (en mayúsculas e incluyendo las llaves)</div>
                <div><span class="bold">{TOTAL} </span><span>El monto total del pedido</span></div>
                <div><span class="bold">{WAIT} </span><span>Tiempo aproximado que demorara en realizarse el pedido</span></div>
                <div><span class="bold">{DATE} </span><span>Fecha y hora en que está programado el pedido</span></div>
                <div><span class="bold">{ETA} </span><span>Tiempo estimado cuando un encargo será despachado (encargos)</span></div>
                <div><span class="bold">{NAME} </span><span>Nombre del cliente</span></div>
                <div><span class="bold">{ADDRESS} </span><span>Dirección de envío del pedido</span></div>
                <div><span class="bold">{LOCAL} </span><span>Nombre del local</span></div>
                <div><span class="bold">{REASON} </span><span>Motivo por cual el pedido fue cancelado</span></div>
            </div>

            <div class="separator mt20 mb20"></div>

            <input-color
            label="Color del Botón"
            v-model="modalCustomization.email_button_color">
            </input-color>
        </div>

    </div>

</modal>
</template>

<script>
export default {
    data() {
        return {
            type: null,
            modalCustomization: null,
            store: null,
            keywords: {
                '{TOTAL}': '$20.000',
                '{WAIT}': '17:30 hrs',
                '{TIME}': '17:30 hrs',
                '{DATE}': 'sábado 1 de enero a las 17:30 hrs',
                '{ETA}': 'el 1 de enero', 
                '{NAME}': 'Miguel',
                '{ADDRESS}': 'Calle 1, Concepción',
                '{LOCAL}': 'Nombre Local',
                '{LOCAL_ADDRESS}': 'Calle 2, Concepción',
                '{REASON}': 'Motivo por cual el pedido fue cancelado',
                '{POS_DISCOUNT}': '$1.000',
                '{METHOD}': 'Efectivo'
            },
            keywordsNotifications: {
                '{TOTAL}': '$20.000',
                '{WAIT}': '17:30 hrs',
                '{DATE}': 'sábado 1 de enero a las 17:30 hrs',
                '{ETA}': 'el 1 de enero', 
                '{NAME}': 'Miguel',
                '{ADDRESS}': 'Calle 1, Concepción',
                '{LOCAL}': 'Nombre Local',
                '{REASON}': 'Motivo por cual el pedido fue cancelado',
            },
            messageItem: null,
            messageItems: [
                {text: 'Pedido Pendiente', value: 'PENDING'},
                {text: 'Confirmación Necesaria', value: 'CONFIRM'},
                {text: 'Pago Online', value: 'ONLINE_PAYMENT'},
                {text: 'Pago Transferencia Bancaria', value: 'BANK_PAYMENT'},
                {text: 'Pedido en Preparación', value: 'PREPARING'},
                {text: 'Pedido Programado', value: 'SCHEDULED'},
                {text: 'Encargo Procesado', value: 'SHIPMENT'},
                {text: 'Encargo con Retiro', value: 'SHIPMENT_TAKEAWAY'},
                {text: 'Pedido en Tránsito', value: 'DELIVERY'},
                {text: 'Encargo en Tránsito', value: 'SHIPMENT_DELIVERY'},
                {text: 'Pedido Listo para Retiro', value: 'TAKEAWAY'},
                {text: 'Pedido Completado', value: 'COMPLETED'},
                {text: 'Pedido Cancelado', value: 'CANCELLED'},
                {text: 'Descuento Ingresad en Caja', value: 'DISCOUNT'},
            ],
            notificationsItems: [
                {text: 'Pedido fue Recibido', value: 'recieved'},
                {text: 'Confirmación del Pedido', value: 'confirmation'},
                {text: 'Pago Online Pendiente', value: 'online_payment'},
                {text: 'Depósito Bancario Pendiente', value: 'bank_deposit'},
                {text: 'Pedido Programado Agendado', value: 'scheduled'},
                {text: 'Pedido en Preparación', value: 'preparing'},
                {text: 'Encargo Procesado', value: 'shipment_ready'},
                {text: 'Pedido en Camino', value: 'delivery'},
                {text: 'Pedido Listo para Retirar', value: 'takeaway'},
                {text: 'Pedido Modificado', value: 'edit'},
                {text: 'Pedido Completado', value: 'completed'},
                {text: 'Pedido Cancelado', value: 'cancelled'},
            ],
            notificationItem: null,
            titles: {
                STYLE: 'Personalizar Apariencia',
                STYLE_FRANCHISE: 'Personalizar Apariencia',
                MESSAGES: 'Personalizar Mensajes de Estado',
                NOTIFICATIONS: 'Personalizar Notificaciones'
            },
            snippets: {},
            enabledSnippets: {},
            zoomLevel: '',
            url: null,
            snippetGroup: '',
        }
    },
    methods: {
        Open(data, type, store, url)
        {
            this.type = type ? type : this.Local.type == 'FRANCHISE'? 'STYLE_FRANCHISE' : 'STYLE';
            this.modalCustomization = data;
            this.store = store ? store : 'ECOMMERCE';
            if(url && process.env.NODE_ENV === 'production') this.url = url.indexOf('https://') < 0 ? 'https://'+url : url;
            else this.url = process.env.VUE_APP_STORE_URL ? process.env.VUE_APP_STORE_URL : 'http://localhost:8081';
        },
        Close()
        {
            this.modalCustomization = null;
        },
        Save()
        {
            if(this.modalCustomization.css)
            {
                this.modalCustomization.css = this.modalCustomization.css.replace(/\n\n\n/g, '');
            }
            this.$emit('OnSaved', this.modalCustomization);
        },
        LoadSnippets() {

            this.Loading();
            axios.get(`/influye/snippets`).then(res => {

                let lastGroup = null;
                res.data.forEach(entry => {
                    if(entry.key.indexOf('HEADER:') > -1) lastGroup = entry.key;
                    if(lastGroup) entry.group = lastGroup;
                });

                this.snippets = res.data;

                let css = this.modalCustomization.css;
                console.log(this.modalCustomization);
                this.snippets.forEach(snippet => {
                    let re = new RegExp(`\\/\\*SNIPPET_START:${snippet.key}\\*\\/((.|\\n)*)\\/\\*SNIPPET_END:${snippet.key}\\*\\/`, 'g');
                    let matches = css.match(re);
                    if(matches && matches.length)
                    {
                        let snippetCSS = matches[0];
                        this.$set(this.enabledSnippets, snippet.key, 'ENABLED');
                        if(snippetCSS.indexOf('/*MOBILE*/') > -1) this.$set(this.enabledSnippets, snippet.key, 'MOBILE');
                        else if(snippetCSS.indexOf('/*DESKTOP*/') > -1) this.$set(this.enabledSnippets, snippet.key, 'DESKTOP');
                    }   
                    else
                    {
                        this.$set(this.enabledSnippets, snippet.key, 'DISABLED');
                    } 
                });

                this.Response(res);
            }).catch(err => {
                this.Response(err);
            });
        },
        EnableSnippet(key, value)
        {
            this.$set(this.enabledSnippets, key, value);
            console.log('enable snippet ' + key);
            let css = this.modalCustomization.css;
            let re = new RegExp(`\\/\\*SNIPPET_START:${key}\\*\\/((.|\\n)*)\\/\\*SNIPPET_END:${key}\\*\\/`, 'g');
            let matches = css.match(re);
            console.log(matches);
            if(matches && matches.length)
            {
                css = css.replace(matches[0], '');
            }

            if(value != 'DISABLED') 
            {
                let pre = '';
                let after = '';

                if(value == 'MOBILE')
                {
                    pre = '/*MOBILE*/\n.mobile {\n';
                    after = '}\n';
                }
                if(value == 'DESKTOP')
                {
                    pre = '/*DESKTOP*/\n.desktop {\n';
                    after = '}\n';
                }

                let snippet = this.snippets.find(el => {return el.key == key});

                let snippetCode = snippet.code;
                let snippetCSS = `/*SNIPPET_START:${key}*/\n${pre}${snippetCode}\n${after}/*SNIPPET_END:${key}*/`

                css = snippetCSS + '\n\n' + css;
            }

            this.modalCustomization.css = css;
        },
        SnippetCount(key) {
            let ret = 0;
            this.snippets.forEach(snippet => {
                if(snippet.group == key && this.enabledSnippets[snippet.key] !== 'DISABLED') ret++;
            });
            return ret;
        },
        UpdateLivePreview() 
        {
            let iframe = this.$refs.livePreview;
            if(iframe)
            {
               iframe.contentWindow.postMessage(['pcall', this.cssCode], '*');
            }
        },
        MapCSS(haystack, replacement, defaultValue)
        {
            for(var key in replacement)
            {
                if(haystack.indexOf(key) > -1)
                    return replacement[key];
            }
            return defaultValue ? defaultValue : '';
        },

    },
    computed: {
        message() {
            if(!this.modalCustomization || !this.type == 'MESSAGES') return '';
            var ret = '';
            if(this.messageItem == 'PENDING') ret = this.modalCustomization.status_order_pending;
            else if(this.messageItem == 'CONFIRM') ret = this.modalCustomization.status_order_confirmation;
            else if(this.messageItem == 'ONLINE_PAYMENT') ret = this.modalCustomization.status_order_payment;
            else if(this.messageItem == 'BANK_PAYMENT') ret = this.modalCustomization.status_order_bank;
            else if(this.messageItem == 'PREPARING') ret = this.modalCustomization.status_order_preparing;
            else if(this.messageItem == 'SCHEDULED') ret = this.modalCustomization.status_order_scheduled
            else if(this.messageItem == 'SHIPMENT') ret = this.modalCustomization.status_order_shipment;
            else if(this.messageItem == 'SHIPMENT_TAKEAWAY') ret = this.modalCustomization.status_order_shipment_takeaway;
            else if(this.messageItem == 'DELIVERY') ret = this.modalCustomization.status_order_delivery;
            else if(this.messageItem == 'SHIPMENT_DELIVERY') ret = this.modalCustomization.status_order_delivery;
            else if(this.messageItem == 'TAKEAWAY') ret = this.modalCustomization.status_order_takeaway;
            else if(this.messageItem == 'COMPLETED') ret = this.modalCustomization.status_order_completed;
            else if(this.messageItem == 'CANCELLED') ret = this.modalCustomization.status_order_cancelled;
            else if(this.messageItem == 'DISCOUNT') ret = this.modalCustomization.status_order_preparing+'\n\n'+this.modalCustomization.pos_discount;

            for(var key in this.keywords)
            {
                var re = new RegExp(key,"g");
                ret = ret.replace(re, this.keywords[key]);
            }

            return ret.replace(/\n/g, '<br>');
        },
        messageImage() {
            if(!this.modalCustomization || !this.type == 'MESSAGES') return null;
            if(this.messageItem == 'PENDING') return this.Util.Image('banners', this.modalCustomization.image_order_pending, 'PREVIEW_PENDING.png');
            else if(this.messageItem == 'CONFIRM') return this.Util.Image('banners', this.modalCustomization.image_order_confirmation, 'PREVIEW_CONFIRMATION.png');
            else if(this.messageItem == 'ONLINE_PAYMENT') return this.Util.Image('banners', this.modalCustomization.image_order_payment, 'PREVIEW_ONLINE_PAYMENT.png');
            else if(this.messageItem == 'BANK_PAYMENT') return this.Util.Image('banners', this.modalCustomization.image_order_bank, 'PREVIEW_BANK.png');
            else if(this.messageItem == 'PREPARING') return this.Util.Image('banners', this.modalCustomization.image_order_preparing, 'PREVIEW_PREPARING.png');
            else if(this.messageItem == 'SCHEDULED') return this.Util.Image('banners', this.modalCustomization.image_order_scheduled, 'PREVIEW_SCHEDULED.png');
            else if(this.messageItem == 'SHIPMENT') return this.Util.Image('banners', this.modalCustomization.image_order_shipment_ready, 'PREVIEW_SHIPMENT.png');
            else if(this.messageItem == 'SHIPMENT_TAKEAWAY') return this.Util.Image('banners', this.modalCustomization.image_order_shipment_takeaway, 'PREVIEW_SHIPMENT.png');
            else if(this.messageItem == 'DELIVERY') return this.Util.Image('banners', this.modalCustomization.image_order_delivery, 'PREVIEW_DELIVERY.png');
            else if(this.messageItem == 'SHIPMENT_DELIVERY') return this.Util.Image('banners', this.modalCustomization.image_order_shipment, 'PREVIEW_SHIPMENT_DELIVERY.png');
            else if(this.messageItem == 'TAKEAWAY') return this.Util.Image('banners', this.modalCustomization.image_order_takeaway, 'PREVIEW_TAKEAWAY.png');
            else if(this.messageItem == 'COMPLETED') return this.Util.Image('banners', this.modalCustomization.image_order_completed, 'PREVIEW_COMPLETED.png');
            else if(this.messageItem == 'CANCELLED') return this.Util.Image('banners', this.modalCustomization.image_order_cancelled, 'PREVIEW_CANCELLED.png');
            else if(this.messageItem == 'DISCOUNT') return this.Util.Image('banners', this.modalCustomization.image_order_preparing, 'PREVIEW_PREPARING.png');
        },
        notificationMessage() {
            if(!this.modalCustomization || !this.type == 'NOTIFICATION') return null;
            var ret = this.modalCustomization['message_'+this.notificationItem];
            if(!ret) return '';

            for(var key in this.keywordsNotifications)
            {
                var re = new RegExp(key,"g");
                ret = ret.replace(re, this.keywordsNotifications[key]);
            }

            return ret.replace(/\n/g, '<br>');
        },
        cssCode() {
            if(!this.modalCustomization) return '';
            let customizationCSS = `
            /*
            GoogleFont: ${this.modalCustomization.font}
            */
            :root {
                --icon-border-radius: ${this.MapCSS(this.modalCustomization.logo_style, {ROUND: '10px', CIRCLE: '50%'})};
                --icon-shadow: ${this.MapCSS(this.modalCustomization.logo_style, {SHADOW: '0px 10px 10px 0px rgba(0,0,0,0.1)'})};
                --btn-shadow: ${this.MapCSS(this.modalCustomization.button_style, {SHADOW: '0px 3px 7px 0px rgba(0,0,0,0.2)'})};
                --btn-border-radius: ${this.MapCSS(this.modalCustomization.button_style, {ROUND: '3px', CIRCLE: '20px'})};
                --btn-border: ${this.MapCSS(this.modalCustomization.button_style, {BORDER: `3px`, THINBRD: '1px'}, '0px')};
                --left-bg: ${this.modalCustomization.left_bg};
                --left-fc: ${this.modalCustomization.left_fc};
                --left-bg-o: ${this.Util.ApplyOpacity(this.modalCustomization.left_bg, 0.1)}; /*Color con opacidad*/
                --left-fc-o: ${this.Util.ApplyOpacity(this.modalCustomization.left_fc, 0.1)}; /*Color con opacidad*/
                --center-bg: ${this.modalCustomization.center_bg};
                --center-fc: ${this.modalCustomization.center_fc};
                --center-c1: ${this.modalCustomization.center_c1};
                --center-c2: ${this.modalCustomization.center_c2};
                --center-bg-o: ${this.Util.ApplyOpacity(this.modalCustomization.center_bg, 0.1)}; /*Color con opacidad*/
                --center-fc-o: ${this.Util.ApplyOpacity(this.modalCustomization.center_fc, 0.1)}; /*Color con opacidad*/
                --center-c1-o: ${this.Util.ApplyOpacity(this.modalCustomization.center_c1, 0.1)}; /*Color con opacidad*/
                --center-c2-o: ${this.Util.ApplyOpacity(this.modalCustomization.center_c2, 0.1)}; /*Color con opacidad*/
                --right-bg: ${this.modalCustomization.right_bg};
                --right-fc: ${this.modalCustomization.right_fc};
                --right-c1: ${this.modalCustomization.right_c1};
                --right-c2: ${this.modalCustomization.right_c2};
                --right-bg-o: ${this.Util.ApplyOpacity(this.modalCustomization.right_bg, 0.1)};  /*Color con opacidad*/
                --right-fc-o: ${this.Util.ApplyOpacity(this.modalCustomization.right_fc, 0.1)};  /*Color con opacidad*/
                --right-c1-o: ${this.Util.ApplyOpacity(this.modalCustomization.right_c1, 0.1)};  /*Color con opacidad*/
                --right-c2-o: ${this.Util.ApplyOpacity(this.modalCustomization.right_c2, 0.1)};  /*Color con opacidad*/
                --modal-border-radius: ${this.MapCSS(this.modalCustomization.modal_style, {ROUND: '25px'})};
                --modal-bg: ${this.modalCustomization.modal_bg};
                --modal-fc: ${this.modalCustomization.modal_fc};
                --modal-c1: ${this.modalCustomization.modal_c1};
                --modal-c2: ${this.modalCustomization.modal_c2};
                --modal-bg-o: ${this.Util.ApplyOpacity(this.modalCustomization.modal_bg, 0.1)};  /*Color con opacidad*/
                --modal-fc-o: ${this.Util.ApplyOpacity(this.modalCustomization.modal_fc, 0.1)};  /*Color con opacidad*/
                --modal-c1-o: ${this.Util.ApplyOpacity(this.modalCustomization.modal_c1, 0.1)};  /*Color con opacidad*/
                --modal-c2-o: ${this.Util.ApplyOpacity(this.modalCustomization.modal_c2, 0.1)};  /*Color con opacidad*/
                --modal-cy: ${this.modalCustomization.modal_cy};
                --modal-cn: ${this.modalCustomization.modal_cn};
            }
            body, input {
                font-family: '${this.modalCustomization.font}', serif;
            }
            `;


            if(this.modalCustomization.css) customizationCSS += '\n\n' + this.modalCustomization.css;
            
            return customizationCSS;
            
        }
        

    },
    watch: {
        cssCode: function(n, o) {
            this.UpdateLivePreview();
        }
    }
}
</script>

<style lang="scss">

.preview-window {
    position: absolute;
    background: white;
    border-radius: 5px;
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2);
    left: -340px;
    width: 320px; 
    height: 180px;
    display: flex;
    font-size: 6px;
    font-weight: 500;
    overflow: hidden;

    > {
        height: 100%;
    }

    .left {
        width: 25%;
    }
    .middle {
        width: 50%;
    }
    .right {
        width: 25%;
    }

    &.VIRTUAL_MENU {
        .middle {
            width: 75%;
        }
    }

    .c2 {
        opacity: 0.6;
    }

    /*
    {text: 'Cuadrado', value: 'FLAT'},
    {text: 'Cuadrado con sombra', value: 'FLAT_SHADOW'},
    {text: 'Cuadrado con borde', value: 'FLAT_BORDER'},
    {text: 'Redondeado', value: 'ROUND'},
    {text: 'Redondeado con sombra', value: 'ROUND_SHADOW'},
    {text: 'Redondeado con borde', value: 'ROUND_BORDER'},
    {text: 'Circular', value: 'CIRCLE'},
    {text: 'Circular con sombra', value: 'CIRCLE_SHADOW'},
    {text: 'Circular con borde', value: 'CIRCLE_BORDER'},
    */
    

    .FLAT_SHADOW {
        box-shadow: 0px 2px 3px -1px rgba(0,0,0,0.2);
    }
    .FLAT_BORDER {
        border: 1px solid black;
    }
    .ROUND {
        border-radius: 2px;
    }
    .ROUND_SHADOW {
        border-radius: 2px;
        box-shadow: 0px 2px 3px -1px rgba(0,0,0,0.2);
    }
    .ROUND_BORDER {
        border-radius: 2px;
        border: 1px solid black;
    }
    .CIRCLE {
        border-radius: 10px;
    }
    .CIRCLE_SHADOW {
        border-radius: 10px;
        box-shadow: 0px 2px 3px -1px rgba(0,0,0,0.2);
    }
    .CIRCLE_BORDER {
        border-radius: 10px;
        border: 1px solid black;
    }
    img.CIRCLE, img.CIRCLE_SHADOW {
        border-radius: 50%;
    }

    .items-container {
        display: flex;
        > div {
            margin-left: 2px;
            margin-right: 2px;
            &:first-child {
                margin-left: 0px;
            }
            &:last-child {
                margin-right: 0px;
            }
        }
    }

    .MODAL-ROUND {
        border-radius: 6px;
        overflow: hidden;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.4);
    }

    .MODAL-FLAT {
        overflow: hidden;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.4);
    }

}

.delay-05s {
    animation-delay: 0.5s !important;
}

.text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.logo {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    border-radius: 50%;
    margin-right: 10px;
}

.live-preview {
    left: unset; 
    right: 620px; 
    width: 640px; 
    height: 320px;
    overflow: unset;

    iframe {
        display: block; 
        width: 200%; 
        height: 200%; 
        border: 0; 
        transform: scale(0.5); 
        transform-origin: 0 0;
    }

    &.zoom-xl {
        iframe {
            width: 250%; 
            height: 250%; 
            transform: scale(0.4); 
        }
    }

    &.zoom-mobile {
        width: 320px; 
        height: 600px;
        iframe {
            width: 125%; 
            height: 125%; 
            transform: scale(0.8); 
        }
    }

    &.zoom-tablet {
        width: 400px; 
        height: 512px;
        iframe {
            width: 200%; 
            height: 200%; 
            transform: scale(0.5); 
        }
    }

}

</style>