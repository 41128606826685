export default {

    App: null,
    server: 'DEFAULT',
    connectionAddresses: null,
    socket: null,
    backend: process.env.VUE_APP_BACKEND_URL,
    ws: process.env.VUE_APP_REALTIME_SERVER,

    SetServer(server, token)
    {
        server = server ? server : 'DEFAULT';
        console.log(`set server: ${server}`);

        var serverChanged = server !== this.server;        
        this.server = server;

        this.connectionAddresses = this.ConnectionAddresses(server);
        var axios = require('axios');
        var axiosConfig = {
            baseURL: `${this.connectionAddresses.backend}admin/`,
        };
        if(token)
        {
            if(token === 'DEFAULT') token = JSON.parse(localStorage.token);
            axiosConfig.headers = {
                Authorization: `Bearer ${token}`
            }
        }
        window.axios = axios.create(axiosConfig);
        window.axios.interceptors.request.use((config) => {
            const idempotencyKey = Util.UUIDv4();
            config.headers['Idempotency-Key'] = idempotencyKey;
            return config;
        }, (error) => {
            return Promise.reject(error);
        });

        if(serverChanged)
        {
            this.backend = this.connectionAddresses.backend;
            this.ws = this.connectionAddresses.ws;
        }

    },

    ConnectionAddresses(server)
    {
        var backend = process.env.VUE_APP_BACKEND_URL;
        var ws = process.env.VUE_APP_REALTIME_SERVER;
        if(server && server != 'DEFAULT')
        {
            //testing
            //8001 =>localhost:8001, localhost:7001, localhost:6001
            var port = parseInt(server);
            if(Number.isInteger(port))
            {
                backend = `http://localhost:${port}/`;
                ws = `http://localhost:${port-1000}/`;
            }
            else
            {
                //production
                //a1 => a1.influye.app, a1a.influye.app, a1s.influye.app
                backend = `https://${server}.influye.app/`;
                ws = `https://${server}a.influye.online/`;
            }   
        } 
        
        console.log(`backend: ${backend}\nws: ${ws}`);
        
        return {
            'backend': backend,
            'ws': ws,
        };
    },

    ConnectToSocket(url, params)
    {
        if(!this.socket) return;
        url = url ? url : this.connectionAddresses.ws;
        this.socket.disconnect();
        console.log('server changed -> connect to new ws');
        this.socket.io.opts.query = params;
        this.socket.io.uri = url;
        this.socket.connect();
    },

    colors: {
        8: ['#5908fe', '#c900d1', '#fb00a2', '#ff0079', '#ff3358', '#ff7043', '#ff993f', '#f3bb51'],
        7: ['#5908fe', '#d400c9', '#ff0093', '#ff0067', '#ff5f49', '#ff933e', '#f3bb51'],
        6: ['#5908fe', '#e100be', '#ff0080', '#ff4353', '#ff8a3e', '#f3bb51'],
        5: ['#5908fe', '#f100ad', '#ff0067', '#ff7b40', '#f3bb51'],
        4: ['#5908fe', '#ff0093', '#ff5f49', '#f3bb51'],
        3: ['#5908fe', '#ff0067', '#f3bb51'],
        2: ['#5908fe', '#f3bb51'],
        1: '#5908fe'
    },
    baseColors: {
        red: '#ff495b',
        green: '#0cc683',
        yellow: '#f3bb51',
        purple: '#5908fe',
        blue: '#2390fa',
        pink: '#d167d3',
        delivery: '#1e86f1',
        serve: '#46b9b4',
        takeaway: '#e43b50',
        pos: '#2390fa',
        ecommerce: '#E601EB',
        virtualmenu: '#5acd92'
    },
    ChartColor(index, total, keyColors, key) {
        if(keyColors && key && keyColors[key]) return keyColors[key];
        if(total == 1) return this.colors[1];
        return index >= 0 && index < total ? this.colors[Math.min(total, 8)][Math.min(index, 7)] : 'lightgray';
    },
    Chart: {
        purpleGradient: {border: 'rgba(89, 8, 254, 1)', top: 'rgba(89, 8, 254, 0.5)', bottom: 'rgba(89, 8, 254, 0)'},
        purple: {top: 'rgba(89, 8, 254, 1)', bottom: 'rgba(89, 8, 254, 1)'},
        green: {top: '#0cc683', bottom: '#0cc683'},
        red: {top: '#ff495b', bottom: '#ff495b'},
        yellow: {top: '#f3bb51', bottom: '#f3bb51'},
        orderType: {SERVE: '#46b9b4', DELIVERY: '#1e86f1', TAKEAWAY: '#e43b50', Delivery: '#1e86f1', Llevar: '#e43b50'},
        saleType: {INFLUYE_ADMIN: '#2390fa', INFLUYE_WEB: '#E601EB'},
        hours: {'00': '#30354b', '01': '#0a2d65', '02': '#203794', '03': '#3741c4', '04': '#5163cc', '05': '#6c85d5', '06': '#a2a1e7', '07': '#beaeef', '08': '#e2befb', '09': '#f9cde8', '10': '#e7e4f2', '11': '#d0fffe', '12': '#b9ffff', '13': '#89edfd', '14': '#68cffa', '15': '#95dddc', '16': '#cbecb5', '17': '#f8f4aa', '18': '#f9e73d', '19': '#fdb153', '20': '#ff7f69', '21': '#fe4c80', '22': '#d45a81', '23': '#964973', '24': '#30354b', '00:00 hrs': '#30354b', '01:00 hrs': '#0a2d65', '02:00 hrs': '#203794', '03:00 hrs': '#3741c4', '04:00 hrs': '#5163cc', '05:00 hrs': '#6c85d5', '06:00 hrs': '#a2a1e7', '07:00 hrs': '#beaeef', '08:00 hrs': '#e2befb', '09:00 hrs': '#f9cde8', '10:00 hrs': '#e7e4f2', '11:00 hrs': '#d0fffe', '12:00 hrs': '#b9ffff', '13:00 hrs': '#89edfd', '14:00 hrs': '#68cffa', '15:00 hrs': '#95dddc', '16:00 hrs': '#cbecb5', '17:00 hrs': '#f8f4aa', '18:00 hrs': '#f9e73d', '19:00 hrs': '#fdb153', '20:00 hrs': '#ff7f69', '21:00 hrs': '#fe4c80', '22:00 hrs': '#d45a81', '23:00 hrs': '#964973', '24:00 hrs': '#30354b'},
        days: {1: '#993f98', 2: '#f04e52', 3: '#447fc1', 4: '#ffdd00', 5: '#f36e23', 6: '#47bda1', 0: '#bed730', Lunes: '#993f98', Martes: '#f04e52', Miércoles: '#447fc1', Jueves: '#ffdd00', Viernes: '#f36e23', Sábado: '#47bda1', Domingo: '#bed730'}
    },
    DaysArr: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
    DaysArrEs: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
    DictDaysEnToEs: {monday: 'Lunes', tuesday: 'Martes', wednesday: 'Miércoles', thursday: 'Jueves', friday: 'Viernes', saturday: 'Sábado', sunday: 'Domingo'},
    MonthsArrEs: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    CancelledReasons: ['Cliente no encontrado.', 'Cliente disconforme.', 'Pedido incorrecto.', 'Rechazado por demora.'],
    RejectedReasons: ['No podemos cumplir con el pedido.', 'Nos demoraremos mucho en hacer el pedido.', 'No alcanzaremos a entregar el pedido.'],
    Plans: {
        FREE: {
            title: 'Gratis',
            max_transactions: 500,
            max_customers: 500,
            max_accounts: 4
        },
        PYME: {
            title: 'Pyme',
            max_transactions: 1000,
            max_customers: 1500,
            max_accounts: 8
        },
        PRO: {
            title: 'Pro',
        },
        POSPYME: {
            title: 'POS Pyme',
            max_transactions: 500,
            version: 2
        },
        POSPRO: {
            title: 'POS Pro',
            version: 2
        },
        ECOMMERCE: {
            title: 'Ecommerce',
            version: 2
        },
        ECOMMERCEPLUS: {
            title: 'Ecommerce Plus',
            version: 2
        },
        'POSPYME+ECOMMERCE': {
            title: 'POS Pyme + Ecommerce',
            version: 2
        },
        'POSPRO+ECOMMERCE': {
            title: 'POS Pro + Ecommerce',
            version: 2
        },
        'POSPYME+ECOMMERCEPLUS': {
            title: 'POS Pyme + Ecommerce Plus',
            version: 2
        },
        'POSPRO+ECOMMERCEPLUS': {
            title: 'POS Pro + Ecommerce Plus',
            version: 2
        },
        DEMO: {
            title: 'Demo'
        }
    },
    Roles: {
        admin: 'Admin Global',
        local_admin: 'Admin Local',
        cashier: 'Caja',
        delivery: 'Repartidor',
        kitchen: 'Cocina',
        sub_admin: 'Sub Admin',
        waiter: 'Mesero',
    },
    SaleType: {
        TAKEAWAY: 'Llevar',
        SERVE: 'Servir',
        DELIVERY: 'Delivery',
    },
    TransactionStatus: {
        PENDING: 'Pendiente',
        DELIVERY: 'Delivery',
        COMPLETED: 'Completada',
        CANCELLED: 'Cancelada'
    },
    FacturaStatus: {
        SENT: { label: 'Emitida', color: 'cyellow' },
        RECIEVED: { label: 'Recibido confirmado', color: 'cgreen' },
        OK: { label: 'Recibida exitósamente', color: 'cgreen' },
        CANCELLED_SENT: { label: 'Cancelación enviada', color: 'cyellow' },
        CANCELLED_RECIEVED: { label: 'Cancelación recibida', color: 'cgreen' },
        CANCELLED: { label: 'Cancelada', color: 'cgreen' },
        ERROR: { label: 'Error en sobre', color: 'cred' },
        FAILED: { label: 'Error de documento', color: 'cred' },
        RETRY_VERIFICATION_FAILED: { label: 'No hubo respuesta del SII', color: 'cred' },
        RETRY_VALIDATION_FAILED: { label: 'No hubo respuesta del SII', color: 'cred' },
    },
    TransactionSource: {
        INFLUYE_APP: {title: 'Influye App', icon: 'star'},
        INFLUYE_WEB: {title: 'Página Web', icon: 'web'},
        INFLUYE_ADMIN: {title: 'Influye Admin', icon: 'cashier'},
        UBEREATS: {title: 'UberEats', icon: 'ubereats'}
    },
    TransactionRevisionsEvents: {
        EDIT_DELIVERY: 'Se cambió el repartidor',
        EDIT_PAYMENT: 'Se cambió el método de pago y/o propinas',
        EDIT_ORDER: 'Se editaron items del pedido',
        EDIT_ORDER_TYPE: 'Se cambió el tipo del pedido',
        REMOVED_ITEM: 'Se quitaron items del pedido',
        TRANSFER_ITEM_RECIEVED: 'Se recibieron items de otra mesa',
        TRANSFER_ITEM: 'Se enviaron items a otra mesa',
        EDIT_TRANSACTION: 'Se editó la transacción',
        EDIT_PARTIAL_PAYMENT: 'Se registró un pago parcial',
        COMPLETE_TRANSACTION: 'Se completó el pedido'
    },
    DTE: {
        30: 'Factura',
        32: 'Factura de ventas y servicios no afectos o exentos de IVA',
        33: 'Factura electrónica',
        34: 'Factura no afecta o exenta electrónica',
        35: 'Boleta',
        38: 'Boleta exenta',
        39: 'Boleta electrónica',
        40: 'Liquidación factura',
        41: 'Boleta exenta electrónica',
        43: 'Liquidación factura electrónica',
        45: 'Factura de compra',
        46: 'Factura de compra electrónica',
        48: 'Pago electrónico',
        50: 'Guía de despacho',
        52: 'Guía de despacho electrónica',
        55: 'Nota de débito',
        56: 'Nota de débito electrónica',
        60: 'Nota de crédito',
        61: 'Nota de crédito electrónica',
        103: 'Liquidación',
        110: 'Factura de exportación electrónica',
        111: 'Nota de débito de exportación electrónica',
        112: 'Nota de crédito de exportación electrónica'
    },
    AvatarColors: ['#d18000', '#d0433f', '#dc1777', '#c233a0', '#6064e2', '#246eb6', '#018290', '#7ba100', '#9256d2', '#637989'],
    Keywords: {
        DELIVERY: {label: 'Delivery', icon: '666'},
        TAKEAWAY: {label: 'Llevar', icon: '3529'},
        SERVE: {label: 'Servir', icon: '1295'},
        CASH: {label: 'Efectivo', icon: '706'},
        ONLINE: {label: 'Pago Online', icon: '687'},
        BANK_DEPOSIT: {label: 'Transferencia', icon: '1090'},
        INFLUYE_ADMIN: {label: 'POS', icon: '4773'},
        INFLUYE_WEB: {label: 'Store', icon: '1469'},
        UNDEFINED: {label: 'Sin definir', icon: '226'},
    },
    LogTypes: {
        BOLETA_CREATED: {title: '', icon: 'receipt', color: '#0cc683', secondary: 'plus'},
        BOLETA_FAILED: {title: '', icon: 'receipt', color: '#ff495b', secondary: 'circle-exclamation'},
        BOLETA_ERROR_SENT: {title: '', icon: 'receipt', color: '#ff495b', secondary: 'circle-exclamation'},
        BOLETA_ERROR_RECIEVED: {title: '', icon: 'receipt', color: '#ff495b', secondary: 'circle-exclamation'},
        BOLETA_OK: {title: '', icon: 'receipt', color: '#5908fe', secondary: 'check'},
        
        CUSTOMER_CREATED: {title: '', icon: 'user', color: '#0cc683', secondary: 'plus'},
        CUSTOMER_DELETED: {title: '', icon: 'user', color: '#ff495b', secondary: 'trash'},
        CUSTOMER_UPDATED: {title: '', icon: 'user', color: '#f3bb51', secondary: 'edit'},
        CUSTOMERS_IMPORT: {title: '', icon: 'user', color: '#0cc683', secondary: 'check-list'},
        CUSTOMER_BAN: {title: '', icon: 'user', color: '#ff495b', secondary: 'forbidden'},
        CUSTOMER_UNBAN: {title: '', icon: 'user', color: '#0cc683', secondary: 'circle-check'},
        
        EXPENSE_ADDED: {title: '', icon: 'money', color: '#0cc683', secondary: 'plus'},
        EXPENSE_DELETED: {title: '', icon: 'money', color: '#ff495b', secondary: 'trash'},
        EXPENSE_ITEM_CREATED: {title: '', icon: 'money', color: '#0cc683', secondary: 'plus'},
        EXPENSE_ITEM_EDIT: {title: '', icon: 'money', color: '#f3bb51', secondary: 'edit'},
        EXPENSE_ITEM_DELETED: {title: '', icon: 'money', color: '#ff495b', secondary: 'trash'},
        EXPENSE_CREATED: {title: '', icon: 'money', color: '#0cc683', secondary: 'plus'},
        
        IAP_CREATED: {title: '', icon: 'web', color: '#0cc683', secondary: 'plus'},
        IAP_ACCEPTED: {title: '', icon: 'web', color: '#0cc683', secondary: 'check'},
        IAP_DISCOUNT: {title: '', icon: 'web', color: '#f3bb51', secondary: 'discount'},
        IAP_REJECTED: {title: '', icon: 'web', color: '#ff495b', secondary: 'forbidden'},
        IAP_DISABLED: {title: '', icon: 'web', color: '#ff495b', secondary: 'forbidden'},
        IAP_ENABLED: {title: '', icon: 'web', color: '#0cc683', secondary: 'circle-check'},
        IAP_AUTO_ACCEPT_ENABLED: {title: '', icon: 'web', color: '#0cc683', secondary: 'attention-bell'},
        IAP_AUTO_ACCEPT_DISABLE: {title: '', icon: 'web', color: '#ff495b', secondary: 'attention-bell'},
        
        INVENTORY_ADDED: {title: '', icon: 'box', color: '#0cc683', secondary: 'plus'},
        INVENTORY_REMOVED: {title: '', icon: 'box', color: '#ff495b', secondary: 'minus'},
        INVENTORY_EDIT: {title: '', icon: 'box', color: '#f3bb51', secondary: 'edit'},
        INVENTORY_CREATED: {title: '', icon: 'box', color: '#0cc683', secondary: 'plus'},
        INVENTORY_DELETED: {title: '', icon: 'box', color: '#ff495b', secondary: 'trash'},
        INVENTORY_TRANSACTION: {title: '', icon: 'box', color: '#f3bb51', secondary: 'plus'},
        INVENTORY_TRANSACTION_DELETED: {title: '', icon: 'box', color: '#ff495b', secondary: 'trash'},
        INVENTORY_INVOICE_COMPLETED: {title: '', icon: 'check-list', color: '#5908fe', secondary: 'check'},
        INVENTORY_INVOICE_DELETED: {title: '', icon: 'check-list', color: '#ff495b', secondary: 'trash'},
        INVENTORY_INVOICE_CREATED: {title: '', icon: 'check-list', color: '#0cc683', secondary: 'plus'},
        INVENTORY_INVOICE_EDIT: {title: '', icon: 'check-list', color: '#f3bb51', secondary: 'edit'},
        PROVIDER_CREATED: {title: '', icon: 'check-list', color: '#0cc683', secondary: 'check'},
        PROVIDER_EDIT: {title: '', icon: 'check-list', color: '#f3bb51', secondary: 'edit'},
        PROVIDER_DELETED: {title: '', icon: 'check-list', color: '#ff495b', secondary: 'trash'},
        INVOICE_COMPLETED: {title: '', icon: 'check-list', color: '#5908fe', secondary: 'plus'},
        INVENTORY_IMPORTED: {title: '', icon: 'box', color: '#f3bb51', secondary: 'check-list'},
        INVENTORY_RESET: {title: '', icon: 'box', color: '#ff495b', secondary: 'cross'},
        INVENTORY_RESET_ITEMS: {title: '', icon: 'box', color: '#ff495b', secondary: 'cross'},
        
        PENDING_PAYMENT_COMPLETED: {title: '', icon: 'hourglass', color: '#5908fe', secondary: 'check'},
        
        RVD_ERROR: {title: '', icon: 'sheet', color: '#ff495b', secondary: 'circle-exclamation'},
        RVD_NO_PERMISSION: {title: '', icon: 'sheet', color: '#ff495b', secondary: 'circle-exclamation'},
        RVD_NOT_RECIEVED: {title: '', icon: 'sheet', color: '#ff495b', secondary: 'circle-exclamation'},
        RVD_RECIEVED: {title: '', icon: 'sheet', color: '#5908fe', secondary: 'check'},
        
        FOLIO_CONSUMED: {title: '', icon: 'sheet', color: '#f3bb51', secondary: 'circle-exclamation'},
        BOLETA_RECIEVED: {title: '', icon: 'invoice', color: '#0cc683', secondary: 'check'},
        BOLETA_REJECTED: {title: '', icon: 'invoice', color: '#ff495b', secondary: 'forbidden'},

        TABLE_DISJOIN: {title: '', icon: 'table', color: '#f3bb51', secondary: 'link'},
        TABLE_JOIN: {title: '', icon: 'table', color: '#f3bb51', secondary: 'link'},
        TABLE_RESERVATION: {title: '', icon: 'table', color: '#d167d3', secondary: 'calendar'},
        
        TRANSACTION_CANCELLED: {title: '', icon: 'bag', color: '#ff495b', secondary: 'forbidden'},
        TRANSACTION_COMPLETED: {title: '', icon: 'bag', color: '#5908fe', secondary: 'check'},
        TRANSACTION_CREATED: {title: '', icon: 'bag', color: '#0cc683', secondary: 'plus'},
        TRANSACTION_DELETED: {title: '', icon: 'bag', color: '#ff495b', secondary: 'trash'},
        TRANSACTION_DELIVERY: {title: '', icon: 'bag', color: '#2390fa', secondary: 'right-arrow'},
        TRANSACTION_EDIT: {title: '', icon: 'bag', color: '#f3bb51', secondary: 'edit'},
        TRANSACTION_PAID: {title: '', icon: 'bag', color: '#0cc683', secondary: 'bill'},
        TRANSACTION_EDIT_ADDED_ITEMS: {title: '', icon: 'bag', color: '#f3bb51', secondary: 'plus'},
        TRANSACTION_EDIT_REMOVED_ITEMS: {title: '', icon: 'bag', color: '#f3bb51', secondary: 'minus'},
        
        TURN_ENDED: {title: '', icon: 'chronometer', color: '#0cc683', secondary: 'check'},
        TURN_STARTED: {title: '', icon: 'chronometer', color: '#d167d3', secondary: 'right-arrow'},
        TURN_COMMENT: {title: '', icon: 'chronometer', color: '#f3bb51', secondary: 'comment'},
        TURN_CASH: {title: '', icon: 'chronometer', color: '#f3bb51', secondary: 'bill'},

        COUPON_USED: {title: '', icon: 'coupon', color: '#E601EB', secondary: 'receipt'},
        COUPON_CREATED: {title: '', icon: 'coupon', color: '#0cc683', secondary: 'plus'},
        COUPON_EDIT: {title: '', icon: 'coupon', color: '#f3bb51', secondary: 'edit'},
        COUPON_CODES_DELETED: {title: '', icon: 'coupon', color: '#ff495b', secondary: 'trash'},
        COUPON_CODES_ADDED: {title: '', icon: 'coupon', color: '#0cc683', secondary: 'plus'},
        COUPON_DELETED: {title: '', icon: 'coupon', color: '#ff495b', secondary: 'trash'},
        COUPON_ENABLED: {title: '', icon: 'coupon', color: '#0cc683', secondary: 'check'},
        COUPON_DISABLED: {title: '', icon: 'coupon', color: '#ff495b', secondary: 'forbidden'},

        MENU_SECTION_CREATED: {title: '', icon: 'menu', color: '#0cc683', secondary: 'plus'},
        MENU_SECTION_EDIT: {title: '', icon: 'menu', color: '#f3bb51', secondary: 'edit'},
        MENU_SECTION_DELETED: {title: '', icon: 'menu', color: '#ff495b', secondary: 'trash'},
        MENU_ITEM_CREATED: {title: '', icon: 'menu', color: '#0cc683', secondary: 'plus'},
        MENU_ITEM_DELETED: {title: '', icon: 'menu', color: '#ff495b', secondary: 'trash'},
        MENU_ITEM_EDIT: {title: '', icon: 'menu', color: '#f3bb51', secondary: 'edit'},
        MENU_AVAILABILITY_EDIT: {title: '', icon: 'menu', color: '#f3bb51', secondary: 'edit'},
        MENU_SCHEDULE_CREATED: {title: '', icon: 'menu', color: '#0cc683', secondary: 'plus'},
        MENU_SCHEDULE_EDIT: {title: '', icon: 'menu', color: '#f3bb51', secondary: 'edit'},
        MENU_SCHEDULE_DELETED: {title: '', icon: 'menu', color: '#ff495b', secondary: 'trash'},
        MENU_IMPORTED: {title: '', icon: 'menu', color: '#f3bb51', secondary: 'check-list'},

        SCHEDULED_DATE_EDIT: {title: '', icon: 'calendar', color: '#f3bb51', secondary: 'edit'},

    },
    StatusOrderPya: {
        REJECTED: 'Rechazado',
        EXPIRED: 'Expirado',
        PREORDER: 'Por confirmar',
        CONFIRMED: 'Confirmado',
        CANCELLED: 'Cancelado',
        IN_PROGRESS: 'En progreso',
        NEAR_PICKUP: 'Cerca de retiro',
        PICKED_UP: 'Recogido',
        NEAR_DROPOFF: 'Cerca de entrega',
        COMPLETED: 'Entregado'
    },
    StatusCodeOrderPya: {
        REJECTED: 'Rech',
        EXPIRED: 'Exp',
        PREORDER: 'Pre',
        CONFIRMED: 'Conf',
        CANCELLED: 'Canc',
        IN_PROGRESS: 'Prog',
        NEAR_PICKUP: 'Ret',
        PICKED_UP: 'Recog',
        NEAR_DROPOFF: 'Ent',
        COMPLETED: 'Com'
    },
    PayMethod(method) {
        if(method == 'CASH') return 'Efectivo';
        if(method == 'UNDEFINED') return 'Sin definir';
        if(method == 'ONLINE') return 'Pago Online';
        if(method == 'BANK_DEPOSIT') return 'Transferencia';
        if(method == 'PENDING_PAYMENT') return 'Pago Pendiente';
        if(method == 'NO_SELECT') return 'Seleccionar método de pago';
        return method;
    },
    TransbankPaymentType(type) {
        /*
        VD = Venta Débito.
        VN = Venta Normal.
        VC = Venta en cuotas.
        SI = 3 cuotas sin interés.
        S2 = 2 cuotas sin interés.
        NC = N Cuotas sin interés
        VP = Venta Prepago.
        */
        if(type == 'VN') return 'Crédito 1 cuota';
        if(type == 'S2') return 'Crédito 2 cuotas (sin interés)';
        if(type == 'SI') return 'Crédito 3 cuotas (sin interés)';
        if(type == 'VC') return 'Crédito en cuotas';
        if(type == 'VD') return 'Débito Redcompra';
        if(type == 'VP') return 'Débito Redcompra';
        return 'Crédito en cuotas';
    },
    AccountType(account) {
        if(account.role == 'admin') return {label: 'Super Admin', color: '#935eff'};
        if(account.role == 'sub_admin')
        {
            if(account.is_salesman) return {label: 'Consultor', color: '#0cc683'};
            else return {label: 'Sub Admin', color: '#2390fa'};
        } 
        if(account.role == 'local_admin') return {label: 'Administrador', color: '#935eff'};
        if(account.role == 'cashier') return {label: 'Cajero', color: '#ff8d34'};
        if(account.role == 'delivery') return {label: 'Repartidor', color: '#1e86f1'};
        if(account.role == 'kitchen') return {label: 'Cocina', color: '#e43b50'};
        if(account.role == 'waiter') return {label: 'Mesero', color: '#5acd92'};
        return null;
    },
    PrivilegeType(privilege) {
        if(privilege.type.indexOf('ACCOUNTS') > -1) return {color: '#009bdb', icon: 'fa fa-user'};
        if(privilege.type.indexOf('DISCOUNTS') > -1) return {color: 'rgb(0, 181, 255)', icon: 'fa fa-percentage'};
        if(privilege.type.indexOf('PRODUCTS') > -1) return {color: '#75ad74', icon: 'fa fa-drumstick-bite'};
        if(privilege.type.indexOf('LOCAL') > -1) return {color: '#ec5757', icon: 'fa fa-building'};
        return {color: '#5757ec', icon: 'fa fa-lock'};
    },
    TransactionType(transaction, basic){
        var ret = '';
        if(transaction.type == 'DELIVERY') ret = transaction.custom_data && transaction.custom_data.shipment ? 'Encargo' : 'Delivery';
        if(transaction.type == 'TAKEAWAY') ret =  transaction.custom_data && (transaction.custom_data.activation_time || transaction.custom_data.is_shipment_takeaway) ? 'Retirar' : 'Llevar';
        if(transaction.type == 'SERVE') ret = transaction.table_name ? (basic ? 'Mesa' : `Mesa (${transaction.table_name})`) : 'Servir';
        if(basic) return ret;
        return ret + (transaction.iap ? ` (Pedido a través de ${this.TransactionSource[transaction.source].title})` : '');
    },

    GoTo(path) {
        window.location.href = path;
    },

    IsFile(str){
        if(str == null) return false;
        return str.substring(str.length-4).substring(0,1) == '.';
    },

    MinutesToTime(mins){
        if(mins === null || typeof mins == 'string' && mins.trim() === '') return '';
        mins = parseInt(mins);
        var ret = this.Zeropad((Math.floor(mins/60)%24),2).toString() + ':' + this.Zeropad(mins%60, 2).toString();
        return ret;
    },

    SecondsToTime(secs, base)
    {
        if(!base) base = '';
        var value = 0;
        var unit = '';
        secs = parseInt(secs);
        
        if(secs < 60)
        {
            value = secs;
            unit = value == 1 ? 'segundo' : 'segundos';
            secs = 0;
        }
        else if(secs < 3600)
        {
            value = parseInt(secs / 60);
            unit = value == 1 ? 'minuto' : 'minutos';
            secs -= value * 60;
        }
        else if(secs < 86400)
        {
            value = parseInt(secs / 3600);
            unit = value == 1 ? 'hora' : 'horas';
            secs -= value * 3600;
        }
        else if(secs <  2592000)
        {
            value = Math.round(secs / 86400);
            unit = value == 1 ? 'día' : 'dias';
            secs -= value * 86400;
        }
        else if(secs < 31536000)
        {
            value = Math.round(secs / 2592000);
            unit = value == 1 ? 'mes' : 'meses';
            secs -= value * 2592000;
        }
        else
        {
            value = Math.round(secs / 31536000);
            unit = value == 1 ? 'año' : 'años';
            secs -= value * 31536000;
        }
        
        var ret = value + ' ' + unit;
        if(base == 'SINGLE') return ret;
        if(secs > 0) return this.SecondsToTime(secs, base + ret + ' ');
        return base + ret;
    },

    Zeropad(number, amount) {
        if (number >= Math.pow(10, amount)) {
            return number;
        }
        return (Array(amount).join(0) + number).slice(-amount);
    },

    Copy(obj, values) 
    {
        var ret = JSON.parse(JSON.stringify(obj));
        if(values)
        {
            for(var key in values)
            {
                ret[key] = values[key];
            }
        }
        return ret;
    },

    InString(haystack, needle, returnIfUndefined)
    {
        if(!haystack || !needle) return returnIfUndefined == undefined ? true : returnIfUndefined;
        return haystack.trim().toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").indexOf(needle.trim().toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) > -1;
    },

    HasPrivileges(privileges, allOfThem) {
        
        if(!Auth) return false;
        if(Auth.role == 'admin' || Auth.role == 'local_admin') return true;
        if(!allOfThem) allOfThem = false;
        if(typeof privileges == 'string') privileges = [privileges];

        for(var i = 0; i < privileges.length; i++)
        {
            var hasPrivilege = Auth.privileges.find((el) => {return el == privileges[i]}) != null;
            if(allOfThem && !hasPrivilege) return false;
            if(!allOfThem && hasPrivilege) return true;
        }
        return allOfThem;
    },

    ProfilePicture(url, size)
    {
        if(!size) size = 50;
        if(url.indexOf('facebook') > -1) return `${url}?width=${size}&height=${size}`;
    },

    Number(number, short)
    {
        if(number === null || number === undefined || isNaN(number)) return '';
        //number = (Math.round(number*10)/10).toString().replace('.', ',');
        //number = number.toString().replace('.', ',');

        //number = parseFloat(number);
        number = parseInt(number * 1000) / 1000;

        var suffix = '';
        if(short)
        {
            if(number >= 1000000)
            {
                number = number / 1000000;
                suffix = 'M';
            }
            else if(number >= 1000)
            {
                number = number / 1000;
                suffix = 'K';
            }
            //number = (Math.round(number*10)/10).toString().replace('.', ',');
            number = (Math.round(number*10)/10);
        }

        number = number.toString();
        var parts = number.split('.');
        return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.') + (parts.length > 1 ? `,${parts[1]}` : '') + suffix;
        //return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + suffix;
    },

    Price(amount, short) {
        return '$' + this.Number(parseInt(amount), short);
    },

    Date(date, format, locale, inputFormat)
    {
        if(!date) return 'Desconocido';
        if(!format) format = 'L';

        var moment = require('moment');
        moment.locale(locale ? locale : 'es');
        var ret = null;
        if(date == 'now') ret = moment();
        else if(typeof(date) == 'number') ret = moment.unix(date);
        else ret = inputFormat ? moment(date, inputFormat) : moment(date);
        return format == 'timestamp' ? ret.unix() : ret.format(format);
    },

    Rut(rut)
    {
        if(!rut) return null;
        if(typeof rut == 'number') rut = rut.toString();
        var dv = rut.substr(rut.length - 1);
        return `${this.Number(rut.substr(0, rut.length-1))}-${dv}`;
    },

    GroupByDate(data, range, calculateTotal)
    {
        if(data.length == 0) return;
        if(range.group == 'AUTO')
        {
            range.rangeStart = data[0].date;
            range.rangeEnd = data[data.length-1].date;
            var diff = (data[data.length-1].date - data[0].date) / (60*60);
            if(diff < 24) range.group = 'hour';
            else if(diff < 24*30) range.group = 'day';
            else if(diff < 24*30*12) range.group = 'month';
            else range.group = 'year';
        }

        var moment = require('moment');
        moment.locale('es');

        var ret = [];
        var groups = {};

        var start = moment.unix(range.rangeStart).startOf(range.group);
        var end = moment.unix(range.rangeEnd);


        do {

            var group = start.unix();
            if(range.group == 'hour') group = this.Date(group, 'HH:mm');
            else if(range.group == 'day') group = this.Date(group, 'L');
            else if(range.group == 'weekday') group = this.Date(group, 'ddd');
            else if(range.group == 'month') group = this.Date(group, 'MMM');
            else if(range.group == 'year') group = this.Date(group, 'YYYY');

            groups[start.unix()] = {
                'group': group,
                'date': start.toDate(),
                'data': [],
                'total': 0
            };
            ret.push(groups[start.unix()]);
            start.add(1, range.group == 'weekday' ? 'day' : range.group);
        } while(start.unix() < end.unix())


        for(var i = 0; i < data.length; i++)
        {
            var group = moment.unix(data[i].date).startOf(range.group == 'weekday' ? 'day' : range.group).unix();
            groups[group].data.push(data[i]);
            if(calculateTotal) {
                groups[group].total += calculateTotal(data[i]);
            }
        }

        return ret;
    },

    URI(url) 
    {
        url = url.replace(/\//g, '|');
        return encodeURIComponent(url);
    },

    Ratio(current, total)
    {
        if(!current || !total || total == 0) return '0';
        return (100*current/total * 10 / 10).toFixed(1);
    },

    DefaultLocalConfig(local)
    {
        if(local.config == null) local.config = {};
        if(local.config.accept_cash == null) local.config.accept_cash = true;
        if(local.config.pay_methods == null) local.config.pay_methods = [];
        if(local.config.accept_delivery == null) local.config.accept_delivery = true;
        if(local.config.expenses_types == null) local.config.expenses_types = [];
        return local;
    },

    PrintTransaction(transaction, types, event)
    {
        if(!types) types = 'ALL';

        if(types == 'PENDING-ALL' || transaction.status == 'COMPLETED')
        {
            types = types == 'PENDING-ALL' ? 'PENDING' : types;
            var t = this.Copy(transaction);
            for(var i = 0; i < t.orders.length; i++)
            {
                t.orders[i].delivered = false;
                t.orders[i].prepared = false;
            }
            transaction = t;
        }
        else if(types == 'PENDING-LAST')
        {
            types = 'PENDING';
            var t = this.Copy(transaction);
            var timestamp = 0;
            for(var i = 0; i < t.orders.length; i++)
            {
                t.orders[i].delivered = false;
                t.orders[i].prepared = false;
                if(t.orders[i].added_at > timestamp) timestamp = t.orders[i].added_at;
            }
            for(var i = t.orders.length-1; i >= 0; i--)
                if(t.orders[i].added_at !== timestamp) t.orders.splice(i, 1);
            transaction = t;
        }

        var idEvent = Math.random().toString();
        console.log('print transaction request');
        this.NotifyService('reprint', {data: transaction, id: idEvent, types: types, event: event});
    },

    NotifyService(action, data)
    {
        var Axios = require('axios');
        Axios.post(process.env.VUE_APP_INFLUYE_SERVICE + action, data).then(res => {
            console.log(res);
        }).catch(err => {
            console.log(err);
        });

        data.action = action;
        axios.post(`/service/notify/${this.App.Local.id}`, {data: data}).then(res => {
            console.log(res);
        }).catch(err => {
            console.log(err);
        });
    },

    isMobileOrTable()
    {
        var userAgent = this.getUserAgent();

        var userAgentPart = userAgent.substr(0, 4);
    
        return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(userAgent)
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(userAgentPart);    
    },

    errors: {
        INVALID_LOCAL: 'Local inválido',
        NO_DATA: 'No hay datos disponibles.',
        INVALID_ACCOUNT: 'Usuario o contraseña incorrecta.',
        INVALID_DELIVERY: 'Información de delivery insuficiente. Se debe llenar al menos el campo de dirección.',
        TRANSACTION_CANCELLED: 'La transacción ya ha sido cancelada.',
        TRANSACTION_COMPLETED: 'La transacción ya ha sido completada.',
        LOCAL_NULL: 'El local ingresado no existe.',
        STOCK_NULL: 'El item de inventario ingresado no existe.',
        REPORT_NULL: 'EL reporte ingresado no existe.',
        USER_NULL: 'El cliente ingresado no existe.',
        INVALID_RUT: 'El RUT ingresado no es válido.',
        INVALID_PHONE: 'El teléfono ingresado no es válido.',
        ERROR: 'Ha ocurrido un error. Inténtalo de nuevo más tarde.',
        DISCOUNT_NULL: 'El descuento seleccionado no existe.',
        DISCOUNT_TAKEN: 'Ya se ha tomado este descuento el día de hoy. ¡Vuelve otro día!',
        DISCOUNT_SCHEDULE_UNAVAILABLE: 'El descuento seleccionado no está disponible en estos momentos, inténtalo otro día u a otra hora.',
        COUPON_USED: 'Este cupón de descuento ya ha sido utilizado.',
        COUPON_EXPIRED: 'Este cupón de descuento ha expirado.',
        COUPON_TAKEN: 'El cupón ya ha sido obtenido.',
        COUPON_UNAVAILABLE: 'Código inválido',
        WRONG_VALIDATION_CODE: 'El código de validación ingresado es incorrecto.',
        WRONG_VERIFICATION_CODE: 'El código de validación ingresado es incorrecto.',
        VERIFICATION_NULL: 'Verificación no disponible.',
        ALREADY_VERIFIED: 'La cuenta ya ha sido verificada.',
        EMAIL_RUT_TAKEN: 'Correo o RUT ingresado ya ha sido utilizado.',
        INVALID_RUT: 'El RUT ingresado no es válido.',
        INVALID_RUT_FORMAT_EXCEL : 'El RUT ingresado debe ser solo números, sin puntos ni guión.',
        WRONG_USER: 'Este cupón pertenece a otra persona. ¡No sabemos como ha llegado a tus manos!',
        PASS_TAKEN: 'El código ingresado ya ha sido utilizado.',
        PASS_EXPIRED: 'El código ingresado ha expirado.',
        PASS_FULL: 'El código ingresado ya no está disponible.',
        GIFT_EXPIRED: 'El código ingresado ha expirado.',
        GIFT_FULL: 'El código ingresado ya no está disponible.',
        GIFT_UNAVAILABLE: 'El código ingresado ya no está disponible.',
        GIFT_TAKEN: 'El código ingresado ya ha sido utilizado.',
        CODE_NULL: 'El código ingresado no es válido.',
        CODE_EXPIRED: 'El código ingresado ha expirado.',
        INSUFFICENT_LEVEL: 'Nivel insuficiente.',
        INSUFFICENT_COINS: 'Corbatines insuficientes.',
        LOCAL_NULL: 'El local solicitado no existe.',
        CODE_INVALID: 'El código ingresado no es válido.',
        TRANSACTION_CANCELLED: 'La compra seleccionada ha sido cancelada.',
        TRANSACTION_COMPLETED: 'La compra seleccionada ha sido completada.',
        TRANSACTION_PENDING: 'La compra seleccionada se encuentra pendiente. Recibirás tus puntos cuando se termine la transacción.',
        TRANSACTION_NULL: 'No se ha encontrado ninguna transacción',
        INVALID_REPORT_RANGE: 'Rango de tiempo del reporte inválido',
        CUSTOMER_NULL: 'El cliente no existe',
        INVALID_TURN_PASSWORD: 'La contraseña ingresada es incorrecta',
        ACCOUNT_EXISTS: 'Ya existe un usuario con esta cuenta',
        ACCOUNT_NULL: 'No existe la cuenta',
        COUPON_NULL: 'No existe el cupón',
        INVALID_FILE: 'Archivo inválido',
        INVENTORY_NULL: 'No existe el item de inventario',
        PAYMENT_METHOD_NULL: 'No existe el método de pago',
        NO_PENDING_TRANSACTIONS: 'No hay transacciones pendientes',
        ACCESS_DENIED: 'Acceso denegado',
        TURN_ACTIVE: 'Esta cuenta ya tiene un turno activo',
        TURN_INACTIVE: 'No hay un turno activo',
        TRANSACTION_INVALID: 'Transacción inválida',
        DELIVERY_ACCOUNT_INVALID: 'Repartidor inválido',
        TRANSACTION_COMPLETED_USER_NULL: 'Se ha completado la transacción pero el usuario ingresado no existe',
        WRONG_LOGIN: 'Usuario o contraseña incorrecta',
        INVALID_DATA: 'Los datos ingresados no son válidos',
        DEMO_ACCOUNT: 'Esta es una cuenta demo por lo que no se puede eliminar ni cambiar la contraseña',
        DEMO_WRONG_LOGIN: 'La contraseña ingresada no es correcta o el demo al que se intenta acceder ha expirado',
        DEMO_EXISTS: 'Ya existe un demo activo con este nombre, intenta acceder a él usando una contraseña',
        DEMO_NO_DATA: 'El local demo no existe',
        INVALID_TRANSACTION: 'Transacción inválida',
        TRANSACTION_ON_DELIVERY: 'El pedido ha sido enviado por lo que la transacción debe ser Delivery',
        INVALID_CUSTOMER: 'El cliente seleccionado no es válido',
        CUSTOMER_EXISTS: 'El cliente ya existe',
        REFUNDED: 'La transacción ya ha sido reembolsada',
        REFUND_ERROR: 'Ha ocurrido un error al generar el reembolso en Flow',
        TRANSACTIONS_LIMIT_REACHED: 'Se ha alcanzado el límite de transacciones mensuales',
        INVENTORY_ITEMS_LIMIT_REACHED: 'Se ha alcanzado el límite de items de inventario',
        INVENTORY_TRANSACTIONS_LIMIT_REACHED: 'Se ha alcanzado el límite de transacciones de inventario mensuales',
        EXPENSES_LIMIT_REACHED: 'Se ha alcanzado el límite de gastos configurables',
        EXPENSES_TRANSACTIONS_LIMIT_REACHED: 'Se ha alcanzado el límite de gastos mensuales',
        PENDING_PAYMENTS_LIMIT_REACHED: 'Se ha alcanzado el límite de pagos pendientes configurables',
        TURNS_LIMIT_REACHED: 'Se ha alcanzado el límite de turnos mensuales',
        TURNS_CONCURRENT_LIMIT_REACHED: 'Se ha alcanzado el límite de turnos simultaneos',
        MENU_ITEMS_LIMIT_REACHED: 'Se ha alcanzado el límite de items disponibles',
        REPORTS_LIMIT_REACHED: 'Se ha alcanzado el límite de reportes programables',
        ALERTS_LIMIT_REACHED: 'Se ha alcanzado el límite de alertas programables',
        STAFF_LIMIT_REACHED: 'Se ha alcanzado el límite de cuentas',
        PAYMENT_METHODS_LIMIT_REACHED: 'Se ha alcanzado el límite de métodos de pagos configurables',
        TABLES_LIMIT_REACHED: 'Se ha alcanzado el límite de mesas configurables',
        INVALID_SCHEDULE: 'Horario inválido',
        INVALID_CERT: 'Certificado digital inválido',
        BOLETA_EXISTS: 'Ya se ha emitido una boleta para esta transacción',
        PAYMENT_METHOD_INVALID: 'No se ha podido emitir la boleta debido a sus métodos de pago',
        INVALID_FOLIOS: 'No hay folios disponibles',
        DELIVERY_ACCOUNT_PHONE_REQUIRED: 'Se requiere que la cuenta tenga un número de teléfono asociado para hacer este reparto',

    },
    Error(error)
    {
        if(this.errors[error]) return this.errors[error];
        return error;
    },
    RoundPesos(total, roundUp)
    {
        var round_total = Math.ceil(total);
        var last = parseInt(round_total.toString().split('').pop());
        round_total /= 10;
        if(roundUp)
            round_total = Math.ceil(round_total) * 10;
        else
            round_total = (last <= 5 ? Math.floor(round_total) : Math.ceil(round_total)) * 10;
        return round_total;
    },

    NaturalSort(arr, key)
    {
        if(!key) key = 'title';
        var a, b, a1, b1, rx=/(\d+)|(\D+)/g, rd=/\d+/;
        arr.sort(function(as, bs){
            a= String(as[key]).toLowerCase().match(rx);
            b= String(bs[key]).toLowerCase().match(rx);
            while(a.length && b.length){
                a1= a.shift();
                b1= b.shift();
                if(rd.test(a1) || rd.test(b1)){
                    if(!rd.test(a1)) return 1;
                    if(!rd.test(b1)) return -1;
                    if(a1!= b1) return a1-b1;
                }
                else if(a1!= b1) return a1> b1? 1: -1;
            }
            return a.length- b.length;
        });
        return arr;
    },

    Arr(obj)
    {
        return Object.values(obj);
    },

    CommaList(obj, key)
    {
        var ret = '';
        if(Array.isArray(obj))
        {
            obj.forEach(val => {
                if(key) ret += `, ${val[key]}`;
                else ret += `, ${val}`;
            });
        }
        else
        {
            for(var objKey in obj)
            {
                if(key == 'key') ret += `, ${objKey}`;
                else ret += `, ${key ? obj[objKey][key] : obj[objKey]}`;
            }
        }
        return ret.substring(2);
    },

    ReverseArr(arr)
    {
        var ret = [];
        for(var i = arr.length - 1; i >= 0; i--)
            ret.push(arr[i]);
        return ret;
    },

    Operator(val1, op, val2) {
        switch(op)
        {
            case '=': return val1 == val2;
            case '>': return val1 > val2;
            case '>=': return val1 >= val2;
            case '<': return val1 < val2;
            case '<=': return val1 <= val2;
            case '%': return val1 !== 0 && (val1 % val2 == 0);
        }
    },

    ApplyModifier(total, modifier)
    {
        modifier = modifier.toString().toUpperCase();
        var value = parseInt(modifier.replace('%', '').replace('-', '').replace('X', ''));
        var newTotal = null;
        if(modifier.indexOf('%') > -1 || modifier.indexOf('X') > -1)
            newTotal = parseInt(total*value/100);
        else if(modifier.indexOf('-') > -1)
            newTotal = value;
        else
            newTotal = total < value ? 0 : (total - value);
        return newTotal < 0 ? 0 : newTotal;
    },

    BenefitConditions(conditions, user)
    {
        if(conditions.user_restrictions)
        {
            if(!user || !user.level) return false;
            var validUsers = conditions.user_restrictions;
            //console.log(validUsers);
            //console.log(user);
            //console.log(validUsers.indexOf(user.rut));
            if(!this.InString(validUsers, user.rut) && !this.InString(validUsers, user.name)) return false;
        }

        var moment = require('moment');
        var today = moment();
        var day = this.DaysArr[today.format('d')-1];
        if(conditions[`is_restricted_${day}`])
        {
            try {
                var start = conditions[`${day}_start`].split(':');
                var finish = conditions[`${day}_finish`].split(':');
                if(start.length == 2 && finish.length == 2)
                {
                    var timeToday = today.format('H')*3600 + today.format('m')*60;
                    //console.log(today.format('H:m'));
                    var timeStart = parseInt(start[0])*3600 + parseInt(start[1])*60;
                    var timeFinish = parseInt(finish[0])*3600 + parseInt(finish[1])*60;
                    //console.log(`${timeStart} < ${timeToday} < ${timeFinish}`);
                    if(timeToday < timeStart || timeToday > timeFinish) return false;
                }
            } catch (error) {
                    
            }
        }
        

        return true;
    },

    BenefitDiscount(items, benefit)
    {
        var arrItems = [];
        var totalMoney = 0;
        
        for(var key in items)
        {
            for(var j = 0; j < items[key].quantity; j++)
            {
                totalMoney += items[key].price;
                arrItems.push(items[key]);
            }
        }
        
        arrItems.sort((a, b) => {
            return a.price - b.price;
        });

        var discounts = [];
        var totalDiscount = 0;
        for(var i = 0; i < benefit.length; i++)
        {
            var groupMoney = 0;
            var group = benefit[i];

            var conditionVal2 = parseInt(group.apply_condition.replace('>', '').replace('<', '').replace('=', '').replace('>=', '').replace('<=', '').replace('%','').replace('ORDER', '').replace('MODIFIERS_TOTAL', '').replace('TOTAL', '').replace('MODIFIERS_COUNT',''));
            var conditionOp = null;
            if(group.apply_condition.indexOf('>=') > -1) conditionOp = '>=';
            else if(group.apply_condition.indexOf('>') > -1) conditionOp = '>';
            else if(group.apply_condition.indexOf('<=') > -1) conditionOp = '<=';
            else if(group.apply_condition.indexOf('<') > -1) conditionOp = '<';
            else if(group.apply_condition.indexOf('=') > -1) conditionOp = '=';
            else if(group.apply_condition.indexOf('%') > -1) conditionOp = '%';

            var arrGroupItems = Object.values(group.items);
            var applyItems = [];
            for(var j = 0; j < arrItems.length; j++)
            {
                if(arrGroupItems.length == 0 || group.items[arrItems[j].id])
                    applyItems.push(arrItems[j]);
            }

            var conditionVal1 = 0;
            var min = null;
            var max = null;
            for(var j = 0; j < applyItems.length; j++)
            {
                if(group.apply_target == 'ITEMS' && (!group.modifier_condition || (group.modifier_condition && applyItems[j].modifiers.filter((a) => {return group.modifier_condition.toUpperCase().indexOf(a.title.toUpperCase()) >= 0}).length > 0)))
                {
                    if(group.apply_condition.indexOf('ORDER') > -1) conditionVal1 = j+1;
                    else if(group.apply_condition.indexOf('MODIFIERS_COUNT') > -1) conditionVal1 = applyItems[j].modifiers.length; //Contar modificadores
                    else if(group.apply_condition.indexOf('MODIFIERS_TOTAL') > -1) 
                    {
                        var modTotal = 0;
                        for(var k = 0; k < applyItems[j].modifiers.length; k++)
                            modTotal += applyItems[j].modifiers[k].price;
                        conditionVal1 = modTotal;
                    }
                    else if(group.apply_condition.indexOf('TOTAL') > -1) conditionVal1 = applyItems[j].price;

                    if(this.Operator(conditionVal1, conditionOp, conditionVal2))
                    {
                        var mod = group.apply_modifier;
                        if(mod == 'MIN') mod = `-${applyItems[j].item_price}`;
                        if(mod == 'MAX') mod = `-${applyItems[j].price}`;

                        var discount = this.ApplyModifier(applyItems[j].price, mod);
                        if(discount > 0)
                        {
                            totalDiscount += discount;
                            discounts.push({
                                index: j+1,
                                price: applyItems[j].price,
                                item: applyItems[j].title,
                                discount: discount,
                                info: `(Grupo ${i+1}, ${group.apply_target}, ${group.apply_condition}, ${group.apply_modifier})`
                            });
                        }
                    }
                }
                else
                {
                    if(min === null || applyItems[j].price < min) min = applyItems[j].price;
                    if(max === null || applyItems[j].price > max) max = applyItems[j].price;

                    if(group.apply_condition.indexOf('ORDER') > -1) conditionVal1++;
                    else if(group.apply_condition.indexOf('MODIFIERS_COUNT') > -1) conditionVal1 += applyItems[j].modifiers.length; //Contar modificadores
                    else if(group.apply_condition.indexOf('MODIFIERS_TOTAL') > -1) 
                    {
                        var modTotal = 0;
                        for(var k = 0; k < applyItems[j].modifiers.length; k++)
                            modTotal += applyItems[j].modifiers[k].price;
                        conditionVal1 += modTotal;
                    }
                    else if(group.apply_condition.indexOf('TOTAL') > -1) conditionVal1 += applyItems[j].price;
                    groupMoney += applyItems[j].price;
                }
            }
            if(group.apply_target == 'GROUP')
            {
                if(this.Operator(conditionVal1, conditionOp, conditionVal2))
                {
                    var mod = group.apply_modifier;
                    if(mod == 'MIN') mod = `-${min}`;
                    if(mod == 'MAX') mod = `-${max}`;
                    var discount = this.ApplyModifier(groupMoney, mod);
                    if(discount > 0)
                    {
                        totalDiscount += discount;
                        discounts.push({
                                index: i+1,
                                item: `Grupo`,
                                price: 0,
                                discount: discount,
                                info: `(${group.apply_target}, ${group.apply_condition}, ${group.apply_modifier})`
                            });
                    }
                }
            }

            

        }
        return {
            arrItems: arrItems,
            arrDiscounts: discounts,
            totalMoney: totalMoney,
            totalDiscount: totalDiscount
        }
    },

    ItemSummary(item)
    {
        var ret = {
            type: item.type,
            id: item.id,
            title: item.title,
            description: item.description,
            price: item.price,
            image: item.image,
            categories: {}
        }

        if(item.type == 0)
        {
            //ret.categories = {};
            for(var i = 0; i < item.categories.length; i++)
            {
                ret.categories[item.categories[i].id] = item.categories[i].type;
            }
        }
        else
        {
            ret.products = {};
            for(var i = 0; i < item.products.length; i++)
            {   
                var product = this.ItemSummary(item.products[i]);
                ret.products[product.id] = product;
                for(var key in product.categories)
                {
                    var category = product.categories[key];
                    ret.categories[key] = category;
                }
            }
        }
        return ret;
    },

    BenefitAppliesToItem(benefit, item, user)
    {
        //Ver si se cumplen las condiciones del beneficio
        if(benefit.conditions)
        {
            var moment = require('moment');
            var currentDay = moment().format('dddd').toLowerCase();
            var currentHour = moment().format('H');
            if(benefit.conditions.is_restricted_days)
            {
                var activeDay = (benefit.conditions[currentDay] && (currentHour >= benefit.conditions[`${currentDay}_start`]) && (currentHour <= benefit.conditions[`${currentDay}_finish`]));
                if(!activeDay) return false;
            }
            
            if(benefit.conditions.is_restricted_users)
            {
                if(!user) return false;
                var activeUser = benefit.conditions.restricted_users.indexOf(user.user.rut) > -1;
                if(!activeUser) return false;
            }
        }

        if(benefit.items.length == 0) return true;

        for(var i = 0; i < benefit.items.length; i++)
        {
            if(benefit.items[i] && item.id == benefit.items[i].type + '_' + benefit.items[i].id)
            {
                return true;
            }
        }
        return false;
    },

    OrderItem(item)
    {
        var modifiers = [];
        //checkear minimo de modificadores
        if(item.type == 0) item.products = [item];

        for(var i = 0; i < item.products.length; i++)
        {
            var product = item.products[i];
            for(var j = 0; j < product.modifiers.length; j++)
            {
                var modifier = product.modifiers[j];
                var min = parseInt(modifier.min);
                var count = modifier.count ? modifier.count : 0;
                if(min > count)
                {
                    return {error: true, reason: 'MODIFIERS_MISSING'};
                }
                
                for(var k = 0; k < modifier.modifiers.length; k++)
                {
                    var mod = modifier.modifiers[k];
                    if(mod.selected)
                    {
                        modifiers.push({
                            product: {
                                group: i,
                                title: product.title,
                            },
                            key: `${modifier.title}_${mod.title}`,
                            title: mod.title,
                            price: parseInt(mod.price)
                        });
                    }    
                }
            }
        }

        if(item.type == 0) delete item.products;

        var key = `${item.type}_${item.id}_`;
        var total_money = item.price;
        var mods = [];
        var keys = [];

        for(var i = 0; i < modifiers.length; i++)
        {
            var mod = modifiers[i];
            if(mod.title)
            {
                mods.push(mod);
                keys.push(mod.title);
                total_money += parseInt(mod.price);
            }
        }

        keys.sort((a, b) => {
            if(a < b) return -1;
            if(b > a) return 1;
            return 0;
        });
        key += keys.join('_');

        return {
            key: key,
            item: item,
            modifiers: mods,
            total_money: total_money
        };
    },

    Ticket(items, benefits, promotions, user) {
        
        var orders = {};
        var total_money = 0;
        var items2 = []; //copia de los items para ordenarlos por precio
        var discounts = []; //Descuentos que se aplican a esta compra

        for(var i = 0; i < items.length; i++)
        {
            var order = items[i];
            if(orders[order.key])
            {
                orders[order.key].quantity++;
                orders[order.key].total_money = orders[order.key].quantity * orders[order.key].price;
                total_money += orders[order.key].price;
            }
            else
            {
                orders[order.key] = {
                    quantity: 1,
                    item: this.ItemSummary(order.item),
                    modifiers: order.modifiers ? order.modifiers : [],
                    price: order.total_money,
                    total_money: order.total_money
                }
                total_money += order.total_money
            }
            items2.push({
                id: order.item.type + '_' + order.item.id,
                id_item: order.item.id,
                item_type: order.item.type,
                title: order.item.title,
                price: order.item.price,
                order_index: orders[order.key].quantity - 1,
                item_index: i
            });
        }

        //ordernar los items para ver que descuentos se aplican
        items2.sort((a, b) => {
            if(a.price == b.price) return (b.id_item + (b.item_type == 0 ? 0 : 1000000)) - (a.id_item + (a.item_type == 0 ? 0 : 1000000));
            return b.price - a.price;
        });
        console.log('-');
        console.log(items2);
        var benefits = JSON.parse( JSON.stringify(benefits) );
        //agregar las promociones del local
        for(var i = 0; i < promotions.length; i++)
        {
            benefits.push({
                id: null,
                id_benefit: promotions[i].id,
                benefit_data: promotions[i]
            })
        }

        var total_discount = 0;

        for(var i = 0; i < items2.length; i++)
        {
            var item = items2[i];
            for(var j = 0; j < benefits.length; j++)
            {
                var coupon = benefits[j];
                var benefit = coupon.benefit_data;
                if(this.BenefitAppliesToItem(benefit, item, user))
                {
                    benefit.itemCount = benefit.itemCount ? (benefit.itemCount+1) : 1;
                    benefit.target = parseFloat(benefit.target);
                    benefit.price_modifier = parseFloat(benefit.price_modifier);

                    var applies = false;
                    if(benefit.target_type == 'ORDER_GREATER_THAN')
                        applies = benefit.itemCount > benefit.target;
                    else if(benefit.target_type == 'ORDER_NUMBER')
                        applies = benefit.itemCount == benefit.target;
                    else if(benefit.target_type == 'ORDER_EVERY')
                        applies = (benefit.itemCount % benefit.target) == 0;
                    else if(benefit.target_type == 'TOTAL_GREATER_THAN')
                        applies = total_money >= benefit.target;

                    if(applies)
                    {
                        var discount = 0;
                        if(benefit.price_modifier_type == 'DISCOUNT')
                            discount = item.price * (benefit.price_modifier/100);
                        else if(benefit.price_modifier_type == 'FIXED')
                            discount = item.price - benefit.price_modifier;
                        else if(benefit.price_modifier_type == 'FIXED_DISCOUNT')
                            discount = benefit.price_modifier;
                
                        if((!item.discount) || (item.discount && discount > item.discount.discount) )
                        {
                            item.discount = {
                                discount: discount,
                                item: {
                                    id: item.id,
                                    title: item.title,
                                    price: item.price,
                                    order_index: item.order_index,
                                    item_index: i,
                                    id_item: item.id_item,
                                    item_type: item.item_type
                                },
                                title: benefit.title,
                                label: benefit.price_modifier_type == 'DISCOUNT' ? `${benefit.price_modifier}% DCTO` : `a ${Util.Price(benefit.price_modifier, false, 0)}`,
                                coupon: {
                                    id: coupon.id,
                                    id_benefit: coupon.id_benefit
                                },
                            };
                        }

                    }
                }
            }

            if(item.discount)
            {
                total_discount += item.discount.discount;
                discounts.push(item.discount);
            }

        }

        for(var i = 0; i < benefits.length; i++)
        {
            var benefit = benefits[i].benefit_data;
            benefit.target = parseFloat(benefit.target);
            benefit.price_modifier = parseFloat(benefit.price_modifier);
            if(benefit.items.length == 0 && benefit.target_type == 'TOTAL_GREATER_THAN' && total_money >= benefit.target)
            {
                var discount = 0;
                if(benefit.price_modifier_type == 'DISCOUNT')
                    discount = total_money * (benefit.price_modifier/100);
                else if(benefit.price_modifier_type == 'FIXED')
                    discount = total_money - benefit.price_modifier;
                else if(benefit.price_modifier_type == 'FIXED_DISCOUNT')
                    discount = benefit.price_modifier;
                
                total_discount += discount;
                discounts.push({
                    discount: discount,
                    item: null,
                    label: benefit.price_modifier_type == 'DISCOUNT' ? `${benefit.price_modifier}% DCTO` : `a ${Util.Price(benefit.price_modifier, false, 0)}`,
                    coupon: {
                        id: benefits[i].id,
                        id_benefit: benefits[i].id_benefit
                    },
                })
            }
        }

        return {
            orders: orders,
            discounts: discounts,
            total_money: Util.RoundPesos(total_money - total_discount),
            total: total_money,
            total_discount: total_discount
        };
    },

    Storage(folder, file)
    {
        return `${this.backend}storage/${folder}/${file}`;
    },

    NumberToPlace(number)
    {
        number = number.toString();
        var lastDigit = number.substr(number.length-1, 1);
        switch(lastDigit)
        {
            default: return number + 'ava';
            case '1': return 'primera';
            case '2': return 'segunda';
            case '3': return 'tercera';
            case '4': return 'cuarta';
            case '5': return 'quinta';
            case '6': return 'sexta';
            case '7': return 'séptima';
            case '8': return 'octava';
            case '9': return 'novena';
            case '10': return 'décima';
        }
    },

    RandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    InsidePolygon(point, polygon)
    {
        // ray-casting algorithm based on
        // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
    
        var x = point.lat, y = point.lng;
    
        var inside = false;
        for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
            var xi = polygon[i].lat, yi = polygon[i].lng;
            var xj = polygon[j].lat, yj = polygon[j].lng;
    
            var intersect = ((yi > y) != (yj > y))
                && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
        }
        return inside;
    },

    ModifierGroupLimits(group) {
        if(isNaN(group.min) || isNaN(group.max)) return '';
        if(group.min == 0 && group.max == 0) return '';
        if(group.min == 0 && group.max > 0) return `(Máximo ${group.max})`;
        if(group.min > 0 && group.max == 0) return `(Mínimo ${group.min})`;
        if(group.min > group.max) return '';
        if(group.min == group.max) return `(Obligatorio ${group.min})`
        return `(Entre ${group.min} y ${group.max})`;
    },

    Today()
    {
        var moment = require('moment');
        return {
            day: moment().format('dddd').toLowerCase(),
            hour: parseInt(moment().format('H')),
            minutes: parseInt(moment().format('m')),
            time: moment().format('HH:mm'),
            dayInWeek: parseInt(moment().format('d')),
            dayInMonth: parseInt(moment().format('D')),
            dayInYear: parseInt(moment().format('DDD')),
            month: moment().format('MMMM').toLowerCase(),
            monthOfYear: parseInt(moment().format('M')),
            year: parseInt(moment().format('YYYY')),
            date: moment().format('DD/MM/YYYY'),
            datetime: moment().format('DD/MM/YYYY HH:mm'),
            moment: moment()
        }
    },

    TicketVoucher(ticket, len)
    {
        var moment = require('moment');
        moment.locale('es');

        var lines = [];
        var config = localStorage.PRINTER_CONFIG ? JSON.parse(localStorage.PRINTER_CONFIG) : {};
        if(!config.len) config.len = 32;
        if(!config.size) config.size = [1, 1];
        if(!config.font) config.font = 'A';
        if(!config.style) config.style = 'NORMAL';
        if(!config.align) config.align = 'LT';
        if(!config.spacing) config.spacing = 0;
        if(!config.lineSpace) config.lineSpace = 30;
        len = len ? len : config.len;

        function clean(str)
        {
            str = str.replace(/°/g, '');
            return str.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        }

        function space(Str, space, options)
        {
            if(!options) options = {};
            if(!options.dir) options.dir = 'left';
            if(!options.fill) options.fill = ' ';
            if(!options.upper) options.upper = false;

            var str = Str.substring(0, space);
            str = clean(str);
            if(options.upper) str = str.toUpperCase();
            var ret = options.dir == 'right' ? str.padEnd(space, options.fill) : str.padStart(space, options.fill);
            return ret;
        }

        function line(content, options)
        {
            if(!options) options = {};
            if(!options.size) options.size = config.size;
            if(!options.font) options.font = config.font;
            if(!options.style) options.style = config.style;
            if(!options.align) options.align = config.align;
            if(!options.spacing) options.spacing = config.spacing;
            if(!options.lineSpace) options.lineSpace = config.lineSpace;

            if(!content)
            {
                lines.push({text: space('', len, {fill: '-'}), options: options});
            }
            else
            {
                lines.push({text: content, options: options});
            }
        }

        if(config.include_margin_top && config.margin_top)
        {
            for(var i = 0; i < config.margin_top; i++)
            {
                lines.push({control: 'LF'});
            }
        }

        if(config.include_local_logo)
        {
            lines.push({image: 'logo.png'});
        }

        line(ticket.local.name, {style: 'B', align: 'CT'});
        if(ticket.local.address)
            line('Direccion: ' + clean(ticket.local.address));
        if(config.include_local_phone && Local && Local.phone)
        {
            line('Contacto: ' + Local.phone);
        }
        line();

        if(config.include_voucher_number && ticket.ticket_id)
            line('Numero:' + space(ticket.ticket_id, len-7));
        line('Fecha:' + space(moment.unix(ticket.creation.time).format('DD/MM/YYYY HH:mm'), len-6));
        if(ticket.creation.account && config.include_cashier)
            line('Caja:' + space(ticket.creation.account.name, len-5));
        line();

        if(ticket.sale.type == 'DELIVERY' && config.include_delivery_info)
        {
            line('Nombre: ' + clean(ticket.sale.name));
            line('Direccion: ' + clean(ticket.sale.address));
            line('Contacto: ' + ticket.sale.phone);
            line();
        }

        if(ticket.sale.type == 'LOCAL' && config.include_local_info)
        {
            if(ticket.sale.mode) line(ticket.sale.mode == 'SERVE' ? 'Pedido para servir' : 'Pedido para llevar');
            if(ticket.sale.name) line('Nombre: ' + clean(ticket.sale.name));
            line();
        }

        for(var key in ticket.orders)
        {
            var order = ticket.orders[key];
            var modifiers = this.ModifiersList(order);
            line(space(order.quantity.toString(), 3, {dir: 'right'}) + space(order.item.title, len-11, {upper: true, dir: 'right'}) + space(`$${order.item.price * order.quantity}`, 8));
            for(var i = 0; i < modifiers.length; i++)
            {
                var mod = modifiers[i];
                if(mod.section)
                {
                    line('   ' + clean(mod.title.substr(0, len)));
                }
                else
                {
                    var m = mod.title.toUpperCase();
                    if(config.replace_modifiers && config.modifiers_replacement)
                    {
                        for(var j = 0; j < config.modifiers_replacement.length; j++)
                        {
                            m = m.replace(config.modifiers_replacement[j].word.toUpperCase(), config.modifiers_replacement[j].replacement.toUpperCase());   
                        }
                        
                    }
                    var price = config.hide_zero_price && mod.price == 0 ? '' : `$${mod.price * order.quantity}`;
                    line('   ' + space(m, len-11, {upper:true, dir: 'right'}) + space(price, 8));
                    
                }
            }
        }
        line();

        if(ticket.comment && config.include_comments)
        {
            var comments = ticket.comment.split('\n');
            for(var i = 0; i < comments.length; i++)
            {
                line(clean(comments[i]));
            }
            line();
        }

        line('Subtotal:' + space(`$${ticket.total}`, len-9));
        if(ticket.total_discount > 0)
        {
            line('Descuento:' + space(`- $${ticket.total_discount}`, len-10));
            if(config.include_discounts_details)
            {
                for(var i = 0; i < ticket.discounts.length; i++)
                {
                    var discount = ticket.discounts[i];
                    if(discount.title)
                        line(clean(' '+discount.title.substr(0, len - 10)) + space('- $'+discount.discount, 10) );
                    else
                        line(clean( (' '+discount.item.title+' '+discount.item.label).substr(0, len - 10)) + space('- $'+discount.discount, 10) );
                }
            }
        }
        line('Total:' + space(`$${ticket.total_money}`, len-6));
        line();

        if(ticket.payment.method == 'CASH')
        {
            if(ticket.payment.cash > 0 && ticket.payment.cash - ticket.total_money >= 0)
            {
                var vuelto = ticket.payment.cash-ticket.total_money;
                line('Efectivo:' + space(`$${ticket.payment.cash}`, len-9))
                line('Vuelto:' + space(`$${vuelto}`, len-7));
            }
            else
            {
                line('Pago:' + space('Efectivo', len-5));
            }
            
        }
        else if(ticket.payment.method == 'MIXED')
        {
            for(var i = 0; i < ticket.payment.methods.length; i++)
            {
                var m = ticket.payment.methods[i];
                line(`${m.method}:` + space(`$${m.subtotal}`, len-(m.method.length+1)));
            }
        }
        else
        {
            line('Pago:' + space(ticket.payment.method, len-5));
        }

        if(config.include_additional_message && config.additional_message)
        {
            line();
            var comments = config.additional_message.split('\n');
            for(var i = 0; i < comments.length; i++)
            {
                line(clean(comments[i]));
            }
        }
        
        if(ticket.influye_code)
        {
            line();
            line('Felicidades!');
            line(`${ticket.local.name} e Influye te regalan ${Math.floor(ticket.total/100)} corbatines en tu compra`);
            line('Ingresa a:');
            line('https://influye.app, registra tu codigo y canjea tus premios');
            line(`Codigo Influye: ${ticket.influye_code}`);
            //line('Codigo Influye: ' + space(`${ticket.influye_code}`, len-15));
            if(config.include_qr)
            {
                lines.push({qr: `https://influye.app/${ticket.influye_code}`, margin: config.qr_margin, size: config.qr_size});
            }
        }
        else
        {
            if(ticket.client && ticket.client.name)
            {
                line();
                line(`Felicidades ${ticket.client.name}!`);
                line(`${ticket.local.name} e Influye te regalan ${Math.floor(ticket.total/100)} corbatines en tu compra`);
                line('Revisalos en:');
                line('https://influye.app');
            }
        }

        if(config.include_margin_bottom && config.margin_bottom)
        {
            for(var i = 0; i < config.margin_bottom; i++)
            {
                lines.push({control: 'LF'});
            }
        }

        console.log(lines);
        return lines;

    },

    PlaceElement(el, parent)
    {
        parent.appendChild(el);
    },

    Open(url, sameTab)
    {
        window.open(url, sameTab ? '_self' : '_blank');
    },

    DownloadFile(res, filename, decodeBase64)
    {
        var type = res.headers ? res.headers['content-type'] : res.type;
        if(decodeBase64)
        {
            res.data = 'data:text/plain;base64,' + res.data;
        }
        var blob = new Blob([res.data], { type: type, encoding: 'UTF-8' });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    },

    DownloadZip(filename, data) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;base64,' + data);
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    },

    Hex2Rgb(hex) {
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function(m, r, g, b) {
          return r + r + g + g + b + b;
        });
      
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
    },

    async Clipboard(text)
    {
        if(process.env.CORDOVA_PLATFORM == 'android' || process.env.CORDOVA_PLATFORM == 'ios')
        {
            cordova.plugins.clipboard.copy(text);
        }
        else
        {
            var copy = require('clipboard-copy');
            await copy(text);
        }

        this.app.Toast('Texto copiado');
    },

    isDev() {
        return process.env.NODE_ENV == 'development';
    },

    StringToTime(str)
    {
        if(!str) return null;
        function addTime(H, m)
        {
            var date = new Date();
            var hours = date.getHours()+H;
            var minutes = date.getMinutes()+m;
            
            return Util.Zeropad((hours+parseInt(minutes/60))%24, 2) + ':' + Util.Zeropad(minutes%60, 2);
        }

        //en H m
        var H = 0;
        var m = 0;
        var parts = str.match(/en\s*(\d+)\s*(\d+)/i);
        if(parts)
        {
            H = parseInt(parts[1]);
            m = parseInt(parts[2]);
            return addTime(H, m);
        }
        
        parts = str.match(/(\d+)\s*m/i);
        if(parts)
            m = parseInt(parts[1]);
        parts = str.match(/(\d+)\s*h/i);
        if(parts)
            H = parseInt(parts[1]);
        parts = str.match(/(\d+)\s*h.(d+)/i);
        if(parts)
            m = parseInt(parts[2]);
        
        if(H || m) return addTime(H, m);

        parts = str.match(/(\d+).(\d+)/i);
        if(parts)
        {
            H = parseInt(parts[1]);
            m = parseInt(parts[2]);
        }

        parts = str.match(/(\d+)/i);
        if(parts)
        {
            H = parseInt(parts[1]);
            if(str.toUpperCase().indexOf('PM') > -1) H += 12;
        }
        if(H > 23 || m > 59) return null;
        return Util.Zeropad(H, 2)+':'+Util.Zeropad(m, 2);
    },

    TimeToMinutes(time)
    {
        if(time == 'now')
        {
            var date = new Date();
            return (date.getHours() * 60) + date.getMinutes();
        }

        if(!time || time.length !== 5) return null;
        var parts = time.split(':');
        if(parts.length !== 2) return null;
        var h = parseInt(parts[0]);
        var m = parseInt(parts[1]);
        if(h > 23 || h < 0 || m > 59 || m < 0) return null;
        return (h * 60) + m;
    },

    /*
    @param discount string que representa el descuento (ej: -1000, 10%, 10x)
    @param total el total a cual aplicar el descuento
    @return objeto que contiene el descuento y el nuevo total. retorna null si es invalido
    */
    DiscountString(discount, total)
    {
        if(!discount) return null;
        var num = discount.toUpperCase().replace('-', '').replace('%', '').replace('X', '');
        if(isNaN(num)) return null;
        var totalDiscount = 0;
        if(discount.toUpperCase().indexOf('X') > -1 || discount.indexOf('%') > -1)
            totalDiscount = num * total / 100;
        else
            totalDiscount = num;
        
        if(totalDiscount > total) totalDiscount = total;
        return {
            discount: totalDiscount,
            total: total - totalDiscount
        };

    },

    /*
    @function OpenWhatsapp abre whatsapp en una nueva ventana con la api
    @param phone <String> el número de telefono
    @param text <String> el texto del mensaje por defecto
    @param replace <Object> [Optional] un diccionario con la palabras a remplazar en text
    */
    OpenWhatsapp(phone, text, replace)
    {
        phone = phone.replace('+', '').replace(/ /g, '');
        if(phone.length == 8) phone = '569'+phone;
        else if(phone.length == 9) phone = '56'+phone;
        if(!text) text = '';
        text = text.replace(/\n/g, '%0D%0A');
        if(replace)
        {
            for(var key in replace)
            {
                var re = new RegExp(key, 'g');
                text = text.replace(re, replace[key] ? replace[key] : '');
            }
        }
        if(localStorage.nativeWhatsapp) this.Open(`whatsapp://send?phone=${phone}&text=${text}`);
        else this.Open(`https://${window.innerWidth > 820 ? 'web' : 'api'}.whatsapp.com/send?phone=${phone}&text=${text}&source=&data=`);
    },

    OpenGoogleMaps(point1, point2)
    {
        console.log(point1);
        if(typeof point1 == 'string')
        {
            this.Open(`http://maps.google.co.in/maps?q=${point1.replace(/#/g, '')}`);
        }
        else if(point2)
        {
            this.Open(`https://www.google.com/maps/dir/${point1.latitude},${point1.longitude}/${point2.latitude},${point2.longitude}`);
        }
        else
        {
            this.Open(`https://maps.google.com/?q=${point1.latitude},${point1.longitude}`);
        }
    },

    OpenWaze(address)
    {
        if(!address) return;
        this.Open(`https://waze.com/ul?q=${address.replace(/ /g, '+')}`);
    },

    IAPColor(iap)
    {
        if(iap.status == 'SCHEDULED') return '#CCC';
        if(iap.takeaway_time == 'SCHEDULE') return '#666';
        return iap.type == 'DELIVERY' ? 'rgb(0, 151, 255)' : '#c34b4b';
    },

    RoundFloat(n) 
    {
        return parseInt(n * 1000) / 1000;
    },

    isInt(n)
    {
        return parseInt(n) == parseFloat(n);
    },

    Address(address, comment)
    {
        if(!address) return '';
        var parts = address.split(',');
        var ret = parts[0];
        if(parts.length > 1) ret += `, ${parts[1]}`;
        if(comment) ret += ` (${comment})`;
        return ret;
    },

    CancelledReason(reason)
    {
        var reasons = {
            'CANT_COMPLETE': 'Uno o más items de tu pedido no están disponibles',
            'NO_DELIVERY' : 'El local está temporalmente sin delivery',
            'CLOSE_SOON' : 'El local cerrará pronto y no alcanzará a hacer tu pedido',
            'BUSY' : 'Hay muchos pedidos en el local y se demorará mucho en hacer el tuyo',
            'PENDING_TIMEOUT' : 'El local no ha aceptado el pedido dentro del tiempo límite',
            'WAITING_TIMEOUT' : 'El cliente no ha confirmado el pedido dentro del tiempo límite',
            'WAITING_PAYMENT_TIMEOUT' : 'El cliente no ha realizado el pago online dentro del tiempo límite',
            'WAITING_BANK_DEPOSIT_TIMEOUT' : 'El local no confirmó el depósito bancario dentro del tiempo límite',
            'DUPLICATED': 'El pedido está duplicado y se canceló uno',
            'AUTO_CLOSED': 'El pedido no fue atendido a tiempo'
        };
        return reasons[reason] ? reasons[reason] : reason;
    },

    Sum(arr, key)
    {
        if(!arr) return 0;
        var total = 0;
        arr.forEach(entry => {total += entry[key]});
        return total;
    },

    ModifiersList(item)
    {
        //console.log(item);
        var ret = [];
        var lastGroup = null;
        var lastMod = null;
        var modCount = 0;

        if(item.added_at) ret.push({type: 'ADDED_AT', value: Util.Date(item.added_at, 'HH:mm'), price: 0});

        if(item.modifiers && item.modifiers.length)
        {
            if(item.combo)
            {
                if(item.comment) ret.push({type: 'COMMENT', value: item.comment, price: 0});
                item.modifiers.forEach(subItem => {
                    ret.push({type: 'SUBITEM', value: subItem.title, price: subItem.price});
                
                    lastGroup = null;
                    subItem.modifiers.forEach(modifier => {
                        
                        var group = modifier.group ? modifier.group : modifier.section;
                        var key = `${group}-${modifier.title}`;
                        if(lastMod !== key) modCount = 1;
                        if(group)
                        {
                            if(!lastGroup || group != lastGroup)
                            {
                                ret.push({type: 'GROUP', value: group, price: 0});        
                                lastGroup = group;
                            }
                        }
    
                        ret.push({type: 'MODIFIER', value: (modCount>1?`(${modCount}) `:'')+modifier.title.replace(' > ', ' ⇄ '), price: modifier.price, key: key, count: item.quantity > 1 ? modCount * item.quantity : 1});
                        lastMod = key;
                        modCount++;
                    });
    
                    if(subItem.comment) ret.push({type: 'COMMENT', value: subItem.comment, price: 0});
                    lastMod = null;

                });
            }
            else
            {
                
                item.modifiers.forEach(modifier => {

                    
                    var group = modifier.group ? modifier.group : modifier.section;
                    var key = `${group}-${modifier.title}`;
                    if(lastMod !== key) modCount = 1;
                    if(group)
                    {
                        if(!lastGroup || group != lastGroup)
                        {
                            ret.push({type: 'GROUP', value: group, price: 0});        
                            lastGroup = group;
                        }
                    }
                    
                    ret.push({type: 'MODIFIER', value: (modCount>1?`(${modCount}) `:'')+modifier.title.replace(' > ', ' ⇄ '), price: modifier.price, key: key, count: item.quantity > 1 ? modCount * item.quantity : 1});
                    
                    lastMod = key;
                    modCount++;

                });

            }
            
        }

        if(item.comment && !item.combo) ret.push({type: 'COMMENT', value: item.comment, price: 0});


        var lastKey = null;
        for(var i = ret.length-1; i >= 0; i--)
        {
            if(lastKey && ret[i].key && ret[i].key == lastKey)
                ret.splice(i, 1);

            lastKey = ret[i].key;
        }
        
        return ret;
    },

    UID() 
    {
        return Math.random().toString().substr(2);
    },

    DivideInteger(number, parts)
    {
        var subtotal = parseInt(number / parts);
        var round = Math.floor(subtotal/10)*10;
        var first = number - (round * parts);
        var isFirst = true;

        var subtotals = [];
        for(var i = 0; i < parts; i++)
        {
            subtotals.push(round + (isFirst ? first : 0));
            isFirst = false;
        }
        return subtotals;
    },

    
    MethodChange(transaction, method, index)
    {
        if(method.method !== 'CASH') return false;
        var total = parseInt(method.subtotal);
        if(isNaN(total)) return false;

        var cash = parseInt(method.cash);
        if(isNaN(cash)) return false;

        if(transaction.payment.length == 1)
            total += this.Sum(transaction.custom_data.tips, 'total');
        else
        {
            total += this.MethodTip(transaction, index);
        }

        return cash >= total ? cash - total : false;
    },
    MethodTip(transaction, index)
    {
        if(!transaction.custom_data || !transaction.custom_data.tips) return false;
        var totalTip = 0;
        for(var i = 0; i < transaction.custom_data.tips.length; i++)
        {
            if(transaction.custom_data.tips[i].index === index || transaction.payment.length == 1)
                totalTip += transaction.custom_data.tips[i].total;
        }
        return totalTip > 0 ? totalTip : false;
    },

    Timestamp() {
        return Math.floor(Date.now() / 1000);
    },

    IsObject(o) {
        return o !== null && typeof o === 'object' && Array.isArray(o) === false;
    },
    
    SettingsToPaymentMethods(settings, includePending)
    {
        var ret = settings.accept_cash ? [{text: 'Efectivo', value: 'CASH'}] : [];
        for(var i = 0; i < settings.payment_methods.length; i++)
        {
            if(!settings.payment_methods[i].pending_payment || includePending)
                ret.push({text: this.PayMethod(settings.payment_methods[i].title), value: settings.payment_methods[i].title});
        }
        
        if(settings.accept_bank_deposit || settings.accepts_bank_deposit)
            ret.push({text: this.PayMethod('BANK_DEPOSIT'), value: this.PayMethod('BANK_DEPOSIT')});

        if(settings.accept_webpay || settings.accepts_online_payment)
            ret.push({text: this.PayMethod('ONLINE'), value: 'ONLINE'});

        return ret;
    },

    Partial(part, total)
    {
        part = part.toString().toUpperCase();
        if(part.indexOf('%') > -1 || part.indexOf('X') > -1)
        {
            part = parseFloat(part.replace('%', '').replace('X', ''));
            part = total * (part / 100);
            return part >= 0 ? part : null;
        }
        else if(part.indexOf('-') > -1)
        {
            part = parseInt(part.replace('-', ''));
            return total - part >= 0 ? total - part : part - total;
        }
        else if(!isNaN(part))
        {
            part = parseInt(part);
            return part;
        }
        return null;
    },

    InputSelectCustomValuePrice(value)
    {
        value = parseInt(value);
        if(isNaN(value) || value < 0) return {text: null, value: null};
        return {text: Util.Price(value), value: value};
    },

    Image(path, file, fallback)
    {
        if(!file || file == 'DELETE') return fallback ? this.Image(path, fallback) : null;
        if(file.indexOf('data:') > -1 ) return file.substr(file.indexOf('data:'));
        return `${this.backend}storage/${path}/${file}`;
    },

    StoreLocal(key, value, removeIfFalse)
    {
        localStorage.setItem(key, value);
        if(removeIfFalse && !value) localStorage.removeItem(key);
        return value;
    },

    Initials(value)
    {
        var parts = value.split(' ');
        if(parts.length == 1)
        {
            var lastChar = value.charAt(value.length-1);
            if(lastChar == lastChar.toUpperCase())
                return (value.charAt(0)+lastChar).toUpperCase();
            else
                return value.substr(0, 2);
        }
        else
        {
            return (parts[0].charAt(0)+parts[1].charAt(0)).toUpperCase();
        }
    },

    Alphanumeric(str, replacement)
    {
        return str.replace(/[^a-z0-9]/gi, replacement ? replacement : '');
    },

    OrderItemKey(item)
    {
        var key = item.id;
        for(var i = 0; i < item.modifiers.length; i++)
        {
            var mod = item.modifiers[i];
            key += `-${mod.group}-${mod.title}`;
        }
        return key;
    },

    InfluyeTransactionToTransactionV2(ticket)
    {
        var orders = JSON.parse(JSON.stringify(Array.isArray(ticket.orders) ? ticket.orders : Object.values(ticket.orders)));
        orders.forEach(item => {
            /*
            let modifiersTotal = 0;
            item.modifiers.forEach(modifier => {
                modifiersTotal += modifier.price;
            });
            */
            item.price = item.total,
            item.total_money = item.total * item.quantity;
        });

        if(ticket.type == 'DELIVERY' && ticket.total.delivery)
        {
            orders.push({
                quantity: 1,
                title: 'Reparto',
                price: ticket.total.delivery,
                id: null,
                total_money: ticket.total.delivery,
                modifiers: [],
                autocomplete: true,
                delivered: true,
                type: 'DELIVERY',
            });
        }

        var takeawayTime = ticket.takeaway_time;
        if(takeawayTime == 'AUTO') takeawayTime = 'Lo antes posible';
        if(takeawayTime == 'SCHEDULE') takeawayTime = this.Date(ticket.scheduled_at, 'HH:mm DD/MM/YYYY');

        var total = ticket.total.total - (ticket.total.tip ? ticket.total.tip : 0);

        return {
            id_ticket: ticket.id,
            status: 'PENDING',
            type: ticket.type,
            payment: [{method: ticket.payment_method, subtotal: total, cash: ticket.cash}],
            orders: orders,
            discounts: ticket.total.discounts ? ticket.total.discounts : [],
            customer: {
                name: ticket.customer_name, 
                phone: ticket.phone, 
                address: ticket.address ? (ticket.address + (ticket.address_comment ? ` (${ticket.address_comment})` : '')) : '', 
                email: ticket.custom_data.email
            },
            comment: ticket.comment,
            custom_data: {
                created_by: 'Influye',
                delivery_by: '',
                completed_by: '',
                cancelled_by: '',
                cancelled_reason: '',
                local: ticket.local.name, 
                tips: ticket.total.tip ? [{method: ticket.payment_method, total: ticket.total.tip}] : [],
                delivery_time: ticket.type == 'DELIVERY' ? takeawayTime : null,
                activation_time: ticket.type == 'TAKEAWAY' ? takeawayTime : null,
                takeaway_ready: ticket.ready_at,
                scheduled_at: ticket.scheduled_at,
                latitude: ticket.latitude,
                longitude: ticket.longitude,
                delivery_cost: ticket.total.delivery ? ticket.total.delivery : null,
                shipment: typeof ticket.messages == 'object' && ticket.messages.shipment_start ? ticket.messages : null//{shipment_start: null, shipment_end: null, time: null}
            },
            total_money: total,
            total_discount: ticket.total.discount,
            pending_payment: 0,
            iap: ticket.source == 'INFLUYE_WEB',
            source: ticket.source,
            table_name: null,
            created_at: ticket.created_at,
            completed_at: ticket.completed_at,
            cancelled_at: ticket.cancelled_at,
            delivery_at: ticket.delivery_at,
            paid_at: ticket.payment_at,
            edit_at: null,
            id_user: null,
            id_local: ticket.id_local,
            id_created_by: null,
            id_completed_by: null,
            id_delivery_by: null,
            id_cancelled_by: null,
            id_boleta: null,
            code: null,
        }
    },

    /*
    Calcula la cantidad y costo unitario de items de inventario compuestos a partir del objeto de inventario
    */
    CalculateInventoryComposite(inventory)
    {
        for(let key in inventory)
        {
            let inventoryItem = inventory[key];
            if(inventoryItem.subitems && inventoryItem.subitems.length)
            {
                let unitCost = 0;
                let quantity = 0;
                let missingSubitem = false;
                inventoryItem.subitems.forEach(subitem => {
                    let inventorySubitem = inventory[subitem.title];
                    if(inventorySubitem)
                    {
                        if(inventorySubitem.quantity <= 0)
                        {
                            missingSubitem = true;
                        }
                        else if(subitem.quantity > 0)
                        {
                            let performance = inventorySubitem.performance ? (inventorySubitem.performance / 100) : 1;
                            let subitemQuantity = (performance * inventorySubitem.quantity) / subitem.quantity;
                            if(quantity == 0 || subitemQuantity < quantity) quantity = subitemQuantity;
                        }
                        
                        if(inventorySubitem.unit_cost)
                            unitCost += (subitem.quantity * inventorySubitem.unit_cost);
                    }
                    else
                    {
                        missingSubitem = true;
                    }

                    
                });
                inventoryItem.unit_cost = unitCost;
                inventoryItem.last_unit_cost = unitCost;
                inventoryItem.quantity = missingSubitem ? 0 : quantity;
            }
        }
        return inventory;
    },

    Filename(name, ext, includeDate)
    {
        name = name.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/ /g, '_').replace(/-/g, '_');

        if(includeDate) {
            var date = new Date();
            name += `_${date.getFullYear()}_${date.getMonth()+1}_${date.getDate()}`;
        } 
        return name + '.' + ext;
    },
    
    /**
     * Calcula la suma de un campo específico en un array de objetos.
     * @param {Array} array 
     * @param {String} field 
     * @returns {number}
     */
    ArraySum(array, field) {
        return array.reduce((accumulator, currentItem) => accumulator + currentItem[field], 0);
    },

    ApplyOpacity(color, opacity)
    {
        if(color.indexOf('rgb') > -1)
        {
            color = color.split('(')[1];
            color = color.replace(')', '');
            var colors = color.split(/,/g);
            return `rgba(${colors[0]}, ${colors[1]}, ${colors[2]}, ${opacity})`;
        }
        else if(color.indexOf('#') > -1)
        {
            var hex = color.replace('#', '');
            if (hex.length === 3) {
                hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
            }    
            
            const r = parseInt(hex.substring(0, 2), 16);
            const g = parseInt(hex.substring(2, 4), 16);
            const b = parseInt(hex.substring(4, 6), 16);
            
            /* Backward compatibility for whole number based opacity values. */
            if (opacity > 1 && opacity <= 100) {
                opacity = opacity / 100;   
            }

            return `rgba(${r},${g},${b},${opacity})`;
        }
        return color;
    },
    
    UUIDv4() {
        return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
            (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
        );
    },
}