<template>
    <div class="relative fill flex column justify-center align-center ofy login-bg">
        <video autoplay muted loop class="absolute trbl0 fill" style="object-fit: fill;">
            <source src="https://backend.influye.app/storage/resources/bggradient.mp4" type="video/mp4">
        </video>
        <div class="absolute trbl0 fill flex column justify-center align-center">
            <img v-if="localData && localData.logotype" :src="img(`logos/${localData.logotype}`)" style="max-width: 150px; max-height: 100px; border-radius: 15px" class="mb10">
            <img v-else src="../../assets/logo-login.png" width="200px" class="mb10">
            
            <div class="ta-center mb10 selectable cw" @click="$router.push('/')">Volver al inicio</div>

            <div class="hs">

                <div class="flex column section" style="width: 300px">                    
                    <div class="flex column justify-center h100">
                        
                        <input-field class="mb15"
                            ref="PIN"
                            v-model="pin"
                            type="password"
                            placeholder="PIN"
                            @OnEnter="LoginWaiter()"
                            inputClass="secondary"
                            :autofocus="true"
                            @OnChange="NoLetterAllowed"
                        >
                        </input-field>
                        <div class="">
                            <div class="flex mb10" style="justify-content: space-between; gap: 5px;">
                                <button class="flex-fill secondary" @click="TypeNumber('1')">1</button>
                                <button class="flex-fill secondary" @click="TypeNumber('2')">2</button>
                                <button class="flex-fill secondary" @click="TypeNumber('3')">3</button>
                            </div>
                            <div class="flex mb10" style="justify-content: space-between; gap: 5px;">
                                <button class="flex-fill secondary" @click="TypeNumber('4')">4</button>
                                <button class="flex-fill secondary" @click="TypeNumber('5')">5</button>
                                <button class="flex-fill secondary" @click="TypeNumber('6')">6</button>
                            </div>
                            <div class="flex mb10" style="justify-content: space-between; gap: 5px;">
                                <button class="flex-fill secondary" @click="TypeNumber('7')">7</button>
                                <button class="flex-fill secondary" @click="TypeNumber('8')">8</button>
                                <button class="flex-fill secondary" @click="TypeNumber('9')">9</button>
                            </div>
                            <div class="flex" style="justify-content: space-between; gap: 5px;">
                                <button class="flex-fill error-color primary" @click="Clear()" :disabled="pin.length==0"><i class="icon icon-left-arrow"></i></button>
                                <button class="flex-fill secondary" @click="TypeNumber('0')">0</button>
                                <button class="flex-fill purple primary" @click="LoginWaiter()"><i class="icon icon-enter"></i></button>
                            </div>
                        </div>
                    </div>

                </div>
                
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return{
            pin: "",
            localData : null
        }

    },
    mounted(){
        this.localData = JSON.parse(localStorage.getItem('owner'));
    },
    methods: {
        LoginWaiter() {
            this.$root.$emit('Loading');
            axios.post(`/login/waiter/${this.localData.slug}`, {pin:this.pin}).then((res) => {
                if(res.data.error)
                {
                    this.Response(res.data);
                }
                else
                {
                    this.$root.$emit('Response', res);
                    NativeStorage.setItem('token', res.data.token, () => {
                        this.$store.commit('login', {token: res.data.token, app: this.$root});
                    }, () => {});
                }
            }).catch(err => {
                this.Response(err);
            });
            this.pin = ""
        },
        Clear(){
            if (this.pin.length > 0) {
                this.pin = this.pin.slice(0, -1); // Elimina el último carácter
                this.$refs.PIN.Focus()
            }
        },
        TypeNumber(number){
            this.pin += number
            this.$refs.PIN.Focus()
        },
        NoLetterAllowed(){
            let onlyNumbers = this.pin.replace(/\D/g, '');
            this.pin = onlyNumbers
            this.$refs.PIN.currentValue = onlyNumbers
        }
    },
}
</script>
<style>


</style>