<template>
<vertical-content class="pa-body" @OnBottom="LoadNotifications()">
    <div class="secondary-text ta-center mt10" v-if="!notifications.length">{{ nothingEnabled? 'No tienes ninguna sección habilitada, favor hablar con encargado.' : 'No tienes notificaciones' }}</div>
    <div class="" v-if="notifications.length">
        <div :class="`section relative mb10 ${notification.reference !== null ? 'selectable' : ''}`"
        @click="notification.reference ? OpenNotification(notification) : null"
        v-for="(notification, index) in notifications"
        :key="notification.id">
            <badge v-if="index < newNotifications" pulse class="absolute" style="left: -5px; top: -5px"></badge>
            <div class="fill flex align-center">
                <avatar class="mr10"
                :icon="Util.LogTypes[notification.event] ? Util.LogTypes[notification.event].icon : 'circle-exclamation'"
                :secondaryIcon="Util.LogTypes[notification.event] ? Util.LogTypes[notification.event].secondary : ''"
                :color="Util.LogTypes[notification.event] ? Util.LogTypes[notification.event].color : ''">
                </avatar>
                <div class="flex column flex-fill">
                    <div class="secondary-text">{{Util.Date(notification.created_at, 'LLLL')}}</div>
                    <div class="bold">{{notification.title}}</div>
                    <div class="secondary-text" v-html="notification.description"></div>
                </div>
                <!--<i v-if="notification.reference !== null" class="icon icon-arrow-right-o c4"></i>-->
            </div>
        </div>
    </div>
    <transaction-details ref="transactionDetails"></transaction-details>
    <turn-details ref="turnDetails"></turn-details>
    <expense-details ref="expenseDetails"></expense-details>
    <inventory-details ref="inventoryDetails"></inventory-details>
    <pending-payment-details ref="pendingPaymentDetails"></pending-payment-details>
    <iap-details ref="iapDetails"></iap-details>
</vertical-content>
</template>
<script>
export default {
    data() {
        return {
            notifications: [],
            newNotifications: 0,
            nothingEnabled: false,
        }
    },
    mounted() {
        if(this.Auth.role == "local_admin"){
            let routesEnabled = null;
            const restrictionsAdmin = this.Auth.restrictions_admin;

            if(this.Local.type == 'FRANCHISE'){
                routesEnabled = {
                    'dashboard' : restrictionsAdmin.dashboard_enabled,
                    'transactions' : restrictionsAdmin.transactions_enabled,
                    'inventory' : restrictionsAdmin.inventory_enabled,
                    'customers' : restrictionsAdmin.customers_enabled,
                    'locals' : restrictionsAdmin.locals_enabled,
                    'menu' : restrictionsAdmin.menu_enabled,
                    'settings' : restrictionsAdmin.settings_enabled,
                }
            }else{
                routesEnabled = {
                    'dashboard' : restrictionsAdmin.dashboard_enabled,
                    'transactions' : restrictionsAdmin.transactions_enabled,
                    'inventory' : restrictionsAdmin.inventory_enabled,
                    'expenses' : restrictionsAdmin.expenses_enabled,
                    'facturas': restrictionsAdmin.facturas_enabled,
                    'payments' : restrictionsAdmin.payments_enabled,
                    'turns' : restrictionsAdmin.turns_enabled,
                    'customers' : restrictionsAdmin.customers_enabled,
                    'menu' : restrictionsAdmin.menu_enabled,
                    'coupons' : restrictionsAdmin.coupons_enabled,
                    'settings' : restrictionsAdmin.settings_enabled,
                    'orders' : restrictionsAdmin.orders_enabled,
                }
            }

            const allValuesAreZero = Object.values(routesEnabled).every(value => value == 0);

            if(allValuesAreZero) this.nothingEnabled = true;
        }

        this.newNotifications = this.$store.state.unreadNotifications;
        this.Loading();
        axios.get('/notifications').then(res => {
            console.log(res.data);
            this.notifications = res.data;
            if(this.$route.params.id)
                this.OpenNotificationID(this.$route.params.id);
            else
                this.Response(res);
        }).catch(err => {
            this.Response(err);
        });

        this.$root.$on('LoadNotification', this.OpenNotificationID);

        this.$store.commit('notificationsCount', 0);
        axios.patch('/notifications/reset').then(res => {
            console.log(res.data);
        }).catch(err => {
            console.log(err);
        });

    },
    methods: {
        OpenNotification(notification) {
            if(notification.event.indexOf('IAP_') >= 0)
                this.$refs.iapDetails.Open(notification.reference);
            if(notification.event.indexOf('INVENTORY') >= 0)
                this.$refs.inventoryDetails.Open(notification.reference);
            else if(notification.event.indexOf('TURN') >= 0)
                this.$refs.turnDetails.Open(notification.reference);
            else if(notification.event.indexOf('EXPENSE') >= 0)
                this.$refs.expenseDetails.Open(notification.reference);
            else if(notification.event.indexOf('PENDING_PAYMENT') >= 0)
                this.$refs.pendingPaymentDetails.Open(notification.reference);
            else if(notification.event.indexOf('TRANSACTION') >= 0)
                if (notification.description.indexOf('agendado') >= 0){
                    this.$refs.iapDetails.Open(notification.reference);
                }else{
                    this.$refs.transactionDetails.Open(notification.reference);
                }
        },
        OpenNotificationID(id)
        {
            this.Loading();
            axios.get(`/notification/${id}`).then(res => {
                this.Response(res);
                this.OpenNotification(res.data);
            }).catch(err => {
                this.Response(err);
            });
        },
        LoadNotifications()
        {
            this.Loading();
            axios.get(this.notifications.length ? `/notifications/${this.notifications[this.notifications.length-1].created_at - 1}` : '/notifications').then(res => {
                console.log(res.data);
                this.notifications = this.notifications.concat(res.data);
                this.Response(res);
            }).catch(err => {
                this.Response(err);
            });
        }
    },
    beforeDestroy() {
        this.$root.$off('LoadNotification', this.OpenNotificationID);
    }
}
</script>