<template>
<div></div>
</template>

<script>
export default {
    data() {
        return {
            width: -1,
            height: -1,
            initialHeight: -1,
            current: 'none',
        }
    },
    mounted() {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.initialHeight = window.innerHeight;
        this.$set(this.$root, 'currentPlatform', this.width > 820 ? 'desktop' : 'mobile');
        document.documentElement.style.height = this.height + 'px';
		document.body.style.height = this.height + 'px';

        window.addEventListener('resize', (data) => {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
            if(window.visualViewport)
                this.height = window.visualViewport.height;
            
			document.documentElement.style.height = this.height + 'px';
			document.body.style.height = this.height + 'px';
        });
        /*
        if(Keyboard)
        {
            window.addEventListener('keyboardWillShow', (event) => {
                this.$set(this.$root, 'currentKeyboard', true);
            });
            window.addEventListener('keyboardDidHide', () => {
                this.$set(this.$root, 'currentKeyboard', false);
            });
        }
        */
    },
    watch: {
        width: function(n, o){
            this.current = n > 820 ? 'desktop' : 'mobile';
        },
        current: function(n, o) {
            this.$set(this.$root, 'currentPlatform', n);
            if(n == 'desktop') this.$root.$emit('OnDesktop');
            if(n == 'mobile') this.$root.$emit('OnMobile');
        }
    }
}
</script>

<style>

</style>
