<template>
<modal v-model="expense" :dir="['right', 'center']" :modalClass="['fill', 'sm']" title="Gasto" hideActions>

    <i class="options icon-dots-vertical" v-if="canDelete" slot="options" @click="Options([
        {text: 'Eliminar', class: 'error-color', function: Delete, confirm: `¿Eliminar transacción de gasto?`}
    ])"></i>

    <div class="section flex column copyable" v-if="expense">
        <div class="mb15">
            <div class="bold">Gasto</div>
            <div>{{expense.type}}</div>
        </div>
        <div class="mb15">
            <div class="bold">Fecha</div>
            <div>{{Util.Date(expense.date, 'LLL')}}</div>
        </div>
        <div class="mb15">
            <div class="bold">Total</div>
            <div>{{Util.Price(expense.money)}}</div>
        </div>
        <div class="mb15" v-if="expense.money > 0">
            <div class="bold">Método de Pago</div>
            <div>{{Util.PayMethod(expense.pay_method)}}</div>
        </div>
        <div class="mb15">
            <div class="bold">Ingresado por</div>
            <div>{{expense.custom_data.created_by}}</div>
        </div>
        <div class="mb15" v-if="expense.comment">
            <div class="bold">Comentario</div>
            <div>{{expense.comment}}</div>
        </div>
        <input-files readonly label="Documentos" v-model="expense.custom_data.files" v-if="expense.custom_data.files && expense.custom_data.files.length"></input-files>
        <!--
        <div class="input-style selectable" v-if="expense.custom_data.image" @click="Util.Open(img(`documents/${expense.custom_data.image}`))">
            <div class="input-label">Documento</div>
            <div class="contain-img" :style="{'width': '100%', height: '200px', 'background-image': img(`documents/${expense.custom_data.image}`, true)}"></div>
        </div>
        -->
    </div>

</modal>
</template>
<script>
export default {
    data() {
        return {
            expense: null
        }
    },
    methods: {
        async Open(expense) 
        {
            if(typeof expense == 'number')
            {
                this.Loading();
                try {
                    expense = (await axios.get(`/expense/${expense}`)).data;    
                    this.Response({error: false});
                } catch (error) {
                    this.Response({error: true, reason: 'DATA_NULL'});
                }
                
            }
            this.expense = expense;
        },
        Delete() {
            this.Loading();
            axios.delete(`/expense/${this.expense.id}`).then(res => {
                this.Info('Gasto eliminado');
                this.$emit('OnTransactionDeleted', this.expense);
                this.expense = null;
            }).catch(err => {
                this.Response(err);
            });
        }
    },
    computed: {
        canDelete() {
            if(!this.expense) return false;
            if(this.Auth.role.indexOf('admin') == -1) return false;
            return true;
            //var moment = require('moment');
            //return this.expense.date >= moment().unix() - (60*60*24*2);
        }
    }
}
</script>