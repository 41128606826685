<template>
	<div id="app">

		<!-- Logging in screen -->
		<transition leave-active-class="animated fadeOut faster">
		<div v-if="loggingIn" class="absolute fill flex justify-center align-center" style="background-color: var(--purple); z-index: 1000">
			<img :src="require('./assets/influye_iso_login.png')" width="50px" class="animated pulse infinite">
		</div>
		</transition>

		<fullscreen-header-default ref="menu">
			<router-view></router-view>
		</fullscreen-header-default>
		<!-- Menu de Opciones -->
		<modal v-model="modalOptions" :dir="['up', 'center']" :modalClass="['up w100','']"
		:cursorPosition="!modalOptions.backdrop && desktop" :domElement="!modalOptions.backdrop && desktop ? (modalOptions?modalOptions.target:null) : null"
		hideActions :hideBackdrop="!modalOptions.backdrop && desktop">
			<div class="" :style="{'min-width': '200px', width: '100%', 'max-width': desktop ? '80vw' : ''}">
				<!--<h4 v-if="optionsTitle" v-html="optionsTitle" class="pa10 ta-center hide-desktop" style="padding-bottom: 0px"></h4>-->
				<!--<div v-if="optionsBody" v-html="optionsBody" class="pa10 secondary-text" style="padding-bottom: 0px"></div>-->
				<div class="flex column vertical-options input-popover" ref="options">
					<button v-for="(option, index) in options"
					:key="index"
					:class="`${option.class}`"
					:disabled="option.disabled === true"
					@click="ClickOption(option)"><i v-if="option.icon" :class="`icon icon-${option.icon} mr5`"></i>{{option.text}}</button>
				</div>
			</div>
		</modal>
		<modal v-model="modalOptionsConfirm" modalClass="sm">
			<div class="pa20">
				<div v-if="modalOptionsConfirm" class="ta-center" v-html="modalOptionsConfirm.title"></div>
				<input-field v-if="modalOptionsConfirm && modalOptionsConfirm.input" :label="modalOptionsConfirm.input" v-model="modalOptionsConfirm.input_value" :lines="3" class="mt20"></input-field>
			</div>
			<button slot="actions" v-if="modalOptionsConfirm && modalOptionsConfirm.cancel===undefined" @click="modalOptionsConfirm=null" class="secondary">Cancelar</button>
			<button slot="actions" v-if="modalOptionsConfirm" :class="`primary ${modalOptionsConfirm.class}`" @click="modalOptionsConfirm.function(modalOptionsConfirm.input_value);modalOptions=false;modalOptionsConfirm=false">{{modalOptionsConfirm.text}}</button>
		</modal>
		<!-- Fin Menu Opciones -->
		
		<transition enter-active-class="animated fadeIn superfast" leave-active-class="animated fadeOut superfast">
		<div class="quest flex align-center" v-if="currentQuest" ref="quest">
			<div v-html="currentQuest.text"></div>
		</div>
		</transition>
		

		<loading ref="loading"></loading>
  		<size-manager></size-manager>

	</div>
</template>

<script>
export default {
	name: 'app',
	data() {
		return {
			options: [],
			optionsTitle: null,
			optionsBody: null,
			modalOptions: false,
			modalOptionsConfirm: null,
			currentQuest: null,
			timerQuest: null,
			quests: [],
			cursorPosition: null,
			loggingIn: true,
			updateAvailable: false,
			timerSession: null,
			modals: [],
			back: true,
			backByModal: false,
			lastClickedElement: null,
			currentClickedElement: null,
		}
	},
	mounted() {

		if(localStorage.dark !== undefined)
		{
			this.$store.commit('setDarkMode', true);
		}

		this.Util.App = this;

		window.onmousemove = event => {
			this.cursorPosition = event;
		}

		let lastClickedElement = null;
		let currentClickedElement = null;
		document.addEventListener('click', function(event) {
			lastClickedElement = currentClickedElement;
			currentClickedElement = event.target;
		}, true);

		this.$root.$on('ShowOptions', (options, event, backdrop) => {
			
			setTimeout(() => {
				
				if(this.modalOptions)
					this.modalOptions = false;

				if(lastClickedElement !== null && lastClickedElement == currentClickedElement) 
				{
					lastClickedElement = null;
					currentClickedElement = null;
					return;
				}

				var unavailableOptions = 0;
				for(var i = 0; i < options.length; i++)
					if(options[i].if === false) unavailableOptions++;
				
				if(unavailableOptions == options.length) return;
				
				this.$nextTick(() => {
					this.options = [];
					for(var i = 0; i < options.length; i++)
					{
						if(options[i].if === undefined || options[i].if === true)
							this.options.push(options[i]);
					}
					this.modalOptions = {
						target: event instanceof Element ? event : (event ? event.srcElement : null),
						backdrop: backdrop
					};
				});

				setTimeout(() => {
					var first = this.$refs.options.querySelectorAll('button');
					if(first.length) this.desktop ? first[first.length-1].scrollIntoView() : first[0].scrollIntoView;
				}, this.mobile ? 400 : 10);

			}, 10);

		});
		this.$root.$on('Confirm', params => {
			this.modalOptionsConfirm = params;
		});

		this.$root.$on('Toast', params => {
			var override = typeof params == 'string' ? false : !!params.override;

			if(override) 
			{
				this.quests = [];
				this.currentQuest = null;
			}


			this.quests.push(typeof params == 'string' ? {
				text: params, icon: 'icon-exclamation'
			} : params);
			if(this.currentQuest === null || override) this.NextQuest();
			if(override && this.$refs.quest) {
				this.$refs.quest.classList.remove('pop');
				setTimeout(() => {
					this.$refs.quest.classList.add('pop');
				}, 10);
			}
		});

		//back button hack
		this.$root.$on('OnModalOpen', modal => {
			this.modals.push({modal: modal, id: modal.id});
			//console.log(`open: ${this.modals.length} id: ${modal.id}`);
			/*
			if(process.env.CORDOVA_PLATFORM !== 'android' && process.env.CORDOVA_PLATFORM !== 'ios')
				window.history.pushState({id: modal.id}, "title 1");
			*/
		});
		this.$root.$on('OnModalClose', modal => {
			for(var i = 0; i < this.modals.length; i++)
			{
				if(modal.id == this.modals[i].id)
				{
					this.modals.pop();
					//console.log(`close: ${this.modals.length}`);
					break;
				}
			}
			/*
			if(process.env.CORDOVA_PLATFORM !== 'android' && process.env.CORDOVA_PLATFORM !== 'ios')
				window.history.back();
			*/
		});

		this.$router.beforeEach((to, from, next) => {

			console.log('modals status');
			for(var i = this.modals.length - 1; i >= 0; i--)
			{
				if(!this.modals[i].modal.isOpen)
				{
					this.modals.splice(i, 1);
				}
			}

			if(this.modals.length)
			{
				this.modals.pop().modal.Close();
				next(false);
			}
			else
				next();
		});
		
		if(process.env.CORDOVA_PLATFORM == 'android')
		{
			document.addEventListener("backbutton", () => {
				if(this.modals.length)
				{
					var modal = this.modals[this.modals.length-1];
					modal.Close(false);
				}
				else
				{	
					if(window.count > 0)
					{
						navigator.app.backHistory();
						window.count--;
					}
					else
					{
						navigator.app.exitApp();
					}
				}
			}, false);
		}
		else if(process.env.CORDOVA_PLATFORM == 'android')
		{
			window.onpopstate = (e) => {
				if(!this.backByModal)
				{
					if(this.modals.length)
					{
						console.log(`pop state: ${this.modals.length}`);
						this.modals.pop().modal.Close(false);
					}
				}
				this.backByModal = false;
			};
		}


		this.SetupGoogleAnalytics();

		document.addEventListener('keyup', e => {

			if(this.$refs.loading.isLoading) return;

			if(this.$refs.loading.openValue)
			{
				if(e.key == 'Escape' || e.key == 'Enter') this.$refs.loading.openValue = false;
				return;
			}

			if(this.modals.length)
			{
				let lastModal = this.modals[this.modals.length-1].modal;
				console.log(lastModal);
				if(e.key == 'Escape')
				{
					if(lastModal.closeable) lastModal.Close();
				}
				else if(e.key == 'Enter')
				{
					let buttons = lastModal.$el.querySelectorAll('button.primary');
					if(!buttons.length) return;
					let currentFocused = document.activeElement;
					if(currentFocused.nodeName == 'TEXTAREA') return;
					if(currentFocused && currentFocused.closest('.input-popover')) return;
					let lastButton = buttons[buttons.length-1];
					if(!lastButton.disabled && lastButton !== currentFocused) 
						lastButton.click();
				}
			}
		});

	},
	methods: {
		ClickOption(option)
		{
			if(option.confirm)
			{
				this.modalOptionsConfirm = {
					text: option.text,
					class: option.class,
					function: option.function,
					title: typeof option.confirm == 'string' ? option.confirm : null
				}
			}
			else
			{
				option.function();
				this.modalOptions = false;
			}
		},
		NextQuest(override)
		{
			if(this.timerQuest) clearTimeout(this.timerQuest);
			if(this.quests.length == 0) return;
			var quest = this.quests.shift();
			if(process.env.CORDOVA_PLATFORM == 'android' || process.env.CORDOVA_PLATFORM == 'ios')
				window.plugins.toast.show(quest.text, 2000, 'bottom');
			else
				this.currentQuest = quest;

			this.timerQuest = setTimeout(() => {
				this.currentQuest = null;
				setTimeout(() => {
					this.NextQuest();
				}, 200)
			}, 2000);
		},
		HandleNotification(args)
		{
			console.log(args);
			if(args.type == 'notification')
			{
				if(this.$route.name == 'notifications')
					this.$root.$emit('LoadNotification', args.id);
				else
					this.$router.push(`/notifications/${args.id}`);
			}
		},
		SetupGoogleAnalytics()
		{
			if(process.env.VUE_APP_GOOGLE_ANALYTICS_ID)
			{
				console.log("Setup Google Analytics with tag "+process.env.VUE_APP_GOOGLE_ANALYTICS_ID);
				var newScript = document.createElement("script");
				newScript.type = "text/javascript";
				newScript.setAttribute("async", "true");
				newScript.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id="+process.env.VUE_APP_GOOGLE_ANALYTICS_ID);
				document.documentElement.firstChild.appendChild(newScript);

				window.dataLayer = window.dataLayer || [];
				function gtag(){dataLayer.push(arguments);}
				gtag('js', new Date());
				gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_ID);

				/*
				window.gtag = gtag;

				this.$root.$on('RequestGTAG', (request) => {
					console.log(request);
					console.log(this.Account);
				});
				this.$root.$on('RequestErrorGTAG', (error) => {
					console.log(error);
				});
				*/

			}

		},
	},
	computed: {
		wsConnectionQuery() {
			if(!this.Auth) return;
			if(!this.Local) return;
			return {
				id_local: this.Local.id,
				local: this.Local.name,
				id_account: this.Auth.id,
				account: this.Auth.name,
				account_role: this.Auth.role,
			}
		}
	},
	watch: {
		Auth: function(n, o) {
			if(n)
			{
				if(process.env.CORDOVA_PLATFORM != 'android' && process.env.CORDOVA_PLATFORM != 'ios')
				{
					this.timerSession = setInterval(() => {
						NativeStorage.getItem('token', token => {}, () => {
							this.$refs.menu.LogOut();
							this.Info(`Se ha cerrado la sesión`);
						});
					}, 1000);
				}
			}
			else
			{
				if(this.timerSession) clearInterval(this.timerSession);
			}
		},
		dark: function(n, o)
		{
			if(n)
				document.body.classList.add('dark');
			else
				document.body.classList.remove('dark');
		},
		wsConnectionQuery: function(n, o)
		{
			if(n)
			{
				console.log('connect to new ws');
				this.$socket.client.disconnect();
				this.$socket.client.io.opts.query = n;
				this.$socket.client.io.uri = this.Util.connectionAddresses.ws;
				this.$socket.client.connect();
			}
			else
			{
				this.$socket.client.disconnect();
			}
		}
	},
	sockets: {
		connect() {
			console.log(`Connected to ws ${this.$socket.client.io.uri}!`);
			//this.$socket.client.emit('ACCOUNT_CONNECTED', this.Auth.id);
		}
	},
}

</script>

<style lang="scss">
#app {
	width: 100%;
	height: 100%;
}

.quest {
	position: fixed;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 9999;
    background-color: var(--black);
    border-radius: 10px;
    padding: 10px;
    color: var(--white);
	line-height: 1.2;
    font-size: 12px;

	&.pop {
		animation: anim-pop-out 0.5s forwards;
	}
}

@keyframes anim-pop-out {
	0% {
		transform: translateX(-50%) scale(1.2);
	}
	100% {
		transform: translateX(-50%) scale(1);
	}
}

@import './scss/app.scss';
</style>
