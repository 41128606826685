<template>
<div class="fill flex column align-center justify-center pa20 ta-center">

    <img :src="require(`../../assets/${dark ? 'logo-login' : 'logo-color'}.png`)" style="height: 50px">
    <div v-if="local" class="flex column align-center justify-center" style="margin-top: 30px">
        <div class="purple bold f18 mb10">{{Local.modules.plan == 'DEMO' ? 'El periodo de prueba' : 'La licencia'}} de {{local.name}} ha expirado</div>
        <div class="mb20">
            <span v-if="Auth.role == 'local_admin' && Local.modules.plan != 'DEMO'">La suscripción al plan de {{Util.Number(local.plan_price)}} UF ha expirado el {{Util.Date(local.plan_expiration)}}. </span>
            <span v-if="Auth.role == 'local_admin' && Local.modules.plan == 'DEMO'">El periodo de prueba ha terminado el {{Util.Date(local.plan_expiration)}}. </span>
            Renueva tu suscripción para seguir usando Influye.
        </div>

        <div class="flex column" style="width: 200px">
            <button class="primary purple mb5" v-if="Auth.role == 'local_admin' && Local.modules.plan != 'DEMO'" @click="Util.Open(`${subscriptionUrl}/options?code=${local.service_key}`, true)">Renovar Plan</button>
            <button class="primary purple mb5" v-if="Auth.role == 'local_admin' && Local.modules.plan == 'DEMO'" @click="Util.Open(`https://influyeapp.cl/`, true)">Contratar un Plan</button>
            <button class="secondary mb5" @click="Util.OpenWhatsapp('+56984088047')">Obtener Ayuda</button>
            <button class="secondary" @click="$root.$emit('LogOut', true)">Cerrar Sesión</button>
        </div>
    </div>

</div>
</template>

<script>
export default {
    data() {
        return {
            local: null,
            subscriptionUrl: process.env.VUE_APP_SUBSCRIPTION_URL,
        }
    },
    mounted() {
        if(!this.Local) 
        {
            this.$router.push('/');
            return;
        }

        this.Loading();
        axios.get(`/local/${this.Local.id}/expiration`).then(res => {
            this.Response(res);
            this.local = res.data;
        }).catch(err => {
            this.Response(err);
        });
    }
}
</script>

<style>

</style>