<template>
<div class="absolute" ref="parent" style="z-index: 100; overflow: hidden; width: 100%; height: 100%; top: 0; left: 0; right: 0; bottom: 0; pointer-events: none">
    
    <transition
    enter-active-class="animated fadeIn superfast"
    leave-active-class="animated fadeOut superfast">
        <div v-if="isOpen" @click="isOpen=false" class="absolute fill" style="background-color: rgba(0, 0, 0, 0.5); pointer-events: all"></div>
    </transition>

    <transition
    :enter-active-class="`animated ${positionProperties.anim.enter} superfast`"
    :leave-active-class="`animated ${positionProperties.anim.leave} superfast`"
    v-on:enter="OnOpen"
    v-on:after-leave="OnClose">
    <div v-if="(forceVisible!== null && forceVisible===true) || (forceVisible===null && (isOpen || fixed))" ref="menu" class="absolute flex column menu" :style="positionProperties.style">
        <slot></slot>
    </div>
    </transition>

    <div class="absolute fill menuPersistent" :style="{display: persistenVisible ? 'inherit' : 'none'}" ref="persistent">
        <slot name="persistent"></slot>
    </div>

</div>
</template>

<script>
export default {
    props: {
        position: {
            type: String,
            default: 'left'
        },
        fixed: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: '300px'
        },
        maxHeight: {
            type: String,
            default: null
        },
        maxWidth: {
            type: String,
            default: null
        },
        forceVisible: {
            type: Boolean,
            default: null
        }
    },
    data() {
        return {
            id: null,
            isOpen: false,
            persistenVisible: false
        }
    },
    mounted() {
        this.id = Math.random().toString().replace('0.', '');
        this.$root.$on('OnDesktop', this.Close);
        document.body.appendChild(this.$el);
    },
    methods: {
        Open() {
            this.isOpen = true;
            this.$nextTick(() => {
                this.$emit('OnOpen');
            });
        },
        Close() {
            this.isOpen = false;
        },
        OnOpen() {
            this.$refs.menu.appendChild(this.$refs.persistent);
            this.persistenVisible = true;
        },
        OnClose() {
            this.$refs.parent.appendChild(this.$refs.persistent);
            this.persistenVisible = false;
            this.$emit('OnClose');
        }
    },
    computed: {
        positionProperties() {
            var anims = {
                left: {enter: 'slideInLeft', leave: 'slideOutLeft'},
                right: {enter: 'slideInRight', leave: 'slideOutRight'},
                top: {enter: 'slideInDown', leave: 'slideOutUp'},
                bottom: {enter: 'slideInUp', leave: 'slideOutDown'},
            };
            var ret = {
                anim: anims[this.position],
                style: {}
            };
            ret.style.width = (this.position == 'left' || this.position == 'right') ? this.size : '100%';
            ret.style.height = (this.position == 'left' || this.position == 'right') ? '100%' : this.size;
            ret.style[this.position] = 0;
            if(this.maxHeight) ret.style.maxHeight = this.maxHeight;
            if(this.maxWidth) ret.style.maxWidth = this.maxWidth;
            return ret;
        }
    },
    watch: {
        isOpen: function(n, o){
            if(n) {
                document.body.appendChild(this.$el);
            }
            this.$root.$emit(n ? 'OnModalOpen' : 'OnModalClose', this);
        }
    },
    beforeDestroy() {
        this.$root.$off('OnDesktop', this.Close);
        if(this.$el.parentNode)
            this.$el.parentNode.removeChild(this.$el);
    }
}
</script>

<style scoped>
.menu {
    pointer-events: all;
}
.menuPersistent {
    pointer-events: none;
}
.menuPersistent div {
    pointer-events: all;
}
</style>
