<template>
<div class="fill ofy" @scroll="OnScroll($event)" ref="container">
    <slot></slot>
</div>
</template>

<script>
export default {
    props: {
        list: {
            type: [Array, Object],
            default: null
        }
    },
    data() {
        return {
            lastBottom: 0,
        }
    },
    methods: {
        Reset() {
            this.lastBottom = 0;
        },
        OnScroll(e) {
            var bottom = Math.ceil(this.$refs.container.offsetHeight + this.$refs.container.scrollTop);
            if(this.lastBottom !== bottom && bottom >= this.$refs.container.scrollHeight && bottom > this.lastBottom)
            {
                this.lastBottom = bottom;
                this.$emit('OnBottom');
            }
        }
    },
    watch: {
        list: function(n, o) 
        {
            if(n && o && n.length > o.length)
                this.lastBottom = 0;
        }
    }
}
</script>