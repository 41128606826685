<template>
<div class="fill absolute trbl0" style="top: 0; z-index: 100; pointer-events: none; overflow: hidden">
    <div class="wrapper">
        <transition
        enter-active-class="animated fadeIn superfast"
        leave-active-class="animated fadeOut superfast">
            <div v-if="value && !hideBackdrop" @click="Close()" class="absolute fill" style="background-color: rgba(0, 0, 0, 0.5); pointer-events: all"></div>
        </transition>
        
        <div class="fill flex justify-center align-center" :style="{'pointer-events': value && !hideBackdrop ? 'all' : 'none'}">
            <transition
            :enter-active-class="`${currentDir}In`"
            :leave-active-class="`${currentDir}Out`">
            <div :id="id" v-if="value" :class="`modal ${currentClass}`" ref="modal" :style="fixedPosition?fixedPosition:null">
                <div v-if="title !== null" class="header">
                    <div class="flex column fill">
                        <div class="flex align-center">
                            <slot name="pre"></slot>
                            <div class="bold flex-fill ellipsis" style="font-size: 16px" v-html="title"></div>
                            <slot name="options"></slot>
                            <!--<button v-if="closeable" :class="`transparent icon icon-${mobile ? Util.ModalDirArrow[currentDir] : 'close'}`" @click="Close()"></button>-->
                            <button v-if="closeable" :class="`transparent icon icon-cross ml5`" @click="Close()"></button>
                        </div>
                        <slot name="header"></slot>
                    </div>
                </div>
                
                <div class="body" ref="body">
                    <slot></slot>
                </div>
                
                <div class="actions" v-if="!hideActions">
                    <slot name="actions"></slot>
                </div>
                
            </div>
            </transition>
        </div>
        
    </div>
    
</div>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Number, Boolean, Object, Array],
            default: null
        },
        closeable: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null
        },
        dir: {
            type: [String, Array],
            default: 'center'
        },
        modalClass: {
            type: [String, Array],
            default: ''
        },
        emitEvents: {
            type: Boolean,
            default: true
        },
        hideActions: {
            type: Boolean,
            default: false
        },
        cursorPosition: {
            type: Boolean,
            default: false
        },
        hideBackdrop: {
            type: Boolean,
            default: false
        },
        domElement: {
            type: HTMLElement,
            default: null
        },
        offset: {
            type: Object,
            default: null
        },
        clickOutside: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            id: null,
            emit: true,
            fixedPosition: null,
        }
    },
    mounted() {
        this.id = 'uid'+this.Util.UID();
        document.body.appendChild(this.$el);
    },
    methods: {
        Close(emit) {
            if(this.closeable)
            {
                if(emit === false) this.emit = false;
                this.$emit('input', false);
            }
        },
        CheckClickOutside(event)
        {
            //console.log(event.path[0]);
            if(!this.clickOutside) return;
            var target = event && event.target ? event.target : null;
            if(target)
            {
                var parent = target.closest(`#${this.id}`);
                if(parent) return;
            }
            /*
            if(event && event.path)
            {
                for(var i = 0; i < event.path.length; i++) 
                {
                    console.log(event.path[i]);
                    if(event.path[i].getAttribute && event.path[i].getAttribute('id') == this.id) return;
                }
            }
            */
            this.Close();
        }
    },
    computed: {
        currentDir() {
            return typeof this.dir == 'string' ? this.dir : (this.mobile ? this.dir[0] : this.dir[1]);
        },
        currentClass() {
            return typeof this.modalClass == 'string' ? this.modalClass : (this.mobile ? this.modalClass[0] : this.modalClass[1]);
        },
        isOpen() {
            return !!this.value;
        },
    },
    watch: {
        isOpen: function(n, o){
            if(n)
            {
                
                if(this.hideBackdrop)
                {
                    setTimeout(() => {
                        document.addEventListener('click', this.CheckClickOutside);
                    }, 10)
                    
                }

                this.fixedPosition = null;
                if(this.desktop)
                {
                    if(this.domElement)
                    {
                        var pos = this.domElement.getBoundingClientRect();
                        
                        var offset = this.offset ? this.offset : {};
                        if(!offset.width) offset.width = 0;
                        if(!offset.x) offset.x = 0;
                        if(!offset.y) offset.y = 0;

                        //console.log(pos);
                        
                        var middle = {x: window.innerWidth/2, y: window.innerHeight/2};
                        this.fixedPosition = {};
                        if(pos.x > middle.x)
                            this.fixedPosition.right = `calc(100% - ${pos.x}px - ${pos.width}px + ${offset.x}px)`;
                        else
                            this.fixedPosition.left = `${pos.x+offset.x}px`;

                        if(pos.y > middle.y)
                            this.fixedPosition.bottom = `calc(100% - ${pos.y}px + ${offset.y}px)`;
                        else
                            this.fixedPosition.top = `${pos.y + pos.height + offset.y}px`;

                        this.fixedPosition.position = 'absolute';
                        this.fixedPosition['min-width'] = `${pos.width + offset.width}px`;
                        if(offset.width) this.fixedPosition['max-width'] = `${pos.width + offset.width}px`;
                        
                    }
                    else if(this.cursorPosition)
                    {
                        var pos = this.$root.$children[0].cursorPosition;
                        
                        if(pos)
                        {
                            var middle = {x: window.innerWidth/2, y: window.innerHeight/2};

                            this.fixedPosition = {};
                            if(pos.clientX > middle.x)
                                this.fixedPosition.right = `${window.innerWidth-pos.clientX+5}px`;
                            else
                                this.fixedPosition.left = `${pos.clientX+5}px`;

                            if(pos.clientY > middle.y)
                                this.fixedPosition.bottom = `${window.innerHeight-pos.clientY+5}px`;
                            else
                                this.fixedPosition.top = `${pos.clientY+5}px`;

                            this.fixedPosition.position = 'absolute';
                    
                        }
                    }
                    
                    /*
                    if(!this.Util.isMobileOrTablet)
                    {
                        setTimeout(() => {
                            if(this.$refs.modal)
                            {
                                var inputs = this.$refs.modal.getElementsByTagName('input');
                                if(inputs.length > 0)
                                {
                                    inputs[0].focus();
                                    inputs[0].select();
                                }
                            }
                        }, 100);
                    }
                    else
                        console.log('is mobile, do not auto select');
                    */
                }
                document.body.appendChild(this.$el);



            }
            else
            {
                if(this.hideBackdrop)
                {
                    document.removeEventListener('click', this.CheckClickOutside);
                }

            }
            
            /*
            if(this.emitEvents)
            {
                
                if(!this.emit)
                {
                    this.emit = true;
                }
                else
                {
                    if(n)
                        setTimeout(() => {this.$root.$emit(n ? 'OnModalOpen' : 'OnModalClose', this)}, 50);
                    else
                        this.$root.$emit(n ? 'OnModalOpen' : 'OnModalClose', this);
                }
                
            }
            */

           if(this.emitEvents)
                this.$root.$emit(n ? 'OnModalOpen' : 'OnModalClose', this);

            this.$emit('OnStateChanged', n);
        }
    },
    beforeDestroy() {
        if(this.$el.parentNode)
            this.$el.parentNode.removeChild(this.$el);
    }
}
</script>

<style lang="scss">
.modal {
    background-color: var(--white);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 15px -3px;
    border-radius: 10px;
    pointer-events: all; 
    z-index: 21;
    display: flex;
    flex-direction: column;
    max-height: 100%;

    .header {
        padding: 10px 20px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid var(--gray-2);

        .tabs {
            margin-bottom: -12px;
            margin-left: -16px;
            margin-right: -16px;
        }

    }

    .body {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        overflow-y: auto;
        
    }

    .actions {
        background-color: var(--gray-3);
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
        border-top: 1px solid var(--gray-2);
        padding: 20px;
        display: flex;
        justify-content: flex-end;
        flex-shrink: 0;
 
        overflow-x: hidden;

        > button {
            margin-left: 8px;
        }
    }

    &.left {
        position: absolute;
        left: 0;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        max-height: 100% !important;
    }
    &.right {
        position: absolute;
        right: 0;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        max-height: 100% !important;
    }
    &.up {
        position: absolute;
        bottom: 0;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    &.down {
        position: absolute;
        top: 0;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }

    &.borderless, &.fill {
        border-radius: 0px;
    }

    &.sm {
        width: 400px;
        max-width: 90%;
        max-height: 90%;
    }
    &.md {
        width: 600px;
        max-width: 90%;
        max-height: 90%;
    }
    &.lg {
        width: 800px;
        max-width: 90%;
        max-height: 90%;
    }

    &.xl {
        width: 1200px;
        max-width: 90%;
        max-height: 90%;
    }

}


.centerIn {
    animation: anim-center-in 0.2s ease-out 0s;
}
@keyframes anim-center-in {
    0% {
        transform: scale(0.7);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
.centerOut {
    animation: anim-center-out 0.1s linear 0s;
}
@keyframes anim-center-out {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(0.7);
        opacity: 0;
    }
}

.upIn {
    animation: anim-up-in 0.3s ease-out 0s;
}
@keyframes anim-up-in {
    0% {
        transform: translateY(100vh);
    }
    100% {
        transform: translateY(0);
    }
}
.upOut {
    animation: anim-up-out 0.2s linear 0s;
}
@keyframes anim-up-out {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(100vh);
    }
}

.downIn {
    animation: anim-down-in 0.3s ease-out 0s;
}
@keyframes anim-down-in {
    0% {
        transform: translateY(-100vh);
    }
    100% {
        transform: translateY(0);
    }
}
.downOut {
    animation: anim-down-out 0.2s linear 0s;
}
@keyframes anim-down-out {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-100vh);
    }
}

.leftIn {
    animation: anim-left-in 0.3s ease-out 0s;
}
@keyframes anim-left-in {
    0% {
        transform: translateX(-100vw);
    }
    100% {
        transform: translateX(0);
    }
}
.leftOut {
    animation: anim-left-out 0.2s linear 0s;
}
@keyframes anim-left-out {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100vw);
    }
}

.rightIn {
    animation: anim-right-in 0.3s ease-out 0s;
}
@keyframes anim-right-in {
    0% {
        transform: translateX(100vw);
    }
    100% {
        transform: translateX(0);
    }
}
.rightOut {
    animation: anim-right-out 0.2s linear 0s;
}
@keyframes anim-right-out {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100vw);
    }
}

.dark {
    .modal {
        background-color: var(--dark-bg-1);
        
        .header {
            border-bottom: 1px solid var(--dark-outline);
        }

        .actions {
            background-color: var(--dark-bg-0);
            border-top: none;
        }

    }
}

</style>
