<template>
<modal v-model="modal" :title="client?client.name:''" dir="right" :modalClass="['fill', 'md right h100']" hideActions>

    <!-- Menu de opciones ... -->
    <i v-if="client && Local.type == 'LOCAL'" class="options icon-dots-vertical" slot="options" @click="Options([
        {text: 'Eliminar', class: 'error-color', function: () => {DeleteLocalCustomer(client.phone)}, if: client.id_user === null, confirm: '¿Eliminar cliente de forma definitiva?'},
        {text: 'Editar', function: () => {ClickBtnEditLocalCustomer()}, if: client.id_user === null},
    ])"></i>
    
    <div v-if="client" class="pa20 copyable">

        <!-- Datos del cliente y estadisticas básicas -->
        <div>
            <div class="f18 mb20">Datos</div>
            
            <div class="flex mb15" v-if="client.phone">
                <div class="flex-fill">
                    <div class="bold">Teléfono</div>
                    <div>{{client.phone}}</div>
                </div>
                
                <i class="options icon-dots-vertical" @click="Options([
                    {text: 'Llamar', function: () => {Util.Open(`tel:${client.phone}`)}},
                    {text: 'Abrir en Whatsapp', function: () => {Util.OpenWhatsapp(client.phone, '')}},
                    {text: 'Mensaje Predeterminado (Whatsapp)', function: () => {Util.OpenWhatsapp(client.phone, whatsappMessage, {'{name}': client.name})}},
                ])"></i>
            </div>
            <div class="flex mb15" v-if="client.rut">
                <div class="flex-fill">
                    <div class="bold">RUT</div>
                    <div>{{ Util.Rut(client.rut) }}</div>
                </div>
            </div>
            <div class="mb15 flex" v-if="client.address">
                <div class="flex-fill">
                    <div class="bold">Dirección</div>
                    <div>{{client.address}}</div>
                </div>
                
                <i class="options icon-dots-vertical" @click="Options([
                    {text: 'Buscar en Waze', function: () => {Util.OpenWaze(address)}, if: mobile},
                    {text: 'Buscar en Google Maps', function: () => {Util.Open(`http://maps.google.co.in/maps?q=${client.address}`)}}
                ])"></i>
            </div>

            <div class="mb15" v-if="client.email">
                <div class="bold">Email</div>
                <div>{{client.email}}</div>
            </div>

            
            <div class="mb15">
                <div class="bold">Total Gastado</div>
                <div>{{Util.Price(client.total_money)}}</div>
            </div>
            
            <div class="flex mb15">
                <div class="flex-fill">
                    <div class="bold">Transacciones</div>
                    <div>{{client.transactions.length}}</div>
                </div>
                
                <button class="secondary" @click="$refs.transactionsList.Open(client.transactions)">Ver Transacciones</button>
            </div>
            

            <div class="flex mb15">
                <div class="" v-if="client.first_transaction_at">
                    <div class="bold">Primera Compra</div>
                    <div>{{Util.Date(client.first_transaction_at, 'LL')}}</div>
                </div>
                <div class="ml20" v-if="client.last_transaction_at && client.last_transaction_at !== client.first_transaction_at">
                    <div class="bold">Última Compra</div>
                    <div>{{Util.Date(client.last_transaction_at, 'LL')}}</div>
                </div>
            </div>

            
            <div class="mb15" v-if="client.transactions.length">
                <div class="bold">Promedio de Compra</div>
                <div>{{Util.Price(parseInt(client.total_money/client.transactions.length))}}</div>
            </div>

            <div class="" v-if="client.first_transaction_at && client.last_transaction_at !== client.first_transaction_at">
                <div class="bold">Antigüedad</div>
                <div>{{Util.SecondsToTime(client.last_transaction_at - client.first_transaction_at, 'SINGLE')}}</div>
            </div>
            

        </div>

        <!-- Gráficos de estadísticas -->
        <div>
            
            <div class="separator mb20 mt20"></div>

            <div class="f18 mb20">Preferencias</div>

            <!-- Gráfico de preferencias de items -->
            <div class="bold">Items</div>
            <chart class="mb20" v-if="items"
            :data="[items]"
            dataset="count"
            type="pie"
            :singleDataset="false"
            :maxDatasets="6"
            :showLegend="true"
            :xLabelFormat="null"
            :yLabelFormat="null"
            :label="(data) => {return data.data}"
            :tooltip="(data, label) => {return label + ': ' + data}"
            height="200px">
            </chart>

            <!-- Gráfico de preferencias de horarios -->
            <div class="bold">Horas</div>
            <chart class="mb20" v-if="hours"
            :data="[hours]"
            dataset="count"
            type="pie"
            :singleDataset="false"
            :showLegend="true"
            :xLabelFormat="null"
            :yLabelFormat="null"
            :label="(data) => {return data.data}"
            :tooltip="(data, label) => {return label + ': ' + data}"
            height="200px"
            :customColor="[null, null]"
            :keyColors="Util.Chart.hours">
            </chart>

            <!-- Gráfico de preferencias de dias -->
            <div class="bold">Días</div>
            <chart class="mb20" v-if="days"
            :data="[days]"
            dataset="count"
            type="pie"
            :singleDataset="false"
            :showLegend="true"
            :xLabelFormat="null"
            :yLabelFormat="null"
            :label="(data) => {return data.data}"
            :tooltip="(data, label) => {return label + ': ' + data}"
            height="200px"
            :customColor="[null, null]"
            :keyColors="Util.Chart.days">
            </chart>

            <!-- Gráfico de preferencias de metodos de pago -->
            <div class="bold">Método de Pago</div>
            <chart class="mb20" v-if="methods"
            :data="[methods]"
            dataset="count"
            type="pie"
            :singleDataset="false"
            :maxDatasets="7"
            :showLegend="true"
            :xLabelFormat="null"
            :yLabelFormat="null"
            :label="(data) => {return data.data}"
            :tooltip="(data, label) => {return label + ': ' + data}"
            height="200px">
            </chart>

            <!-- Gráfico de preferencias de tipo venta (servir, delivery...) -->
            <div class="bold">Tipo de Venta</div>
            <chart class="" v-if="services"
            :data="[services]"
            dataset="count"
            type="pie"
            :singleDataset="false"
            :maxDatasets="7"
            :showLegend="true"
            :xLabelFormat="null"
            :yLabelFormat="null"
            :label="(data) => {return data.data}"
            :tooltip="(data, label) => {return label + ': ' + data}"
            height="200px"
            :customColor="[null, null]"
            :keyColors="Util.Chart.orderType">
            </chart>

        </div>
            
    </div>

    <!-- Modal de lista de transacciones -->
    <transactions-list ref="transactionsList"></transactions-list>
    
    <!-- Modal para editar un cliente -->
    <modal v-model="modalLocalCustomer" :dir="['right', 'center']" :modalClass="['fill', 'sm']" :title="modalLocalCustomer ? 'Editar Cliente' : ''">
        <div class="section" v-if="modalLocalCustomer">

            <input-field class="mb15"
            v-model="modalLocalCustomer.newPhone"
            label="Teléfono">
            </input-field>

            <input-field class="mb15"
            v-model="modalLocalCustomer.name"
            label="Nombre">
            </input-field>

            <input-field class="mb15"
            v-model="modalLocalCustomer.rut"
            label="RUT"
            placeholder="(Opcional)">
            </input-field>

            <input-field class="mb15"
            v-model="modalLocalCustomer.address"
            label="Dirección"
            placeholder="(Opcional)">
            </input-field>

            <input-field
            v-model="modalLocalCustomer.email"
            label="Email"
            placeholder="(Opcional)">
            </input-field>
        </div>

        <button slot="actions" @click="EditLocalCustomer()" class="primary purple">Editar</button>
        
    </modal>

    <!-- Modal para enviar mensaje por whatsapp -->
    <modal v-model="modalWhatsappMessage" dir="up" maxSize="80vh" desktopHeight="auto" desktopWidth="50vw">
        <div class="pa10">
            <div class="secondary-text mb5">La palabra {name} será remplazada por el nombre del cliente (incluyendo las llaves).</div>
            <input-field
            v-model="whatsappMessage"
            :lines="10">
            </input-field>
        </div>
        <div class="modal-actions">
            <button class="selected-color outline" @click="SendWhatsappMessage()">Enviar</button>
        </div>
    </modal>

</modal>
</template>

<script>
export default {
    data() {
        return {
            //bool de estado de este modal
            modal: false,
            //datos del cliente
            client: null,
            coupons: null,
            //estado del modal para agregar puntos
            modalAddPoints: null,
            //estado del modal para editar un cliente
            modalLocalCustomer: null,
            //estado del modal para enviar un mensaje por whatsapp
            modalWhatsappMessage: null,
            //String que almacena el mensaje de whatsapp
            whatsappMessage: '',
            //Menu del local (para el envio de cupones de descuento)
            menu: null,
        }
    },
    methods: {
        /*
        Descarga los datos y abre el modal de detalles de un cliente
        @param client id del cliente
        */
        Open(client) {
            this.$root.$emit('Loading');
            axios.get(`/customers/${this.Local.id}/${client}`).then(res => {
                this.$root.$emit('Response', res);
                console.log(res.data);
                this.client = res.data;
                this.coupons = null;
                if(res.data.default_message) this.whatsappMessage = res.data.default_message;
                this.modal= true;
            }).catch(err => {
                this.$root.$emit('Response', err);
            });
        },
        /*
        Edita un cliente (solo no registrados en Influye)
        */
        EditLocalCustomer()
        {
            this.Loading();
            axios.patch(`/customer/${this.Local.id}/${this.modalLocalCustomer.phone}`, this.modalLocalCustomer).then(res => {
                this.Response(res);
                console.log(res.data);
                this.modalLocalCustomer = null;
                this.client = res.data;
                this.$emit('OnCustomerEdit', res.data);
            }).catch(err => {
                this.Response(err);
            });
        },
        /*
        Elimina un cliente (solo no registrados en Influye)
        */
        DeleteLocalCustomer(phone)
        {
            this.Loading();
            axios.delete(`/customer/${this.Local.id}/${phone}`).then(res => {
                this.client = null;
                this.modal = null;
                this.Info(`El cliente ha sido eliminado`);
                this.$emit('OnCustomerDeleted', res.data);
            }).catch(err => {
                this.Response(err);
            });
        },
        /*
        Abre una ventana nueva con la URL de la api de whatsapp para enviar un mensaje al número de telefono del cliente y el texto ingresado
        */
        SendWhatsappMessage()
        {
            this.Util.OpenWhatsapp(this.modalWhatsappMessage.phone, this.whatsappMessage, {'{name}': this.modalWhatsappMessage.name});
            this.modalWhatsappMessage = null;
        },
        ClickBtnEditLocalCustomer(){
            this.modalLocalCustomer = Util.Copy(this.client, {newPhone: this.client.phone})
            this.modalLocalCustomer.rut = Util.Rut(this.modalLocalCustomer.rut)
        }
    },
    computed: {
        //Stats de items del cliente en formato para mostrarlas en los graficos
        items() {
            if(!this.client) return null;
            var ret = [];
            for(var key in this.client.items)
            {
                ret.push({data: key, count: this.client.items[key].count});
            }
            return ret.length ? ret.sort((a, b) => {return b.count - a.count}) : null;
        },
        //Stats de horarios del cliente en formato para mostrarlas en los graficos
        hours() {
            if(!this.client) return null;
            var ret = [];
            for(var key in this.client.hours)
            {
                ret.push({data: key + ':00 hrs', count: this.client.hours[key]});
            }
            return ret.length ? ret.sort((a, b) => {return b.count - a.count}) : null;
        },
        //Stats de dias del cliente en formato para mostrarlas en los graficos
        days() {
            if(!this.client) return null;
            var ret = [];
            for(var key in this.client.days)
            {
                ret.push({data: Util.DaysArrEs[parseInt(key)-1 < 0 ? 6 : parseInt(key)-1], count: this.client.days[key]});
            }
            return ret.length ? ret.sort((a, b) => {return b.count - a.count}) : null;
        },
        //Stats de tipo de venta del cliente en formato para mostrarlas en los graficos
        services() {
            if(!this.client) return null;
            var ret = [];
            for(var key in this.client.services)
            {
                ret.push({data: Util.SaleType[key], count: this.client.services[key]});
            }
            return ret.length ? ret.sort((a, b) => {return b.count - a.count}) : null;
        },
        //Stats de metodos de pago del cliente en formato para mostrarlas en los graficos
        methods() {
            if(!this.client) return null;
            var ret = [];
            for(var key in this.client.methods)
            {
                ret.push({data: Util.PayMethod(key), count: this.client.methods[key].count});
            }
            return ret.length ? ret.sort((a, b) => {return b.count - a.count}) : null;
        },
    }
}
</script>

<style>
.coupon {
    display: block;
    max-width: 400px;
    width: 100%;
    max-height: 100px;
    border-radius: 3px;
    margin-left: auto;
    margin-right: auto;
}
</style>